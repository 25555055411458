BaseComponent = require '../../BaseComponent'
classnames = require 'classnames'
config = require 'root/config'

TagsActionCreator = require 'actions/TagsActionCreator'
TagTargetActionCreator = require 'actions/TagTargetActionCreator'
CRMInfoModuleActionCreator = require('actions/CRMInfoModuleActionCreator').default
TagsConversationActionCreator = require 'actions/TagsConversationActionCreator'

TagsStore = require 'stores/TagsStore'
CRMInfoModuleStore = require 'stores/CRMInfoModuleStore'
CustomerOrdersStore = require 'stores/CustomerOrdersStore'
TagsConversationStore = require 'stores/TagsConversationStore'
ConversationItemsStore = require 'stores/ConversationItemsStore'

Button = require '../../shared/Button'
Select = require '../../shared/Select'
Tag = require './../TagsWarningComponent/Tag'
Checkbox = require 'components/shared/Checkbox'

tagsConstants = require 'constants/Tags'
CONVERSATION_STATUS = require 'constants/ConversationStatuses'
{ TAG_DASHBOARD, TAGS_FOR_DASHBOARD, TAG_NO_NEED } = require 'constants/conversations'
GROUP_DASHBOARD_IG = 149

# crminfo = require '@verdaccio/crminfo'
# { DropdownSelect } = crminfo.controls

styles = require './../TagsWarningComponent/tags-warning-component'

class TagsWarningDashboardComponent extends BaseComponent
  constructor: (props) ->
    super(props)

    @state =
      tags: []
      error: null
      loading: false
      disabledDashboard: false
      btnDisabled: true
      perPage: 30
      orders: null
      ordersLoading: false
      conversationGroups: []
      conversationGroupsLoading: false
      group: null
      order:
        label: 'Select order'
        value: []
    @initComponent()

  dependsOnStores: [TagsStore, TagsConversationStore]

  getState: ->
    orderList = null

    if TagsStore.conversationTagsWithGroup.length > 0
      orderList = TagsStore.conversationTagsWithGroup.map (order) =>
        label: order.name
        value: {...order}
    
    loading = Object.keys TagsStore.conversationTagTargetLoading

    ordersLoading: TagsStore.ordersLoading
    conversationGroupsLoading: TagsStore.conversationGroupsLoading
    group: TagsStore.conversationGroups[0]
    orders: orderList
    loading: loading.length > 0


  componentDidUpdate: (prevProps, prevState) =>
    # console.log '@state.ordersLoading: ', @state.ordersLoading
    # console.log 'prevState.ordersLoading: ', prevState.ordersLoading
    # console.log '@state.loading: ', @state.loading
    # console.log 'prevState.loading: ', prevState.loading
    # console.log '@props.data.hasOrder: ', @props.data.hasOrder
    # console.log '====='

    if !prevState.group && @state.group
      if @props.data.hasOrder
        tags = TagsStore.conversationGroups.filter((t) -> t.name == TAG_DASHBOARD).concat(TAGS_FOR_DASHBOARD)
      else
        tags = TAGS_FOR_DASHBOARD

      @setState
        tags: tags


    if prevState.ordersLoading && !@state.ordersLoading || prevState.loading && !@state.loading || !prevState.loading && @state.loading
      checkedOrder = []
      orderList = TagsStore.conversationTagsWithGroup.map (o) =>
        if !!o.checked
          checkedOrder = checkedOrder.concat(o.name)

      if checkedOrder.length > 0
        order =
          label: checkedOrder.join(', ')
          value: checkedOrder
      else
        order =
          label: 'Select order'
          value: []

      @setState
        order: order

  requestData: =>
    _.defer(TagsConversationActionCreator.getPreselectedTagsForConversation)
    setTimeout(
      =>
        TagsActionCreator.getTagsForOrderGroup(
          TagsStore.conversationGroups[0]?.id
          @props.data.conversationId
        )
      5
    )
    setTimeout(
      =>
        TagsActionCreator.getGeneralList({
          search_query: TAG_DASHBOARD
          type: tagsConstants.CONVERSATION_TAGS_TYPE
          page: 0
          per_page: @state.perPage
          target_id: @props.data.conversationId
        })
      10
    )

  optionRenderer: (props) =>
    checked = props.data.value.checked

    React.createElement("div", {"className": (styles.tag_item), "onClick": (() => @onOrderTagSelected(props.data))},
      React.createElement(Checkbox, { \
        "checked": (checked)
      }),
      React.createElement("span", null, ( props.data.label ))
    ) 

  valueRenderer: (props) =>
    React.createElement("span", null, ( props.data.label ))

  getTitleText: =>
    'Please don\'t forget to add #dashboard_submit before closing the chat'

  onOrderTagSelected: (tag) =>
    return null if !@state.orders?.length
    { order } = @state
    group = @state.group

    if tag.value.checked
      TagTargetActionCreator.destroyOrder(
        tag.value
        tagsConstants.CONVERSATION_TAGS_TYPE
        @props.data.conversationId
      )
      if @state.order.value.length == 1
        @setState
          loading: TagsStore.conversationTagTargetLoading[tag.label]
          btnDisabled: true

    else
      TagTargetActionCreator.createOrder(
        tag.label
        @props.data.conversationId
        @state.group.id
        tagsConstants.CONVERSATION_TAGS_TYPE
      )
      @setState
        loading: TagsStore.conversationTagTargetLoading[tag.label]
        btnDisabled: false

  onChange: (tag) =>
    btnDisabledStatus = false

    if tag.name == TAG_DASHBOARD && !tag.checked
      if CRMInfoModuleStore.currentCustomer &&  !@state.orders
        customer = CRMInfoModuleStore.currentCustomer.crm_ids[0]
        CRMInfoModuleActionCreator.getWithTags(
          customer
          config.urls.customers.ordersWithTags(customer, TAG_DASHBOARD)
        )

      if @state.order.value.length == 0
        btnDisabledStatus = true

    # unselect all orders
    if tag.name == TAG_DASHBOARD && !!tag.checked
      TagsStore.conversationTagsWithGroup.some (t) =>
        if @state.order.value.indexOf(t.name) > -1
          tag =
            label: t.name
            value: {...t}
          @onOrderTagSelected tag

    newTagsState = @state.tags.map (t) ->
      if tag.name == t.name
        {...t, checked: !t.checked}
      else
        {...t, checked: false}

    isBtnDisabled = newTagsState.find (t) -> !!t.checked
    if !isBtnDisabled
      btnDisabledStatus = true

    @setState
      tags: newTagsState
      btnDisabled: btnDisabledStatus

  onClose: =>
    { closeModal } = @props.data

    if closeModal
      @props.data.updateConversationStatus(CONVERSATION_STATUS.CLOSED)
      @props.onClick()
    else if @state.tags.some((tag) -> tag.checked && tag.name == TAG_NO_NEED)
      @setState disabledDashboard: true
      @props.data.onClick()
    else if @state.tags.some((tag) -> tag.checked)
      @props.data.updateConversationStatus(CONVERSATION_STATUS.CLOSED)
      @props.onClick()
    else
      @props.onClick()

  renderTags: ->
    return false if !@state.tags.length
    { btnDisabled, orders, tags, order, } = @state
    isCheckedDashboard = tags.find (t) -> t.name == TAG_DASHBOARD && t.checked
    isDisabledSelect = !orders?.length || !isCheckedDashboard

    (React.createElement("div", null,
      (tags.map (tag) =>
        disabled = tag.name == TAG_NO_NEED && tags.find((tag) -> tag?.checked)?.name == TAG_DASHBOARD

        React.createElement("div", {"key": ("#{tag.id}#{tag.name}")},
          React.createElement(Tag, { \
            "tag": (tag),  \
            "onClick": (@onChange),  \
            "loading": (TagsConversationStore.tagsLoadings[tag.id]),  \
            "selected": (tag.checked),  \
            "disabled": (disabled)
          }),
          (if tag.name == TAG_DASHBOARD
            React.createElement(Select.default, { \
              "className": (classnames(styles.component_selector, { [styles.disabled]: isDisabledSelect })),  \
              "backspaceRemovesValue": (false),  \
              "name": 'admin-components-type',  \
              "value": (order),  \
              "options": (orders),  \
              "onChange": (@onOrderTagSelected),  \
              "isDisabled": (isDisabledSelect),  \
              "components": ({
                Option: @optionRenderer
                SingleValue: @valueRenderer
              })
            })
          )
        )
      )
    ))
  
  render: =>
    { isDashboard, closeModal } = @props.data
    { btnDisabled, order } = @state
    
    title = 'Got it'

    if closeModal
      title = 'Close chat'

    @state.tags.some (tag) ->
      if tag.checked && tag.name == TAG_DASHBOARD
        title = 'Close chat'

    React.createElement("div", {"className": (styles.container)},
      React.createElement("img", { \
        "src": (require './images/hash-tags-notification.svg'),  \
        "className": (styles.icon)
      }),
      React.createElement("p", {"className": (styles.text)},
        (@getTitleText())
      ),

      (@renderTags()),

      React.createElement(Button, { \
        "title": (title),  \
        "onClick": (@onClose),  \
        "disabled": (@state.loading || btnDisabled),  \
        "className": (styles.button)
      })
    )

module.exports = TagsWarningDashboardComponent
