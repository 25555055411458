import React, { useState, useEffect } from 'react';

import { Header, Body, CollapsedBox } from './components';

import styles from './index.styl';

const QAInfoItem = ({ message, isEditMode, isAllUnfolds }) => {
  const massageText = isEditMode ? message.text : (message[0].info.target_text || 'message text');

  const [isOpen, toggleOpened] = useState(isEditMode ? true : false);
  const [innerEditMode, toggleInnerEditMode] = useState(false);

  const onToggleOpened = () => {
    toggleOpened(!isOpen);
  }

  const onEditMode = () => {
    toggleOpened(true);
    toggleInnerEditMode(true);
  }

  const offEditMode = () => {
    toggleOpened(false);
    toggleInnerEditMode(false);
  }

  useEffect(() => {
    if (isAllUnfolds === undefined) return;
  
    toggleOpened(isAllUnfolds);
  }, [isAllUnfolds]);

  return (
    <div className={styles.qa_info_item}>
      <Header
          massageText={massageText}
          isEditMode={isEditMode}
          onToggleOpened={onToggleOpened}
          message={message}
          isOpen={isOpen}
          onEditMode={onEditMode}
          innerEditMode={innerEditMode}
        />
      <CollapsedBox isOpen={isOpen}>
        <Body isAllUnfolds={isAllUnfolds} message={message} isEditMode={isEditMode} innerEditMode={innerEditMode} offEditMode={offEditMode} />
      </CollapsedBox>
    </div>
  );
};

export default QAInfoItem;
