BaseStore = require 'stores/BaseStore'
ActionTypes = require 'constants/ActionTypes'
AuthStore = require './AuthStore'

class RankingRulesStore extends BaseStore
  constructor: ->
    super()
    @loading = false
    @rankingRules = []
    @currentConversation = []
    @currentConversationId = null
    @currentMessage = null
    @total = 0
    @error = null
    @success = ''
    @registerActions()
    @reportCount = 0

  _registerToActions: ({ type, payload }) =>
    actions = @getActions()
    actions[type].call(@, payload) if actions[type]

  getActions: =>
    "#{ActionTypes.RANKING_RULES_REQUESTING}": ->
      @loading = true
      @error = null
      @emitChange()
    "#{ActionTypes.GET_RANKING_RULES_SUCCESS}": @onLoadRankingRules
    "#{ActionTypes.UPDATE_RANKING_RULE_SUCCESS}": @onUpdateRankingRules
    "#{ActionTypes.DELETE_RANKING_RULE_SUCCESS}": @onDeleteRankingRules

    "#{ActionTypes.RANKING_RULES_OPEN_CONVERSATION}": (payload) ->
      @currentConversation = payload
      @currentConversationId = payload?.id
      @currentMessage = null
      @emitChange()

    "#{ActionTypes.RANKING_RULES_CONVERSATIONS_SUCCESS}": (payload) ->
      @loading = false
      @messages = payload.messages
      @calcReportsCount()
      @emitChange()

    "#{ActionTypes.RANKING_CONVERSATIONS_MESSAGE_ADD_REPORTS}": (payload) ->
      { messageId, messageType, reports } = payload
      if @messages[messageType] && @messages[messageType][messageId]
        delete @messages[messageType][messageId]
      
      @messages = Object.assign(
          {},
          @messages,
          {
            [messageType]: Object.assign({}, @messages[messageType], { [messageId]: reports })
          }
      )
      @currentMessage = null
      @calcReportsCount()
      @emitChange()

    "#{ActionTypes.RANKING_CONVERSATIONS_MESSAGE_DELETE_REPORT}": (payload) ->
      { messageId, messageType, reportId } = payload
      messageReports = @messages[messageType][messageId]
      updateMessagReports = messageReports.filter((report) -> report.id != reportId)
      if updateMessagReports.length == 0
        delete @messages[messageType][messageId]
      else
        @messages = Object.assign(
            {},
            @messages,
            {
              [messageType]: Object.assign({}, @messages[messageType], {[messageId]: updateMessagReports})
            }
        )
      @calcReportsCount()
      @emitChange()

    "#{ActionTypes.RANKING_CONVERSATIONS_MESSAGE_DELETE_REPORTS}": (payload) ->
      { messageId, messageType } = payload
      delete @messages[messageType][messageId]
      @calcReportsCount()
      @emitChange()
      
    "#{ActionTypes.RANKING_RULES_CONVERSATIONS_REQUESTING}": (payload) ->
      @loading = true

    "#{ActionTypes.RANKING_RULES_SELECT_MESSAGE}": (payload) ->
      if @currentMessage?.target_id == payload.message.id
        @currentMessage = null
      else
        if payload.message.type == 'message'
          text = payload.message.text
        else
          text = payload.text
        @currentMessage =
          target_id: payload.message.id
          target_type: payload.message.type
          text: text
          created_at: payload.message.created_at
          nativeText: payload.message.text
      @emitChange()

  onUpdateRankingRules: (payload) ->
    @loading = false
    objIndex = @rankingRules.findIndex (o) -> o.id == payload.id
    @rankingRules[objIndex] = payload
    @error = null
    @emitChange()

  onLoadRankingRules: (payload) ->
    @loading = false
    @rankingRules = payload?.ranking_rules
    @total = payload?.total
    @error = null
    @emitChange()

  onDeleteRankingRules: (id) ->
    @loading = false
    @rankingRules = @rankingRules.filter (rule) -> rule.id != id
    @total = payload?.total - 1
    @error = null
    @emitChange()
  
  calcReportsCount: () ->
    @reportCount = Object.values(@messages).reduce((prev, next) -> 
      prev = prev + Object.values(next).reduce((__prev, __next) -> 
          __prev = __prev + __next.length
          return __prev
      , 0);
      return prev 
    , 0);

module.exports = new RankingRulesStore()
