moment = require 'moment'

AbstractStatisticComponent = require '../AbstractStatisticComponent'
{ formatFromSecondsToTime,
 formatNumberToMoney,
 formatNumberToPercent } = require 'lib/utils'
styles = require 'components/shared/BaseAgentStatisticComponent/base-agent-statistic'


class TLMonthlyStatisticComponent extends AbstractStatisticComponent

  constructor: (props) ->
    super(props)
    @tableFields =
      date:
        label: 'Month'
        presenter: (value) -> 
          moment(value, 'YYYY-MM-DD').format 'DD MMM YYYY'
        handlers: {}
      shifts_count:
        label: '# Shifts'
        presenter: (value, shift) ->
          "#{value - shift.second_shifts_count}/#{value}"
        handlers: {}
      average_response_time:
        label: 'Average Response Time'
        presenter: (value) -> value
        handlers: {}
      average_waiting_time:
        label: 'Average Wait Time'
        presenter: (value) -> value
        handlers: {}
      csr_average_response_time:
        label: 'Average Response Time (CSR)'
        presenter: (value) -> value
        handlers: {}
      csr_average_waiting_time:
        label: 'Average Wait Time (CSR)'
        presenter: (value) -> value
        handlers: {}
      sales_average_response_time:
        label: 'Average Response Time (Sales)'
        presenter: (value) -> value
        handlers: {}
      sales_average_waiting_time:
        label: 'Average Wait Time (Sales)'
        presenter: (value) -> value
        handlers: {}
      average_good_rt:
        label: 'Average % of Chats with Good RT'
        presenter: (value) -> formatNumberToPercent value
        handlers: {}
      average_good_wt:
        label: 'Average % of Chats with Good WT'
        presenter: (value) -> formatNumberToPercent value
        handlers: {}
      striked_percent:
        label: '% strikes'
        presenter: (value) -> formatNumberToPercent value
        handlers: {}
      salary:
        label: 'Salary'
        presenter: (value) -> formatNumberToMoney value
        handlers: {}
      approved_bonus:
        label: 'Approved Bonus'
        presenter: (value) -> formatNumberToMoney value
        handlers: {}
      total:
        label: 'Total'
        presenter: (value) -> formatNumberToMoney value
        handlers: {}
    @type = 'monthly'
    @initComponent()

module.exports = TLMonthlyStatisticComponent
