classnames = require 'classnames'
styles = require './button'

propTypes = require 'prop-types'

button = (props) ->
  React.createElement("button", {  \
    "className": (classnames(styles.button, props.className,
      "#{styles.button_white}": props.white
      "#{styles.button_red}": props.red
      "#{styles.button_disabled}": props.disabled
    )),  \
    "onClick": (props.onClick if props.onClick && !props.disabled),  \
    "type": (props.type if props.type),  \
    "data-event": (props.dataEvent),  \
    "disabled": (props.disabled)
  },
    (props.title)
  )

button.propTypes = 
  title: propTypes.string.isRequired
  className: propTypes.string
  disabled: propTypes.bool
  white: propTypes.bool
  red: propTypes.bool
  onClick: propTypes.func

module.exports = button
