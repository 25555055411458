{ Fragment } = require 'react';
BaseComponent = require '../../BaseComponent'
moment = require 'moment'
classnames = require 'classnames'

TakeNewButtonComponent = require './TakeNewButtonComponent'
ConversationsActionCreator = require 'actions/ConversationsActionCreator'
AnomalyActionCreator = require 'actions/AnomalyActionCreator'
AuthStore = require 'stores/AuthStore'
SettingsStore = require 'stores/SettingsStore'
ConversationsUnassignedStore = require 'stores/ConversationsUnassignedStore'
ConversationsMyStore = require 'stores/ConversationsMyStore'
{ isGeekly, isMyNerdify } = require 'root/config'
ShiftAgentsStore = require 'stores/ShiftAgentsStore'
ProfileStore = require 'stores/ProfileStore'
ChatSuggestionPopup = require('components/notifications/ChatSuggestionPopup').default

{
  TAKE_NEW_BUTTON_ACCESS,
  TAKE_NEW_LEAD_FILTER
} = require '../../../constants/Agents'
DEFAULT_FILTER_TIME = 2
DEFAULT_UC_TIME = 5
DEFAULT_AB_GROUP = 'A'

styles = require './take-new-conversation'

conversationsActionCreator = new ConversationsActionCreator()

class TakeNewConversationComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @state =
      isOpenSuggestionPopup: false
      shift:
        average_response_time: 0
        unique_clients_count: 0
      unassignedCount: 0
    @initComponent()

  dependsOnStores: [ConversationsUnassignedStore, AuthStore, SettingsStore]

  getState: ->
    limit = SettingsStore.values.active_conv_limit || 5
    if AuthStore.user?.order_placing
      limit = SettingsStore.values.order_placing_chat_limit || 5
    mustHave: @calculateMustHave()
    activeConvLimit: limit
    shift: AuthStore.user?.last_shift
    unassignedCount: @unassignedCount()

  componentDidUpdate: ->
    super()
    if !@state.isOpenSuggestionPopup
      @setState
        isOpenSuggestionPopup: true

  unassignedCount: =>
    filterTime = (SettingsStore.values.lead_filter_time || DEFAULT_FILTER_TIME) * 60 * 1000
    if SettingsStore.values.lead_filter_time == 0
      filterTime = 0
    conversations = ConversationsUnassignedStore.activeConversations
    conversations = conversations.filter (c) ->
      if c.customer
        if AuthStore.user.litero
          c.customer.options?.litero
        else
          !c.customer.options || !c.customer.options.litero
      else
        false
    conversations = conversations.filter (c) ->
      if c.customer
        if AuthStore.user.order_placing
          drop_order_tag = (c.tags || []).find (t) ->
            t.name == 'drop_order_placer'
          !drop_order_tag
        else
          true
      else
        false
    conversations = conversations.filter (c) ->
      if c.customer
        if AuthStore.user.mathon
          if AuthStore.user.role == 'sales'
            c.customer.options?.mathon
          else
            true
        else
          !c.customer.options || !c.customer.options.mathon
      else
        false

    if !AuthStore.user.litero
      conversations = conversations.filter (c) ->
        if c.customer
          if c.customer.lead
            if AuthStore.user.role == 'overflow_agent'
              !c.customer.options || c.customer.options.overflow_agent
            else
              !c.customer.options || !c.customer.options.overflow_agent
          else
            AuthStore.user.role != 'overflow_agent'
        else
          false
      if !SettingsStore.values.allow_csr_newbie_agent_to_ai
        conversations = conversations.filter (c) ->
          customer_tag = c.customer.tags.find (t) ->
            t.name == 'nerdychat'
          if customer_tag
            AuthStore.user.role != 'newbie_agent'
          else
            true
      if TAKE_NEW_LEAD_FILTER[AuthStore.user.role]
        conversations = conversations.filter (c) ->
          if !c.customer
            false
          else
            wt = 0
            if c.awaiting_from
              wt = new Date() - Date.parse(c.awaiting_from)
            wt > filterTime || !c.customer.lead
        conversations = conversations.filter (c) ->
          if c.awaiting_from
            wt = new Date() - Date.parse(c.awaiting_from)
            waiting_seconds = AuthStore.user.last_shift?.waiting_seconds
            if waiting_seconds == undefined
              waiting_seconds = DEFAULT_UC_TIME
            wt > waiting_seconds * 1000
          else
            true
      if AuthStore.user.role == 'sales'
        conversations = conversations.filter (c) ->
          if c.customer
            if AuthStore.user.ab_group
              if c.customer.ab_group
                c.customer.lead && c.customer.ab_group == AuthStore.user.ab_group
              else
                c.customer.lead && AuthStore.user.ab_group == DEFAULT_AB_GROUP
            else
              c.customer.lead
          else
            false

    conversations.filter((c) -> !c.reserve && c.status != 'dormant').length

  calculateMustHave: =>
    if AuthStore.user
      AuthStore.user.is_take_new_musthave
    else
      true

  isBusy: =>
    new_count = AuthStore.user.new_conversations_count
    open_count = AuthStore.user.open_conversations_count
    activeConv = new_count + open_count
    if AuthStore.user.role == 'sales'
      return !@isGreen()
    if AuthStore.user.role == 'overflow_agent'
      return false
    activeConv >= @state.activeConvLimit

  isGreen: =>
    if AuthStore.user.role == 'sales'
      conversations = ConversationsUnassignedStore.activeConversations
      lead_conversations_count = conversations
        .filter (c) ->
          if c.customer
            c.customer.lead
          else
            false
        .length
      if lead_conversations_count > 0
        return true
    false

  onTakeNew: =>
    isBusy = @isBusy()
    AnomalyActionCreator.takeNewClick()
    if !isBusy && @state.unassignedCount > 0
      conversationsActionCreator.assignMostWaiting()


  onCloseSuggestionPopup: =>
    localStorage.setItem('suggestionPopup', new Date())
    @setState
      isOpenSuggestionPopup: false

  checkLastShowSuggestionPopup: ->
    time = localStorage.getItem('suggestionPopup')
    if (!time)
      return true

    minutes = SettingsStore?.settings?.chat_suggestions_frequency?.value || 3
    eventTime = moment(time).add(minutes, 'minutes')
    diffTimeCountdown = moment(eventTime).diff(moment())

    if (diffTimeCountdown < 0)
      return true
    return false

  render: ->
    averageRT = @state.shift?.average_response_time || 0
    rt = Math.round(parseFloat(averageRT) * 100 / 60) / 100
    uniqClients = @state.shift?.unique_clients_count || 0
    activeConversations = ConversationsMyStore.activeConversations?.length
    shiftCurrentAgent=ProfileStore?.profile?.id
    isAgentOnBreak = ShiftAgentsStore.agentsWFH?[shiftCurrentAgent]?.break_at

    bonus = 0
    minCountUC = if isGeekly then 28 else 26
    if uniqClients > minCountUC
      bonus = (uniqClients - minCountUC) * 1.5

    openSuggestionPopup = @checkLastShowSuggestionPopup()
    showSuggestionPopup =
      @state.mustHave &&
      @state.unassignedCount > 0 &&
      uniqClients >= 1 &&
      !isAgentOnBreak &&
      SettingsStore?.settings?["enable_chat_suggestions_#{AuthStore.user.role}"]?.value &&
      @state.isOpenSuggestionPopup &&
      openSuggestionPopup

    # console.log 'showSuggestionPopup: ', showSuggestionPopup

    React.createElement(Fragment, null,
      (if isMyNerdify || isGeekly
        React.createElement("div", {"className": (styles.shift_bar)},
          React.createElement("div", {"className": (styles.col)},
            React.createElement("div", {"className": (styles.item)},
              React.createElement("span", null, "Active Chats:"),
              React.createElement("b", {"className": (classnames(styles.cnt,
              "#{styles.danger}": activeConversations <= 3
              "#{styles.success}": activeConversations > 3
              ))},
                (activeConversations)
              )
            ),
            React.createElement("div", {"className": (styles.item)},
              React.createElement("span", null, "Chat Goal:"),
              React.createElement("b", {"className": (styles.cnt)}, "4")
            )
          ),
          React.createElement("div", {"className": (styles.col)})
        )
      ),

      React.createElement("div", null,
        React.createElement("span", {"className": (styles.stats)}, """
          UC: """, React.createElement("strong", null, (uniqClients)),
          React.createElement("span", {"className": (styles.slash)}, " \x2F "), """
          RT: """, React.createElement("strong", null, (rt), " min")
        ),
        React.createElement(TakeNewButtonComponent, { \
          "isBusy": (@isBusy()),  \
          "mustHave": (@state.mustHave),  \
          "isGreen": (@isGreen()),  \
          "unassignedCount": (@state.unassignedCount),  \
          "onClick": (@onTakeNew)
        })
      ),
      (if showSuggestionPopup
        React.createElement(ChatSuggestionPopup, {"onClose": (@onCloseSuggestionPopup)})
      )
    )

module.exports = TakeNewConversationComponent
