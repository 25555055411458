_ = require 'underscore'
classnames = require 'classnames'
uuid = require 'uuid'
browserHistory = require './../../../history'
config = require 'root/config'

BaseComponent = require 'components/BaseComponent'
SettingsActionCreator = require 'actions/SettingsActionCreator'
SettingsStore = require 'stores/SettingsStore'
Button = require 'components/shared/Button'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
SwitchComponent = require 'components/shared/SwitchComponent'
InputCounter = require('components/shared/InputCounter').default
AuthStore = require 'stores/AuthStore'
styles = require './settings.styl'
{ menus } = require 'root/config'
AuthStore = require 'stores/AuthStore'
{ ROLES } = require 'constants/Agents'
IGNORE_FIELDS =
  order_placing_chat_limit: true
  ip_addresses: true
  ip_enable: true
  auto_response: true
  textback_repeat: true
  nerdify_app_chat_status: true
  idle_assign_users: true
  allow_csr_newbie_agent_to_ai: true
  enable_auto_assign: true
  enable_chat_suggestions: true
  assign_chat_limit_agent: true
  assign_chat_limit_newbie_agent: true
  assign_chat_limit_sales: true
  chat_suggestions_frequency: true
  forbidden_words: true
  auto_assign_users: true

accessConfig = {}
menus.forEach (item) ->
  if item.key == 'settings'
    accessConfig =
      toPage: item.accessToPage || null

class SettingsComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @state =
      settings: {}
      loading: false
      errors: {}
      hasErrorAutoAssign: false
    @initComponent()

  componentWillMount: ->
    if accessConfig.toPage
      unless ROLES[AuthStore.user.role].value in accessConfig.toPage
        browserHistory.default.push '/'

  componentDidMount: ->
    @checkErrorAutoAssign()

  dependsOnStores: [SettingsStore]

  getState: ->
    settings: SettingsStore.settings
    loading: SettingsStore.loading
    errors: SettingsStore.errors

  requestData: =>
    setTimeout(
      -> SettingsActionCreator.get()
      0
    )

  onSave: => SettingsActionCreator.update(settings: @state.settings)

  onChange: ({ target: { value } }, settingType) =>
    { settings } = @state
    settings[settingType] = settings[settingType] || {}
    settings[settingType].value = value
    @setState { settings }
    if (settingType == 'active_conv_limit')
      @checkErrorAutoAssign()

  checkErrorAutoAssign: () =>
    { settings } = @state
    hasErrorAutoAssign = false
    ['agent', 'newbie_agent', 'sales'].map (role) =>
      settingType = "assign_chat_limit_#{role}"
      if settings[settingType] && settings[settingType].value > Number.parseInt settings.active_conv_limit?.value
        hasErrorAutoAssign = true
    @setState { hasErrorAutoAssign }

  onCountChange: (value, settingType) =>
    { settings } = @state
    settings[settingType] = settings[settingType] || {}
    settings[settingType].value = value
    @checkErrorAutoAssign()
    @setState { settings }

  onChangeBoolean: (field) =>
    ({target: {checked}}) =>
      { settings } = @state
      settings[field] = settings[field] || {}
      settings[field].value = checked
      @setState { settings }

  onChangeSwicthData: (field) =>
    { settings } = @state
    settings[field] = settings[field] || {}
    settings[field].value = !settings[field].value
    @setState { settings }

  isShowInput: (settingType) =>
    !settingType.includes 'assign_chat_limit'
    !settingType.includes 'order_placing_chat_limit'
    !settingType.includes 'enable_auto_assign'
    !settingType.includes 'ip_enable'
    !settingType.includes 'enable_auto_assign'
    !settingType.includes 'ip_addresses'
    !settingType.includes 'textback_repeat'
    !settingType.includes 'idle_assign_users'
    !settingType.includes 'enable_chat_suggestions'

  filterSettings: (keys) =>
    keys.filter (settingType) =>
      !IGNORE_FIELDS[settingType]

  isShowErrorAutoAssign: (value) =>
    { settings } = @state
    settings.active_conv_limit.value < value

  render: ->
    { onChange, onChangeBoolean, onCountChange } = @
    { settings, errors, loading, hasErrorAutoAssign } = @state
    ip_addresses = settings.ip_addresses?.value || {}
    ip_enable = !!settings.ip_enable?.value
    auto_response = !!settings.auto_response?.value
    order_placing_chat_limit = settings.order_placing_chat_limit?.value || 5
    idle_assign_users = !!settings.idle_assign_users?.value
    allow_csr_newbie_agent_to_ai = !!settings.allow_csr_newbie_agent_to_ai?.value
    enable_auto_assign = !!settings.enable_auto_assign?.value
    enable_chat_suggestions = {}
    ['agent', 'newbie_agent', 'sales'].forEach (role) =>
      enable_chat_suggestions[role] = !!settings["enable_chat_suggestions_#{role}"]?.value
    textback_repeat = !!settings.textback_repeat?.value
    nerdify_app_chat_status = !!settings.nerdify_app_chat_status?.value
    isNotShowAppControl = config.isGeekly || config.isQuizEasy || config.isAwesomeTutors
    
    React.createElement("div", {"className": (styles.container)},
      React.createElement("div", {"className": (styles.title)}, """
        Settings
"""),
      React.createElement(LoadableWrapperComponent, {"loading": (loading)},
        (@filterSettings(_.keys(settings))
          .map (settingType, i) =>
            if (@isShowInput(settingType))
              React.createElement("div", {"key": (i), "className": (styles.input_container)},
                React.createElement("div", null, (settingType.split('_').join(' '))),
                React.createElement("input", { \
                  "type": 'text',  \
                  "name": (settingType),  \
                  "value": (settings[settingType].value),  \
                  "onChange": ((e) -> onChange(e, settingType)),  \
                  "className": (classnames(styles.input, "#{styles.error}": hasErrorAutoAssign && settingType == 'active_conv_limit')),  \
                  "placeholder": (settingType),  \
                  "disabled": (
                    settings[settingType].access &&
                    !settings[settingType].access[AuthStore.user.role]
                  )
                }),
                React.createElement("div", {"className": (styles.error)},
                  (errors[settingType])
                )
              )
        ),
        React.createElement("div", {"className": (styles.input_container_wrap)},
          (!isNotShowAppControl &&
            React.createElement("div", {"key": ('f'), "className": (styles.input_container)},
              React.createElement("label", {"htmlFor": 'nerdify_app_chat_status'}, "Nerdify App 2.0 chat doesn’t work"),
              React.createElement("input", { \
                "className": (styles.checkbox),  \
                "type": 'checkbox',  \
                "name": 'nerdify_app_chat_status',  \
                "onChange": (onChangeBoolean('nerdify_app_chat_status')),  \
                "checked": (nerdify_app_chat_status),  \
                "disabled": (
                  settings.nerdify_app_chat_status?.access? &&
                  !settings.nerdify_app_chat_status.access[AuthStore.user.role]
                )
              })
            )
          ),
          React.createElement("div", {"key": ('order_placing'), "className": (styles.input_container)},
            React.createElement("label", {"htmlFor": 'order_placing_chat_limit'}, "Order placing chat limit"),
            React.createElement("input", { \
              "type": 'text',  \
              "name": ('order_placing_chat_limit'),  \
              "value": (order_placing_chat_limit),  \
              "onChange": ((e) -> onChange(e, 'order_placing_chat_limit')),  \
              "className": (styles.input),  \
              "placeholder": ('order_placing_chat_limit'),  \
              "disabled": (
                settings['order_placing_chat_limit']?.access &&
                !settings['order_placing_chat_limit'].access[AuthStore.user.role]
              )
            })
          ),

          React.createElement("div", {"key": ('b'), "className": (styles.input_container)},
            React.createElement("label", {"htmlFor": 'textback_repeat'}, "Enable textback repeat after 5 mins"),
            React.createElement("input", { \
              "className": (styles.checkbox),  \
              "type": 'checkbox',  \
              "name": 'textback_repeat',  \
              "onChange": (onChangeBoolean('textback_repeat')),  \
              "checked": (textback_repeat),  \
              "disabled": (
                settings.textback_repeat?.access? &&
                !settings.textback_repeat.access[AuthStore.user.role]
              )
            })
          ),
          React.createElement("div", {"key": ('c'), "className": (styles.input_container)},
            React.createElement("label", {"htmlFor": 'auto_response'}, "Auto response enable (be careful!)"),
            React.createElement("input", { \
              "className": (styles.checkbox),  \
              "type": 'checkbox',  \
              "name": 'auto_response',  \
              "onChange": (onChangeBoolean('auto_response')),  \
              "checked": (auto_response),  \
              "disabled": (
                settings.auto_response?.access? &&
                !settings.auto_response.access[AuthStore.user.role]
              )
            })
          ),
          React.createElement("div", {"key": ('d'), "className": (styles.input_container)},
            React.createElement("label", {"htmlFor": 'idle_assign_users'}, "Disable IDLE notifications for agents without chat"),
            React.createElement("input", { \
              "className": (styles.checkbox),  \
              "type": 'checkbox',  \
              "name": 'idle_assign_users',  \
              "onChange": (onChangeBoolean('idle_assign_users')),  \
              "checked": (idle_assign_users),  \
              "disabled": (
                settings.idle_assign_users?.access? &&
                !settings.idle_assign_users.access[AuthStore.user.role]
              )
            })
          ),
          React.createElement("div", {"key": ('g'), "className": (styles.input_container)},
            React.createElement("label", {"htmlFor": 'allow_csr_newbie_agent_to_ai'}, "Allow csr_newbie_agent to process NerdyChat chats"),
            React.createElement("input", { \
              "className": (styles.checkbox),  \
              "type": 'checkbox',  \
              "name": 'allow_csr_newbie_agent_to_ai',  \
              "onChange": (onChangeBoolean('allow_csr_newbie_agent_to_ai')),  \
              "checked": (allow_csr_newbie_agent_to_ai),  \
              "disabled": (
                settings.allow_csr_newbie_agent_to_ai?.access? &&
                !settings.allow_csr_newbie_agent_to_ai.access[AuthStore.user.role]
              )
            })
          )
        ),

        React.createElement("div", {"className": (styles.input_container_wrap)},
          React.createElement("div", {"key": ('l'), "className": (styles.input_container)},
            React.createElement("div", {"key": ('j'), "className": (classnames(styles.title, styles.title__assign))}, """
              Auto Assign
"""),

            React.createElement("div", {"className": (styles.chat_body)},
              React.createElement("div", {"className": (classnames(styles.chat_row, styles.bg_row))},
                React.createElement("div", null, "Auto assign users"),
                React.createElement("textarea", { \
                  "rows": 10,  \
                  "name": ('auto_assign_users'),  \
                  "value": (settings['auto_assign_users']?.value),  \
                  "onChange": ((e) -> onChange(e, 'auto_assign_users')),  \
                  "className": (classnames(styles.input, "#{styles.error}": hasErrorAutoAssign && settingType == 'active_conv_limit')),  \
                  "placeholder": ('email,limit'),  \
                  "disabled": (
                    settings['auto_assign_users'].access &&
                    !settings['auto_assign_users'].access[AuthStore.user.role]
                  )
                })
              )
            ),

            React.createElement("div", {"className": (styles.chat_body)},
              React.createElement("div", {"className": (classnames(styles.chat_row, styles.bg_row))},
                React.createElement("div", null, "Enable auto-assign"),
                React.createElement(SwitchComponent, { \
                  "className": (classnames(styles.switch, "#{styles.active}": enable_auto_assign)),  \
                  "value": (enable_auto_assign),  \
                  "onToggle": (() => @onChangeSwicthData('enable_auto_assign')),  \
                  "disabled": (
                    settings['enable_auto_assign'].access &&
                    !settings['enable_auto_assign'].access[AuthStore.user.role]
                  )
                })
              ),

              (['agent', 'newbie_agent', 'sales'].map (role) =>
                settingType = "assign_chat_limit_#{role}"
                if settings[settingType]
                  React.createElement("div", {"key": (settingType), "className": (styles.chat_row)},
                    React.createElement("div", null, (settingType.split('_').join(' '))),
                    React.createElement(InputCounter, { \
                      "value": (settings[settingType].value),  \
                      "onInputChange": ((e) -> onCountChange(e, settingType)),  \
                      "disabled": (
                        settings[settingType].access &&
                        !settings[settingType].access[AuthStore.user.role]
                      ),  \
                      "hasError": (@isShowErrorAutoAssign(settings[settingType].value))
                    })
                  )
              )
            ),
            (if hasErrorAutoAssign
              React.createElement("p", {"className": (styles.error)}, ('Number can’t be higher than Active conv limit'))
            )
          ),
          React.createElement("div", {"key": ('m'), "className": (styles.input_container)},
            React.createElement("div", {"key": ('k'), "className": (classnames(styles.title, styles.title__assign))}, """
              Auto Suggestions
"""),
            React.createElement("div", {"className": (styles.chat_body)},
              (['agent', 'newbie_agent', 'sales'].map (role) =>
                settingType = "enable_chat_suggestions_#{role}"
                if settings[settingType]
                  React.createElement("div", {"key": (settingType), "className": (classnames(styles.chat_row, styles.bg_row))},
                    React.createElement("div", null, "Enable chat suggeses for ", (role)),
                    React.createElement(SwitchComponent, { \
                      "className": (classnames(styles.switch, "#{styles.active}": enable_chat_suggestions[role])),  \
                      "value": (enable_chat_suggestions[role]),  \
                      "onToggle": (() => @onChangeSwicthData(settingType)),  \
                      "disabled": (
                        settings[settingType].access? &&
                        !settings[settingType].access[AuthStore.user.role]
                      )
                    })
                  )
              ),
              (
                settingType = 'chat_suggestions_frequency'
                if settings[settingType]
                  React.createElement("div", {"key": ('n'), "className": (styles.chat_row)},
                    React.createElement("div", null, (settingType.split('_').join(' ')), " (min 5; max 30) mins"),
                    React.createElement(InputCounter, { \
                      "value": (settings[settingType].value),  \
                      "onInputChange": ((e) -> onCountChange(e, settingType)),  \
                      "disabled": (
                        settings[settingType].access &&
                        !settings[settingType].access[AuthStore.user.role]
                      )
                    })
                  )
              )
            )
          )
        ),
        React.createElement("div", {"key": ('e')},
          React.createElement(Button, { \
            "title": 'Save settings',  \
            "onClick": (@onSave),  \
            "disabled": (hasErrorAutoAssign)
          })
        )
      )
    )

module.exports = SettingsComponent
