BaseStore = require './BaseStore'
Dispatcher = require '../dispatcher/Dispatcher'
ActionTypes = require '../constants/ActionTypes'
config = require 'root/config'

MAP_PROPERTY =
  bundleVersion: 'bundleVersion'
  nerdify_app_chat_status: 'nerdifyAppChatStatus'

class InterfaceStore extends BaseStore
  constructor: ->
    super()
    @burger = no
    @channels = {}
    @customers = {}
    @conversations = {}
    @multiselect = {}
    @bundleVersion = null
    @nerdifyAppChatStatus = false
    @onlyRouteComponent = false

    @modalComponent = null
    @modalComponentData = null

    @lockedModalComponent = null
    @lockedModalComponentData = null

    @popupComponent = null
    @popupComponentData = null

    @_clear()
    @registerActions()

  _clear: =>
    @editing =
      loadMore: {}
      object: undefined
      errors: undefined
      type: undefined

    @multiselect.list = []
    @multiselect.enabled = no

    @assigner =
      opened: false

  _registerToActions: ({ type, payload }) =>
    switch type
      when ActionTypes.MQTT_SYSTEM_INFO_UPDATING
        Object.keys payload.message
          .forEach (key) =>
            @[MAP_PROPERTY[key]] = payload.message[key]
        @emitChange()

      when ActionTypes.SETTINGS_GET_SUCCESS
        @nerdifyAppChatStatus = payload?.nerdify_app_chat_status?.value
        @emitChange()

      # UI

      when ActionTypes.SELECT_TAB
        @_clear()
        @emitChange()

      when ActionTypes.CLOSE_BURGER_MENU
        @burger = false
        @emitChange()

      when ActionTypes.INTERFACE_HIDE_ALL_APP_DETAILS
        @onlyRouteComponent = true
        @emitChange()

      when ActionTypes.MULTISELECT_TOGGLE
        if @multiselect.enabled
          @multiselect.list = []
        @multiselect.enabled = !@multiselect.enabled
        @emitChange()

      when ActionTypes.MULTISELECT_TOGGLE_INDEX
        index = payload
        if _(@multiselect.list).contains index
          @multiselect.list = _(@multiselect.list).without index
        else
          @multiselect.list.push index

        @multiselect.enabled = @multiselect.list.length > 0
        @emitChange()

      when ActionTypes.ASSIGN_SELECTOR_TOGGLE
        @assigner.opened = !@assigner.opened
        @emitChange()

      when ActionTypes.CUSTOMERS_UPDATE_SUCCESS
        @editing.errors = undefined
        @processing = false
        @complete = true
        @emitChange()

      # CHANNELS

      when ActionTypes.CHANNELS_GET_SUCCESS
        @editing.object = JSON.parse(JSON.stringify(payload))
        @editing.type = 'Agent'
        @editing.errors = undefined
        @processing = false
        @complete = true
        @emitChange()

      when ActionTypes.CHANNELS_CREATE_SUCCESS, ActionTypes.CHANNELS_NEW
        @editing.object = {}
        @invitation = false
        @editing.type = 'Channel'
        @editing.errors = undefined
        @processing = false
        @complete = true
        @emitChange()

      when ActionTypes.CHANNELS_UPDATE_SUCCESS, ActionTypes.CUSTOMERS_UPDATE_SUCCESS
        @editing.errors = undefined
        @processing = false
        @complete = true
        @emitChange()

      when ActionTypes.CHANNELS_CREATE_FAILURE, ActionTypes.CHANNELS_UPDATE_FAILURE
        @editing.errors = payload.errors
        @processing = false
        @complete = false
        @emitChange()

      # CUSTOMERS

      when ActionTypes.CUSTOMERS_GET_SUCCESS
        @editing.object = JSON.parse(JSON.stringify(payload))
        @editing.type = 'Customer'
        @editing.errors = undefined
        @processing = false
        @complete = true
        @emitChange()

      when ActionTypes.CUSTOMERS_CREATE_SUCCESS, ActionTypes.CUSTOMERS_NEW
        @editing.object = {}
        @editing.type = 'Customer'
        @editing.errors = undefined
        @processing = false
        @complete = true
        @emitChange()

      when ActionTypes.CUSTOMERS_UPDATE_SUCCESS
        @editing.errors = undefined
        @processing = false
        @complete = true
        @emitChange()

      when ActionTypes.CUSTOMERS_CREATE_FAILURE, ActionTypes.CUSTOMERS_UPDATE_FAILURE
        @editing.errors = payload.errors
        @processing = false
        @complete = false
        @emitChange()

      when ActionTypes.CONVERSATIONS_INIT_LIST
        @multiselect.list = []
        @multiselect.enabled = no
        @emitChange()

      when ActionTypes.INTERFACE_OPEN_MODAL
        @modalComponent = payload.component
        @modalComponentData = payload.data
        @emitChange()
      when ActionTypes.INTERFACE_CLOSE_MODAL
        if @modalComponentData && payload.type == @modalComponentData.type
          @modalComponent = null
          @modalComponentData = null
          @emitChange()

      when ActionTypes.INTERFACE_OPEN_LOCKED_MODAL
        @lockedModalComponent = payload.component
        @lockedModalComponentData = payload.data
        @emitChange()
      when ActionTypes.INTERFACE_CLOSE_LOCKED_MODAL
        @lockedModalComponent = null
        @lockedModalComponentData = null
        @emitChange()

      when ActionTypes.INTERFACE_OPEN_POPUP
        @popupComponent = payload.component
        @popupComponentData = payload.data
        @emitChange()
      when ActionTypes.INTERFACE_CLOSE_POPUP
        if @popupComponentData && payload.type == @popupComponentData.type
          @popupComponent = null
          @popupComponentData = null
          @emitChange()

module.exports = new InterfaceStore()
