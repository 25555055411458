BaseComponent = require 'components/BaseComponent'
HashTags = require 'components/HashTags'
TagsStore = require 'stores/TagsStore'
TagsActionCreator = require 'actions/TagsActionCreator'
TagTargetActionCreator = require 'actions/TagTargetActionCreator'
CRMInfoModuleActionCreator = require('actions/CRMInfoModuleActionCreator').default
CRMInfoModuleStore = require 'stores/CRMInfoModuleStore'
CustomerOrdersStore = require 'stores/CustomerOrdersStore'
tagsConstants = require 'constants/Tags'
SettingsStore = require 'stores/SettingsStore'
styles = require './conversation-tags'
config = require 'root/config'
paginationStyles = require 'components/shared/styles/pagination.styl'

class ConversationTagsComponent extends BaseComponent
  dependsOnStores: [TagsStore, CustomerOrdersStore]

  constructor: (props) ->
    super(props)
    @state =
      hashTagList: false
      perPage: 30
      page: 0
      searchQuery: null
    @initComponent()

  componentWillUnmount: ->
    super()
    TagsStore.orderList = []
    document.removeEventListener('click', @closeHashTagList)

  onIconClick: (e) =>
    return if @state.hashTagList
    TagsActionCreator.getGeneralList({
      type: tagsConstants.CONVERSATION_TAGS_TYPE
      page: @state.page
      per_page: @state.perPage
      target_id: @props.conversationId
      search_query: @state.searchQuery
    })
    document.addEventListener('click', @closeHashTagList)
    @setState({hashTagList: true })

  closeHashTagList: (e) =>
    element = e.target
    if element?.parentNode && element?.parentNode?.classList?.contains(paginationStyles.page)
      return

    outsideContainer = true
    while outsideContainer && element.parentElement
      outsideContainer = false if element.getAttribute('id') == 'hash-tags-component'
      element = element.parentElement

    if outsideContainer
      @setState
        hashTagList: false
        searchQuery: null
      document.removeEventListener('click', @closeHashTagList)

  onGroupSelected: (group) =>
    if group.tags_source == tagsConstants.ORDERS_TAGS_SOURCE
      TagsActionCreator.getTagsForOrderGroup(
        group.id
        @props.conversationId
      )

      if CRMInfoModuleStore.currentCustomer
        data =
          tags: group.name
        customer = CRMInfoModuleStore.currentCustomer.crm_ids[0]
        CRMInfoModuleActionCreator.getWithTags(
          customer
          config.urls.customers.ordersWithTags(customer, group.name.toLowerCase())
        )
    else
      TagsActionCreator.getTagsByGroup(
        group
        @props.conversationId
        0
        @state.perPage
      )
    @setState page: 0

  onBackToGroups: =>
    TagsActionCreator.getGeneralList({
      search_query: @state.searchQuery
      type: tagsConstants.CONVERSATION_TAGS_TYPE
      page: @state.page
      per_page: @state.perPage
      target_id: @props.conversationId
    })

  onTagSelected: (tag, group) =>
    if tag.checked
      if group.tags_source == tagsConstants.ORDERS_TAGS_SOURCE
        TagTargetActionCreator.destroyOrder(
          tag
          tagsConstants.CONVERSATION_TAGS_TYPE
          @props.conversationId
        )
      else
        TagTargetActionCreator.destroy tag, @props.conversationId
    else
      if group.tags_source == tagsConstants.ORDERS_TAGS_SOURCE
        TagTargetActionCreator.createOrder(
          tag.name
          @props.conversationId
          group.id
          tagsConstants.CONVERSATION_TAGS_TYPE
        )
      else
        TagTargetActionCreator.create tag, @props.conversationId

  onPageChange: (selected, group) =>
    if selected != @state.page
      if group.id
        TagsActionCreator.getTagsByGroup(
          group
          @props.conversationId
          selected
          @state.perPage
        )
      else
        TagsActionCreator.getGeneralList({
          search_query: @state.searchQuery
          type: tagsConstants.CONVERSATION_TAGS_TYPE
          page: selected
          per_page: @state.perPage
          target_id: @props.conversationId
        })
      
      @setState page: selected
  onTagsSearch: (searchQuery) =>
    TagsActionCreator.getGeneralList({
      search_query: searchQuery
      type: tagsConstants.CONVERSATION_TAGS_TYPE
      page: 0
      per_page: @state.perPage
      target_id: @props.conversationId
    })
    @setState({searchQuery: searchQuery, page: 0})

  render: ->
    React.createElement("div", {  \
      "onClick": (@onIconClick),  \
      "className": (styles.container)
    },
      React.createElement("i", {"className": (styles.icon)}),
      (if @state.hashTagList
        React.createElement("div", {"id": 'hash-tags-component', "className": (styles.list_container)},
          React.createElement(HashTags, { \
            "groups": (TagsStore.conversationGroups),  \
            "tagsWithoutGroup": (TagsStore.conversationTagsWithoutGroup),  \
            "tagsWithGroup": (TagsStore.conversationTagsWithGroup),  \
            "groupsLoading": (TagsStore.conversationGroupsLoading),  \
            "groupsError": (TagsStore.conversationGroupsError),  \
            "tagsLoading": (TagsStore.conversationTagsLoading),  \
            "tagsError": (TagsStore.conversationTagsError),  \
            "onGroupSelected": (@onGroupSelected),  \
            "onTagSelected": (@onTagSelected),  \
            "page": (@state.page),  \
            "pageCount": (TagsStore.conversationPageCount),  \
            "onPageChange": (@onPageChange),  \
            "onBackToGroups": (@onBackToGroups),  \
            "tagTargetLoading": (TagsStore.conversationTagTargetLoading),  \
            "onSearch": (@onTagsSearch),  \
            "searchQuery": (@state.searchQuery)
          })
        ) 
      ),
      (if @props.withTooltip
        React.createElement("span", {"className": (styles.tooltip)}, "!")
      )
    )


module.exports = ConversationTagsComponent
