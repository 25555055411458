BaseStore = require './BaseStore'
ActionTypes = require '../constants/ActionTypes'

actionsMap =
  "#{ActionTypes.SET_XMPP_ACTION}": (payload) ->
    if @conversationActions[payload.conversationId] && JSON.stringify(@conversationActions[payload.conversationId]) == JSON.stringify({[payload.action]: payload.value}) then return
    
    @conversationActions = Object.assign(
      @conversationActions
      { [payload.conversationId]: { [payload.action]: payload.value } }
    )
    @emitChange()
  "#{ActionTypes.SET_XMPP_ROSTERS}": (payload) ->
    if JSON.stringify(@xmppRosters) == JSON.stringify(payload.rosters) then return

    @xmppRosters = payload.rosters
    @emitChange()
  "#{ActionTypes.SET_OPERATOR_TYPING}": (payload) ->
    @operatorTypingText = payload.text
    @emitChange()
  "#{ActionTypes.SET_OPERATOR_MANUAL_TYPING}": (payload) ->
    if @operatorManualTyping == true && payload.value == true
      @operatorManulQueue = [...@operatorManulQueue, true]

    if @operatorManulQueue.length > 0 && payload.value == false
      @operatorManulQueue = @operatorManulQueue.slice(0, -1)
      return
      
    @operatorManualTyping = payload.value
    @operatorTypingText = ''
    @emitChange()

class XMPPStore extends BaseStore
  constructor: ->
    super()
    @conversationActions = {}
    @xmppRosters = []
    @operatorTypingText = ''
    @operatorManualTyping = false
    @operatorManulQueue = []
    @registerActions()

  _registerToActions: ({ type, payload }) =>
    actionsMap[type].call @, payload if actionsMap[type]

module.exports = new XMPPStore()
