import React from 'react';
import classnames from 'classnames';

import styles from './index.styl';

const CollapsedBox = ({ isOpen, children }) => {
  return (
    <div 
      className={classnames(styles.collapsed_box, {[[styles['collapsed_box--open']]]: isOpen })}>
      {children}
    </div>
  )

};

export default CollapsedBox;
