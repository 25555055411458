BaseStore = require 'stores/BaseStore'
ActionTypes = require 'constants/ActionTypes'
Dispatcher = require 'root/dispatcher/Dispatcher'
{ getToken } = require 'lib/tokenStorage'
config = require 'root/config'
mqtt = require 'mqtt'
mqttMatch = require('mqtt-match')

{ oauthRedirect } = require 'lib/utils'

class MqttStore extends BaseStore
  constructor: ->
    super()
    @options =
      username: 'operator'
      keepalive: 15
      reconnectPeriod: 2000
    @client = null
    @subscriptions = {}
    @initials = {}
    @unsubscribes = {}
    @activeSubscriptions = {}
    @initConfig()
    @registerActions()

  initConfig: =>
    @mqttUrl = config.mqttUrl
    @actionSuffix = ''
    @needInitialSubscribe = true

  initial: (topic) ->
    topic.replace(/\+/g, '')

  initialDispatch: (actionType) ->
    action = actionType + '_INITIAL'
    if ActionTypes[action]
      Dispatcher.dispatch(type: action)

  _registerToActions: ({ type, payload }) =>
    switch type
      when "#{ActionTypes.MQTT_CONNECT}#{@actionSuffix}"
        # TODO: unsubscribe logic
        token = getToken()
        return unless token
        @options.password = token.split(' ')[1]
        return unless @options.password
        @client = mqtt.connect(@mqttUrl, @options)
        @client.on 'error', (err) =>
          console.error(err)
          if err.code && (err.code == 5 || err.code == 4)
            oauthRedirect()
        @client.on 'connect', () =>
          topics = Object.keys(@subscriptions)
          if @needInitialSubscribe
            @client.subscribe("smstool/initials/#{@client.options.clientId}")
          if topics.length > 0
            @client.subscribe topics, (error, granted) =>
              if !error
                granted.forEach ({topic}) =>
                  @activeSubscriptions[topic] ||= {}
                  Object.keys(@subscriptions[topic]).forEach (actionType) =>
                    @activeSubscriptions[topic][actionType] = true
                    if @subscriptions[topic][actionType] == 'initial'
                      @activeSubscriptions[topic][actionType] = 'initial'
                      @initialDispatch(actionType)
                      @client.publish("#{@initial(topic)}/initial/#{@client.options.clientId}")
                  delete @subscriptions[topic]
              else
                console.error(error)
          topics = Object.keys(@unsubscribes)
          if topics.length > 0
            @client.unsubscribe topics, (error) =>
              if !error
                topics.forEach (topic) =>
                  delete @activeSubscriptions[topic]
                  delete @unsubscribes[topic]
              else
                console.error(error)
          if Object.keys(@initials).length > 0
            Object.keys(@initials).forEach (topic) =>
              if @activeSubscriptions[topic]
                actionTypes = Object.keys(@activeSubscriptions[topic])
                actionTypes.forEach (actionType) =>
                  if @activeSubscriptions[topic][actionType] == 'initial'
                    @initialDispatch(actionType)
                @client.publish(@initials[topic])
            @initials = {}

        @client.on 'reconnect', () =>
          @initials = {}
          Object.keys(@activeSubscriptions).forEach (topic) =>
            initials = Object.keys(@activeSubscriptions[topic]).filter (actionType) =>
              @activeSubscriptions[topic][actionType] == 'initial'
            if initials.length > 0
              @initials[topic] = "#{@initial(topic)}/initial/#{@client.options.clientId}"

        @client.on 'message', (topic, message) =>
          message = JSON.parse(message)
          initial = false
          if topic == "smstool/initials/#{@client.options.clientId}"
            topic = message.topic
            message = message.payload
            initial = true
          Object.keys(@activeSubscriptions).forEach (subTopic) =>
            if mqttMatch(subTopic, topic)
              Object.keys(@activeSubscriptions[subTopic]).forEach (actionType) =>
                Dispatcher.dispatch
                  type: actionType
                  payload:
                    topic: topic
                    message: message
                    initial: initial
      when "#{ActionTypes.MQTT_INITIAL}#{@actionSuffix}"
        if @client && @client.connected
          @client.publish("#{@initial(payload.topic)}/initial/#{@client.options.clientId}")
        else
          @initials[payload.topic] = "#{@initial(payload.topic)}/initial/#{@client.options.clientId}"
      when "#{ActionTypes.MQTT_SUBSCRIBE}#{@actionSuffix}"
        if @client && @client.connected
          @client.subscribe payload.topic, (error) =>
            if !error
              @activeSubscriptions[payload.topic] ||= {}
              @activeSubscriptions[payload.topic][payload.actionType] = true
              if payload.initial
                @activeSubscriptions[payload.topic][payload.actionType] = 'initial'
                @initialDispatch(payload.actionType)
                @client.publish("#{@initial(payload.topic)}/initial/#{@client.options.clientId}")
            else
              console.error(error)
        else
          @subscriptions[payload.topic] ||= {}
          if payload.initial
            @subscriptions[payload.topic][payload.actionType] = 'initial'
          else
            @subscriptions[payload.topic][payload.actionType] = true
      when "#{ActionTypes.MQTT_UNSUBSCRIBE}#{@actionSuffix}"
        if @client && @client.connected
          if @activeSubscriptions[payload.topic]
            delete @activeSubscriptions[payload.topic][payload.actionType]
          if Object.keys(@activeSubscriptions[payload.topic] || {}).length == 0
            @client.unsubscribe payload.topic, (error) =>
              if !error
                delete @activeSubscriptions[payload.topic]
              else
                console.error(error)
        else
          @unsubscribes[payload.topic] ||= {}
          @unsubscribes[payload.topic][payload.actionType] = true
      when "#{ActionTypes.MQTT_UNSUBSCRIBE_ANY}#{@actionSuffix}"
        topics = []
        Object.keys(@activeSubscriptions).forEach (topic) =>
          if mqttMatch(payload.topicMask, topic) &&
          payload.topic != topic &&
          @activeSubscriptions[topic][payload.actionType]
            delete @activeSubscriptions[topic][payload.actionType]
            topics.push(topic)
        topics.forEach (topic) =>
          if @client && @client.connected
            if Object.keys(@activeSubscriptions[topic] || {}).length == 0
              @client.unsubscribe topic, (error) =>
                if !error
                  delete @activeSubscriptions[topic]
                else
                  console.error(error)
          else
            @unsubscribes[topic] ||= {}
            @unsubscribes[topic][payload.actionType] = true
      when "#{ActionTypes.MQTT_PUBLISH}#{@actionSuffix}"
        if @client && @client.connected
          topic = payload.topic
          if payload.initial
            topic = "#{topic}/initial/#{@client.options.clientId}"
          if payload.message
            @client.publish(topic, payload.message)
          else
            @client.publish(topic)

module.exports = MqttStore
