{ Link } = require 'react-router-dom'
classnames = require 'classnames'

styles = require './agent-list-item'
BaseComponent = require '../../BaseComponent'
InterfaceStore = require '../../../stores/InterfaceStore'
InterfaceActionCreator = require '../../../actions/InterfaceActionCreator'
{ capitalizeFirstLetter } = require '../../../lib/utils'
AgentListActionCreator = require '../../../actions/AgentListActionCreator'
{ ROLES, ACTIVITIES } = require '../../../constants/Agents'

propTypes = require 'prop-types'

class AgentListItemComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  @propTypes:
    agent: propTypes.object.isRequired
    onClick: propTypes.func
    multiselectEnabled: propTypes.bool
    multiselected: propTypes.bool

  getStatus: =>
    if @props.agent.break_at
      return 'On Breake'
    else
      return capitalizeFirstLetter @props.agent.status || 'offline'

  render: ->
    userImage = null
    if @props.agent.image
      userImage = React.createElement("img", {"className": (styles.agent__image), "src": (@props.agent.image)})
    else
      userImage = React.createElement("img", { \
        "className": (styles.agent__image), "src": (require '!file-loader!../../shared/images/unknown.png'),  \
        "alt": 'User image'
      })
    linkToAgent = "/agents/#{@props.agent.id}/shifts"
    status = @props.agent.status || 'offline'
    React.createElement("tr", {"className": (styles.agent)},
      React.createElement("td", {"className": (classnames styles.agent__row, styles.agent__row_name)},
        React.createElement(Link, {"to": ( linkToAgent ), "className": (styles.agent__link)},
          (userImage),
          React.createElement("p", {"className": (styles.agent__label)},
            ("#{@props.agent.first_name} #{@props.agent.last_name}")
          )
        )
      ),
      React.createElement("td", {"className": (classnames styles.agent__row, styles.agent__row_role)},
        React.createElement(Link, {"to": ( linkToAgent ), "className": (styles.agent__link)},
          React.createElement("p", {"className": (classnames styles.agent__label, styles.agent__label_secondary)},
            ((ROLES[@props.agent.role] || {}).label || ROLES.newbie_agent.label)
          )
        )
      ),
      React.createElement("td", {"className": (classnames styles.agent__row, styles.agent__row_status)},
        React.createElement(Link, {"to": ( linkToAgent ), "className": (styles.agent__link)},
          React.createElement("span", {"className": (classnames styles['agent__status-point'],
            "#{styles['agent__status-point_break']}": @props.agent.break_at
            "#{styles['agent__status-point_online']}": status != 'offline' && !@props.agent.break_at
          )}),
          React.createElement("p", {"className": (classnames styles.agent__label, styles.agent__status, styles.agent__label_secondary)},
            (@getStatus())
          )
        )
      ),
      React.createElement("td", null,
        React.createElement(Link, {"to": ( linkToAgent ), "className": (styles.agent__link)},
          React.createElement("p", {"className": (classnames styles.agent__label, styles.agent__label_secondary)},
            ((ACTIVITIES[@props.agent.activity] || {}).label || ACTIVITIES.wasted.label)
          )
        )
      )
    )

module.exports = AgentListItemComponent
