ActionTypes = require '../constants/ActionTypes'
ChannelsAPI = require '../api/ChannelsAPI'
Dispatcher = require '../dispatcher/Dispatcher'

browserHistory = require './../history'

ChannelsActionCreator =
  resetRole: (data) =>
    Dispatcher.dispatch type: ActionTypes.CHANNELS_GET_LIST_REQUESTING
    ChannelsAPI.resetRole(
      (result) ->
        ChannelsAPI.getList(
          data
          (res) -> Dispatcher.dispatch type: ActionTypes.CHANNELS_GET_LIST_SUCCESS, payload: res,
          (error) -> Dispatcher.dispatch type: ActionTypes.CHANNELS_GET_LIST_FAILURE, payload: error
        )
      (error) -> Dispatcher.dispatch type: ActionTypes.CHANNELS_GET_LIST_FAILURE, payload: error
    )
  getList: (data) =>
    Dispatcher.dispatch type: ActionTypes.CHANNELS_GET_LIST_REQUESTING
    ChannelsAPI.getList(
      data
      (data) -> Dispatcher.dispatch type: ActionTypes.CHANNELS_GET_LIST_SUCCESS, payload: data,
      (error) -> Dispatcher.dispatch type: ActionTypes.CHANNELS_GET_LIST_FAILURE, payload: error
    )

  # GET
  get: (id) =>
    Dispatcher.dispatch type: ActionTypes.CHANNELS_GET_REQUESTING, payload: id
    ChannelsAPI.get id,
      (data) => Dispatcher.dispatch type: ActionTypes.CHANNELS_GET_SUCCESS, payload: data,
      (error) => Dispatcher.dispatch type: ActionTypes.CHANNELS_GET_FAILURE, payload: error

  # CREATE
  create: (attributes) =>
    Dispatcher.dispatch type: ActionTypes.CHANNELS_CREATE_REQUESTING, payload: attributes
    ChannelsAPI.create attributes,
      (data) => Dispatcher.dispatch type: ActionTypes.CHANNELS_CREATE_SUCCESS, payload: data,
      (error) => Dispatcher.dispatch type: ActionTypes.CHANNELS_CREATE_FAILURE, payload: error.responseJSON

  # UPDATE
  update: (id, attributes) =>
    Dispatcher.dispatch type: ActionTypes.CHANNELS_UPDATE_REQUESTING, payload: { id, attributes }
    ChannelsAPI.update { id, attributes },
      (data) => Dispatcher.dispatch type: ActionTypes.CHANNELS_UPDATE_SUCCESS, payload: data,
      (error) => Dispatcher.dispatch type: ActionTypes.CHANNELS_UPDATE_FAILURE, payload: error.responseJSON

  # DESTROY
  batchDestroy: (ids, menu) =>
    Dispatcher.dispatch type: ActionTypes.CHANNELS_BATCH_DESTROY_REQUESTING, payload: { ids, menu }
    ChannelsAPI.batchDestroy { ids, menu },
      (data) =>
        Dispatcher.dispatch
          type: ActionTypes.CHANNELS_BATCH_DESTROY_SUCCESS
          payload: data
        browserHistory.default.push('/')
        browserHistory.default.push('/channels')
      ,
      (error) => Dispatcher.dispatch type: ActionTypes.CHANNELS_BATCH_DESTROY_FAILURE, payload: error.responseJSON

  getPagesCount: (per_page, query) =>
    Dispatcher.dispatch type: ActionTypes.CHANNELS_GET_PAGES_COUNT_REQUESTING, payload: { per_page }
    ChannelsAPI.getPagesCount { per_page, query },
      (data) => Dispatcher.dispatch type: ActionTypes.CHANNELS_GET_PAGES_COUNT_SUCCESS, payload: data,
      (error) => Dispatcher.dispatch type: ActionTypes.CHANNELS_GET_PAGES_COUNT_FAILURE, payload: error

  getForCustomer: (customerId) ->
    Dispatcher.dispatch type: ActionTypes.CHANNELS_GET_LIST_REQUESTING
    ChannelsAPI.getForCustomer(
      customerId
      (json) ->
        if json.success
          Dispatcher.dispatch
            type: ActionTypes.CHANNELS_GET_LIST_SUCCESS
            payload: channels: json.data
        else
          Dispatcher.dispatch
            type: ActionTypes.CHANNELS_GET_LIST_FAILURE
            payload: json.error
      (error) -> Dispatcher.dispatch type: ActionTypes.CHANNELS_GET_LIST_FAILURE, payload: error
    )

module.exports = ChannelsActionCreator
