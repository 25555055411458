{ urls } = require '../config'

RankingRulesAPI =
  getRules: (data, success, error) ->
    $.ajax {
      url: urls.rankingRules.getRules
      contentType: 'application/json'
      type: 'GET'
      data,
      success
      error
    }

  updateRule: (id, data, success, error) ->
    $.ajax {
      url: urls.rankingRules.updateRule(id)
      contentType: 'application/json'
      type: 'PUT'
      data: JSON.stringify(data)
      success
      error
    }

  deleteRule: (id, success, error) ->
    $.ajax {
      contentType: 'application/json'
      url: urls.rankingRules.deleteRule(id)
      type: 'DELETE'
      data: JSON.stringify({ id: id })
      success
      error
    }

  addRule: (data, success, error) ->
    $.ajax {
      url: urls.rankingRules.addRule
      type: 'POST'
      contentType: 'application/json'
      data
      success
      error
    }

  getConversationRankingReports: (id, success, error) ->
    $.ajax {
      url: urls.rankingRules.getConversationRankingReports
      type: 'GET'
      contentType: 'application/json'
      data: { conversation_id: id }
      success
      error
    }

  deleteConversationRankingReport: (id, success, error) ->
    $.ajax {
      url: urls.rankingRules.deleteConversationRankingReport(id)
      type: 'DELETE'
      contentType: 'application/json'
      success
      error
    }

  deleteConversationRankingReports: (data, success, error) ->
    $.ajax {
      url: urls.rankingRules.deleteConversationRankingReports
      type: 'POST'
      data: JSON.stringify(data)
      contentType: 'application/json'
      success
      error
    }
  
  createConversationRankingReports: (data, success, error) ->
    $.ajax {
      url: urls.rankingRules.createConversationRankingReports
      type: 'POST'
      data: JSON.stringify(data)
      contentType: 'application/json'
      success
      error
    }

  getReportLogs: (id, success, error) ->
    $.ajax {
      url: urls.rankingRules.getReportLog(id)
      type: 'GET'
      contentType: 'application/json'
      success
      error
    }

  postConversationEstimate: (data, success, error) ->
    $.ajax
      url: urls.ranking.postConversationEstimate
      type: 'POST'
      contentType: 'application/json'
      data: JSON.stringify(data)
      success: success
      error: error
      timeout: 5000

  getClosedConversations: (data, success, error) ->
    $.ajax {
      url: urls.ranking.getClosedConversations
      type: 'GET'
      contentType: 'application/json'
      data
      success
      error
    }

module.exports = RankingRulesAPI
