Isvg = require 'react-inlinesvg'

Button = require '../Button'
styles = require './condition-modal.styl'

module.exports = (props) =>
  React.createElement("div", {"className": (styles.container)},
   (
    props.data.withIcon &&
      React.createElement("div", null,
        React.createElement(Isvg.default, {"src": (require 'components/shared/images/warning_crm.svg')})
      )
   ),
    React.createElement("p", {"className": (styles.text)},
       (props.data.text || '')
    ),
    React.createElement("div", {"className": (styles.btns_container)},
      React.createElement(Button, { \
        "white": true,  \
        "title": (props.data.cancelButtonTitle || 'Cancel'),  \
        "onClick": (props.data.onCancel),  \
        "className": (styles.button),  \
        "dataEvent": "saveOpen"
      }),
      React.createElement(Button, { \
        "title": (props.data.buttonTitle || 'Got it'),  \
        "onClick": (props.data.onClickAgree),  \
        "className": (styles.button),  \
        "dataEvent": "saveOpen"
      })
    )
  )