moment = require 'moment'
classnames = require 'classnames'
browserHistory = require './../../../history'
uuid = require 'uuid'
deepcopy = require 'deepcopy'
_ = require 'underscore'
copyToClipboard = require 'clipboard-copy'
crminfo = require '@verdaccio/crminfo'
React = require 'react'

styles = require './conversation'
Scroll = require 'components/shared/Scroll/Scroll'
ImagePreviewActionCreator = require 'actions/ImagePreviewActionCreator'
InfoModal = require 'components/shared/InfoModal'
constants = require 'constants/base'
BaseComponent = require 'components/BaseComponent'
AssignerComponent = require 'components/shared/AssignerComponent/AssignerComponent'
ConversationStore = require './ConversationStore'
CONVERSATION_STATUS = require 'constants/ConversationStatuses'
ConversationTemplatesActionCreator = require 'actions/ConversationTemplatesActionCreator'
AgentsActionCreator = require 'actions/AgentsActionCreator'
MessageFormComponent = require 'components/chat/form/MessageFormComponent/MessageFormComponent'
TagsSelectorComponent = require 'components/tags/TagsSelectorComponent/TagsSelectorComponent'
BaseInfoComponent = require 'components/customers/BaseInfoComponent'
ConversationItemUtils = require 'lib/ConversationItemUtils'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
conversationHeaderStyles = require '../ConversationHeaderComponent/conversation-header'
ConversationsActionCreator = require 'actions/ConversationsActionCreator'
ConversationHeaderComponent = require '../ConversationHeaderComponent/ConversationHeaderComponent'
PreviousConversationComponent = require '../PreviousConversationComponent/PreviousConversationComponent'
ConversationItemsListComponent = require '../ConversationItemsListComponent'
MessagesAPI = require 'api/MessagesAPI'
MessageSendConstants = require 'constants/MessageSend'
ConversationStatuses = require 'constants/ConversationStatuses'
{ conversationIsClosed, conversationIsNotClosed } = require 'lib/utils'
{ customerModules } = require 'root/config'
InterfaceActionCreator = require 'actions/InterfaceActionCreator'
PreviousConversationsBlockComponent = require '../PreviousConversationsBlockComponent'
{ ROLES } = require 'constants/Agents'
{ SCROLL_DISABLED } = require 'constants/Agents'
{ menus, isGeekly } = require 'root/config'
AuthStore = require 'stores/AuthStore'
ConversationTemplatesStore = require 'stores/ConversationTemplatesStore'
CRMInfoModuleStore = require 'stores/CRMInfoModuleStore'
CustomerOrdersStore = require 'stores/CustomerOrdersStore'
XMPPStore = require 'stores/XMPPStore'
InterfaceStore = require 'stores/InterfaceStore'
ConversationItemsStore = require 'stores/ConversationItemsStore'
ImagePreviewStore = require 'stores/ImagePreviewStore'
ConversationItemsActionCreator = require 'actions/ConversationItemsActionCreator'
{ calculateTopAndBottomPosition } = require 'lib/conversation-utils'
{ FOLDERS } = require 'constants/conversations'
ScheduledMessagesComponent = require 'components/chat/messages/ScheduledMessagesComponent'
BannerAppNerdyChatStatus = require('components/notifications/BannerAppNerdyChatStatus').default
CRMPluginActionCreator = require('actions/CRMPluginActionCreator').default
CRMInfoModuleActionCreator = require('actions/CRMInfoModuleActionCreator').default

pluginConfigs = require('@verdaccio/crminfo').pluginConfigs


ConversationsActionCreator = new ConversationsActionCreator()
ACTIVE_STATUSES = [
  CONVERSATION_STATUS.NEW_MESSAGE,
  CONVERSATION_STATUS.OPENED,
  CONVERSATION_STATUS.DORMANT
]

accessConfig = menus.reduce(
  (result, item) ->
    if item.key in FOLDERS
      result["#{item.key}"] = item.accessToSend
    result
  {}
)

STATUSES =
  HIDE: 'hide'
  SHOW: 'show'

PIXELS_TO_CHAT_BOTTOM = 150 # how much pixels need scroll up to show new-message-notification instead autoscroll to bottom

class ConversationComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @minMessageBoxHeight = 190
    messageBoxHeight = parseInt(window.localStorage.getItem('messageBoxHeight'), 10) || @minMessageBoxHeight
    @setState = R.bind(@setState, @)
    @isStartScrolled = false
    @state = {
      messageBoxHeight
      foundMessagesPosition: 1
      opened:
        # customerInfo: props.match.params.folder != 'unassigned'
        customerInfo: true
      templateState: {}
    }
    @scrollTop = 0
    @scrollBottom = 0
    @isCRMDataSaved = null
    @isChatWaitForText = false
    @textForChat = ''
    @personalStore = ConversationStore
    @actionCreator = ConversationStore.actionCreator
    @isDisallowScroll = false
    @initComponent()
    @MessageFormComponentRef = React.createRef()

  dependsOnStores: [ConversationItemsStore, ConversationStore, ConversationTemplatesStore, CRMInfoModuleStore, CustomerOrdersStore, ImagePreviewStore, AuthStore, XMPPStore, InterfaceStore]

  getState: ->
    @textPasteToChatFunc ConversationTemplatesStore.state
    assignerOpened = @state.assignerOpened
    {
      assignerOpened
      ready:
        conversationItems: false
        customerInfo: false
    }

  componentWillReceiveProps: (nextProps) ->
    if nextProps.match.params.id != @props.match.params.id
      ConversationItemsActionCreator.clearStore()
      @scrollBottom = 0
      @scrollTop = 0
      @setState(searchEnabled: false)
      @conversationId = parseInt nextProps.match.params.id, 10
      @downloadInitialData @conversationId

  componentWillUnmount: ->
    super()
    ConversationItemsActionCreator.clearStore()

  componentWillMount: ->
    @conversationId = parseInt @props.match.params.id, 10
    @downloadInitialData @conversationId

  getTextPasteFunc: (func) =>
    @textPasteFunc = func
    if @isChatWaitForText
      @pasteTextToChatIfCan(@textForChat)
      @clearOrderCalculatorTemplate()
      @isChatWaitForText = false
      @textForChat = ''

  textPasteToChatFunc: (message) =>
    openedConversation = @getOpenedConversation()
    selectedConversation = @getSelectedConversation()
    if selectedConversation?.status in ACTIVE_STATUSES
      if selectedConversation?.customer?.id == message.clientId
        @pasteTextToChatIfCan(message.template)
        @clearOrderCalculatorTemplate()
    else if openedConversation
      if openedConversation.customer?.id == message.clientId
        R.partial(@gotoConversation, [openedConversation?.id])()
        @textForChat = message.template
        @isChatWaitForText = true
        @clearOrderCalculatorTemplate()
    else if message.clientId == selectedConversation?.customer?.id
      setTimeout(
        () => R.partial(@startConversation, [selectedConversation?.customer?.id])()
        0
      )
      @textForChat = message.template
      @isChatWaitForText = true
      @clearOrderCalculatorTemplate()

  pasteTextToChatIfCan: (text) =>
    if @textPasteFunc
      @textPasteFunc text
    else
      copyToClipboard text
      InterfaceActionCreator.openModal InfoModal, {
        text: 'Active conversation is not assigned to you. Text has been saved to the clipboard.'
      }

  clearOrderCalculatorTemplate: ->
    setTimeout(
      () -> ConversationTemplatesActionCreator.clearTemplate()
      0
    )

  downloadInitialData: (conversationId) =>
    setTimeout( -> 
      CRMInfoModuleActionCreator.clearAllData()
      0
    )
    setTimeout(
      =>
        @actionCreator.getClosedConversations conversationId
        ConversationsActionCreator.get conversationId, ConversationStore.conversation
      10
    )

  componentDidMount: ->
    super()
    @refs.conversationItemsScroll.addEventListener('scroll', @onContainerScroll)

  componentWillUnmount: ->
    super()
    @refs.conversationItemsScroll.removeEventListener('scroll', @onContainerScroll)

  componentWillUpdate: (nextProps, nextState) ->
    sendFormEnabled = true
    accessToSend = accessConfig[nextProps.match.params.folder]
    selectedConversation = @getSelectedConversation()
    if accessToSend && accessToSend.indexOf(ROLES[AuthStore.user.role].value) == -1
      sendFormEnabled = selectedConversation? &&
      selectedConversation.agents?.some((a) -> a.id == AuthStore.user.id)
    if !(selectedConversation?.status in ACTIVE_STATUSES) && @refs.messageForm
      elements = @refs.messageForm.getElementsByTagName('grammarly-extension')
      for element in elements
        element.remove() if element
    if @state.sendFormEnabled != sendFormEnabled
      @setState { sendFormEnabled }

    status = (selectedConversation || {}).status
    currentConvId = parseInt nextProps.match.params.id, 10
    if (currentConvId && @conversationId != currentConvId) || (status != 'closed' && @previousStatus == 'closed')
      setTimeout(
        -> ImagePreviewActionCreator.imageCheck({})
        0
      )
      @conversationId = currentConvId
      setTimeout(
        => @actionCreator.getClosedConversations @conversationId
        10
      )
    @previousStatus = status

  updateConversationTags: (tags) =>
    ConversationsActionCreator.update @conversationId, { tags }

  unassignAgent: (agentId) =>
    ConversationsActionCreator.unassignAgent(@conversationId, agentId)

  updateConversationStatus: (status) =>
    ConversationsActionCreator.update @conversationId, { status }

  updateConversationChannel: (channel) =>
    ConversationsActionCreator.updateChannel(@conversationId, channel)

  toggleCustomerInfo: () =>
    @setState R.assocPath(['opened', 'customerInfo'],
      !@state.opened.customerInfo, @state)

  toggleAssigner: =>
    @setState assignerOpened: !@state.assignerOpened

  assignConversationToAgents: (agentIds) =>
    if @conversationId #&& agentIds.length > 0
      ConversationsActionCreator.assignToAgent([@conversationId], agentIds)

  switchDisallowScroll: (value) =>
    @isDisallowScroll = !!value

  scrollToBottom: =>
    el = @refs.conversationItemsScroll
    el.scrollTop = el.scrollHeight if el

  scrollToElement: (el) =>
    parent = @refs.conversationItemsScroll
    parent.scrollTop = el.offsetTop - 10

  scrollToElementEnd: (el) =>
    parent = @refs.conversationItemsScroll
    parent.scrollTop = el.offsetTop + el.clientHeight - parent.clientHeight

  onMessagesSearch: (query, searchType) =>
    @actionCreator.searchMessages(@conversationId, query, searchType) if query
    @setState(searchEnabled: query?, foundMessagesPosition: 1)

  stepFoundMessage: (step) =>
    nextStep = @state.foundMessagesPosition + step
    length = @personalStore.foundMessages.length
    nextStep = length if nextStep < 1
    nextStep = 1 if nextStep > length
    @setState foundMessagesPosition: nextStep

  getFoundMessage: =>
    { foundMessages } = @personalStore
    return null if R.isEmpty(foundMessages)
    foundMessages[@state.foundMessagesPosition - 1]

  onItemElementSelect: (el) =>
    if el
      el.firstChild.scrollIntoViewIfNeeded()

  onChildReady: (name, value) =>
    if @state.ready[name] != value
      @state.ready[name] = value

  assignerClickOutside: =>
    @setState assignerOpened: false

  sendMessage: (message, onSuccessCallback) =>
    message[MessageSendConstants.FIELD_NAME_SEND_STATUS] = MessageSendConstants.SEND_STATUS_SENDING

    MessagesAPI.send message,
      (messageFromServer) =>
        @scrollToBottom()
        onSuccessCallback?()
      (response) =>
        console.log 'error on message sent:'
        console.log response
        @setState text: message.text
        if response.responseJSON?.errors?
          alert response.responseJSON.errors.toString()
        else
          alert 'Message was not sent. An error occured on the server side.'

  startConversation: (customer_id) =>
    if customer_id
      ConversationsActionCreator.startConversationByCustomerId customer_id, (conversation) ->
        browserHistory.default.push("/conversations/all_conversations/#{conversation?.id}")

  gotoConversation: (conversation_id) =>
    if conversation_id
      browserHistory.default.push("/conversations/all_conversations/#{conversation_id}")

  componentDidUpdate: (props) ->
    openedConversation = @getOpenedConversation() || {}
    openedLoading = @personalStore.state.openConversationLoading
    { closedLoading } = @personalStore.loading
    currentConvId = Number.parseInt props.match.params.id
    isCurrentConvId = currentConvId == openedConversation.id
    if !closedLoading && !openedLoading && isCurrentConvId && !@isStartScrolled
      @isStartScrolled = true
      @scrollToBottom()
    else if openedConversation.last_message && isCurrentConvId
      createdAt = new moment openedConversation.last_message.created_at
      if createdAt.diff(@last_message, 's') != 0 && !@isDisallowScroll
        @scrollToBottom()
      @last_message = new moment openedConversation.last_message.created_at

  onContainerScroll: =>
    return unless @getOpenedConversation()
    elementTopOffset = @refs.dialogue.offsetTop
    elementBottomOffset = elementTopOffset + @refs.dialogue.clientHeight
    scrollElement = @refs.conversationItemsScroll
    isElementAboveView = elementBottomOffset < scrollElement.scrollTop
    @isElementBellowView = elementTopOffset > scrollElement.scrollTop + scrollElement.clientHeight
    return if isElementAboveView || @isElementBellowView
    maxScrollValue = @refs.dialogue.clientHeight
    { scrollTop, scrollBottom } = calculateTopAndBottomPosition(
      elementTopOffset, elementBottomOffset, scrollElement, maxScrollValue
    )

    # use native js dom management instead of React approach because of render
    # function is too heavy for rerender scroll every time
    scrollTopElement = @refs.scrollOpenedToTop
    if scrollTopElement
      if scrollTop <= 0
        scrollTopElement.classList.add styles.visibility_hidden
      else
        scrollTopElement.classList.remove styles.visibility_hidden
        scrollTopElement.setAttribute 'style', "top: #{scrollTop}px"
    scrollBottomElement = @refs.scrollOpenedToBottom
    if scrollBottomElement
      if scrollBottom <= 0
        scrollBottomElement.classList.add styles.visibility_hidden
      else
        scrollBottomElement.classList.remove styles.visibility_hidden
        scrollBottomElement.setAttribute 'style', "bottom: #{scrollBottom}px"

    @scrollTop = if scrollTop >= 0 then scrollTop else 0
    @scrollBottom = if scrollBottom >=0 then scrollBottom else 0

  onTopClick: =>
    @scrollToElement(@refs.dialogue)

  onBottomClick: =>
    @scrollToElementEnd(@refs.dialogue)

  onMessageResize: =>
    document.addEventListener('mousemove', @onMessageMouseMove)
    document.addEventListener('mouseup', @onMessageMouseUp)

  onMessageMouseUp: =>
    document.removeEventListener('mousemove', @onMessageMouseMove)
    document.removeEventListener('mouseup', @onMessageMouseUp)
    { messageBoxHeight } = @state
    if messageBoxHeight < @minMessageBoxHeight
      messageBoxHeight = @minMessageBoxHeight
      @setState { messageBoxHeight }
    else if messageBoxHeight > window.innerHeight / 2
      messageBoxHeight = window.innerHeight / 2
      @setState { messageBoxHeight }
    window.localStorage.setItem('messageBoxHeight', messageBoxHeight)

  onMessageMouseMove: ({ movementY }) =>
    @setState(messageBoxHeight: @state.messageBoxHeight - movementY)

  getSelectedConversation: =>
    return null if !@personalStore.conversation
    selectedConversation = @personalStore.conversation
    if selectedConversation?.status != 'closed' && @personalStore.openConversation
      selectedConversation = @personalStore.openConversation
    selectedConversation

  getOpenedConversation: ->
    openedConversation = @personalStore.openConversation
    return null if openedConversation?.status == 'closed'
    openedConversation

  isConverstaionScrolled: =>
    (@scrollBottom > PIXELS_TO_CHAT_BOTTOM) || @isElementBellowView

  addKey: (node) ->
    attr = document.createAttribute('key')
    attr.value = Math.random()
    node.attributes.setNamedItem(attr)
    node.childNodes.forEach (child) =>
      @addKey(child)

  onDeselect: () ->
    setTimeout(
      -> ImagePreviewActionCreator.imageCheck({})
      0
    )

  onSelectAll: =>
    ImagePreviewActionCreator.selectAll @conversationId


  notificationHandler: (data) ->
    orderPlugin = if isGeekly then pluginConfigs.OrderPlugin else pluginConfigs.OrderCompactPlugin
    config = orderPlugin(
        orderId: data.orderId,
        options:
          isResizable: true
          entityKey: @selectedConversation.id
      )
    CRMPluginActionCreator.setConfig(
      config, @selectedConversation.id, "#{@selectedConversation.id}-order-#{data.orderId}", true
    )

  # TODO: Too heavy render method. Need to split
  render: ->
    loading = @personalStore.loading.conversation
    selectedConversation = @getSelectedConversation()
    className = classnames(
      styles.container,
      "#{styles.show}": @state.show,
      "#{styles.unassigned_folder_container}": !@state.opened.customerInfo && !@personalStore.rankingShow
    )
    { MenuGroupFiles } = crminfo.controls
    shortcutsStatus = AuthStore?.user?.shortcuts || STATUSES.SHOW
    bottom = if shortcutsStatus == STATUSES.SHOW then @state.messageBoxHeight + 50 else @state.messageBoxHeight
    openedConversation = @getOpenedConversation()
    isCurrentOpened = (openedConversation || {}).id == Number.parseInt @props.match.params.id
    last_channel = @personalStore.conversation?.last_channel
    active_channel = @personalStore.conversation?.active_channel

    {checked} = ImagePreviewStore
    cntChecked = []
    urls = []
    if !_.isEmpty checked
      cntChecked = Object.keys checked
      urls = Object.values checked

    isConversationClosed = selectedConversation?.status == CONVERSATION_STATUS.CLOSED
    scrollClassName = classnames(
      styles.scroll
      "#{styles.scroll_with_form}": isConversationClosed || @state.sendFormEnabled
    )

    foundMessage = if @state.searchEnabled then @getFoundMessage() else null
    foundMessages = if @state.searchEnabled then @personalStore.foundMessages else []
    foundMessagesIndex = R.indexBy(ConversationItemUtils.getTypeIdIdentifier, foundMessages)
    infoPanel = null
    errorPanel = null
    conversationItemsScrollElement = @refs.conversationItemsScroll
    { getClosedConversationsForPreview } = @actionCreator
    { conversationId, scrollToElement, scrollToElementEnd, onItemElementSelect } = @
  
    if @personalStore.errors.loadErrror
      errorPanel = React.createElement("div", {"className": (styles.customer_info_error_panel)}, """
        Error while loading data. Please reload page or choose other conversation
""")
    if @state.opened.customerInfo && selectedConversation?.customer?
      infoPanel = React.createElement("div", {"className": (styles.customer_info)},
        React.createElement(BaseInfoComponent, { \
          "customerId": (selectedConversation.customer.id),  \
          "anyOpen": (@state.opened.customerInfo),  \
          "onAllCollapse": (@toggleCustomerInfo),  \
          "customerModules": (customerModules),  \
          "conversation": (selectedConversation)
        })
      )
    else
      infoPanel = React.createElement("div", {"className": (styles.customer_info_hidden)},
        React.createElement("div", {"className": (styles.burger), "onClick": (@toggleCustomerInfo)})
      )

    React.createElement("div", {"className": (styles.base)},
      React.createElement(LoadableWrapperComponent, {"loading": (loading)},
        (
          if selectedConversation?
            React.createElement(ConversationHeaderComponent, { \
              "folder": (@props.match.params.folder),  \
              "errors": (_.pick(@state.errors, 'takeNew')),  \
              "conversation": (selectedConversation),  \
              "toggleAssignerHandler": (@toggleAssigner),  \
              "toggleCustomerInfoHandler": (@toggleCustomerInfo),  \
              "updateConversationStatus": (@updateConversationStatus),  \
              "onMessagesSearch": (@onMessagesSearch),  \
              "searchEnabled": (@state.searchEnabled),  \
              "foundMessages": (foundMessages),  \
              "searchLoading": (@personalStore.loading.searchMessages),  \
              "searchStepCallback": (@stepFoundMessage),  \
              "searchPosition": (@state.foundMessagesPosition)
            })
        ),
        React.createElement("div", {"className": (className)},
          (
            if selectedConversation? && @state.assignerOpened
              React.createElement("span", {"className": (styles.assigner_container)},
                React.createElement(AssignerComponent, { \
                  "initialSelectedAgentIds": (R.map(R.prop('id'), selectedConversation.agents)),  \
                  "handleClickOutside": (@assignerClickOutside),  \
                  "highlightedAgentId": (openedConversation?.reserve),  \
                  "onAgentSelected": (@assignConversationToAgents),  \
                  "agentsClassName": (styles.agents_height),  \
                  "reservedAgent": (selectedConversation.reserve),  \
                  "multicheck": true
                })
              )
          ),
          (if !_.isEmpty checked
            React.createElement(MenuGroupFiles, { \
              "isDeselect": true,  \
              "onDeselect": (@onDeselect),  \
              "onSelectAll": (@onSelectAll),  \
              "cnt": (cntChecked.length),  \
              "urls": (urls),  \
              "clientId": (CRMInfoModuleStore.externalClientId),  \
              "notificationHandler": (@notificationHandler),  \
              "selectedConversation": (selectedConversation)
            })
          ),
          React.createElement("div", { \
            "style": ({ bottom: "#{bottom}" } if !isConversationClosed && @state.sendFormEnabled),  \
            "className": (scrollClassName),  \
            "ref": "conversationItemsScroll",  \
            "data-scroll-parent": "true"
          },
            (@personalStore.groupedPreviews.map (preview) ->
              if preview.conversations
                preview.conversations.map (conversation, index) =>
                  return null unless conversation
                  selectedMessage = null
                  selectedMessage = foundMessage if foundMessage?.conversation_id == conversation.id
                  createdDate = moment(conversation.created_at)
                  previewCreated = moment(preview.conversations[index - 1]?.created_at)
                  isOpened = conversation.id == conversationId
                  devider = null
                  if index == 0 || (createdDate.date() != previewCreated.date()) || (createdDate.month() != previewCreated.month())
                    devider = React.createElement("div", {"className": (styles.divider)},
                      (createdDate.format('D MMMM, YYYY'))
                    )
                  React.createElement("div", {"key": (conversation.id)},
                    (devider),
                    React.createElement(PreviousConversationComponent, { \
                      "conversation": (conversation),  \
                      "onTop": (scrollToElement if !SCROLL_DISABLED[AuthStore.user.role]),  \
                      "onBottom": (scrollToElementEnd),  \
                      "scrollElement": (conversationItemsScrollElement),  \
                      "opened": (isOpened),  \
                      "itemsListProps": (
                        itemsHighLightingIndex: foundMessagesIndex
                        selectedItem: selectedMessage
                        onItemSelect: onItemElementSelect
                      ),  \
                      "items": (ConversationItemsStore.conversationItems[conversation.id]),  \
                      "loading": (ConversationItemsStore.loadings[conversation.id]),  \
                      "error": (ConversationItemsStore.errors[conversation.id])
                    })
                  )
              else
                React.createElement(PreviousConversationsBlockComponent, { \
                  "key": (preview.date),  \
                  "title": (preview.date),  \
                  "count": (preview.total),  \
                  "onClick": (getClosedConversationsForPreview.bind null, conversationId, preview.date)
                })
            ),
            React.createElement("div", {"ref": "dialogue", "className": (styles.dialogue)},
              React.createElement(LoadableWrapperComponent, { \
                "loading": (@personalStore.loading.updating)
              },
                (if openedConversation && selectedConversation
                  selectedMessage = null
                  selectedMessage = foundMessage if selectedConversation && foundMessage?.conversation_id == selectedConversation.id
                  React.createElement("div", null,
                    (if InterfaceStore.nerdifyAppChatStatus
                      React.createElement(BannerAppNerdyChatStatus, { \
                        "conversation": (openedConversation),  \
                        "activeChannel": (active_channel?.name),  \
                        "conversationId": (openedConversation.id)
                      })
                    ),
                    (if !SCROLL_DISABLED[AuthStore.user.role]
                      React.createElement("div", {"className": (styles.scroll_container)},
                        React.createElement("span", { \
                          "ref": "scrollOpenedToTop",  \
                          "style": (top: @scrollTop),  \
                          "onClick": (@onTopClick),  \
                          "className": (classnames(styles.scroll_item, styles.scroll_top,
                            "#{styles.visibility_hidden}": @scrollTop == 0
                          ))
                        }),
                        React.createElement("span", { \
                          "ref": "scrollOpenedToBottom",  \
                          "style": (bottom: @scrollBottom),  \
                          "onClick": (@onBottomClick),  \
                          "className": (classnames(styles.scroll_item, styles.scroll_bottom,
                            "#{styles.visibility_hidden}": @scrollBottom == 0
                          ))
                        })
                      )
                    ),
                    React.createElement("div", { \
                      "className": (classnames(styles.opened_conversation,
                        "#{styles.opened_conversation_with_scheduled}": openedConversation.customer?.not_completed_scheduled_messages?.length
                      ))
                    },
                      React.createElement(ConversationItemsListComponent, { \
                        "conversation": (openedConversation),  \
                        "itemsHighLightingIndex": (foundMessagesIndex),  \
                        "selectedItem": (selectedMessage),  \
                        "onItemSelect": (@onItemElementSelect),  \
                        "onReadyChange": (R.partial(@onChildReady, ['conversationItems'])),  \
                        "onUpdateConversation": (@scrollToBottom if isCurrentOpened),  \
                        "onSwitchDisallowScroll": (@switchDisallowScroll),  \
                        "alreadyScrolled": (@isConverstaionScrolled)
                      })
                    )
                  )
                )
              )
            )
          ),
          (
            if selectedConversation?.status in ACTIVE_STATUSES
              if @state.sendFormEnabled
                React.createElement("div", {"ref": "messageForm", "className": (styles.form), "style": (top: "calc(100% - #{@state.messageBoxHeight}px)")},
                  (if XMPPStore.conversationActions[selectedConversation.id]?.isTyping
                      React.createElement("div", {  \
                        "className": (styles.is_typing_block),  \
                        "className": (classnames(styles.is_typing_block,
                          "#{styles.is_typing_block_with_top}": @MessageFormComponentRef?.current?.state?.shortcutsStatus == 'show'
                        ))
                      },
                        React.createElement("div", {"className": (styles.is_typing_block_dots)})
                      )),
                  React.createElement(ScheduledMessagesComponent, {"key": (1),  \
                    "messages": (openedConversation?.customer?.not_completed_scheduled_messages || [])
                  }),
                  React.createElement("div", {"key": (2), "className": (styles.resizable_border), "onMouseDown": (@onMessageResize)}),
                  React.createElement(MessageFormComponent, {"key": (3),  \
                    "customerId": (selectedConversation?.customer?.id),  \
                    "conversation": (selectedConversation),  \
                    "sendMessage": (@sendMessage),  \
                    "last_channel": (last_channel),  \
                    "active_channel": (active_channel),  \
                    "unassignAgent": (@unassignAgent),  \
                    "getTextPasteFunc": (@getTextPasteFunc),  \
                    "agents": (selectedConversation?.agents || []),  \
                    "updateConversationChannel": (@updateConversationChannel),  \
                    "ref": (@MessageFormComponentRef)
                  })
                )
            else if openedConversation
              React.createElement("div", {"className": (styles.new_conversation_wrapper)},
                React.createElement("div", { \
                  "className": (styles.new_conversation),  \
                  "onClick": (
                    R.partial(@gotoConversation, [openedConversation?.id])
                  )
                }, """
                  Go to opened conversation
""")
              )
            else
              React.createElement("div", {"className": (styles.new_conversation_wrapper)},
                React.createElement("div", { \
                  "className": (styles.new_conversation),  \
                  "onClick": (
                    R.partial(
                      @startConversation
                      [selectedConversation?.customer?.id]
                    )
                  )
                }, """
                  Start new conversation
""")
              )
          )
        ),
        (errorPanel),
        (infoPanel)
      )
    )

module.exports = ConversationComponent
