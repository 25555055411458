browserHistory = require './../../../history'
BaseComponent = require 'components/BaseComponent'
CSVExportStore = require 'components/CSVExportComponent/CSVExportStore'
CategoriesSectionComponent = require './CategoriesSectionComponent'
AnswersSectionComponent = require './AnswersSectionComponent'
ReplyAnswersActionCreator = require 'actions/ReplyAnswersActionCreator'
ReplyAnswersStore = require 'stores/ReplyAnswersStore'
{ SORT_LIST, SORT_ORDER } = require 'constants/ReplyTemplates'
{ menus, urls } = require 'root/config'
{ ROLES } = require 'constants/Agents'
{ getQueryParams, buildQueryParamsString, reactDndContext } = require 'lib/utils'
{ updateItemPositionAndItemsArray } = require 'lib/dnd-utils'
AuthStore = require 'stores/AuthStore'
Button = require 'components/shared/Button'
TYPE = 'reply_templates'

styles = require './reply-templates-page'

accessConfig = {}
menus.forEach (item) ->
  if item.key == 'answer-template'
    accessConfig =
      toPage: item.accessToPage || null

class ReplyTemplatesPage extends BaseComponent
  getPersonalStoreClass: -> CSVExportStore

  constructor: (props) ->
    super(props)
    params = getQueryParams window.location.search.substring 1
    @state = {
      subCategoryId: null
      categoryId: null
      categories: []
      subCategories: []
      answers: []
      templateType: params.template_type || 'message'
      sortRule: SORT_LIST.position.value
      categoryPage: 0
      categoryPerPage: 30
      answerPage: 0
      answerPerPage: 30
    }
    @initComponent()

  dependsOnStores: [ReplyAnswersStore]

  loadGroups: (templateType) =>
    setTimeout(
      ReplyAnswersActionCreator.getGroups
      10
      {
        page: @state.categoryPage
        per_page: @state.categoryPerPage
        sort_by: SORT_LIST.position.value
        sort_order: SORT_ORDER[SORT_LIST.position.value]
        template_type: templateType || @state.templateType
      }
    )

  componentDidMount: ->
    super()
    if ROLES[AuthStore.user.role].value in accessConfig.toPage
      @loadGroups()
      setTimeout(
        @actionCreator.get
        10
      )
    else
      browserHistory.default.push '/'

  getState: =>
    categories = ReplyAnswersStore.groups
    subCategories = []
    if @state.categoryId
      category = categories.filter((a) => a.id == @state.categoryId)[0]
      if category
        subCategories = category.categories
    subCategories: subCategories
    categories: ReplyAnswersStore.groups
    answers: ReplyAnswersStore.answers

  onSubCategoryClick: (subCategoryId) =>
    sort_order = SORT_ORDER[@state.sortRule]
    sort_by = @state.sortRule
    setTimeout(
      =>
        ReplyAnswersActionCreator.getAnswersByGroup(subCategoryId, {
          sort_order: sort_order
          sort_by: sort_by
          page: 0
          per_page: @state.answerPerPage
          template_type: @state.templateType
        })
      0
    )
    @setState { subCategoryId, answerPage: 0 }

  onCategoryClick: (categoryId) =>
    sort_order = SORT_ORDER[@state.sortRule]
    sort_by = @state.sortRule

    if categoryId != 'personal' && sort_by == 'user_name'
      @setState { sortRule: 'position' }
      sort_order = SORT_ORDER.position
      sort_by = 'position'

    setTimeout(
      =>
        ReplyAnswersActionCreator.getAnswersByGroup(categoryId, {
          sort_order: sort_order
          sort_by: sort_by
          page: 0
          per_page: @state.answerPerPage
          template_type: @state.templateType
        })
      0
    )
    category = @state.categories.filter((g) -> g.id == categoryId)[0]
    subCategories = []
    subCategoryId = null
    if category
      subCategories = category.categories
    @setState { categoryId, subCategoryId, subCategories, answerPage: 0 }

  onTemplateTypeClick: (templateType) =>
    (e) =>
      params = getQueryParams window.location.search.substring 1
      params.template_type = templateType
      queryParams = "?#{buildQueryParamsString params}"
      window.history.replaceState null, null, queryParams
      @setState { templateType, categoryId: null, subCategoryId: null, page: 0, answerPage: 0 }
      @loadGroups(templateType)

  onSortChange: (value) =>
    categoryId = @state.subCategoryId || @state.categoryId
    if value != @state.sortRule && categoryId
      @setState sortRule: value
      setTimeout(
        =>
          ReplyAnswersActionCreator.getAnswersByGroup categoryId, {
            sort_order: SORT_ORDER[value]
            sort_by: value
            page: @state.answerPage
            per_page: @state.answerPerPage
            template_type: @state.templateType
          }
        0
      )

  onCategoryDelete: (categoryId) =>
    ReplyAnswersActionCreator.deleteGroup categoryId
    @setState categoryId: null if categoryId == @state.categoryId

  onSubCategoryDelete: (categoryId) =>
    ReplyAnswersActionCreator.deleteGroup categoryId, @state.categoryId
    @setState subCategoryId: null if categoryId == @state.subCategoryId

  addNewGroup: (value) =>
    ReplyAnswersActionCreator.createGroup
      name: value
      template_type: @state.templateType

  addNewSubGroup: (value) =>
    ReplyAnswersActionCreator.createGroup
      original_id: @state.categoryId
      name: value
      template_type: @state.templateType

  onAnswerPageChange: ({ selected }) =>
    return if selected == @state.answerPage
    @setState { answerPage: selected }
    categoryId = @state.subCategoryId || @state.categoryId
    setTimeout(
      =>
        ReplyAnswersActionCreator.getAnswersByGroup categoryId, {
          sort_order: SORT_ORDER[@state.sortRule]
          sort_by: @state.sortRule
          page: selected
          per_page: @state.answerPerPage
          template_type: @state.templateType
        }
      0
    )

  onCategoryPageChange: ({ selected }) =>
    return if selected == @state.categoryPage
    @setState { categoryPage: selected }
    setTimeout(
      =>
        ReplyAnswersActionCreator.getGroups {
          page: selected
          per_page: @state.categoryPerPage
          sort_by: SORT_LIST.position.value
          sort_order: SORT_ORDER[SORT_LIST.position.value]
          template_type: @state.templateType
        }
      0
    )

  updateAnswerPosition: (itemId) =>
    answers = @state.answers
    updatedAnswers = updateItemPositionAndItemsArray(answers, itemId)

    setTimeout(
      =>
        ReplyAnswersActionCreator.updateAnswer updatedAnswers.item
      0
    )
    @setState answers: updatedAnswers.items

  updateCategoryPosition: (itemId) =>
    categories = @state.categories
    updatedCategories = updateItemPositionAndItemsArray(categories, itemId)

    setTimeout(
      =>
        ReplyAnswersActionCreator.updateGroup updatedCategories.item
      0
    )
    @setState categories: updatedCategories.items

  updateSubCategoryPosition: (itemId) =>
    categories = @state.subCategories
    updatedCategories = updateItemPositionAndItemsArray(categories, itemId)

    setTimeout(
      =>
        ReplyAnswersActionCreator.updateGroup updatedCategories.item
      0
    )
    @setState subCategories: updatedCategories.items

  swapItems: (items, dragIndex, hoverIndex) =>
    dragItem = items[dragIndex]
    items.splice(dragIndex, 1)
    items.splice(hoverIndex, 0, dragItem)
    return items

  swapCategories: (dragIndex, hoverIndex) =>
    categories = @state.categories
    items = @swapItems(categories, dragIndex, hoverIndex)

    @setState categories: items

  swapSubCategories: (dragIndex, hoverIndex) =>
    categories = @state.subCategories
    items = @swapItems(categories, dragIndex, hoverIndex)

    @setState subCategories: items

  swapAnswers: (dragIndex, hoverIndex) =>
    answers = @state.answers;
    items = @swapItems(answers, dragIndex, hoverIndex)

    @setState answers: items

  buttonColor: (templateType) =>
    templateType != @state.templateType

  render: ->
    answerPageCount = Math.ceil(ReplyAnswersStore.answersTotal / @state.answerPerPage)
    categoryPageCount = Math.ceil(ReplyAnswersStore.groupsTotal / @state.categoryPerPage)
    categoryId = @state.subCategoryId || @state.categoryId
    React.createElement("div", {"className": (styles.overlay)},
      React.createElement("div", {"className": (styles.container)},
        React.createElement("div", {"className": (styles.title)},
          React.createElement(Button, { \
            "title": 'Reply templates',  \
            "onClick": (@onTemplateTypeClick('message')),  \
            "white": (@buttonColor('message'))
          }),
          React.createElement(Button, { \
            "title": 'Comment templates',  \
            "onClick": (@onTemplateTypeClick('ranking')),  \
            "white": (@buttonColor('ranking'))
          }),
          React.createElement(Button, { \
            "title": 'Thread templates',  \
            "onClick": (@onTemplateTypeClick('thread')),  \
            "white": (@buttonColor('thread'))
          }),
          React.createElement(Button, { \
            "title": 'IR templates',  \
            "onClick": (@onTemplateTypeClick('ir')),  \
            "white": (@buttonColor('ir'))
          }),
          React.createElement(Button, { \
            "title": 'Ticket templates',  \
            "onClick": (@onTemplateTypeClick('ticket')),  \
            "white": (@buttonColor('ticket'))
          }),
          (@personalStore.links?[TYPE] &&
            React.createElement("a", { \
              "href": (urls.csvExport.download @personalStore.links?[TYPE]),  \
              "className": (styles.link),  \
              "target": '_blank'
            },
              React.createElement("button", {"className": (styles.button)}, "Csv Export")
            )
          )
        ),
        React.createElement("div", {"className": (styles.tool)},
          React.createElement(CategoriesSectionComponent, { \
            "selected": (@state.categoryId),  \
            "addNewGroup": (@addNewGroup),  \
            "categories": (@state.categories),  \
            "onCategoryClick": (@onCategoryClick),  \
            "loading": (ReplyAnswersStore.groupsLoading),  \
            "onCategoryDelete": (@onCategoryDelete),  \
            "pageCount": (categoryPageCount),  \
            "onPageChange": (@onCategoryPageChange),  \
            "page": (0),  \
            "templateType": (@state.templateType),  \
            "isPeronal": (true),  \
            "isDeleled": (true),  \
            "swapCategories": (@swapCategories),  \
            "updatePosition": (@updateCategoryPosition),  \
            "forcePage": (@state.categoryPage)
          }),
          (if @state.categoryId && @state.categoryId != 'personal' && @state.categoryId != 'deleted'
            React.createElement(CategoriesSectionComponent, { \
              "title": ('Sub-category'),  \
              "addTitle": ('Add new sub-category'),  \
              "addNewGroup": (@addNewSubGroup),  \
              "selected": (@state.subCategoryId),  \
              "categories": (@state.subCategories),  \
              "onCategoryClick": (@onSubCategoryClick),  \
              "loading": (ReplyAnswersStore.groupsLoading),  \
              "onCategoryDelete": (@onSubCategoryDelete),  \
              "pageCount": (1),  \
              "onPageChange": (@onCategoryPageChange),  \
              "page": (0),  \
              "isSub": (true),  \
              "templateType": (@state.templateType),  \
              "swapCategories": (@swapSubCategories),  \
              "updatePosition": (@updateSubCategoryPosition)
            })
          ),
          (if categoryId
            React.createElement(AnswersSectionComponent, { \
              "key": (categoryId),  \
              "answers": (@state.answers),  \
              "loading": (ReplyAnswersStore.answersLoading),  \
              "categoryId": (categoryId),  \
              "onSortChange": (@onSortChange),  \
              "sortRule": (@state.sortRule),  \
              "pageCount": (answerPageCount),  \
              "onPageChange": (@onAnswerPageChange),  \
              "page": (0),  \
              "templateType": (@state.templateType),  \
              "swapAnswers": (@swapAnswers),  \
              "updatePosition": (@updateAnswerPosition),  \
              "pageCategoryCount": (categoryPageCount),  \
              "onPageCategoryChange": (@onCategoryPageChange)
            })
          )
        )
      )
    )

module.exports = reactDndContext(ReplyTemplatesPage)
