moment                      = require 'moment'
RankingRulesStore           = require 'stores/RankingRulesStore'
RankingRulesActionCreator   = require 'actions/RankingRulesActionCreator'
BaseComponent               = require 'components/BaseComponent'
styles                      = require './log-message.styl'

AuthStore = require 'stores/AuthStore'
{ ROLES } = require 'constants/Agents'

# IMPORTANT: this component uses base.styl hardly!
# div classes come from the server
# look for .logMessage class

class LogMessageComponent extends React.Component
  @propTypes:
    logMessage: require('prop-types').object.isRequired

  removeClass: ->
    tags = document.querySelectorAll('.tagname');
    tags.forEach (item) ->
      item.classList.remove('active')


  componentDidUpdate: ->
    if @props.useRankingRules
      if RankingRulesStore.currentMessage?.target_type == 'message'
        @removeClass()

      # tags = document.querySelectorAll('.ranked .tagname');
      # tags.forEach (item) ->
      #   item.classList.add('active_ranked')

  messageForRanking: (e) =>
    if !!@props.isRanked
      return null

    if @props.useRankingRules
      string = @props.logMessage.text
      tmp = document.createElement('div');
      tmp.innerHTML = string
      tag = tmp.querySelector('.tagname')

      if tag
        text = tag.innerHTML

      if e.target.classList.contains('active')
        @removeClass()
      else
        @removeClass()
        e.target.classList.add('active')

      setTimeout(
        => RankingRulesActionCreator.selectRankedMessage @props.logMessage, "##{text}"
        3
      )
      if RankingRulesStore?.currentConversationId != @props.conversation.id
        setTimeout(
          => RankingRulesActionCreator.setCurrentConversation @props.conversation
          1
        )
        setTimeout(
          => RankingRulesActionCreator.getConversationRankedInfo @props.conversation.id
          2
        )

  onClickLogMessage: (e) =>
    rankable = AuthStore?.user.role == ROLES.qa_teamlead.value || AuthStore?.user.role == ROLES.manager.value
    if @props.rankingOptions.isClickableForRank || rankable
      @messageForRanking(e)

  render: ->
    React.createElement("div", {"className": styles.root, "onClick": (@onClickLogMessage), "data-ranked-id": (if @props.isRanked then "#{@props.logMessage.type}_#{@props.logMessage.id}")},
      React.createElement("div", {"className": (if !!@props.isRanked then 'ranked')},
        React.createElement("span", {"className": styles.time}, ( moment(@props.logMessage.created_at).format('HH:mm, ') )),
        React.createElement("span", null, ( moment(@props.logMessage.created_at).format('DD MMM YY') )),
        React.createElement("span", null, " - "),
        React.createElement("span", {"onClick": (@props.openOrder), "className": styles.text, "dangerouslySetInnerHTML": ({__html: @props.logMessage.text})})
      )
    )

module.exports = LogMessageComponent
