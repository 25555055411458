BaseStore = require './BaseStore'
ActionTypes = require 'constants/ActionTypes'
CRMInfoModuleStore = require 'stores/CRMInfoModuleStore'
{ buildCRMInfoRequestData } = require 'lib/utils'

actionsMap =
  "#{ActionTypes.MQTT_ACTIVE_CONVERSATION_CUSTOMER_UPDATING}": (payload) ->
    return unless @customer
    Object.keys(payload.message).forEach (key) =>
      @customer[key] = payload.message[key]
    @emitChange()
  "#{ActionTypes.CUSTOMER_GET_REQUESTING}": (customerId) ->
    @customerLoading = true
    @customerError = null
    @currentCustomerId = customerId
    @emitChange()
  "#{ActionTypes.CUSTOMER_GET_SUCCESS}": (customer) ->
    if @currentCustomerId == customer.id
      @customerLoading = false
      @customer = customer
      CRMInfoModuleStore.requestData = buildCRMInfoRequestData customer
      @emitChange()
  "#{ActionTypes.CUSTOMER_GET_FAILURE}": (error) ->
    @customerLoading = false
    @customerError = error
    @currentCustomerId = null
    @emitChange()
  "#{ActionTypes.CUSTOMER_CLEAR}": () ->
    @customer = null
    @currentCustomerId = null
    @emitChange()

  "#{ActionTypes.CUSTOMER_UPDATE_REQUESTING}": ->
    @updateLoading = true
    @customerError = null
    @emitChange()
  "#{ActionTypes.CUSTOMER_UPDATE_SUCCESS}": (customer) ->
    @updateLoading = false
    @customer = customer
    CRMInfoModuleStore.requestData = buildCRMInfoRequestData customer
    @emitChange()
  "#{ActionTypes.CUSTOMER_UPDATE_FAILURE}": (error) ->
    @updateLoading = false
    @customerError = error
    @emitChange()

  "#{ActionTypes.CUSTOMERS_GET_LIST_REQUESTING}": ->
    @customerListLoading = true
    @customerListError = []
    @emitChange()
  "#{ActionTypes.CUSTOMERS_GET_LIST_SUCCESS}": ({ customers, total, isNewQuery }) ->
    @customerListLoading = false
    @total = total
    if isNewQuery
      @customers = customers
    else
      @customers = @customers.concat customers
    @emitChange()
  "#{ActionTypes.CUSTOMERS_GET_LIST_FAILURE}": (error) ->
    @customerListLoading = false
    @customerListError = error
    @emitChange()

  "#{ActionTypes.CUSTOMER_CREATE_REQUESTING}": ->
    @customerLoading = true
    @customerError = null
    @emitChange()
  "#{ActionTypes.CUSTOMER_CREATE_SUCCESS}": (customer) ->
    @customerLoading = false
    @customers.unshift customer
    @total += 1
    @emitChange()
  "#{ActionTypes.CUSTOMER_CREATE_FAILURE}": (error) ->
    @customerLoading = false
    @customerError = error
    @emitChange()

  "#{ActionTypes.TAG_TARGET_CREATE_SUCCESS}": ({ targetId, tagId, tagName }) ->
    if @customer?.id == targetId
      @customer.tags.push { id: tagId, name: tagName }
      @emitChange()
  "#{ActionTypes.TAG_TARGET_DESTROY_SUCCESS}": ({ targetId, tagId }) ->
    if @customer?.id == targetId
      @customer.tags = @customer.tags.filter (t) -> t.id != tagId
      @emitChange()

  "#{ActionTypes.CUSTOMERS_SEARCH_CLEAR}": ->
    @search = []
    @searchLoading = false
    @searchError = null
    @searchTotal = 0
    @emitChange()
  "#{ActionTypes.CUSTOMERS_SEARCH_REQUESTING}": ->
    @searchLoading = true
    @searchError = null
    @emitChange()
  "#{ActionTypes.CUSTOMERS_SEARCH_SUCCESS}": ({ customers, total, isNewQuery, currentCustomerId })->
    filteredCustomers = customers.filter (c) -> c.id != currentCustomerId
    if isNewQuery
      @search = filteredCustomers
    else
      @search = @search.concat(filteredCustomers)
    @searchLoading = false
    @searchTotal = total
    @emitChange()
  "#{ActionTypes.CUSTOMERS_SEARCH_FAILURE}": (error) ->
    @searchLoading = false
    @searchError = error
    @emitChange()

  "#{ActionTypes.CUSTOMERS_MERGE_SUCCESS}": ({ customer }) ->
    @customer = customer
    @emitChange()

  "#{ActionTypes.CUSTOMERS_UNMERGE_REQUEST}": ->
    @crm_ids = []
    @emitChange()

  "#{ActionTypes.CUSTOMERS_UNMERGE_SUCCESS}": ({ customer,  crm_ids }) ->
    @customer = customer
    @crm_ids = crm_ids
    @emitChange()

  "#{ActionTypes.CREDENTIAL_CREATE_SUCCESS}": ({ credential }) ->
    return unless @customer
    if Array.isArray @customer.credentials
      @customer.credentials.push credential
    else
      @customer.credentials = [credential]
    CRMInfoModuleStore.requestData = buildCRMInfoRequestData @customer
    @emitChange()
  "#{ActionTypes.CREDENTIAL_DESTROY_SUCCESS}": ({ ids }) ->
    return unless @customer
    if Array.isArray @customer.credentials
      @customer.credentials = @customer.credentials.filter (c) -> !(c.id in ids)
    else
      @customer.credentials = []
    CRMInfoModuleStore.requestData = buildCRMInfoRequestData @customer
    @emitChange()
  "#{ActionTypes.CREDENTIAL_UPDATE_SUCCESS}": ({ credential }) ->
    return unless @customer
    if Array.isArray @customer.credentials
      @customer.credentials = @customer.credentials.map (c) ->
        return c unless c.id == credential.id
        credential 
    else
      @customer.credentials = [credential]
    CRMInfoModuleStore.requestData = buildCRMInfoRequestData @customer
    @emitChange()

class CustomersStore extends BaseStore
  constructor: ->
    super()
    @customers = []
    @customer = null
    @customerListLoading = false
    @customerLoading = false
    @customerError = null
    @customerListError = []
    @search = []
    @searchLoading = false
    @searchError = null
    @searchTotal = 0
    @total = 0
    @updateLoading = false
    @currentCustomerId = null
    @crm_ids = []
    @registerActions()

  _registerToActions: ({ type, payload }) =>
    actionsMap[type].call @, payload if actionsMap[type]

module.exports = new CustomersStore
