config = require '../config'

AgentsAPI =
  getList: (scope, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.agents.getList
      type: 'GET'
      data: scope
      success: successCallback
      error: errorCallback
      timeout: 5000

  get: (id, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.agents.get(id)
      type: 'GET'
      success: successCallback
      error: errorCallback
      timeout: 5000

  create: (attributes, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.agents.create
      data: JSON.stringify(attributes)
      contentType: 'application/json'
      type: 'POST'
      success: successCallback
      error: errorCallback
      timeout: 5000

  update: ({ id, attributes }, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.agents.update(id)
      data: JSON.stringify(attributes)
      contentType: 'application/json'
      type: 'PUT'
      success: successCallback
      error: errorCallback
      timeout: 10000

  updateAvatar: ({id, data}, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.agents.updateAvatar(id)
      data: JSON.stringify(data)
      contentType: 'application/json'
      type: 'PUT'
      success: successCallback
      error: errorCallback
      timeout: 30000

  batchDestroy: ({ ids, menu }, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.agents.batch_destroy
      contentType: 'application/json'
      data: JSON.stringify({ ids })
      type: 'DELETE'
      success: successCallback
      error: errorCallback

  getPagesCount: ({ per_page, query }, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.agents.pages_count
      type: 'GET'
      data: { per_page, query }
      success: successCallback
      error: errorCallback
      timeout: 5000

  ping: (successCallback, errorCallback) ->
    $.ajax
      url: config.urls.agents.ping
      type: 'POST'
      success: successCallback
      error: errorCallback

  getUsersForGroup: (data, success, error) ->
    $.ajax {
      url: config.urls.agents.usersForGroup
      type: 'GET'
      data
      success
      error
    }

  closeNotification: (id, successCallback, errorCallback) ->
    $.ajax {
      url: config.urls.agents.closeNotification(id)
      type: 'POST'
      success: successCallback
      error: errorCallback
    }

  removeShiftUser: (id, successCallback, errorCallback) ->
    $.ajax {
      url: config.urls.agents.removeShiftUser(id)
      type: 'POST'
      success: successCallback
      error: errorCallback
    }

  updateShift: (successCallback, errorCallback) ->
    $.ajax {
      url: config.urls.agents.updateShift()
      type: 'POST'
      success: successCallback
      error: errorCallback
    }

  endShift: (agentId, successCallback, errorCallback) ->
    $.ajax {
      url: config.urls.agents.endShift()
      type: 'POST'
      contentType: 'application/json'
      data: JSON.stringify({ agent_id: agentId })
      success: successCallback
      error: errorCallback
    }

module.exports = AgentsAPI
