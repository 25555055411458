ConversationsStore = require './abstract/ConversationsStore'
ActionTypes = require '../constants/ActionTypes'

class ConversationsUnassignedStore extends ConversationsStore
  constructor: ->
    super()
    @salesCount = 0
    @csrCount = 0
    @registerActions()

  onConversationsUpdating: (payload) =>
    super(payload)
    @salesCount = 0
    @csrCount = 0
    Object.keys(@conversationsHash).map (key) =>
      if @conversationsHash[key].status == 'new_message'
        if @conversationsHash[key].customer.lead == true
          @salesCount += 1
        if @conversationsHash[key].customer.lead == false
          @csrCount += 1
    @emitChange()

  getActions: =>
    "#{ActionTypes.CONVERSATIONS_SET_DEFAULT_UNASSIGNED_STATE}": @onSetDefaultState
    "#{ActionTypes.CONVERSATIONS_GET_LIST_REQUESTING}": @onSearchInit
    "#{ActionTypes.CONVERSATIONS_GET_LIST_SUCCESS}": @onSearchSuccess
    "#{ActionTypes.CONVERSATIONS_GET_LIST_FAILURE}": @onSearchError
    "#{ActionTypes.CONVERSATIONS_SEARCH_RESET}": @onSearchReset
    "#{ActionTypes.MQTT_UNASSIGNED_ACTIVE_CONVERSATIONS_REQUEST}": @onConversationsRequest
    "#{ActionTypes.MQTT_UNASSIGNED_ACTIVE_CONVERSATIONS_UPDATING_INITIAL}": @onConversationsRequest
    "#{ActionTypes.MQTT_UNASSIGNED_ACTIVE_CONVERSATIONS_UPDATING}": @onConversationsUpdating

module.exports = new ConversationsUnassignedStore()
