require './lib/StringExtension'
require './lib/RamdaExtension'

require 'react-widgets/dist/css/react-widgets.css'
momentLocalizer = require 'react-widgets-moment'
moment = require('moment')
require 'intro.js/introjs.css'
require './lib/introjs.css'
require '@verdaccio/crminfo/dist/style.css'
require '@verdaccio/crminfo/dist/reach-editable-style.css'
Sentry = require('@sentry/react');
crminfo = require('@verdaccio/crminfo')
{ BrowserTracing } = require('@sentry/tracing')

config = require './config'

crminfo.init(config.crminfo)

{ hotjar } = require 'react-hotjar'

momentLocalizer()

require('prop-types').resetWarningCache()

if config.sentry
  Sentry.init({
    dsn: 'https://2508d688ea7947eda5ac24d638e556f3@o255621.ingest.sentry.io/6697820',
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
  })


Router = require './router'

jQuery.support.cors = true
window.ReactDOM = ReactDOM
window.R = R

if config.hotjarId && config.hotjarSv
  hotjar.initialize(config.hotjarId, config.hotjarSv)

infinityPing = require './infinity-ping.coffee'
infinityPing()

agentPing = require './agentPing.coffee'
agentPing()

infinitySync = require './infinity-sync.coffee'
infinitySync()

ReactDOM.render Router, document.getElementById('root')
