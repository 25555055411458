moment = require 'moment'
classnames = require 'classnames'

BaseComponent = require 'components/BaseComponent'
ConversationEachHourByChannelStore = require './ConversationEachHourByChannelStore'
ChartComponent = require '../../ChartComponent/ChartComponent'
{buildOptions} = require 'lib/dashboard-utils'
styles = require '../dashboard.styl'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'

CHANNEL_LABELS =
  'phone': 'SMS',
  'xmpp': 'Web'

class ConversationEachHourByChannelComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  getPersonalStoreClass: -> ConversationEachHourByChannelStore

  componentDidMount: ->
    super()
    setTimeout(
      @actionCreator.get
      10
    )

  channelName: (channelType) ->
    CHANNEL_LABELS[channelType] || channelType

  getConversationsByChannel: (channelType) =>
    data = @personalStore.channelType[channelType]

    xAxis: categories: data.map((d) -> d.name)
    yAxis: {}
    series: [{
      name: 'Opened'
      data: data.map((d) -> d.y[0])
    }, {
      name: 'Closed'
      data: data.map((d) -> d.y[1])
    }]
    chart: type: 'column'
    plotOptions:
      column:
        pointPadding: 0.2
        borderWidth: 0
    title: text: "#{@channelName(channelType)} Chats per hour"
    yTitle: 'Conversations'

  render: ->
    React.createElement("div", {  \
      "className": (classnames styles.grid,
        "#{styles.grid__full_height}": @personalStore.loading
    )},
      React.createElement(LoadableWrapperComponent, {"loading": (@personalStore.loading)},
        (unless @personalStore.loading
          React.createElement("div", null,
            (Object.keys(@personalStore.channelType).map (channelType) =>
              React.createElement("div", {"className": (styles.full_row_of_grid)},
                React.createElement("div", {"className": (styles.chart_container)},
                  React.createElement(ChartComponent, { \
                    "container": ("ConversationsCountInEachHourByChannel#{channelType}"),  \
                    "options": (buildOptions @getConversationsByChannel(channelType))
                  })
                )
              )
            ),
            React.createElement("button", {  \
              "className": (styles.refresh),   \
              "onClick": (@actionCreator.get)
            }, """
              Refresh
""")
          )
        )
      )
    )

module.exports = ConversationEachHourByChannelComponent
