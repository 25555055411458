AgentsAPI           = require '../api/AgentsAPI'
Dispatcher          = require '../dispatcher/Dispatcher'
ActionTypes         = require '../constants/ActionTypes'
GuidedActionCreator = require './abstract/GuidedActionCreator'

browserHistory = require './../history'

AgentsActionCreator =
  new: -> Dispatcher.dispatch type: ActionTypes.AGENTS_NEW

  # DESTROY
  batchDestroy: (ids, menu) ->
    Dispatcher.dispatch type: ActionTypes.AGENTS_BATCH_DESTROY_REQUESTING, payload: { ids, menu }
    AgentsAPI.batchDestroy { ids, menu },
      (data) ->
        Dispatcher.dispatch
          type: ActionTypes.AGENTS_BATCH_DESTROY_SUCCESS
          payload: data
        browserHistory.default.push('/')
        browserHistory.default.push('/agents')
      ,
      (error) -> Dispatcher.dispatch type: ActionTypes.AGENTS_BATCH_DESTROY_FAILURE, payload: error.responseJSON

  getPagesCount: (per_page, query) ->
    Dispatcher.dispatch type: ActionTypes.AGENTS_GET_PAGES_COUNT_REQUESTING, payload: { per_page }
    AgentsAPI.getPagesCount { per_page, query },
      (data) -> Dispatcher.dispatch type: ActionTypes.AGENTS_GET_PAGES_COUNT_SUCCESS, payload: data,
      (error) -> Dispatcher.dispatch type: ActionTypes.AGENTS_GET_PAGES_COUNT_FAILURE, payload: error

  closeNotification: (id, successCallback) ->
    AgentsAPI.closeNotification(
      id
      (data) ->
        payload =
          data: data
          id: id
        Dispatcher.dispatch
          type: ActionTypes.CLOSE_IDLE_NOTIFICATION
          payload: payload
        successCallback?()
    )

  removeShiftUser: (id, successCallback) ->
    AgentsAPI.removeShiftUser(
      id
      ->
        successCallback?()
    )

  updateAgent: (id, attributes) =>
    Dispatcher.dispatch type: ActionTypes.AGENTS_UPDATE_STATUS_REPLY_REQUESTING
    AgentsAPI.update { id, attributes },
      (response) ->
        if response.success
          Dispatcher.dispatch
            type: ActionTypes.AGENTS_UPDATE_STATUS_REPLY_SUCCESS
            payload: response.data
        else
          Dispatcher.dispatch
            type: ActionTypes.AGENTS_UPDATE_STATUS_REPLY_FAILURE
            payload: response.errors
      (error) ->
        console.error error.responseJSON
        Dispatcher.dispatch
          type: ActionTypes.AGENTS_UPDATE_STATUS_REPLY_FAILURE
          payload: 'An error occurred while updating user'


module.exports = AgentsActionCreator
