import React, { useState } from 'react';
import { controls } from '@verdaccio/crminfo';

import { QAInfoProvider } from './QAInfoContext';
import QAInfoItem from './QAInfoItem';

import styles from './index.styl';

const QAInfoList = ({ currentMessage, messages: approvedMessages, loading, currentConversationId, isAllUnfolds, user }) => {
  
  if (loading) {
    return <div className={styles.no_data_container}><controls.Loader sm_loader /></div>
  }

  if ((!currentMessage) && (!approvedMessages || Object.keys(approvedMessages).length === 0)) {
    return <div className={styles.no_data_container}>Please select message or tag</div>
  }

  return (
   <QAInfoProvider currentConversationId={currentConversationId} user={user}>
     {
       currentMessage && (
        <QAInfoItem message={currentMessage} isEditMode />
       )
     }
      {
        Object.entries(approvedMessages).map(([messageType, messages]) => {
          return Object.entries(messages).map(([messageId, massageReports]) => {
            return (
              <QAInfoItem key={messageId} message={massageReports} isAllUnfolds={isAllUnfolds} />
            )
          })
        })
      }
   </QAInfoProvider>
  )
};

export default QAInfoList;
