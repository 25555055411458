{ NavLink } = require 'react-router-dom'

styles                  = require 'components/shared/styles/list-item'
BaseComponent           = require 'components/BaseComponent'
InterfaceStore          = require 'stores/InterfaceStore'
InterfaceActionCreator  = require 'actions/InterfaceActionCreator'

propTypes = require 'prop-types'

class ChannelListItemComponent extends React.Component
  @propTypes:
    onClick: propTypes.func
    multiselectEnabled: propTypes.bool
    multiselected: propTypes.bool

  onClick: (e) =>
    e.preventDefault() if @props.multiselectEnabled
    @props.onClick?(e)

  render: ->
    className = styles.container
    className += " #{styles.multiselected_enabled}" if @props.multiselectEnabled
    className += " #{styles.multiselected}" if @props.multiselected
    className += ' ' + styles.disabled if !@props.multiselectEnabled && @props.channel?.service_name == 'API'

    React.createElement(NavLink, { \
      "to": ( "/overflow/channels/#{@props.channel.id}" ),  \
      "onClick": @onClick,  \
      "className": ( className ),  \
      "activeClassName": ( styles.selected )
    },
      React.createElement("div", {"className": ( styles.main_value )},
        ( @props.channel.name ),
        (
          if @props.channel.disabled
            React.createElement("span", {"style": ({color: 'red'})}, " ", ( "(Disabled)" ), " ")
          if @props.channel.auth_values?.role
            React.createElement("span", {"style": ({color: 'green'})}, " ", ( "(#{@props.channel.auth_values.role})" ), " ")
        )
      )
    )

module.exports = ChannelListItemComponent
