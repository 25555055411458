classnames = require 'classnames'

styles = require './customer-card-credential-form-component.styl'
CREDENTIAL_TYPE = require 'constants/CredentialTypes'
CREDITALS_PATTERNS = require 'constants/CredentialPaterns'
InlineTextInput = require '../InlineTextInput/InlineTextInput'

propTypes = require 'prop-types'

class CustomerCardCredentialFormComponent extends React.Component
  @propTypes:
    credential: propTypes.object.isRequired
    onChange: propTypes.func
    onDestroy: propTypes.func
    onDecryptCredential: propTypes.func

  onDecryptCredential: =>
    @props.onDecryptCredential? @props.credential

  onChange: (value) =>
    changedCredential = _.extend({}, @props.credential, { value: value })
    @props.onChange? changedCredential

  onDestroy: =>
    @props.onDestroy? @props.credential

  getChooseComponent: (isChosen, isEncrypted) =>
    return null if @props.isNotChoosable
    (isEncrypted && !isChosen) ||
    (React.createElement("i", { \
      "onClick": (if isEncrypted then null else @onChooseClick),  \
      "className": (classnames(styles.chosen_control,
        "#{styles.chosen_active}": isChosen
      )) 
    }))

  onChooseClick: (e) =>
    e.stopPropagation()    
    { credential } = @props
    changedCredential = _.extend({}, 
      credential 
      { is_chosen: !credential.is_chosen }
    )
    @props.onChange? changedCredential

  credentialColor: (credential) =>
    if credential.credential_type == 'email' && credential.value && credential.value.match("@qavls")
      return 'red'
    return 'default'

  render: ->
    { credential } = @props
    credentialStyle = classnames(
      styles.credential_value,
      styles["credential_value_color_#{@credentialColor(credential)}"]
    )
    React.createElement("div", {"className": (styles.container)},
      React.createElement("div", {  \
        "className": (classnames(
          styles.credential_icon 
          styles["credential_icon_#{credential.credential_type}"]
        )),  \
        "title": (credential.description)
      }),
      (
        if @props.disabled
          React.createElement("span", {"className": (credentialStyle)},
            (credential.value)
          )
        else
          React.createElement(InlineTextInput, { \
            "className": (credentialStyle),  \
            "value": (credential.value),  \
            "onSubmit": (@onChange),  \
            "onDelete": (@onDestroy if @props.onDestroy),  \
            "textAddition": ((isEncrypted) => @getChooseComponent(credential.is_chosen, isEncrypted)),  \
            "disabled": (!CREDENTIAL_TYPE._EDITABLE[credential.credential_type]),  \
            "editByIcon": (@props.editByIcon),  \
            "isDecrypted": (credential.isDecrypted),  \
            "onDecryptCredential": (@onDecryptCredential if @props.onDecryptCredential),  \
            "pattern": (CREDITALS_PATTERNS[credential.credential_type]),  \
            "id": (credential.id),  \
            "isValidEmail": (credential.isValid)
          })
      )
    )

module.exports = CustomerCardCredentialFormComponent
