classnames = require 'classnames'
deepcopy = require 'deepcopy'
ReactDnD = require 'react-dnd'
Isvg = require 'react-inlinesvg'

Button = require 'components/shared/Button'
ItemTypes = require './../ItemTypes'
WarningPopupComponent = require '../WarningPopupComponent'
Checkbox = require 'components/shared/Checkbox'
ReplyAnswersActionCreator = require 'actions/ReplyAnswersActionCreator'
dndUtils = require 'lib/dnd-utils'
styles = require './categories-section'
sectionStyles = require '../section-style.styl'


propTypes = require 'prop-types'

OPTIONS =
  'message': [
    ['support', 'SUPPORT ONLY'],
    ['sales', 'SALES ONLY'],
    ['ff', 'FF ONLY']
  ]
  'ticket': [
    ['fcm', 'SM ONLY'],
    ['offf', 'OFFF ONLY'],
    ['qaff', 'QAFF ONLY'],
    ['irff', 'IRFF ONLY'],
    ['offf_geekly', 'GEEKLY SUPPORT ONLY']
  ]
  'ir': [
    ['fcm', 'SM ONLY'],
    ['offf', 'OFFF ONLY'],
    ['qaff', 'QAFF ONLY'],
    ['irff', 'IRFF ONLY'],
    ['offf_geekly', 'GEEKLY SUPPORT ONLY']
  ]
  'thread': [
    ['fcm', 'SM ONLY'],
    ['offf', 'OFFF ONLY'],
    ['qaff', 'QAFF ONLY'],
    ['irff', 'IRFF ONLY'],
    ['offf_geekly', 'GEEKLY SUPPORT ONLY']
  ]

categorySource =
  beginDrag: (props) ->
    id: props.category.id
    index: props.index

  endDrag: (props, monitor) ->
    dragId = monitor.getItem().id
    props.updatePosition(dragId) if monitor.didDrop()

categoryTarget =
  hover: dndUtils.standartHoverHandler

  canDrop: (props, monitor) ->
    return !!props.category

class CategoriesComponent extends React.Component
  constructor: (props) ->
    super(props)
    @state = {
      edit: null
    }

  componentDidUpdate: (prevProps, prevState) ->
    if @props.isSub
      if !prevState.edit && @state.edit
        @refs.input.addEventListener('blur', @inputBlur)

  inputBlur: =>
    @onCategorySave()

  onCategorySave: =>
    if @state.edit && @state.edit.name.trim()
      edit = @state.edit
      edit.template_type = @props.templateType
      edit.template_type += ('_' + edit.suffix) if edit.suffix
      ReplyAnswersActionCreator.updateGroup edit
      @setState edit: null

  onCancel: =>
    edit = @state.edit
    Object.keys(@state.category).forEach (key) =>
      edit[key] = @state.category[key]
    @setState edit: null

  onCategoryEdit: (e) =>
    e.stopPropagation()
    categoryId = parseInt e.currentTarget.dataset.id, 10
    category = @props.categories.filter((c) -> c.id == categoryId)[0]
    @setState
      edit: category
      category: deepcopy(category)

  onEditChange: (e) =>
    { edit } = @state
    edit.name = e.target.value
    @setState { edit }

  onSuffixChange: (suffix) =>
    () =>
      { edit } = @state
      if edit.suffix == suffix
        edit.suffix = null
        edit.categories.forEach (c) ->
          c.suffix = null
      else
        edit.suffix = suffix
        edit.categories.forEach (c) ->
          c.suffix = suffix
      @setState { edit }

  suffixChecked: (suffix) =>
    @state.edit.suffix == suffix

  render: ->
    {
      category,
      onCategoryClick,
      onCategoryDelete,
      onCancelDeleting,
      onApproveDeleting,
      selected,
      deleteCategoryId,
    } = @props

    @props.connectDragSource(
      @props.connectDropTarget(
        React.createElement("li", {"className": (classnames(
          "#{sectionStyles.dropable}": @props.canDrop
          "#{sectionStyles.hide}": @props.isDragging
        ))},
          (if @state.edit && @state.edit.id == category.id
            React.createElement("div", null,
              React.createElement("input", { \
                "value": (@state.edit.name),  \
                "onChange": (@onEditChange),  \
                "ref": "input",  \
                "type": 'text',  \
                "className": (classnames(styles.input_field, styles.input_field_edit))
              }),
              (if !@props.isSub
                React.createElement("div", null,
                  ((OPTIONS[@props.templateType] || []).map (option) =>
                    React.createElement("div", {"className": (styles.options)},
                      React.createElement(Checkbox, { \
                        "checked": (@suffixChecked(option[0])),  \
                        "onClick": (@onSuffixChange(option[0]))
                      }),
                      React.createElement("span", {"className": (styles.label)}, (option[1]))
                    )
                  ),
                  React.createElement("div", null,
                    React.createElement(Button, { \
                      "title": 'Cancel',  \
                      "className": (styles.button),  \
                      "onClick": (@onCancel),  \
                      "white": true
                    }),
                    React.createElement(Button, { \
                      "title": 'Save',  \
                      "className": (styles.button),  \
                      "onClick": (@onCategorySave)
                    })
                  )
                )
              )
            )
          else
            React.createElement("div", { \
              "className": (classnames(sectionStyles.item, styles.category,
                "#{sectionStyles.item_selected}": selected == category.id
              )),  \
              "onClick": (onCategoryClick),  \
              "data-value": (category.id)
            },
              React.createElement("span", null, (category.name)),
              React.createElement("span", {"className": (styles.category_controls)},
                React.createElement("span", { \
                  "onClick": (@onCategoryEdit),  \
                  "data-id": (category.id),  \
                  "className": (styles.edit)
                },
                  React.createElement(Isvg.default, { \
                    "src": (require 'components/shared/images/pancil.svg')
                  })
                ),
                React.createElement("span", { \
                  "onClick": (onCategoryDelete),  \
                  "data-id": (category.id),  \
                  "className": (styles.delete)
                },
                  React.createElement(Isvg.default, { \
                    "src": (require 'components/shared/images/delete.svg')
                  })
                )
              ),

              (if deleteCategoryId && deleteCategoryId == category.id
                React.createElement("div", {"className": (styles.delete_popup)},
                  React.createElement(WarningPopupComponent, { \
                    "text": "Are you sure you want to delete that category?",  \
                    "onCancel": (onCancelDeleting),  \
                    "onDelete": (onApproveDeleting)
                  })
                )
              )
            )
          )
        )
      )
    )


DragSourceDecorator = ReactDnD.DragSource(
  ItemTypes.TEMPLATE_GROUP,
  categorySource,
  (connect, monitor) ->
    connectDragSource: connect.dragSource()
    isDragging: monitor.isDragging()
)

DropTargetDecorator = ReactDnD.DropTarget(
  ItemTypes.TEMPLATE_GROUP,
  categoryTarget,
  (connect, monitor) ->
    connectDropTarget: connect.dropTarget()
    canDrop: monitor.canDrop()
)
CategoriesComponent.propTypes =
  category: propTypes.shape(
    id: propTypes.number
    name: propTypes.string.isRequired
  ).isRequired
  onCategoryClick: propTypes.func
  onCategoryDelete: propTypes.func
  onCancelDeleting: propTypes.func
  onApproveDeleting: propTypes.func
  onCategoryEdit: propTypes.func
  onEditChange: propTypes.func
  selected: propTypes.bool
  deleteCategoryId: propTypes.number
  edit: propTypes.bool
  onClick: propTypes.func.isRequired
  connectDragSource: propTypes.func.isRequired
  connectDropTarget: propTypes.func.isRequired
  isDragging: propTypes.bool.isRequired
  swapItems: propTypes.func.isRequired
  updatePosition: propTypes.func

module.exports = DropTargetDecorator(DragSourceDecorator(CategoriesComponent))
