uuid                          = require 'uuid'
moment                        = require 'moment'
classnames                    = require 'classnames'

BaseComponent                 = require '../../BaseComponent'
{formatFromSecondsToTime}     = require '../../../lib/utils'
{STATUSES_RANKING_RULE, QUALITY, BAD_TAGS, STATUSES} = require '../../../constants/Ranking'

styles                        = require './ranking-conversation-item'

propTypes                     = require 'prop-types'

class RankingConversationsItemComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  @propTypes:
    conversation: propTypes.object.isRequired

  buildRankingScore: =>
    {approve_data} = @props.conversation
    return [] unless approve_data.ranking_role_message_score || approve_data.ranking_role_log_message_score
    data = []

    wrapStyles = classnames(
      styles['conversation__conversation-info-ranking_wrap']
    )
    scoreStyles = classnames(
      styles['conversation__conversation-info-ranking']
      styles['conversation__conversation-info-ranking_score']
    )

    if approve_data.ranking_role_message_score
      rankigOrderStyles = classnames(
        styles['conversation__conversation-info-ranking']
        styles["conversation__conversation-info-ranking_chat"]
      )

      data.push React.createElement("div", {"key": (uuid.v4()), "className": (wrapStyles)}, React.createElement("span", {"className": (rankigOrderStyles)},
          (STATUSES_RANKING_RULE.message_ranked.label)
        ),
        React.createElement("span", {"className": (scoreStyles)}, """
          Final score: """, (approve_data.ranking_role_message_score)
        ))


    if approve_data.ranking_role_log_message_score
      rankigChatStyles = classnames(
        styles['conversation__conversation-info-ranking']
        styles['conversation__conversation-info-ranking_order']
      )

      data.push React.createElement("div", {"key": (uuid.v4()), "className": (wrapStyles)}, React.createElement("span", {"className": (rankigChatStyles)},
          (STATUSES_RANKING_RULE.order_ranked.label)
        ),
        React.createElement("span", {"className": (scoreStyles)}, """
          Final score: """, (approve_data.ranking_role_log_message_score)
        ))

    data


  buildConversationApproveData: =>
    {approve_data} = @props.conversation
    return [] unless approve_data.status

    { rule_ranking } = approve_data
    
    if !rule_ranking
      statusStyles = classnames(
        styles['conversation__conversation-info-tag']
        styles["conversation__conversation-info-tag_nonrankable"]
      )
      return [
        React.createElement("span", {"key": (0), "className": (statusStyles)},
          (STATUSES_RANKING_RULE.unranked.label)
        )
      ]

    status = STATUSES_RANKING_RULE[rule_ranking.status] || STATUSES[rule_ranking.status]
    statusStyles = classnames(
      styles['conversation__conversation-info-tag']
      styles["conversation__conversation-info-tag_#{rule_ranking.status}"]
    )
    return [
      React.createElement("span", {"key": (0), "className": (statusStyles)},
        (status.label)
      )
    ]

  render: ->
    { conversation } = @props
    {approve_data} = @props.conversation
    last_message = conversation.last_message || {}
    React.createElement("div", {"className": (styles.conversation)},
      React.createElement("div", {"className": (styles['conversation__customer-info'])},
        React.createElement("span", {"className": (styles['conversation__customer-info-name'])},
          (conversation.customer.name)
        ),
        React.createElement("span", {"className": (styles['conversation__customer-info-service'])},
          ("(#{(last_message.channel || {}).name})" if (last_message.channel || {}).name)
        ),
        React.createElement("span", {"className": (styles['conversation__customer-info-date'])},
          (moment(conversation.created_at).format 'D MMM')
        )
      ),
      React.createElement("ul", {"className": (styles.conversation__tags)},
        (
          conversation.tags.map (tag, i) ->
            React.createElement("li", {"className": (styles.conversation__tag), "key": (i)}, "#", (tag.name))
        )
      ),

      React.createElement("div", {"className": (styles.flex)},
        (if approve_data?.ranking_role_message_score ||approve_data?.ranking_role_log_message_score
          React.createElement("div", null,
            (@buildRankingScore())
          )
        else 
          @buildConversationApproveData()
        ),
        React.createElement("div", null,
          React.createElement("span", {"className": (styles['conversation__conversation-info-message'])},
            (conversation.messages_count)
          ),
          React.createElement("span", {"className": (styles['conversation__conversation-info-time'])},
            (formatFromSecondsToTime conversation.average_response_time)
          )
        )
      )

    )

module.exports = RankingConversationsItemComponent
