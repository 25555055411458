_ = require 'underscore'
uuid = require 'uuid'
classnames = require 'classnames'

config = require 'root/config'
styles = require 'components/shared/BaseFormComponent/base-form'
myStyles = require './channel-form'
AuthStore = require 'stores/AuthStore'
BaseFormComponent = require 'components/shared/BaseFormComponent/BaseFormComponent'
SaveButtonComponent = require 'components/shared/SaveButtonComponent/SaveButtonComponent'
ChannelFormStore = require './ChannelFormStore'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'

class ChannelFormComponent extends BaseFormComponent
  constructor: (props) ->
    super(props)
    @authFields = {
      Twilio: [
        ["role", 'Role']
      ]
      Facebook: [
        ["role", 'Role']
      ]
      Custom: [
        ["role", 'Role']
      ]
      Customerio: [
        ["role", 'Role']
      ]
      Mynerdify: [
        ["role", 'Role']
      ]
      Bandwidth: [
        ["role", 'Role']
      ]
      Xmpp: [
        ["role", 'Role']
      ]
      AiXmpp: [
        ["role", 'Role']
      ]
    }
    @initComponent()

  attributes: ['id', 'auth_values']

  getPersonalStoreClass: -> ChannelFormStore

  getState: (props = @props, state) ->
    state = super(props, state)
    _(state).extend
      user: AuthStore.user
      attributes: if props.new then @defaults() else _.extend({}, @defaults(), @personalStore.channel)
      loading: @personalStore.loading

  getDataKey: =>
    @props.match.params.id

  requestData: =>
    if @props.match.params.id
      _.defer @actionCreator.get, @props.match.params.id
    else
      _.defer @actionCreator.new

  options: [
    { value: 'all', label: 'All' },
    { value: 'overflow_agent', label: 'OverflowAgent' },
  ]

  defaults: ->
    auth_values: { role: 'all' }

  selectHandler: (e) =>
    newState = @state

    newState.attributes.auth_values['role'] = e.target.value

    @setState newState

  selectValue: () =>
    @state.attributes.auth_values?['role'] || 'all'

  getterAuthValues: (attr) =>
    if attr == 'name' || attr == 'highlight_color' || attr == 'country_type'
      @state.attributes[attr]
    else
      @state.attributes.auth_values?[attr]

  submitForm: (e) =>
    attrs = {}
    attrs['auth_values'] = @state.attributes['auth_values']
    attrs['id'] = @state.attributes['id']
    if attrs['auth_values']['role'] == 'all'
      delete attrs['auth_values']['role']
    if @state.attributes.id
      @actionCreator.update @state.attributes.id, attrs, @showSavedPopup, @showErrorPopup
    e.preventDefault()

  render: ->
    React.createElement("div", {"className": ( classnames styles.scroll_container, myStyles.container )},
      React.createElement("div", {"className": ( styles.container )},
        React.createElement(LoadableWrapperComponent, {"loading": ( @state.loading.get || @state.loading.createOrUpdate )},
          React.createElement("form", {"onSubmit": ( @submitForm )},
            ( @renderErrors() ),
            (
              @renderSelect(
                'auth_values_role',
                'Role',
                @options,
                {handler: @selectHandler, value: @selectValue}
              )
            ),
            React.createElement("div", {"className": ( styles.form_group )},
              React.createElement(SaveButtonComponent, { \
                "type": ( if @state.errors then 'tryAgain' else 'normal' ),  \
                "popup": @state.saveButtonPopup,  \
                "process": @state.loading.createOrUpdate
              })
            )
          )
        )
      )
    )

module.exports = ChannelFormComponent
