{MIN_PASSWORD_LENGTH} = require '../constants/Agents'

validateOnEmpty = (value) ->
  if value.trim() == ''
    "Field can't be blank"

validateOnEmail = (value) ->
  emailRegex = /.+@.+\..+/i
  unless value.match emailRegex
    'Email has wrong format'

validatePassword = (value) ->
  if value.trim().length < MIN_PASSWORD_LENGTH
    "Password must be #{MIN_PASSWORD_LENGTH} symbols or greater"

validateOnAny = (value) ->
  false

module.exports =
  validateOnEmpty: validateOnEmpty
  validateOnEmail: validateOnEmail
  validatePassword: validatePassword
  validateOnAny: validateOnAny
