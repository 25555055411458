BaseStore = require './BaseStore'
ActionTypes = require '../constants/ActionTypes'

actionMap =
  "#{ActionTypes.AUTH_VALIDATE_TOKEN_REQUESTING}": ->
    @validateTokenRequesting = true
    @emitChange()
  "#{ActionTypes.AUTH_VALIDATE_TOKEN_SUCCESS}": ({user}) ->
    @validateTokenRequesting = false
    @user = user
    @authorized = true
    @emitChange()
  "#{ActionTypes.AUTH_VALIDATE_TOKEN_FAILURE}": ->
    @validateTokenRequesting = false
    @authorized = false
    window.location.reload()
  "#{ActionTypes.MQTT_USER_UPDATING}":({message}) ->
    Object.keys(message).forEach (key) =>
      @user[key] = message[key]
    @emitChange()
  "#{ActionTypes.AGENTS_UPDATE_STATUS_REPLY_REQUESTING}": ->
    @loading = true
    @errors = null
    @emitChange()
  "#{ActionTypes.AGENTS_UPDATE_STATUS_REPLY_SUCCESS}":(payload) ->
    @loading = false
    @errors = null
    @user.reply_template_status = payload?.options?.reply_template_status
    @user.wfh_notification = payload?.options?.wfh_notification
    @user.brb_sound = payload?.options?.brb_sound
    @user.shortcuts = payload?.options?.shortcuts
    @emitChange()

class AuthStore extends BaseStore
  constructor: () ->
    super()
    @user = {}
    @loading = false
    @authorized = undefined
    @validateTokenRequesting = false
    @registerActions()

  _clear: =>
    @errors = []
    @awaiting = no

  _registerToActions: ({type, payload}) =>
    actionMap[type].call @, payload if actionMap[type]

module.exports = new AuthStore()
