Highcharts = require 'highcharts'

BaseComponent = require 'components/BaseComponent'
AuthStore = require 'stores/AuthStore'
ConversationsDashboardStore = require 'stores/ConversationsDashboardStore'
ConversationsUnassignedStore = require 'stores/ConversationsUnassignedStore'
AgentsDashboardStore = require 'stores/AgentsDashboardStore'
ShiftAgentsStore = require 'stores/ShiftAgentsStore'
ChartComponent = require '../../ChartComponent/ChartComponent'
ConversationsActionCreator = require 'actions/ConversationsActionCreator'
AgentsDashboardActionCreator = require 'actions/AgentsDashboardActionCreator'
ShiftAgentsActionCreator = require 'actions/ShiftAgentsActionCreator'
{ buildOptions } = require 'lib/dashboard-utils'
{ ROLES, FOR_DASHBOARD } = require 'constants/Agents'
conf = require 'root/config'

styles = require '../dashboard.styl'
ConversationsActionCreator = new ConversationsActionCreator()

class ActiveStatusConversationByAgentsComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()
  
  dependsOnStores: [ConversationsDashboardStore, AgentsDashboardStore, ConversationsUnassignedStore, ShiftAgentsStore]

  componentDidMount: ->
    super()
    setTimeout(
      ->
        ConversationsActionCreator.subscribeDashboardConversations()
        ConversationsActionCreator.subscribeUnassignedConversations()
        AgentsDashboardActionCreator.subscribe()
        if AuthStore.user.role == 'teamlead'
          ShiftAgentsActionCreator.sendInitial()
        else
          ShiftAgentsActionCreator.dashboardSubscribe()
      0
    )

  componentWillUnmount: ->
    super()
    setTimeout(
      ->
        ConversationsActionCreator.unSubscribeDashboardConversations()
        ConversationsActionCreator.unSubscribeUnassignedConversations()
        AgentsDashboardActionCreator.unSubscribe()
        if AuthStore.user.role != 'teamlead'
          ShiftAgentsActionCreator.dashboardUnSubscribe()
      0
    )


  getActiveConversationsInAgentsStatus: (position) =>
    countedNew = {}
    countedOpened = {}
    countedDormant = {}
    agentsList = ConversationsDashboardStore.agents

    ids = Object.keys(agentsList).filter (id) ->
      ROLES[agentsList[id].role].value in FOR_DASHBOARD
    index = Math.trunc(ids.length / 2)
    arrays = [ids.slice(0, index), ids.slice(index)]
    ids = arrays[position]

    xAxis:
      categories: _.map ids, (id) ->
        uc = '-'
        agent = AgentsDashboardStore.agents[parseInt(id)]
        if agent
          uc = agent.last_shift?.unique_clients_count || 0
        agentsList[id].last_name + ' <b>' + uc + '</b>'
    series: [{
        name: 'New'
        data: _.map ids, (id) -> if agentsList[id].new_message then agentsList[id].new_message else null
        color: '#76d466'
    }, {
        name: 'Open'
        data: _.map ids, (id) -> if agentsList[id].opened then agentsList[id].opened else null
        color: '#53a8ff'
    }, {
        name: 'Dormant'
        data: _.map ids, (id) -> if agentsList[id].dormant then agentsList[id].dormant else null
        color: '#ffdc00'
    }, {
        name: 'Inactive'
        data: _.map ids, (id) -> if agentsList[id].inactive then agentsList[id].inactive else null
        color: '#fbe6e4'
    }]
    chart:
      type: 'column'
    plotOptions:
      column:
        groupPadding: 0
        stacking: 'normal'
        dataLabels:
          enabled: true
          color: (Highcharts.theme && Highcharts.theme.dataLabelsColor) || 'white'
          style: textShadow: '0 0 3px black'
    title: text: 'Currently  active  status  conversation by agents'
    yTitle: 'Conversations'

  calculateWFH: ->
    csrCount = 0
    salesCount = 0
    csrBreak = 0
    salesBreak = 0
    agents = ShiftAgentsStore.agentsWFH
    for key, agent of agents
      { role_names, break_at } = agent
      if role_names.includes('csr_sales')
        salesCount += 1
        if break_at
          salesBreak += 1
      if ['csr_agent', 'csr_newbie_agent'].some((r) -> role_names.includes(r))
        csrCount += 1
        if break_at
          csrBreak += 1
    { csrCount, csrBreak, salesCount, salesBreak }

  render: ->
    unassigned = ConversationsUnassignedStore
    { sales, csr } = ConversationsDashboardStore
    { csrCount, csrBreak, salesCount, salesBreak } = @calculateWFH()

    React.createElement("div", null,
      React.createElement("div", {"className": (styles.grid)},
        React.createElement("div", {"className": (styles.full_row_of_grid)},
          React.createElement("div", {"className": (styles.chart_container)},
            React.createElement(ChartComponent, { \
              "container": 'ActiveConversationsInAgentsStatusTop',  \
              "options": (buildOptions @getActiveConversationsInAgentsStatus(0))
            })
          )
        ),
        React.createElement("div", {"className": (styles.full_row_of_grid)},
          React.createElement("div", {"className": (styles.chart_container)},
            React.createElement(ChartComponent, { \
              "container": 'ActiveConversationsInAgentsStatusBottom',  \
              "options": (buildOptions @getActiveConversationsInAgentsStatus(1))
            })
          )
        )
      ),
      React.createElement("div", {"className": (styles.totals)},
        React.createElement("div", {"className": (styles.totals__row)},
          React.createElement("div", {"className": (styles.totals__col)}, "Totals"),
          React.createElement("div", {"className": (styles.totals__col)}, "CSR"),
          (conf.showTotalActiveConversationsSales &&
            React.createElement("div", {"className": (styles.totals__col)}, "SAL")
          )
        ),
        React.createElement("div", {"className": (styles.totals__row)},
          React.createElement("div", {"className": (styles.totals__col)}, "All"),
          React.createElement("div", {"className": (styles.totals__col)}, ((csr.total || 0) + unassigned.csrCount)),
          (conf.showTotalActiveConversationsSales &&
            React.createElement("div", {"className": (styles.totals__col)}, ((sales.total || 0) + unassigned.salesCount))
          )
        ),
        React.createElement("div", {"className": (styles.totals__row)},
          React.createElement("div", {"className": (styles.totals__col)}, React.createElement("span", {"className": (styles.totals__unassigned)}, "Unassigned")),
          React.createElement("div", {"className": (styles.totals__col)}, (unassigned.csrCount)),
          (conf.showTotalActiveConversationsSales &&
            React.createElement("div", {"className": (styles.totals__col)}, (unassigned.salesCount))
          )
        ),
        React.createElement("div", {"className": (styles.totals__row)},
        React.createElement("div", {"className": (styles.totals__col)}, React.createElement("span", {"className": (styles.totals__new_message)}, "New")),
          React.createElement("div", {"className": (styles.totals__col)}, (csr.new_message || 0)),
          (conf.showTotalActiveConversationsSales &&
            React.createElement("div", {"className": (styles.totals__col)}, (sales.new_message || 0))
          )
        ),
        React.createElement("div", {"className": (styles.totals__row)},
          React.createElement("div", {"className": (styles.totals__col)}, React.createElement("span", {"className": (styles.totals__opened)}, "Open")),
          React.createElement("div", {"className": (styles.totals__col)}, (csr.opened || 0)),
          (conf.showTotalActiveConversationsSales &&
            React.createElement("div", {"className": (styles.totals__col)}, (sales.opened || 0))
          )
        ),
        React.createElement("div", {"className": (styles.totals__row)},
          React.createElement("div", {"className": (styles.totals__col)}, React.createElement("span", {"className": (styles.totals__dormant)}, "Dormant")),
          React.createElement("div", {"className": (styles.totals__col)}, (csr.dormant || 0)),
          (conf.showTotalActiveConversationsSales &&
            React.createElement("div", {"className": (styles.totals__col)}, (sales.dormant || 0))
          )
        ),
        React.createElement("div", {"className": (styles.totals__row)},
          React.createElement("div", {"className": (styles.totals__col)}, React.createElement("span", {"className": (styles.totals__inactive)}, "Inactive")),
          React.createElement("div", {"className": (styles.totals__col)}, (csr.inactive || 0)),
          (conf.showTotalActiveConversationsSales &&
            React.createElement("div", {"className": (styles.totals__col)}, (sales.inactive || 0))
          )
        ),
        React.createElement("div", {"className": (styles.totals__row)}, React.createElement("div", {"className": (styles.totals__col)})),
        React.createElement("div", {"className": (styles.totals__row)},
          React.createElement("div", {"className": (styles.totals__col)}, "Shift"),
          React.createElement("div", {"className": (styles.totals__col)}, (csrCount || 0)),
          (conf.showTotalActiveConversationsSales &&
            React.createElement("div", {"className": (styles.totals__col)}, (salesCount || 0))
          )
        ),
        React.createElement("div", {"className": (styles.totals__row)},
          React.createElement("div", {"className": (styles.totals__col)}, "BRB"),
          React.createElement("div", {"className": (styles.totals__col)}, (csrBreak || 0)),
          (conf.showTotalActiveConversationsSales &&
            React.createElement("div", {"className": (styles.totals__col)}, (salesBreak || 0))
          )
        )
      )
    )

module.exports = ActiveStatusConversationByAgentsComponent
