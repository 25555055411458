Auth = require 'j-toker'
_ = require 'underscore'

styles = require './app-component.styl'
AuthStore = require 'stores/AuthStore'
BaseComponent = require './BaseComponent'
MenuComponent = require './menu/MenuComponent/MenuComponent'
Dispatcher = require '../dispatcher/Dispatcher'
ActionTypes = require '../constants/ActionTypes'
InterfaceStore = require 'stores/InterfaceStore'
SoundStore = require 'stores/SoundStore'
AuthActionCreator = require 'actions/AuthActionCreator'
ConversationTemplatesActionCreator = require 'actions/ConversationTemplatesActionCreator'
InterfaceActionCreator = require 'actions/InterfaceActionCreator'
RankingRulesActionCreator = require 'actions/RankingRulesActionCreator'
{ ACTIVITIES, ROLES, SHIFT_TIME } = require 'constants/Agents'
SystemAttentionComponent = require './shared/SystemAttentionComponent'
LoadableWrapperComponent = require './shared/LoadableWrapperComponent/LoadableWrapperComponent'
config = require '../config'
ModalWindow = require './shared/ModalWindow'
TagsWarningComponent = require './tags/TagsWarningComponent'
SettingsActionCreator = require 'actions/SettingsActionCreator'
CRMInfoModuleActionCreator = require('actions/CRMInfoModuleActionCreator').default
CRMPluginActionCreator = require('actions/CRMPluginActionCreator').default
MqttActionCreator = require 'actions/MqttActionCreator'
MqttStoreClass = require 'stores/MqttStore'
MqttStore = new MqttStoreClass()
MqttGlobalStoreClass = require 'stores/MqttGlobalStore'
MqttGlobalStore = new MqttGlobalStoreClass()
AttentionComponent = require './shared/AttentionComponent'
CRMPluginStore = require 'stores/CRMPluginStore'
CRMInfoModuleStore = require 'stores/CRMInfoModuleStore'
{ buildCRMInfoRequestData, getQueryParams } = require 'lib/utils'
SettingsStore = require 'stores/SettingsStore'
ShiftAgentsStore = require 'stores/ShiftAgentsStore'
ProfileStore = require 'stores/ProfileStore'
CustomerTicketsActionCreator = require 'components/customers/CRMInfoModule/CustomerTicketsComponent/CustomerTicketsActionCreator'
OnlineActionCreator = require 'actions/OnlineActionCreator'
TeamleadNotifications = require 'components/notifications/TeamleadNotifications'
IdlePopup = require 'components/notifications/IdlePopup'
{ localStorageKey } = require 'constants/CRMPlugin'
Notification = require('react-web-notification').default
AgentLocationModalComponent = require 'components/agents/AgentLocationModalComponent'
browserHistory = require('root/history').default

crminfo = null
if config.crmPluginEnabled
  crminfo = require '@verdaccio/crminfo'

{ WFHNoticeContainer, WFHRollCallViolationContainer } = crminfo.WFHTool


require '../vendor/stylesheets/normalize'
require '../vendor/stylesheets/font-awesome/font-awesome'
require '../assets/stylesheets/base'
require '../../node_modules/croppie/croppie.css'
require '../../node_modules/dropzone/dist/dropzone.css'
require '../../node_modules/emojione-picker/css/picker.css'
require '../../node_modules/emojione/assets/css/emojione.css'


class AppComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @state =
      connected: true
      upToDate: true
      locationUpToDate: true
    if config.updateNotification
      @state.version = __webpack_hash__
    @initComponent()

  dependsOnStores: [AuthStore, InterfaceStore, CRMPluginStore, MqttStore, SoundStore, MqttGlobalStore, ShiftAgentsStore]

  getState: =>
    authorizing: AuthStore.validateTokenRequesting
    authorized: AuthStore.authorized
    burger: InterfaceStore.burger
    onlyRouteComponent: InterfaceStore.onlyRouteComponent
    user: AuthStore.user

  closeMenu: InterfaceActionCreator.closeBurgerMenu

  requestData: =>
    _.defer AuthActionCreator.validateToken

  componentDidMount: ->
    window.onerror = @onConnectionError if config.sentry
    SettingsActionCreator.get()
    MqttActionCreator.connect()
    MqttActionCreator.connectGlobal()
    OnlineActionCreator.subscribe()
    super()

  onConnectionError: (msg, url, lineNo, columnNo, error) =>
    return if !error && msg == 'ResizeObserver loop limit exceeded'

    jsonErrorReg = /Uncaught SyntaxError: Unexpected token.*in JSON at position.*/
    @setState connected: false if msg && msg.match jsonErrorReg

  # TODO: move to utils
  checkLocation: (location) =>
    return true unless location
    return true if !location.country && !location.region && !location.city
    return true unless location.updated_at
    (new Date().getTime() - location.updated_at) >= SHIFT_TIME

  componentDidUpdate: ->
    super()
    if AuthStore.user.activity == ACTIVITIES.wasted.value &&
        AuthStore.user.id
      AuthActionCreator.logout()

    if @state.locationUpToDate && AuthStore.user.id
      if @checkLocation(AuthStore.user.location)
        setTimeout(
          ->
            InterfaceActionCreator.openModal(AgentLocationModalComponent, {
              type: 'agentLocation'
              location: AuthStore.user.location
              id: AuthStore.user.id
            })
          10
        )
      @setState locationUpToDate: false

    if config.updateNotification && InterfaceStore.bundleVersion
      if @state.version != InterfaceStore.bundleVersion && @state.upToDate
        @setState upToDate: false
      if @state.version == InterfaceStore.bundleVersion && !@state.upToDate
        @setState upToDate: true

  closeModal: -> InterfaceActionCreator.closeModal()
  closeLockedModal: -> InterfaceActionCreator.closeLockedModal()

  onNotificationClick: (e) ->
    window.focus()
    data = e.currentTarget.data
    if data
      link = "/conversations/#{data.folder}/#{data.conversation_id}"
      browserHistory.push(link)

  refreshCrmData: (event)->
    allConfigs = localStorage.getItem(localStorageKey)

    if event.event == 'saveModalContext'
      CRMPluginActionCreator.setConfig(
        event.config, event.entityKey, event.configKey, true, true
      )
    if event.event == 'createOrder'
      customer = CRMInfoModuleStore.currentCustomer
      externalClientId = CRMInfoModuleStore.externalClientId
      if customer && customer.crm_ids && customer.crm_ids.length > 0 && externalClientId && externalClientId == event.clientId
        CRMInfoModuleActionCreator.getOrders(
          customer
          config.urls.customers.orders(customer.crm_ids[0])
          forceUpdate: true
        )
    if event.event == 'createTicket'
      externalClientId = CRMInfoModuleStore.externalClientId
      if externalClientId
        CustomerTicketsActionCreator.getTickets(externalClientId)
    if event.event == 'copyTextToChat'
      setTimeout(
        -> ConversationTemplatesActionCreator.setTemplate(
          template: event.template,
          clientId: event.clientId
        )
        10
      )
    if event.event == 'sendMessages'
      sendMessage = (client, message, interval, advOptions, idx, eventName) ->
        setTimeout(
          ->
            crminfo.sendMessage(event.client, message, advOptions, idx, eventName)
          interval
        )
      interval = 0
      for i in [0...event.messages.length]
        sendMessage(event.client, event.messages[i], interval, event.advOptions, i, event.eventName)
        interval += 4000
    if event.event == 'saveContext'
      allConfigs = localStorage.getItem(localStorageKey)
      try
        allConfigs = JSON.parse(allConfigs) || {}
      catch err
        allConfigs = {}
      allConfigs[event.entityKey] = {} unless allConfigs[event.entityKey]
      allConfigs[event.entityKey][event.configKey] = event.config
      localStorage.setItem(localStorageKey, JSON.stringify(allConfigs))
      if event.openWithContext
        CRMPluginActionCreator.setConfig(
          event.config, event.entityKey, event.configKey, true
        )
    if event.event == 'reloadClientInfo'
      customer = CRMInfoModuleStore.currentCustomer
      externalClientId = CRMInfoModuleStore.externalClientId
      if externalClientId == event.clientId
        return if !customer.crm_ids
        return if customer.crm_ids.length == 0
        CRMInfoModuleActionCreator.getOrders(
          customer
          config.urls.customers.orders(customer.crm_ids[0])
          forceUpdate: true
        )
        CRMInfoModuleActionCreator.getCustomerData(
          customer
          config.urls.customers.crmInfo(customer.crm_ids[0])
          config.urls.customers.entityThreads(customer.crm_ids[0])
          forceUpdate: true
        )

    if event.event == 'reloadRankingRule'
      params = getQueryParams window.location.search.substring 1
      if event.payload
        setTimeout(
          => RankingRulesActionCreator.getRules params
          10
        )

        # CRMInfoModuleActionCreator.get(
        #   customer
        #   config.urls.customers.crmInfo(customer.crm_ids[0])
        #   forceUpdate: true
        # )
        # CRMInfoModuleActionCreator.getCommunications(
        #   customer
        #   config.urls.customers.entityThreads(customer.crm_ids[0])
        #   forceUpdate: true
        # )

  renderNotifications: =>
    if AuthStore.user.role == ROLES.teamlead.value
      React.createElement(TeamleadNotifications, null)

  renderPopups: =>
    React.createElement(IdlePopup, null)

  renderWFHNotifications: =>
    { user } = AuthStore;
    isTeamlead = user.role == ROLES.teamlead.value
    { brb_sound = false } = user
    onSoundNotificationBRB = isTeamlead && brb_sound

    React.createElement("div", null,
      React.createElement(WFHNoticeContainer, {"agents": (Object.values(ShiftAgentsStore.agentsWFH)), "currentAgent": (ProfileStore?.profile?.id), "onSoundNotificationBRB": (onSoundNotificationBRB), "refreshCrmData": (@refreshCrmData)}),
      React.createElement(WFHRollCallViolationContainer, {"agents": (Object.values(ShiftAgentsStore.agentsWFH)), "currentAgent": (ProfileStore?.profile?.id)})
    )

  render: ->
    { children } = @props
    attentionComponent = null
    { user } = @state;
    canGetSoundNotifications = config.mqttSound || user.new_message_sound || user.brb_sound
    isTeamlead = user.role == ROLES.teamlead.value
    { wfh_notification = true } = user
    showWfhNotification = (isTeamlead && wfh_notification) || !isTeamlead



    if InterfaceStore.popupComponent
      PopupComponent = InterfaceStore.popupComponent
      attentionComponent = React.createElement(AttentionComponent, null,
        React.createElement(PopupComponent, {"data": (InterfaceStore.popupComponentData)})
      )

    unless @state.connected
      attentionComponent = React.createElement(SystemAttentionComponent, { \
        "message": 'Connection error.',  \
        "button": 'Reload',  \
        "isError": (true),  \
        "textStyle": (styles.error)
      })

    unless @state.upToDate
      attentionComponent = React.createElement(SystemAttentionComponent, { \
        "message": 'New version is available. When you will be ready:',  \
        "button": 'Refresh page'
      })

    React.createElement(LoadableWrapperComponent, { \
      "loading": (@state.authorizing || @state.authorized == undefined)
    },
      (
        if @state.authorized && @state.onlyRouteComponent
          React.createElement("div", null,
            ( children )
          )
        else if @state.authorized && !@state.onlyRouteComponent
          React.createElement("div", null,
            React.createElement("div", {"className": ( styles.menuContainer )},
              React.createElement(MenuComponent, { \
                "user": (AuthStore.user)
              })
            ),
            React.createElement("div", {"className": ( styles.layoutContainer )},
              ( children )
            ),
            (if InterfaceStore.modalComponent
              ModalComponent = InterfaceStore.modalComponent
              React.createElement(ModalWindow, null,
                React.createElement(ModalComponent, { \
                  "data": (InterfaceStore.modalComponentData),  \
                  "onClick": (@closeModal)
                })
              )
            ),
            (if config.crmPluginEnabled
              React.createElement(crminfo.MainHub, { \
                "height": "50%",  \
                "width": (770),  \
                "onConfigClose": (CRMPluginStore.onConfigClose),  \
                "pluginCallback": (@refreshCrmData),  \
                "detached": true,  \
                "notMinimize": true,  \
                "contextEnabled": true
              })
            ),
            (if canGetSoundNotifications 
              React.createElement("audio", {"className": "audio-element"},
                React.createElement("source", {"src": (require 'root/assets/media/record.mp3')}
                )
              )
            ),
            (if config.mqttNotification
              React.createElement(Notification, { \
                "title": (SoundStore.title),  \
                "disableActiveWindow": (true),  \
                "onClick": (@onNotificationClick),  \
                "options": (SoundStore.options),  \
                "timeout": (5000)
              })
            ),
            (attentionComponent),
            (@renderNotifications()),
            (@renderPopups()),
            (showWfhNotification && @renderWFHNotifications())
        )
      )
      )

module.exports = AppComponent
