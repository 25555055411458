import React, { memo } from 'react';
import {
  useMessageBox,
} from '@connect/connect-xmpp';


const MessageBox = ({ conversation = {}, user = {}, operatorTypingText, operatorManualTyping }) => {
  const { info, active_channel: activeChannel } = conversation;
  const { jabber_jid: jabberJid } = user;

  if (!info || !jabberJid || !activeChannel) return null;

  const { channel_type: channelType } = activeChannel;
  let node = (info[jabberJid] || {}).jabber_node;
  let to = (info[jabberJid] || {}).jabber_pubsub_host;
  const aiNode = (info[jabberJid] || {}).jabber_ai_node;
  const aiTo = (info[jabberJid] || {}).jabber_ai_pubsub_host;
  if (aiNode && aiTo && channelType == 'ai_xmpp') {
    node = aiNode
    to = aiTo
  }

  if (!node || !to) return null;

  const clientChatConfig = {
    node,
    to,
  };

  const {
    debounceIsTyping,
    setIsTyping,
    isChatReady
  } = useMessageBox(clientChatConfig);

  if (!isChatReady) return null;

  if (operatorTypingText) {
    debounceIsTyping();
  }

  if (!operatorTypingText) {
    setIsTyping(operatorManualTyping)
  }


  return null;

}

export default memo(MessageBox);
