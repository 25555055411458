React = require 'react'
{ Link } = require 'react-router-dom'

BaseComponent = require '../../../BaseComponent'

styles = require './take-new-modal'

propTypes = require 'prop-types'

class TakeNewModalComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    audioRef = null
    containerRef = null
    @initComponent()

  componentDidMount: ->
    if !@audioRef then return
    if !@props.data.operator.take_new_sound then return
    
    @containerRef.focus()
    setTimeout(
      =>
        if !@audioRef then return
        
        @audioRef.muted = false
        @audioRef.play()
      1
    )
   
  onAcceptChat: =>
    @props.data.acceptChat()
    @props.onClick()

  onRejectChat: =>
    @props.data.rejectChat()
    @props.onClick()

  render: ->
    React.createElement("div", {"className": (styles.container), "ref": ((ref) => @containerRef = ref)},
      React.createElement("audio", {"preload": 'auto', "muted": "muted", "autoPlay": (true), "ref": ((ref) => @audioRef = ref)},
        React.createElement("source", {"src": (require 'root/assets/media/flute-notification-tone.mp3'), "type": 'audio/mpeg'})
      ),
      React.createElement("img", {"src": (require './images/chat-reset.svg')}),
      
      (if @props.data.reserve_type == 'reminder'
        React.createElement("p", {"className": (styles.text)}, """
          Reminder you set up this shift was triggered
""")
      else
        React.createElement("p", {"className": (styles.text)}, """
          There is a returning user """, React.createElement("strong", null, (@props.data.customer)), """ you already talked to
""")
      ),

      React.createElement("div", {"className": (styles.controls_container)},
        React.createElement("button", { \
          "onClick": (@onAcceptChat),  \
          "className": (styles.control_accept)
        }, """
          Take chat
"""),
        React.createElement("span", { \
          "onClick": (@onRejectChat),  \
          "className": (styles.control_reject)
        }, """
          Don\'t take chat
""")
      ),
      React.createElement("p", {"className": (styles.note)}, """
        This will be reported and you will not get previous
        conversation(s) in your
""", React.createElement(Link, { \
          "onClick": (@props.onClick),  \
          "className": (styles.link),  \
          "to": (@props.data.bonusLink),  \
          "target": '_blank'
        }, """
          bonus calculation
""")
      )
    )

TakeNewModalComponent.propTypes =
  onClick: propTypes.func.isRequired
  data: propTypes.shape({
    bonusLink: propTypes.string.isRequired
    acceptChat: propTypes.func.isRequired
    rejectChat: propTypes.func.isRequired
    customer: propTypes.string.isRequired
  }).isRequired

module.exports = TakeNewModalComponent
