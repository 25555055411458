import React from 'react';
import classnames from 'classnames';

import styles from './index.styl';

const TextArea = ({ value, onChange, isRequired, isError }) => {

  return (
    <textarea className={classnames(styles.textarea, {[[styles.textarea_error]]: isError })} value={value} onChange={onChange} placeholder={`Comment ${isRequired ? '' : '(optional)'}`} />
  )
};

export default TextArea;
