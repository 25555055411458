BaseComponent = require 'components/BaseComponent'
ChannelListComponent = require 'components/overflow/ChannelListComponent/ChannelListComponent'
ChannelsActionCreator = require 'actions/ChannelsActionCreator'
ChannelsStore = require 'stores/ChannelsStore'
styles = require './channels.styl'

class ChannelsComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @state =
      limit: 30
      page: 0
      dataReady: false
    @initComponent()

  dependsOnStores: [ChannelsStore]

  componentDidMount: ->
    super()
    setTimeout(
      => ChannelsActionCreator.getList { page: @state.page, per_page: @state.limit }
      10
    )

  onPaginateClick: ({ selected }) =>
    page = selected
    ChannelsActionCreator.getList { page: page, per_page: @state.limit }
    @setState { page }

  onReset: () =>
    ChannelsActionCreator.resetRole {page: @state.page, per_page: @state.limit}

  render: ->
    React.createElement("div", null,
      React.createElement("div", {"className": (styles.header)},
        React.createElement("div", {"className": (styles.buttons)},
          React.createElement("button", {"className": (styles.button_add), "onClick": (@onReset)}, """
            Reset
""")
        )
      ),
      React.createElement(ChannelListComponent, { \
        "error": (ChannelsStore.error),  \
        "loading": (ChannelsStore.loading),  \
        "channels": (ChannelsStore.channels),  \
        "pagesCount": (Math.ceil(ChannelsStore.total / @state.limit)),  \
        "paginateClickHandler": (@onPaginateClick)
      })
    )

module.exports = ChannelsComponent
