import React from 'react';
import { pluginConfigs, controls } from '@verdaccio/crminfo';
import config from 'root/config';

import CRMPluginActionCreator from 'actions/CRMPluginActionCreator';
import RankingRulesActionCreator from 'actions/RankingRulesActionCreator';

import RankingRulesStore from 'stores/RankingRulesStore';

import BaseComponent from '../../BaseComponent';
import RankingRulesTableComponent from '../RankingRulesTableComponent';

import { getQueryParams, buildQueryParamsString } from 'lib/utils';

import styles from './styles.styl';

const FILTER_DATA = {
  team: config.isMyNerdify ? ['SALES', 'CSR'] : ['CSR'],
  status: ['archived']
};

const DEFAULT_PER_PAGE = 1000;
const DEFAULT_PARAMS = { 
  page: 0,
  per_page: DEFAULT_PER_PAGE,
  sort_order: [],
  sort_by: [],
  team: '',
  status: '',
  pillar: '',
  issue_type: '',
  priority: '',
  created_at: '',
  archived_at: '',
  restored_at: '',
  is_client_affects: '',
};
class RankingRulesComponent extends BaseComponent {
  constructor(props) {
    super(props);

    this.dependsOnStores = [RankingRulesStore];
    const params = getQueryParams(window.location.search.substring(1));

    const filters = {}
    filters.team = params.team || ''
    filters.status = params.status || ''
    filters.pillar = params.pillar || ''
    filters.issue_type = params.issue_type || ''
    filters.priority = params.priority || ''
    filters.created_at = params.created_at || ''
    filters.archived_at = params.archived_at || ''
    filters.restored_at = params.restored_at || ''
    filters.is_client_affects = params.is_client_affects || ''

    let sortOrder = []
    let sortBy = []
    if (params.sort_order) {
      sortOrder = Array.isArray(params.sort_order) ? params.sort_order : [params.sort_order]
    }
    if (params.sort_by) {
      sortBy = Array.isArray(params.sort_by) ? params.sort_by : [params.sort_by]
    }

    this.state = {
      isResetfilters: false,
      params: {
        ...filters,
        page: params.page || 0,
        per_page: params.per_page || DEFAULT_PER_PAGE,
        sort_order: sortOrder,
        sort_by: sortBy,
      }
    };

    this.getRules = this.getRules.bind(this);
    this.updateRule = this.updateRule.bind(this);
    this.deleteRule = this.deleteRule.bind(this);
    this.createRule = this.createRule.bind(this);
    this.editRule = this.editRule.bind(this);
    this.duplicateRule = this.duplicateRule.bind(this);
    this.filterRule = this.filterRule.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.setResetFilter = this.setResetFilter.bind(this);

    this.initComponent();
  }

  componentDidMount() {
    const { params } = this.state
    this.getRules(params);
    super.componentDidMount();
  }

  filterRule(field, value) {
    this.setResetFilter(false);

    let newParams = getQueryParams(window.location.search.substring(1));
    if (newParams.sort_order) {
      newParams.sort_order = Array.isArray(newParams.sort_order) ? newParams.sort_order : [newParams.sort_order]
    }
    if (newParams.sort_by) {
      newParams.sort_by = Array.isArray(newParams.sort_by) ? newParams.sort_by : [newParams.sort_by]
    }
    const isSetFilter = newParams[field] === value;
    newParams = { ...newParams, [field]: isSetFilter ? '' : value };

    this.setState({ params: newParams });
    this.getRules(newParams);
  }

  getRules(params = DEFAULT_PARAMS) {
    const queryParams = `?${buildQueryParamsString(params)}`;
    window.history.replaceState(null, null, queryParams);

    setTimeout(() => {
      RankingRulesActionCreator.getRules(params);
    }, 10);

  }

  updateRule(id, params = DEFAULT_PARAMS) {
    setTimeout(() => {
      RankingRulesActionCreator.updateRule(id, params);
    }, 10);
  }

  deleteRule(id) {
    setTimeout(() => {
      RankingRulesActionCreator.deleteRule(id);
    }, 10);
  }

  createRule() {
    const { params } = this.state
    const teamFilter = params && params.team
    const config = pluginConfigs.CreateRankingRulePlugin({
      options: {
        teams: FILTER_DATA.team,
        teamFilter,
        isResizable: true,
        entityKey: 'newRule',
        isAgreeClose: true,
      }
    })
    CRMPluginActionCreator.setConfig(
      config, 'newRule', 'newRule', true
    )
  }

  duplicateRule(id) {
    const { rankingRules } = RankingRulesStore;
    const { params } = this.state
    const editRule = rankingRules.filter(r => r.id === id)[ 0]
    const teamFilter = params && params.team
    const config = pluginConfigs.CreateRankingRulePlugin({
      options: {
        teams: FILTER_DATA.team,
        teamFilter,
        editRule,
        isDuplicate: true,
        isResizable: true,
        entityKey: 'newRule',
        isAgreeClose: true,
      }
    })
    CRMPluginActionCreator.setConfig(
      config, 'newRule', 'newRule', true
    )
  }

  editRule(id) {
    const { rankingRules } = RankingRulesStore;
    const { params } = this.state
    const editRule = rankingRules.filter(r => r.id === id)[ 0]
    const teamFilter = params && params.team
    const config = pluginConfigs.CreateRankingRulePlugin({
      options: {
        teams: FILTER_DATA.team,
        teamFilter,
        editRule,
        isEdit: true,
        isResizable: true,
        entityKey: 'newRule',
        isAgreeClose: true,
      }
    })
    CRMPluginActionCreator.setConfig(
      config, 'newRule', 'newRule', true
    )
  }

  resetFilter() {
    this.setState({ params: DEFAULT_PARAMS });
    this.setResetFilter(true);
    this.getRules();
  }

  setResetFilter(isReset) {
    this.setState({ isResetfilters: isReset });
  }

  render() {
    const { rankingRules } = RankingRulesStore;
    const { params } = this.state

    return (
      <div className={styles.container}>
        <div className={styles.buttons_wrap}>

          <div>
            {Object.keys(FILTER_DATA).map(k => {
              return FILTER_DATA[k].map(v => {
                const isActive = params[k] && params[k] === v;
                return (
                  <controls.Button
                    key={`${k}_${v}`}
                    className={styles.btn}
                    default={!isActive}
                    primary={isActive}
                    onClick={() => this.filterRule(k, v)}
                  >
                    {v}
                  </controls.Button>
                )
              })
            })}
            <controls.Button link onClick={this.resetFilter}>Reset filters</controls.Button>
          </div>

          <controls.Button successFill className={styles.btn_add} onClick={() => this.createRule()}>Add New Rule</controls.Button>
        </div>

        {!!rankingRules.length &&
          <RankingRulesTableComponent
            rules={rankingRules}
            params={this.state.params}
            updateRule={this.updateRule}
            deleteRule={this.deleteRule}
            editRule={this.editRule}
            duplicateRule={this.duplicateRule}
            getList={this.getRules}
            isResetfilters={this.state.isResetfilters}
            setResetFilter={this.setResetFilter}
          />
        }

        {!rankingRules.length &&
          <div>No data</div>
        }

      </div >
    );
  }
}

export default RankingRulesComponent;