import React, { useState, useRef, useEffect } from 'react';
import classnames from 'classnames';

import styles from './index.styl';

const MENU_ITEMS = [
  {
    title: 'Open in a new tab',
    name: 'newTab',
  },
  {
    title: 'Open in modal',
    name: 'openModal',
  },
  {
    title: 'Uncollapse all',
    name: 'uncollapseAll',
  },
  {
    title: 'Collapse all',
    name: 'collapseAll',
  }
]

const QAInfoMenu = ({ onFonds, onReportPluginOpen, onNewTab }) => {
  const [showMenu, toggleShowMenu] = useState(false);
  const menuRef = useRef(null);

  const onToggleShowMenu = (e) => {
    e.stopPropagation();
    toggleShowMenu(!showMenu);
  };

  const onMenuItemClick = (e) => {
    const { target } = e;
    let name = target.getAttribute('data-name');

    if (!name) {
      name = target.parentElement.getAttribute('data-name');
    }

    switch (name) {
      case 'uncollapseAll':
        onFonds(true);
        break;
      case 'collapseAll':
        onFonds(false);
        break;
      case 'openModal':
        onReportPluginOpen();
        break;
      case 'newTab':
        onNewTab();
        break;
    }
  }

  useEffect(() => {
    const outClickHandler = (e) => {
      const { target } = e;
      
      if (!menuRef || !menuRef.current) return;

      if (menuRef.current.contains(target)) return;

      toggleShowMenu(!showMenu);
    };
    if (!showMenu) {
      document.removeEventListener('click', outClickHandler);
      return;
    }
    document.addEventListener('click', outClickHandler);

    return () => {
      document.removeEventListener('click', outClickHandler);
    }
  }, [showMenu]);

  return (
    <i className={styles.qa_info_menu} onClick={onToggleShowMenu}>
      {showMenu && (
      <div className={styles.qa_info_menu_menu} ref={menuRef}>
        {
          MENU_ITEMS.map(({ title, name }) => (
            <div className={styles.qa_info_menu_item} key={name} data-name={name} onClick={onMenuItemClick}>
              <i className={classnames(styles.qa_info_menu_item__icon, styles[`qa_info_menu_item__icon--${name}`])} />
              <div>{title}</div>
            </div>
          ))
        }
      </div>
      )}
    </i>
  )
};

export default QAInfoMenu;
