Isvg = require 'react-inlinesvg'
classnames = require 'classnames'
deepcopy = require 'deepcopy'

BaseComponent = require 'components/BaseComponent'
CustomMultiSelectComponent = require 'components/shared/CustomMultiSelectComponent'
CustomSelectComponent = require 'components/shared/CustomSelectComponent'
SearchInputComponent = require 'components/shared/SearchInputComponent/SearchInputComponent'
Calendar = require 'components/shared/CalendarComponent'
styles = require './ranking-filter'
{ STATUSES, QUALITY, BAD_TAGS,
CONVERSATIONS_ORDERING } = require 'constants/Ranking'
AgentListStore = require 'stores/AgentListStore'
AgentListActionCreator = require 'actions/AgentListActionCreator'
{ SHIFT_TYPES } = require 'constants/Shifts'
{ capitalizeFirstLetter } = require 'lib/utils'
RankedAgentsListComponent = require '../RankedAgentsListComponent'

shifts = {}

SHIFT_TYPES.forEach (type, i) ->
  shifts[type] =
    label: capitalizeFirstLetter type
    value: type

ordering = Object.assign(
  order:
    label: 'Order'
    value: 'order'
  CONVERSATIONS_ORDERING
)

conversationTags = {}
clientTags = {}
groupTags = {}

class RankingFilterComponent extends BaseComponent

  constructor: (props) ->
    super(props)
    @isAgentsHandling = false
    @state =
      showFilters: false
      showAgents: false
      page: 0
      filters: deepcopy props.filters
      shiftType: props.shiftType
      conversationTag: props.conversationTag
      clientTag: props.clientTag
      tagsGroupId: props.tagsGroupId
    @initComponent()

  dependsOnStores: [AgentListStore]

  componentWillReceiveProps: (props) ->
    conversationTags = props.conversationTags
    clientTags = props.clientTags
    groupTags = props.groupTags
    @setState
      filters: deepcopy props.filters
      shiftType: props.shiftType
      ordering: props.order
      agents: props.agents
      conversationTag: props.conversationTag
      clientTag: props.clientTag
      tagsGroupId: props.tagsGroupId

  onSelectChange: (selectName, value) ->
    newFilters = deepcopy @state.filters
    if value
      newFilters[selectName] = value
    else
      delete newFilters[selectName]

    @setState filters: newFilters

  onShiftChange: (value) =>
    newState = @state
    if value
      correctValues = {}
      Object.keys(value).forEach (val) -> correctValues[SHIFT_TYPES.indexOf val] = true
      newState.shiftType = correctValues
    else
      delete newState.shiftType
    @setState newState

  onOrderingChange: (value) =>
    unless value == @state.ordering
      @setState ordering: (CONVERSATIONS_ORDERING[value] || {}).value

  onSelectConversationTag: (value) =>
    unless value == @state.conversationTag
      @setState conversationTag: value

  onSelectClientTag: (value) =>
    unless value == @state.clientTag
      @setState clientTag: value

  onSelectGroupTag: (value) =>
    unless value == @state.tagsGroupId
      @setState tagsGroupId: value

  searchOperators: (query) =>
    AgentListActionCreator.getList
      search_query: query.toLowerCase()
      page: 0
      per_page: 20
    document.addEventListener 'click', @onAgentsBlur unless @isAgentsHandling
    @isAgentsHandling = true
    @setState
      showAgents: true
      agents: @props.agents
      page: 0

  onClearSearch: =>
    AgentListActionCreator.getList
      search_query: ''
      page: 0
      per_page: 20
    document.addEventListener 'click', @onAgentsBlur unless @isAgentsHandling
    @isAgentsHandling = true
    @setState
      showAgents: true
      agents: @props.agents

  newDateRangeHandler: (newRange) =>
    @props.onChangeFiltersHandler(
      @state.filters
      @state.shiftType
      @state.ordering
      @state.conversationTag
      @state.clientTag
      @state.tagsGroupId
      newRange.startDate.format 'YYYY-MM-DD'
      newRange.endDate.format 'YYYY-MM-DD'
    )

  onFiltersClick: =>
    document.addEventListener 'click', @filtersBlur
    @setState showFilters: !@state.showFilters

  filtersBlur: ({target}) =>
    if !$(target).parents('#selectors').length && target != @refs.selectors
      document.removeEventListener 'click', @filtersBlur
      @setState
        showFilters: false
        filters: @props.filters
        shiftType: @props.shiftType
        ordering: @props.order
        conversationTag: @props.conversationTag
        clientTag: @props.clientTag
        tagsGroupId: @props.tagsGroupId

  onApplyFilters: =>
    @props.onChangeFiltersHandler(
      @state.filters
      @state.shiftType
      @state.ordering
      @state.conversationTag
      @state.clientTag
      @state.tagsGroupId
      @props.beginDate
      @props.endDate
    )
    document.removeEventListener 'click', @filtersBlur
    @isAgentsHandling = false
    @setState showFilters: false

  onResetFilters: =>
    @props.onChangeFiltersHandler(
      {}
      undefined
      undefined
      undefined
      undefined
      undefined
      @props.beginDate
      @props.endDate
    )
    document.removeEventListener 'click', @filtersBlur
    @setState showFilters: false

  removeFilterTag: (filterType, filter) =>
    newFilters = @state.filters
    if Object.keys(newFilters[filterType]).length == 1
      delete newFilters[filterType]
    else
      delete newFilters[filterType][filter]
    @props.onChangeFiltersHandler(
      newFilters
      @state.shiftType
      @state.ordering
      @state.conversationTag
      @state.clientTag
      @state.tagsGroupId
      @props.beginDate
      @props.endDate
    )
    @setState filters: newFilters

  removeTag: (tagType, value) =>
    newState = @state
    if Object.keys(newState[tagType]).length == 1
      delete newState[tagType]
    else
      delete newState[tagType][value]

    @props.onChangeFiltersHandler(
      @state.filters
      newState.shiftType
      newState.ordering
      @state.conversationTag
      @state.clientTag
      @state.tagsGroupId
      @props.beginDate
      @props.endDate
    )
    @setState newState

  removeOrderTag: =>
    delete @state.ordering
    @props.onChangeFiltersHandler(
      @state.filters
      @state.shiftType
      undefined
      @state.conversationTag
      @state.clientTag
      @state.tagsGroupId
      @props.beginDate
      @props.endDate
    )
    @setState @state

  generateSingleTag: (key, value, onClickCallback) ->
    React.createElement("span", { \
      "key": (key),  \
      "className": (styles.filters__tag)
    },
      React.createElement("span", {"className": (styles.filters__tag_label)},
        (value)
      ),
      React.createElement("i", { \
        "className": (styles.filters__tag_icon),  \
        "onClick": (onClickCallback)
      })
    )

  generateFiltersTagsComponent: =>
    filters = @props.filters
    filterConstants =
      status: @props.statuses || STATUSES
      quality: QUALITY
      badTag: BAD_TAGS
    removeFilterTag = @removeFilterTag
    that = @

    filterTags = Object.keys filters
      .map (filterType) ->
        Object.keys filters[filterType]
          .map (filter, i) ->
            that.generateSingleTag(
              i
              filterConstants[filterType][filter].label
              removeFilterTag.bind that, filterType, filter
            )

    if @props.shiftType
      Object.keys @props.shiftType
        .forEach (shift) ->
          filterTags.push(
            that.generateSingleTag(
              filterTags.length
              shifts[SHIFT_TYPES[shift]].label
              that.removeTag.bind that, 'shiftType', shift
            )
          )

    if @props.conversationTag
      Object.keys @props.conversationTag
        .forEach (conversationTag) ->
          filterTags.push(
            that.generateSingleTag(
              filterTags.length
              conversationTag
              that.removeTag.bind(@, 'conversationTag', conversationTag)
            )
          )

    if @props.clientTag
      Object.keys @props.clientTag
        .forEach (clientTag) ->
          filterTags.push(
            that.generateSingleTag(
              filterTags.length
              clientTag
              that.removeTag.bind(@, 'clientTag', clientTag)
            )
          )

    if @props.tagsGroupId
      Object.keys @props.tagsGroupId
        .forEach (tagsGroupId) ->
          filterTags.push(
            that.generateSingleTag(
              filterTags.length
              groupTags[tagsGroupId]?.label
              that.removeTag.bind(@, 'tagsGroupId', tagsGroupId)
            )
          )

    if @props.order
      filterTags.push React.createElement("span", { \
        "key": (filterTags.length),  \
        "className": (styles.filters__tag)
      },
        React.createElement("span", {"className": (styles.filters__tag_label)},
          (CONVERSATIONS_ORDERING[@props.order].label)
        ),
        React.createElement("i", { \
          "className": (styles.filters__tag_icon),  \
          "onClick": (@removeOrderTag)
        })
      )

    if filterTags.length
      React.createElement("div", {"className": (styles.filters__tags)},
        (filterTags)
      )
    else
      null

  onAgentClick: (agent) =>
    indexOfAgent = -1
    @state.agents.forEach (existingAgent, i) ->
      if agent.id == existingAgent.id
        indexOfAgent = i

    newAgents = deepcopy @state.agents
    if indexOfAgent == -1
      newAgents.push
        id: agent.id
        name: "#{agent.first_name} #{agent.last_name}"
    else
      newAgents.splice indexOfAgent, 1
    @setState agents: newAgents

  onApplyAgentsFilter: =>
    @props.onAgentsFilterHandler @state.agents
    document.removeEventListener 'click', @onAgentsBlur
    @isAgentsHandling = false
    @setState
      agents: []
      showAgents: false
      page: 0

  onAgentsBlur: (e) =>
    unless $(e.target).parents('#agents').length
      document.removeEventListener 'click', @onAgentsBlur
      @setState
        agents: []
        showAgents: false
        page: 0
      @isAgentsHandling = false

  onPageChange: ({selected}) =>
    AgentListActionCreator.getList
      search_query: @refs.textbox.refs.searchField.value.toLowerCase()
      page: selected
      per_page: 20
    @setState page: selected

  onChangeRangeType: (type) =>
    @props.onRangeTypeChangeHandler type

  removeAgentTag: (agentIndex) ->
    newAgents = deepcopy @props.agents
    newAgents.splice agentIndex, 1
    @props.onAgentsFilterHandler newAgents
    @setState agents: newAgents

  generateAgentTags: =>
    that = @
    agentTags = @props.agents.map (agent, i) ->
      React.createElement("span", { \
        "key": (i),  \
        "className": (styles.filters__tag)
      },
        React.createElement("span", {"className": (styles.filters__tag_label)},
          (agent.name)
        ),
        (if that.props.access
          React.createElement("i", { \
            "className": (styles.filters__tag_icon),  \
            "onClick": (that.removeAgentTag.bind(that, i))
          })
        )
      )
    if agentTags.length
      React.createElement("div", {"className": (styles.filters__tags)},
        (agentTags)
      )
    else
      null

  render: ->
    withUnranked = @props.filters.status && @props.filters.status.unranked
    withAgents = @props.agents && @props.agents.length > 0
    filtersClasses = classnames styles.filters,
      "#{styles.filters_selected}": @state.showFilters
      "#{styles.filters_nonempty}": Object.keys(@props.filters).length ||
        Object.keys(@props.shiftType || {}).length ||
        @props.order ||
        Object.keys(@props.conversationTag || {}).length ||
        Object.keys(@props.cleintTag || {}).length ||
        Object.keys(@props.tagsGroupId || {}).length
    React.createElement("div", {"className": (styles.container)},
      (if @props.access
        React.createElement("div", {"className": (styles.textbox)},
          React.createElement(SearchInputComponent, { \
            "placeholder": 'Find Operator',  \
            "inputClassName": (styles.textbox_input),  \
            "clearCross": true,  \
            "onClear": (@onClearSearch),  \
            "onChange": (@searchOperators),  \
            "ref": "textbox",  \
            "defaultValue": (@props.query),  \
            "autofocus": true
          })
        )
      ),
      React.createElement("div", {"className": (styles['filters-container'])},
        React.createElement("div", {"className": (styles.calendar)},
          React.createElement(Calendar, { \
            "beginDate": (@props.beginDate),  \
            "endDate": (@props.endDate),  \
            "onSelectHandler": (@newDateRangeHandler),  \
            "type": (@props.rangeType),  \
            "onChangeTypeHandler": (@onChangeRangeType),  \
            "isShiftsMode": true
          })
        ),
        React.createElement("span", {"className": (styles['float-right']), "onClick": (@onFiltersClick)},
          React.createElement("span", {"className": (filtersClasses), "onClick": (@onFiltersClick)},
            React.createElement(Isvg.default, {"src": (require './images/filter.svg')})
          )
        )
      ),
      (if @state.showFilters
        React.createElement("div", { \
          "id": 'selectors',  \
          "ref": "selectors",  \
          "className": (styles['selectors-container'])
        },
          React.createElement("div", {"className": (styles['selectors-container_arrow'])}),
          React.createElement("div", {"className": (styles.select)},
            React.createElement(CustomMultiSelectComponent, { \
              "label": 'Status',  \
              "options": (@props.statuses || STATUSES),  \
              "onOptionSelect": (@onSelectChange.bind(@, 'status')),  \
              "customOptionsStyle": (styles.select__options),  \
              "selectedValues": (@state.filters.status)
            })
          ),
          (if (@state.filters.status || {}).ranked
            React.createElement("div", {"className": (styles.select)},
              React.createElement(CustomMultiSelectComponent, { \
                "label": 'Quality',  \
                "options": (QUALITY),  \
                "onOptionSelect": (@onSelectChange.bind(@, 'quality')),  \
                "customOptionsStyle": (styles.select__options),  \
                "selectedValues": (@state.filters.quality)
              })
            )
          ),
          (if (@state.filters.quality || {}).bad
            React.createElement("div", {"className": (styles.select)},
              React.createElement(CustomMultiSelectComponent, { \
                "label": 'Bad tag',  \
                "options": (BAD_TAGS),  \
                "onOptionSelect": (@onSelectChange.bind(@, 'badTag')),  \
                "customOptionsStyle": (styles.select__options),  \
                "selectedValues": (@state.filters.badTag)
              })
            )
          ),
          React.createElement("div", {"className": (styles.select)},
            React.createElement(CustomMultiSelectComponent, { \
              "label": 'Shift type',  \
              "options": (shifts),  \
              "onOptionSelect": (@onShiftChange),  \
              "customOptionsStyle": (styles.select__options),  \
              "selectedValues": (
                correctShifts = {}
                Object.keys(@state.shiftType || {})
                  .forEach (shift) -> correctShifts[SHIFT_TYPES[shift]] = true
                correctShifts
              )
            })
          ),
          React.createElement("div", {"className": (styles.select)},
            React.createElement(CustomMultiSelectComponent, { \
              "label": 'Conversation tag',  \
              "options": (conversationTags),  \
              "onOptionSelect": (@onSelectConversationTag),  \
              "customOptionsStyle": (styles.select__options),  \
              "selectedValues": (@state.conversationTag)
            })
          ),
          React.createElement("div", {"className": (styles.select)},
            React.createElement(CustomMultiSelectComponent, { \
              "label": 'Client tag',  \
              "options": (clientTags),  \
              "onOptionSelect": (@onSelectClientTag),  \
              "customOptionsStyle": (styles.select__options),  \
              "selectedValues": (@state.clientTag)
            })
          ),
          React.createElement("div", {"className": (styles.select)},
            React.createElement(CustomMultiSelectComponent, { \
              "label": 'Group tag',  \
              "options": (groupTags),  \
              "onOptionSelect": (@onSelectGroupTag),  \
              "customOptionsStyle": (styles.select__options),  \
              "selectedValues": (@state.tagsGroupId)
            })
          ),
          (if @state.agents.length == 1
            React.createElement("div", {"className": (styles.select)},
              React.createElement(CustomSelectComponent, { \
                "defaultValue": (
                  if (ordering[@state.ordering] || {}).value
                    ordering[@state.ordering].value
                  else
                    ordering.order.value
                ),  \
                "options": (ordering),  \
                "onOptionSelect": (@onOrderingChange),  \
                "customOptionsStyle": (styles.select__options)
              })
            )
          ),
          React.createElement("button", { \
            "className": (styles['selectors-container__apply']),  \
            "onClick": (@onApplyFilters)
          }, """
            Apply
"""),
          React.createElement("div", {"className": (styles['selectors-container__reset'])},
            React.createElement("button", { \
              "className": (styles['selectors-container__reset-button']),  \
              "onClick": (@onResetFilters)
            }, """
              Reset filters
""")
          )
        )
      ),
      (@generateFiltersTagsComponent()),
      (@generateAgentTags()),
      (if @state.showAgents && AgentListStore.agents.length
        React.createElement(RankedAgentsListComponent, { \
          "allAgents": (AgentListStore.agents),  \
          "selectedAgents": (@state.agents),  \
          "pageCount": (AgentListStore.pageCount),  \
          "page": (@state.page),  \
          "onAgentClick": (@onAgentClick),  \
          "onApplyAgentsFilter": (@onApplyAgentsFilter),  \
          "onPageChange": (@onPageChange)
        })
      ),
      (withUnranked && withAgents && (
        React.createElement("button", {"className": (styles['next-unranked-btn']), "onClick": (@props.onNextUnranked)}, "Next")
      ))
    )

module.exports = RankingFilterComponent
