{ findDOMNode } = require 'react-dom'
BaseComponent = require 'components/BaseComponent'
InlineTextInput = require 'components/shared/InlineTextInput/InlineTextInput'
CustomersActionCreator = require 'actions/CustomersActionCreator.coffee'

styles = require './customer_nickname'

class CustomerNicknameComponent extends React.Component
  constructor: (props) ->
    super(props)

  updateCustomerAttribute: (attribute, value) =>
    nickname = value
    nickname = '' if nickname == ' '
    setTimeout(
      =>
        CustomersActionCreator.update(@props.customer.id, {"#{attribute}": nickname})
      10
    )

  render: ->
    { customer } = @props
    React.createElement("div", {"className": (styles.simple_column)},
      React.createElement(InlineTextInput, { \
        "className": (styles.nickname_field),  \
        "value": (customer.nickname || ''),  \
        "onSubmit": (R.partial(@updateCustomerAttribute, ['nickname'])),  \
        "placeholder": "Enter client nickname"
      })
    )

module.exports = CustomerNicknameComponent
