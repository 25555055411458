Button = require '../Button'
styles = require './blacklisted-warning-modal.styl'
BaseComponent = require '../../BaseComponent'
CustomerLink = require './CustomerLink'
CRMInfoModuleActionCreator = require('actions/CRMInfoModuleActionCreator').default

class BlacklistedWarningModal extends BaseComponent
  constructor: (props) ->
    super(props)
    @state =
      isOpen: false
      clietnsShow: false
      customersIds: {}
    @initComponent()

  componentDidMount: =>
    if @props.data?.sameCustomers
      clientsIds =
        credential_values: @props.data?.sameCustomers.map (customer) -> customer._id
      CRMInfoModuleActionCreator.getCustomersIdsFromClientsIds(
        clientsIds
        (customers) => @setState({ customersIds: customers }) if customers
      )

  onOpenChange: (value) =>
    @setState isOpen: value

  onToggleCustomersList: =>
    @setState clietnsShow: !@state.clietnsShow

  onCloseAndProceed: =>
    @props.data.onProceed()
    @props.onClick()

  render: ->
    { data } = @props
    { clietnsShow, customersIds } = @state
    React.createElement("div", {"className": (styles.modalContainer)},
      React.createElement("span", {"className": (styles.warning_icon)}),
      React.createElement("h1", {"className": (styles.warning_title)}, "Warning!"),
      React.createElement("p", null, "Next clients will be also affected if you move on"),
      (if data.sameCustomers?.length > 0
        React.createElement("div", null,
          React.createElement("span", {"className": (styles.showHideBtn), "onClick": (@onToggleCustomersList)}, (if clietnsShow then 'Hide' else 'Show')),
          (if clietnsShow
            React.createElement("div", null,
              (data.sameCustomers.map (customer) =>
                React.createElement(CustomerLink, {"customer": (customer), "customersIds": (customersIds)})
              )
            )
          )
        )
      ),
      React.createElement("div", {"className": (styles.modal_buttons)},
        React.createElement(Button, { \
          "title": 'OK',  \
          "white": true,  \
          "onClick": (@onCloseAndProceed)
        }),
        React.createElement(Button, { \
          "title": 'Cancel',  \
          "primary": true,  \
          "onClick": (@props.onClick)
        })
      )
    )


module.exports = BlacklistedWarningModal
