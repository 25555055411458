BaseComponent = require 'components/BaseComponent'
ConversationItemsListView = require '../ConversationItemsListView'
ConversationItemsActionCreator = require 'actions/ConversationItemsActionCreator'
ConversationItemsStore = require 'stores/ConversationItemsStore'

propTypes = require 'prop-types'

sortRule = (item) ->
  switch item.type
    when 'message' then item.created_at
    when 'log_message' then item.created_at
    when 'reminder' then item.time
    when 'prompt' then item.created_at
    when 'scheduled_message' then item.time

class ConversationItemsListSyncComponent extends BaseComponent

  constructor: (props) ->
    super(props)
    @state =
      itemsAmount: 0
      ready: true
    @itemsCount
    @isScrolled = false
    @initComponent()

  componentDidUpdate: ->
    super()
    conversationItems = ConversationItemsStore.conversationItemsSync

    @props.onReadyChange?(true) if conversationItems
    onlyShowed = R.filter (c) =>
      !(c.service_name == 'Facebook' && (c.status in [0, 1, 10]))

    if conversationItems
      conversationItemsAmount = onlyShowed(conversationItems)?.length || 0
    else
      conversationItemsAmount = 0

    @props.onNewItem?()

    if @state.itemsAmount != conversationItemsAmount
      @state.itemsAmount = conversationItemsAmount

    if !ConversationItemsStore.conversationItemsSyncLoading && @props.scrollTop && !@isScrolled
      @isScrolled = true
      @props.scrollTop()

  getItems: ->
    conversationItems = ConversationItemsStore.conversationItemsSync

    if @props.itemsHighLightingIndex?
      R.sortBy(sortRule, conversationItems).map (item) =>
        objectId = item.id
        highlightedItem = @props.itemsHighLightingIndex[objectId]
        if highlightedItem? && highlightedItem.conversation_id == @props.conversation.id
          R.merge(item, R.pick(['text'], highlightedItem))
        else
          item
    else
      conversationItems

  render: ->
    React.createElement(ConversationItemsListView, { \
      "conversation": (@props.conversation),  \
      "items": (@getItems()),  \
      "selectedItem": (@props.selectedItem),  \
      "onItemSelect": (@props.onItemSelect),  \
      "onUpdateConversation": (@props.onUpdateConversation),  \
      "onSwitchDisallowScroll": (@props.onSwitchDisallowScroll),  \
      "alreadyScrolled": (@props.alreadyScrolled),  \
      "useRankingRules": (!!@props?.useRankingRules) 
    })

module.exports = ConversationItemsListSyncComponent
