module.exports =
  myConversations:
    menu: 'Congrats! You can start using one of the main tools for Nerdify ' +
          'CSRs. First thing I want you to pay attention to is left-side ' +
          'menu which consists of several tabs. We will review the ' +
          'functionality of each tab today.'
    myMenu: 'This is “My conversations” tab where you can track all your ' +
            'active chats.'
    takeNew: 'You have to take new chats via <b>“Take new”</b> button. This button ' +
             'has 3 possible states:<br/>Grey and inactive - there are no new ' +
             'unassigned chats at the moment.<br/>Blue and active - you can take ' +
             'a new conversation.</br>Red and active - you have to take a ' +
             'new chat.<br/>As you can see, now this button is inactive and ' +
             'it means that there are no new clients as this moment.'
    statistic: 'Next to “Take new” button you can easily check your ' +
               '<b>statistics</b>.<br/>UC - the amount of unique clients served by ' +
               'you during this shift.<br/>RT - your average response time ' +
               'for this shift.'
    shortcut: 'This is a conversation shortcut. You can quickly check ' +
              'client’s name, time when the last message from the client ' +
              'was received and the message itself. If you have more than 1 ' +
              'assigned conversation you’ll be able to check the whole ' +
              'list here as well.'
  conversation:
    step1: 'Chat view looks like this.'
    step2: 'In the chat window you can see: Operator’s messages'
    step3: 'Client’s messages'
    step4: 'Date and time of message sent'
    step5: 'Actions in the chat - status change, assigns, unassigns, setting ' +
           'reminders and tags, etc'
    step6: 'To see old chats you don’t need to scroll. Just select the ' +
           'date and time of the conversation and open it.'
    step7: 'This is a field for typing your messages. You can also keep ' +
           'track of your message length by looking at the word count in ' +
           'the right upper corner of this field.'
    step8: 'There is an ability to resize message window.'
    step9: 'Once your message is ready you have to click on “Send” button.'
    step10: 'Click on this button to attach files to your message. Remember, ' +
            'we can’t send files to SMS clients.'
    step11: 'By clicking on this button you’ll be able to look through all ' +
            'canned messages.'
    step12: 'You can see the full list of available saved replies and also ' +
            'search for them.'
    step13: 'Once you click on the needed message it moves to the message ' +
            'field.'
    step14: 'Each conversation has to be tagged appropriately. To set a ' +
            'conversational tag you are to click on this button.'
    step15: 'When you click on tag button you’ll see a list with group of tags.'
    step16: 'When the group of tags is opened you can easily add the needed ' +
            'one.<br/>Please keep in mind that some conversational tags are ' +
            'going to be set automatically, e.g. #revision issue + order ' +
            'number, #paid, still you will need to double-check if all ' +
            'necessary tags are present in the conversation by ' +
            'adding/deleting relevant tags.'
    step17: 'This button allows you to make your message more friendly :) ' +
            'But don’t abuse this option.'
    step18: 'Here you can check who is assigned to this particular chat. ' +
            'Also, you can unassign yourself by simply clicking on your name.'
    step19: 'There may be rare cases when you need to choose another channel ' +
            'to send the message. You can choose a channel by clicking on ' +
            'this field.'
    step20: 'Chat status can be changed right here. There are 4 possible ' +
            'statuses of the chat:<br/>New - the last message in the chat is ' +
            'from the client or a reminder/system message<br/>Opened - the ' +
            'last message in the chat is from the operator<br/>Dormant - is set ' +
            'automatically when there is no activity in the chat for 15 mins<br/>' +
            'Closed - is to be set manually when you have done everything ' +
            'needed in the system to address client’s request'
    step21: 'This button allows you to set a reminder.'
    step22: 'This is reminder creation window. To set a reminder you have ' +
            'to enter the text choose a date and  time. Then press the ' +
            '“add reminder” button.'
    step23: 'This button is used to create a schedule message. ' +
            'For now we do not use this functionality.'
    step24: 'In this window you can: create a message, choose date and time ' +
            'when the message should be sent, edit message, delete message.'
    step25: 'There are 2 search fields in My conversations tab. You can ' +
            'search between the chats and inside 1 chat.You can select ' +
            'between Full Search and Partial Search in both fields.\nFull ' +
            'Search (FS) results will show only 100% match within all ' +
            'conversation history. Partial Search (PS) results will show ' +
            'partial match results.'
    step26: 'We’re moving to the right-side menu which is called Sidebar. ' +
            'Sidebar in SMS-Tool consists of several fields and allows ' +
            'Nerdify operators to process main client’s requests without ' +
            'switching between Albatross and SMS-Tool tabs.<br/>' +
            'Sidebar gives you an access to the following tabs:<br/>' +
            '- Client info<br/>- Comments section<br/>- Orders<br/>' +
            '- Payments<br/>- Communication'
    step27: 'Client info tab allows you quickly check main information about ' +
            'the client.  You can check client’s name or phone number in ' +
            'this tab. Also, you can add client’s local time or time zone, ' +
            'edit client’s email, add or delete client’s tags.'
    step28: 'By clicking on this button you can add additional details ' +
            'about the client such as email, phone number, FB account etc.'
    step29: 'When the client asks us to stop texting them, we turn on ' +
            '“OptOut” switch.<br/>However, if the client changes their mind ' +
            'and decides to continue communication with us, you need to ' +
            'turn off this switch first before sending them a message.'
    step30: 'If the client was Blacklisted by Financial Manager or ' +
            'Shift TL, you’ll be able to see “Blacklist” switch turned ' +
            'on. Changing this switch status is not possible for operators.'
    step31: 'Here you can set client’s tags when needed.'
    step32: 'Client plugin button gives you wide functionality.'
    step33: 'Right after clicking on Client Plugin button you’ll see a ' +
            'window with the detailed information about this client.'
    step34: 'Comments section allows to leave specific comments about the ' +
            'client, e.g. English is not their first language, APA formatting ' +
            'for PSYC course, etc'
    step35: 'Orders tab gives you access to the list of all placed orders. ' +
            'Only 25 client\'s orders are displayed in Sidebar.'
    step36: 'You can check the number of placed orders here'
    step37: 'By clicking on “All orders” button you’ll be able to check ' +
            'full orders history'
    step38: 'Every placed order has its own number in our system. It ' +
            'consists of project code - AN for Nerdify orders, year, ' +
            'month and date. The last digits are the serial number of the order.'
    step39: 'These several numbers/letters next to the order number are ' +
            'the payment key of the order. They are the last digits of ' +
            'the payment link for this order.'
    step40: 'Here you can easily check order and writer status of the order.<br/>' +
            'For example, “Unpaid” orders will always have “Not offered” ' +
            'writer status, while “Paid” orders may have “Not found”, ' +
            '“In work”, “Completed”, etc writer statuses.'
    step41: 'Moreover, writer’s status of this order is also visible to us'
    step42: "When you hover the mouse over the order number, you'll be able " +
            "to see the title of this order."
    step43: 'By clicking on “New order” button you’ll be redirected to an ' +
            'order form that allows you to place an order according to ' +
            'client’s request. You can move this window to any part of ' +
            'the screen.'
    step44: 'This is a Quick order form. It opens in a separate window and ' +
            'it is basically a draft of an order that you are creating.'
    step45: 'Filling in red fields in order form are obligatory. You have ' +
            'to select appropriate descriptive order title, order type, ' +
            'number of words/pages/mins/slides.'
    step46: 'Once you entered the title of the order, don’t forget to hit ' +
            '‘Update’. Otherwise the changes won’t be saved.'
    step47: 'You can set order deadline by choosing an exact date on the ' +
            'calendar after clicking on “Done by” and hitting “Update” button.'
    step48: 'Or you can set order deadline by setting the amount of days ' +
            'after clicking on “Done in” and hitting “Update” button.'
    step49: 'Filling in red fields in order form are obligatory. You have ' +
            'to select appropriate descriptive order title, order type, ' +
            'number of words/pages/mins/slides.'
    step51: 'If you already have all necessary files ready, click here to ' +
            'attach them to the order. Note, if there are more than 5 files ' +
            'you have to attach them in zip file. You can also attach files ' +
            'later on in Full Order form.'
    step52: 'Order price will be automatically generated once all the ' +
            'details were added to the order. The total price will be ' +
            'depicted in this section.'
    step53: 'This section shows the amount of money on client’s balance ' +
            '(if there is any).'
    step54: 'Once the price is automatically calculated, “Send calculation ' +
            'to chat” button becomes available.'
    step55: 'This option will send a following price explanation of ' +
            'this order to your chat with the client. NOTE: Please do ' +
            'not forget to adjust necessary variables.'
    step56: 'If you don’t want this order to be saved in the system, ' +
            'just click “Cancel”.'
    step57: 'If you want to save the order and move forward, press ' +
            '“Create order”.'
    step58: 'By clicking on “New order” button you’ll be redirected to an ' +
            'order form that allows you to place an order according to ' +
            'client’s request. You can move this window to any part of ' +
            'the screen.'
    step59: 'Here you can see order number, name of the operator who created ' +
            'this order and date of order creation'
    step60: 'Order and writer statuses are also displayed here.'
    stepx1: 'Filling in red fields in order form are obligatory. ' +
            'You have to select appropriate descriptive order title, main ' +
            'and additional task types, number of words/pages/mins/slides.'
    stepy1: 'Choosing additional task type is also obligatory for any type ' +
            'of order.\nThe main distinction in additional task types lies ' +
            'in where the final paper should be submitted - on client\'s ' +
            'dashboard or in the order form. You will be able to choose the ' +
            'specific subtype using further options.'
    stepy2: 'For instance, if you place an order that requires the Writer to ' +
            'complete a discussion board on Client’s dashboard, you should ' +
            'choose “Submit to online Dashboard: Discussion post” as ' +
            'additional task type.\n Please remember that filling out ' +
            'this additional task type is obligatory for any client\'s ' +
            'requests, from simple movie reviews and research papers to ' +
            'online quizzes to be completed on the dashboard and SPSS tasks.'
    stepy3: 'Here is how the information you’ve put into the fields will ' +
            'be displayed in the order form.'
    stepx2: 'You can set order deadline by choosing an exact date on the ' +
            'calendar after clicking on “Done by” and hitting “Update” button.'
    stepx3: 'Or you can set order deadline by setting the amount of days ' +
            'after clicking on “Done in” and hitting “Update” button.'
    stepx4: 'Here you may add the info about number of sources or formatting ' +
            'style requested by the client. '
    stepx5: 'Here you can also choose Type of English to be used in the ' +
            'order. \'Not specified\' means that the Writer will use ' +
            'American English by default.'
    stepx6: 'Please note that order description should not be longer than ' +
            '500 words. You will be able to keep track of the words you have ' +
            'already input in the word count here.'
    stepx7: 'After you are done filling in order description form, do not ' +
            'forget to hit “Update”, otherwise none of the information will ' +
            'be saved.'
    stepx8: 'Once all the details were added to the order, you will be ' +
            'able to see the order total price here.'
    stepx9: 'You also will be able to add relevant ‘Add-ons’ to the order in ' +
            'the following section.<br/>NOTE: VIP support is not available ' +
            'for Nerdify clients at the moment.'
    stepx10: 'Once you check the box next to the title of the needed add-on, ' +
             'the order price will be adjusted according the add-on price. ' +
             'Remember that all add-ons have different prices.'
    stepx11: 'You can copy the payment link of this order right here.'
    step601: 'In Full Order Form you may add the info about number of ' +
             'sources or formatting style requested by the client.'
    step701: 'Here you can also choose Type of English to be used in the ' +
             'order. \'Not specified\' means that the Writer will use ' +
             'American English by default.'
    step702: 'If your client told you a discount code they’d like to have ' +
             'applied to their order, you need to apply a discount code in ' +
             'this field. When the discount code is applied, you will be ' +
             'able to see the discount amount as well as the discounted ' +
             'price here.'
    step602: 'Click here to add description.'
    step603: 'After you are done filling in order description form, do not ' +
             'forget to hit “Update”, otherwise none of the information ' +
             'will be saved.'
    step604: 'Once all the details were added to the order, you will be ' +
             'able to see the order total price here.'
    step605: 'Here you will be able to see the amount of money on client’s ' +
             'balance (if there is any).'
    step606: 'You can copy the payment link of this order right here. ' +
             'NOTE: if order description field is empty, you won’t be able ' +
             'to copy the payment link.'
    step607: 'Once the payment link is sent you are to check “Payment link ' +
             'sent” box. This way if the client forgets to make the payment ' +
             'for the order, they will receive auto follow-up message from ' +
             'us in 24 hours.'
    step608: 'If you checked “Plag report” box in Quick Order Form, ' +
             'this information will be transferred to Full Form as well.'
    step609: 'These 2 sections are needed to store any order related ' +
             'comments that our writers need to know. Please save client’s ' +
             'personal information or any login information for “Comments ' +
             'for Assigned Writer” field.'
    step6010: 'Click here to attach properly titled files to the order. If ' +
              'there are more than 5 files you have to attach them in zip file.'
    step61: 'It’s very important to click on Done button. Otherwise, ' +
            'your order won’t be saved.'
    step610: 'You can minimize order form by clicking this button.'
    step6101: 'Order form will be downsized to this window with full order ' +
              'number. To get back to the regular sized window just click ' +
              'on the order number.'
    step611: 'If the order consists of several types of tasks (writing + ' +
             'presentation) you will have to create a complex order. A ' +
             'complex order can be created only as an adjustment to the ' +
             'existing order meaning you have to create a regular order ' +
             'first and add order extras after that.'
    step612: 'In order to create a complex order, you have to:<br/><br/>click ' +
             'on the "measured in" field in the order form<br/>click on ' +
             '"Add more"<br/>select type of your request and specify the ' +
             'number of pages/words/slides/minutes that are ordered.<br/>' +
             'click “Add”<br/>A new part of the order will be saved and ' +
             'the payment link will be updated.<br/><br/>You will be able to ' +
             'add multiple parts of the order at a time, e.g. slides + words ' +
             '+ hours of work'
    step62: 'This tab gives you opportunity to review all information which ' +
            'is related to payments.'
    step63: 'Here you can quickly check client’s balance.'
    step64: 'By clicking on All payments button you’ll be able to check the ' +
            'list of transactions which are related to this client.'
    step65: 'This is client’s personal referral link'
    step66: 'There are links to client’s profile. You can send one of them ' +
            'when you offers the client to place an order in the cabinet.'
  communication:
    step1: 'By clicking on “All threads” button you’ll be able to check ' +
           'the Threads lists of the orders (communication inside of the ' +
           'order between writers, OFFF and QAFF or IRFF).'
  customers:
    step1: 'Customers tab allows you to search for the clients and create ' +
           'new ones when needed.'
    step2: 'This is a search field.'
    step3: 'Click on Start conversation button if you need to contact the ' +
           'client.'
  agents:
    step1: 'This is a storage of your shifts key performance indicators. ' +
           'You can sort them by shifts, weeks and months.'
    step2: 'Here you can check whether you get bonuses for your shifts or not.'
  ranking:
    step1: 'This tab gives you an opportunity to check all of your chats.'
    step2: 'You can sort this data by days, weeks and months.'
    step3: 'Besides, you can search for chats using this filter.'
    step4: 'Don’t forget to log out when your shift is finished.'
    step5: 'By clicking on the chosen shift you can check the whole list of ' +
           'your chats during this shift. If the chat is striked it’s ' +
           'marked as Bad. You can also check unranked chats and bad chats.'
    step6: 'To check your mistake you are to click on the chat shortcut.'
    step7: 'Striked chat is highlighted into yellow.'
    step8: 'You can also check the comments from QA TL.'
  dashboard:
    step1: 'This is Dashboard tab that gives you a detailed statistics on ' +
           'the shift flow. Here you can check the number of unique ' +
           'conversations per shift, unique clients pers shift, ' +
           'response and wait time. '
