import React, { useEffect } from 'react';

import RankingRuleDropdownContainer from 'components/rankingRulesModule/RankingRuleDropdownContainer';

import { TextArea } from '../components';

import styles from './index.styl';

const ReportSelectors = ({ report, onAnyDataChange }) => {
  const { comment, id, status, ranking_rule_id, isError, isInnerEdit } = report;

  const onSelectChange = (data) => {
    const { idRule, activeRule } = data;

    if (!idRule && ranking_rule_id) {
      onAnyDataChange(null, null, (__prevData) => {
        return __prevData.map(report => {
          if (report.id === id) {
            delete report['ranking_rule'];
            return {...report, ranking_rule_id: null }
          }
          return report;
        })
      });
      return;
    }

    if (idRule) {
      onAnyDataChange(id, { ranking_rule_id: idRule, ranking_rule: activeRule })
    }
  }

  // useEffect(() => {
  //   onAnyDataChange(id, { comment: '', isError: false })
  // }, [status]);

  return (
    <div className={styles.report_selectors}>
      <RankingRuleDropdownContainer report={isInnerEdit ? report : null} reportStatus={status} onChange={onSelectChange} isError={isError} />
      <TextArea value={comment} onChange={({ target }) => onAnyDataChange(id, { comment: target.value })} />
    </div>
  )

};

export default ReportSelectors;
