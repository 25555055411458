AuthAPI = require 'api/AuthAPI'
AgentsAPI = require 'api/AgentsAPI.coffee'
Dispatcher = require '../dispatcher/Dispatcher'
ActionTypes = require 'constants/ActionTypes'
config = require '../config'

{ clearToken } = require 'lib/tokenStorage'

{ oauthRedirect } = require 'lib/utils'

AuthActionCreator =
  validateToken: ->
    Dispatcher.dispatch type: ActionTypes.AUTH_VALIDATE_TOKEN_REQUESTING

    AuthAPI.validateToken(
      (response) ->
        Dispatcher.dispatch
          type: ActionTypes.MQTT_SUBSCRIBE
          payload:
            topic: "smstool/ping/#{response.id}"
            actionType: ActionTypes.AUTH_VALIDATE_TOKEN_FAILURE
        Dispatcher.dispatch
          type: ActionTypes.AUTH_VALIDATE_TOKEN_SUCCESS
          payload: { user: response }
        Dispatcher.dispatch
          type: ActionTypes.MQTT_SUBSCRIBE
          payload:
            topic: "smstool/users/#{response.id}"
            actionType: ActionTypes.MQTT_USER_UPDATING
        if config.updateNotification
          Dispatcher.dispatch
            type: ActionTypes.MQTT_SUBSCRIBE
            payload:
              topic: "smstool/system_info"
              actionType: ActionTypes.MQTT_SYSTEM_INFO_UPDATING
        if config.mqttSound || response.new_message_sound
          Dispatcher.dispatch
            type: ActionTypes.MQTT_SUBSCRIBE
            payload:
              topic: "smstool/users/all/new_inbound_message"
              actionType: ActionTypes.MQTT_PLAY_SOUND
          Dispatcher.dispatch
            type: ActionTypes.MQTT_SUBSCRIBE
            payload:
              topic: "smstool/users/#{response.id}/new_inbound_message"
              actionType: ActionTypes.MQTT_PLAY_SOUND
        if config.mqttNotification || response.new_message_sound
          Dispatcher.dispatch
            type: ActionTypes.MQTT_SUBSCRIBE
            payload:
              topic: "smstool/users/all/new_inbound_message"
              actionType: ActionTypes.MQTT_NOTIFICATION
          Dispatcher.dispatch
            type: ActionTypes.MQTT_SUBSCRIBE
            payload:
              topic: "smstool/users/#{response.id}/new_inbound_message"
              actionType: ActionTypes.MQTT_NOTIFICATION
        if response.assigned_chat_notification
          Dispatcher.dispatch
            type: ActionTypes.MQTT_SUBSCRIBE
            payload:
              topic: "smstool/assigned_chat_notification/#{response.id}"
              actionType: ActionTypes.MQTT_PLAY_SOUND

      -> Dispatcher.dispatch type: ActionTypes.AUTH_VALIDATE_TOKEN_FAILURE
    )

  logout: ->
    clearToken()
    oauthRedirect({ logout: true })

  ping: (id, token) ->
    Dispatcher.dispatch
      type: ActionTypes.MQTT_PUBLISH
      payload:
        topic: "smstool/ping/#{id}"
        message: JSON.stringify({token: token})
        initial: true

module.exports = AuthActionCreator
