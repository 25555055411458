{ ConnectProvider } = require '@connect/connect-xmpp';
{ Fragment, Component } = require 'react';

AuthStore = require 'stores/AuthStore'
CustomersStore = require 'stores/CustomersStore'
XMPPStore = require 'stores/XMPPStore'

XMPPActionCreator = require 'actions/XMPPActionCreator'

ConversationStore = require './conversations/ConversationComponent/ConversationStore.coffee'

{ TypingQuery, MessageBox } = require './XmppDataLayer'
XmppRosterData = require('components/XmppRosterData').default

BaseComponent = require './BaseComponent'

class XmppData extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  dependsOnStores: [AuthStore, ConversationStore, CustomersStore, XMPPStore]

  onIsCustomerTyping: (isTyping, conversation) => 
    setTimeout ->
        XMPPActionCreator.setXMPPAction(conversation, isTyping, 'isTyping')
      10
  
  onRosterChange: (rosters) =>
    setTimeout ->
        XMPPActionCreator.setXPPRosters rosters
      10

  getSelectedConversation: =>
    return null if !ConversationStore.conversation
    selectedConversation = ConversationStore.conversation
    if selectedConversation?.status != 'closed' && ConversationStore.openConversation
      selectedConversation = ConversationStore.openConversation
    selectedConversation

  render: ->
    selectedConversation = @getSelectedConversation()
    customer = CustomersStore.customer
    { operatorTypingText, operatorManualTyping } = XMPPStore

    React.createElement(Fragment, null,
      (
        if selectedConversation
          React.createElement(Fragment, null,
            React.createElement(TypingQuery, {"conversation": (selectedConversation), "user": (AuthStore.user), "onIsTyping": (@onIsCustomerTyping)}),
            React.createElement(MessageBox, {"conversation": (selectedConversation), "user": (AuthStore.user), "operatorTypingText": (operatorTypingText), "operatorManualTyping": (operatorManualTyping)})
          )
      ),
      React.createElement(XmppRosterData, {"onRosterChange": (@onRosterChange)})
    )
      

module.exports = XmppData