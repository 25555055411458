import React, { createContext, useState, useMemo } from 'react';

import { ModalContainer } from '@verdaccio/crminfo';

import { ROLES } from 'constants/Agents'

export const QAInfoContext = createContext();

export const QAInfoProvider = ({ children, currentConversationId, user }) => {
  const [modalComponent, setModalComponent] = useState(null);

  const isUserValidRole = useMemo(() => {
    const { role } = user;

    const { qa_teamlead: { value: qaValue }, manager: { value: mValue } } = ROLES;

    const VALID_EDIT_ROLES = [qaValue, mValue];

    if (Array.isArray(role)) {
      return role.some(r => VALID_EDIT_ROLES.includes(r))
    }

    return VALID_EDIT_ROLES.includes(role)
  }, [user]);


  return (
    <QAInfoContext.Provider value={{ modalComponent, setModalComponent, currentConversationId, isUserValidRole }}>
      {children}
      {
        modalComponent && (
          <ModalContainer noPadding>{React.cloneElement(modalComponent)}</ModalContainer>
        )
      }
    </QAInfoContext.Provider>
  );
};