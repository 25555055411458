InfoBlockComponent = require 'components/shared/InfoBlockComponent'
RankingRulesStore = require 'stores/RankingRulesStore'
AuthStore = require 'stores/AuthStore'
ConversationStore = require 'components/conversations/ConversationComponent/ConversationStore'

BaseComponent = require 'components/BaseComponent'

{ crmPluginEnabled, isGeekly } = require 'root/config'
CRMPluginActionCreator = require('actions/CRMPluginActionCreator').default

pluginConfigs = null
if crmPluginEnabled
  pluginConfigs = require('@verdaccio/crminfo').pluginConfigs

QAInfo = require('./QAInfo').default
QAInfoMenu = require('./QAInfoMenu').default

icon = require './icon.svg'

propTypes = require 'prop-types'

class RankingModule extends BaseComponent
  constructor: (props) ->
    super(props)
    @state =
      isOpenImmediately: false
      currentMessage: null
      isAllUnfolds: undefined

  dependsOnStores: [RankingRulesStore, ConversationStore, AuthStore]

  componentDidMount: -> @props.setTabsState 'qa-info-module': !!@props.anyOpen

  componentDidUpdate: (props) ->
    if (@state.currentMessage && !@state.isOpen && !@state.isOpenImmediately) 
      @setState isOpenImmediately: true

  onToggleBlock: (dataKey, isOpen) =>
    @props.onToggleBlock(dataKey)

    { isOpenImmediately, currentMessage } = @state;
    
    if (!isOpen && isOpenImmediately && !currentMessage) 
       @setState isOpenImmediately: false, isOpen: isOpen,

  onFonds: (value) =>
    @setState isAllUnfolds: value

    setTimeout =>
      @setState isAllUnfolds: undefined 
      10

  onNewTab: () =>
    { currentConversationId } = RankingRulesStore
    window.open("#{window.location.origin}/ranking_report/#{currentConversationId}", '_blank')

  onReportPluginOpen: () =>
    { messages, currentConversationId, reportCount } = RankingRulesStore
    { conversation } = ConversationStore
    if crmPluginEnabled
      config = pluginConfigs.RankingReportPlugin({ messages: messages, reportCount: reportCount, conversation: conversation, options: { isResizable: true, pluginWidth: 'calc(100vw - 40px)', pluginInitialRightPosition: 20, isGeekly } })
      CRMPluginActionCreator.setConfig(config)

  render: ->
    { isOpenImmediately, isAllUnfolds } = @state
    { currentMessage, messages, loading, currentConversationId, reportCount } = RankingRulesStore
    { user } = AuthStore
    
    @state.currentMessage = currentMessage;

    React.createElement("div", null,
      React.createElement(InfoBlockComponent, {  \
        "dataKey": 'qa-info-module',  \
        "title": 'qa-info',  \
        "openByDefault": (!!@props.anyOpen),  \
        "onToggleBlock": (@onToggleBlock),  \
        "isOpenImmediately": (isOpenImmediately),  \
        "additional": (if reportCount > 0 then reportCount else null),  \
        "icon": (icon),  \
        "customComponent": (if reportCount > 0 then React.createElement(QAInfoMenu, {"onNewTab": (@onNewTab), "onReportPluginOpen": (@onReportPluginOpen), "onFonds": (@onFonds)}) else null)
      },
        React.createElement(QAInfo, {"user": (user), "isAllUnfolds": (isAllUnfolds), "currentMessage": (currentMessage), "messages": (messages), "loading": (loading), "currentConversationId": (currentConversationId)})
      )
    )

RankingModule.propTypes = 
  setTabsState: propTypes.func.isRequired
  onToggleBlock: propTypes.func.isRequired
  anyOpen: propTypes.bool

module.exports = RankingModule
