import React from 'react';
import { useState } from 'react';
import classnames from 'classnames';

import styles from './styles.styl'

const InputCounter = ({ value = '', onInputChange, disabled = false, hasError }) => {
	const [count, setCount] = useState(Number.parseInt(value));

  const handleIncrease = () => {
    const newCount = count + 1;
    setCount(newCount)
    onInputChange(newCount);
  }

  const handleDecrease = () => {
    if (count === 0) return;
    const newCount = count - 1;
    setCount(newCount);
    onInputChange(newCount);
  }

  return (
		<div className={classnames(styles.container, { [styles.error]: hasError })}>
      <button className={styles.btn_left} onClick={handleDecrease} disabled={disabled} />
      <input onChange={onInputChange} type="text" value={count} disabled={disabled} />
      <button className={styles.btn_right} onClick={handleIncrease} disabled={disabled} />
    </div>
  );
}

export default InputCounter;