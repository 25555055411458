classnames = require 'classnames'
browserHistory = require 'root/history'
config = require 'root/config'
BaseComponent = require 'components/BaseComponent'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
ShiftAgentComponent = require 'components/agents/ShiftAgentComponent'
ShiftAgentsActionCreator = require 'actions/ShiftAgentsActionCreator'
AuthStore = require 'stores/AuthStore'
ShiftAgentsStore = require 'stores/ShiftAgentsStore'
OnlineStore = require 'stores/OnlineStore'
ConversationsDashboardStore = require 'stores/ConversationsDashboardStore'
ConversationsActionCreator = require 'actions/ConversationsActionCreator'
ConversationsActionCreator = new ConversationsActionCreator()
styles = require './agent-list'
{ ROLES, SHIFT_AGENTS_ACCESS } = require 'constants/Agents'


class ShiftAgentsComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  dependsOnStores: [ShiftAgentsStore, OnlineStore, ConversationsDashboardStore]

  componentWillMount: ->
    userRole = ROLES[AuthStore.user.role].value
    unless SHIFT_AGENTS_ACCESS[userRole]
      return browserHistory.default.push '/'
    unless config.showShiftAgents
      browserHistory.default.push '/'

  componentDidMount: ->
    super()
    setTimeout(
      ->
        ConversationsActionCreator.subscribeDashboardConversations()
        ShiftAgentsActionCreator.subscribe()
        ShiftAgentsActionCreator.sendInitial()
      0
    )

  componentWillUnmount: ->
    super()
    setTimeout(
      ->
        ConversationsActionCreator.unSubscribeDashboardConversations()
        ShiftAgentsActionCreator.unSubscribe()
      0
    )

  updateShift: =>
    setTimeout(
      ->
        ShiftAgentsActionCreator.updateShift()
      0
    )

  render: ->
    React.createElement(LoadableWrapperComponent, {"loading": (ShiftAgentsStore.loading || ShiftAgentsStore.wfh_loading)},
      React.createElement("div", {"className": (styles.container)},
        React.createElement("table", {"className": (styles.agents__head)},
          React.createElement("thead", null,
              React.createElement("tr", null,
                React.createElement("th", { \
                  "className": (classnames(
                    styles['agents__head-item']
                    styles.agents__head_name
                    styles.agents__head_type_1
                  ))
                }, """
                  Operator Name
"""),
                React.createElement("th", {"className": (classnames(
                    styles['agents__head-item']
                    styles.agents__head_role
                    styles.agents__head_type_1
                  ))
                }, """
                  Role
"""),
                React.createElement("th", {"className": (classnames(
                    styles['agents__head-item']
                    styles.agents__head_location
                    styles.agents__head_type_1
                  ))
                }, """
                  Location
"""),
                React.createElement("th", {"className": (classnames(
                    styles['agents__head-item']
                    styles.agents__head_status
                    styles.agents__head_type_1
                  ))
                }, """
                  Status
"""),
                React.createElement("th", {"className": (classnames(
                    styles['agents__head-item']
                    styles.agents__head_min
                    styles.agents__head_center
                    styles.agents__head_type_2
                  ))
                }, """
                  IDLE
"""),
                React.createElement("th", {"className": (classnames(
                    styles['agents__head-item']
                    styles.agents__head_center
                    styles.agents__head_min
                    styles.agents__head_type_3
                  ))
                }, """
                  UC
"""),
                React.createElement("th", {"className": (classnames(
                    styles['agents__head-item']
                    styles.agents__head_min
                    styles.agents__head_center
                    styles.agents__head_type_3
                  ))
                }, """
                  RT
"""),
                React.createElement("th", {"className": (classnames(
                    styles['agents__head-item']
                    styles.agents__head_min
                    styles.agents__head_center
                    styles.agents__head_type_3
                  ))
                }, """
                  Login
"""),
                React.createElement("th", {"className": (classnames(
                    styles['agents__head-item']
                    styles.agents__head_min
                    styles.agents__head_center
                    styles.agents__head_type_3
                  ))
                }, """
                  Logout
"""),
                React.createElement("th", {"className": (classnames(
                    styles['agents__head-item']
                    styles.agents__head_min
                    styles.agents__head_center
                    styles.agents__head_type_3
                  ))
                }, """
                  New #
"""),
                React.createElement("th", {"className": (classnames(
                    styles['agents__head-item']
                    styles.agents__head_counter
                    styles.agents__head_center
                    styles.agents__head_type_5
                  ))
                }, """
                  Open #
"""),
                React.createElement("th", {"className": (classnames(
                    styles['agents__head-item']
                    styles.agents__head_counter
                    styles.agents__head_center
                    styles.agents__head_type_6
                  ))
                }, """
                  Dormant #
"""),
                React.createElement("th", {"className": (classnames(
                    styles['agents__head-item']
                    styles.agents__head_counter
                    styles.agents__head_center
                    styles.agents__head_type_7
                  ))
                }, """
                  Inactive #
"""),
                React.createElement("th", {"className": (classnames(
                    styles['agents__head-item']
                    styles.agents__head_type_8
                    styles.agents__head_rc
                    styles.agents__head_center
                  ))
                }, """
                Roll call 1
"""),
                React.createElement("th", {"className": (classnames(
                    styles['agents__head-item']
                    styles.agents__head_type_8
                    styles.agents__head_rc
                    styles.agents__head_center
                  ))
                }, """
                Roll call 2
"""),
                React.createElement("th", {"className": (classnames(
                    styles['agents__head-item']
                    styles.agents__head_type_8
                    styles.agents__head_rc
                    styles.agents__head_center
                  ))
                }, """
                Roll call 3
"""),
                React.createElement("th", {"className": (classnames(
                    styles['agents__head-item']
                    styles.agents__head_type_8
                    styles.agents__head_rc
                    styles.agents__head_center
                  ))
                }, """
                Roll call 4
"""),
                React.createElement("th", null,
                  React.createElement("button", {"className": (styles.btn_update), "onClick": (() => @updateShift())}, "Update")
                )
              )
          ),
          React.createElement("tbody", null,
              (ShiftAgentsStore.list.map (agent, i) ->
                React.createElement(ShiftAgentComponent, { \
                  "key": (i),  \
                  "agent": (agent),  \
                  "dashboardAgents": (ConversationsDashboardStore.agents),  \
                  "rollCallsNames": (ShiftAgentsStore.rollCallsNames)
                })
              )
          )
        )
      )
    )


module.exports = ShiftAgentsComponent
