export default {
  getCRMInfoId: (customer) => {
    if (!customer) return null;

    if (Object.keys(customer).length === 0) return null;

    const { crm_ids: crmIds } = customer;

    if (!crmIds) return 'no_crm_id';

    if (Array.isArray(crmIds) && customer.crm_ids.length === 0) return 'no_crm_id';
  
    return crmIds[0];
  }
};
