PersonalizedStore = require 'stores/abstract/PersonalizedStore'
WaitingTimeActionCreator = require './WaitingTimeActionCreator'
ActionTypes = require 'constants/ActionTypes'

actionsMap = 
  "#{ActionTypes.WAITING_TIME_STAT_REQUESTING}": ->
    @loading = true
    @error = null
    @emitChange()
  "#{ActionTypes.WAITING_TIME_STAT_SUCCESS}": (data) ->
    @loading = false
    @currentWT = data.current_wt
    @shiftWT = data.shift_wt
    @goodWT = data.good_wt
    @interval = data.interval
    @channelType = data.channel_type
    @emitChange()
  "#{ActionTypes.WAITING_TIME_STAT_ERROR}": (error) ->
    @loading = false
    @error = error
    @emitChange()


class ResponseTimeStore extends PersonalizedStore
  constructor: ->
    super()
    @loading = false
    @currentWT = {}
    @shiftWT = {}
    @channelType = {}
    @goodWT = null
    @interval = null
    @error = null
    @registerActions()

  getActionCreator: -> new WaitingTimeActionCreator

  _registerToActions: ({ type, payload, guid }) =>
    return unless @isPersonalAction(guid)
    actionsMap[type].call(@, payload) if actionsMap[type]

module.exports = ResponseTimeStore
