classnames = require 'classnames'
styles = require './autocomplete'

class Autocomplete extends React.Component
  constructor: (props) ->
    super()
    @state =
      activeSuggestion: 0
      filteredSuggestions: []
      showSuggestions: false
      value: props.defaultValue

  onChange: (e) =>
    { suggestions, onChange, name } = @props
    value = e.currentTarget.value
    filteredSuggestions = suggestions.filter (suggestion) =>
      suggestion.toLowerCase().indexOf(value.toLowerCase()) > -1
    showSuggestions = filteredSuggestions.length > 0
    onChange(name, value)
    @setState
      activeSuggestion: 0
      filteredSuggestions: filteredSuggestions
      showSuggestions: showSuggestions
      value: value

  onClick: (e) =>
    { onChange, name } = @props
    value = e.currentTarget.innerText
    onChange(name, value)
    @setState
      activeSuggestion: 0
      filteredSuggestions: []
      showSuggestions: false
      value: value

  onKeyDown: (e) =>
    { activeSuggestion, filteredSuggestions } = @state
    { onChange, name } = @props
    if e.keyCode == 13
      value = filteredSuggestions[activeSuggestion]
      onChange(name, value)
      @setState
        activeSuggestion: 0
        showSuggestions: false
        value: value
    if e.keyCode == 38
      return if activeSuggestion == 0
      @setState activeSuggestion: activeSuggestion - 1
    if e.keyCode == 40
      return if activeSuggestion == filteredSuggestions.length - 1
      @setState activeSuggestion: activeSuggestion + 1

  render: ->
    { activeSuggestion, filteredSuggestions, showSuggestions, value } = @state
    { placeholder, maxLength } = @props
    suggestions = <div></div>
    if showSuggestions
      suggestions = <ul className={styles.suggestions}>
        {filteredSuggestions.map (suggestion, index) =>
          <li
            className={classnames styles.suggestion,
              "#{styles.suggestion_active}": index == activeSuggestion}
            key={suggestion}
            onClick={@onClick}
          >
            {suggestion}
          </li>
        }
      </ul>
    <React.Fragment>
      <input
        className={styles.input}
        type={'text'}
        placeholder={placeholder}
        maxLength={maxLength}
        value={value}
        onChange={@onChange}
        onKeyDown={@onKeyDown}
      />
      {suggestions}
    </React.Fragment>

module.exports = Autocomplete
