import React from 'react';

import { controls } from '@verdaccio/crminfo';

import styles from './index.styl';

const Modal = ({ header, text, onClose, onSuccess, btnsNames = ['Cancel', 'Delete'] }) => {
  return (
    <div className={styles.modal}>
      <h2 className={styles.modal__header}>{header}</h2>
      <p className={styles.modal__text}>{text}</p>
      <div className={styles.modal_btn_block}>
        <controls.Button className={styles.modal_btn_block__cancel} onClick={onClose}>
          {btnsNames[0]}
        </controls.Button>
        <controls.Button className={styles.modal_btn_block__delete} onClick={onSuccess}>
          {btnsNames[1]}
        </controls.Button>
      </div>
    </div>
  )
};

export default Modal;
