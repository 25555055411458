AgentsAPI = require '../api/AgentsAPI'
Dispatcher = require '../dispatcher/Dispatcher'
ActionTypes = require '../constants/ActionTypes'

module.exports =
  setXMPPAction: (conversationId, value, action) ->
    setTimeout ->
      Dispatcher.dispatch
        type: ActionTypes.SET_XMPP_ACTION
        payload: { conversationId, value, action }
      10
  setXPPRosters: (rosters) ->
    setTimeout ->
      Dispatcher.dispatch
        type: ActionTypes.SET_XMPP_ROSTERS
        payload: { rosters }
      10
  setOperatorTyping: (text) ->
    setTimeout ->
      Dispatcher.dispatch
        type: ActionTypes.SET_OPERATOR_TYPING
        payload: { text }
      10
  setManualTyping: (value) ->
    setTimeout ->
      Dispatcher.dispatch
        type: ActionTypes.SET_OPERATOR_MANUAL_TYPING
        payload: { value }
      10