{ Link } = require 'react-router-dom'
classnames = require 'classnames'
Isvg = require 'react-inlinesvg'

BaseComponent = require 'components/BaseComponent'
InterfaceActionCreator = require 'actions/InterfaceActionCreator'
InterfaceStore = require 'stores/InterfaceStore'
styles = require '../MenuComponent/menu'

icons = {
  unassigned: require './bubble.svg'
  my_conversations: require './my_conversation.svg'
  all_conversations: require './mail.svg'
  customers: require './customers.svg'
  dashboard: require './dashboard.svg'
  channels: require './channels.svg'
  overflow_channels: require './overflow_channels.svg'
  agents: require './agents.svg'
  'qa-dashboard': require './qa-dashboard.svg'
  ranking: require './ranking.svg'
  messages_ranking: require './messages_ranking.svg'
  'ranking_rules': require './ranking_rules.svg'
  'page_ranking_tools': require './tools.svg'
  'csv-export': require './csv-export.svg'
  groups: require './agents.svg'
  shift_agents: require './agents.svg'
  tags: require './tags.svg'
  settings: require './settings.svg'
  'answer-template': require './template-icon.svg'
  admin: require './reminder.svg'
  landings: require './landings.svg'
  rotations: require './rotations.svg'
}

class MenuItemComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  render: ->
    key = @props.menu.key
    path = @props.menu.path || ("/" + key)
    className = classnames(styles.link, styles[key], {
      "#{styles.selected}": window.location.pathname.startsWith(path)
    })
    React.createElement(Link, { \
      "to": (path),  \
      "className": (className),  \
      "onClick": (@handler),  \
      "title": (@props.menu.name)
    },
      React.createElement("span", {"className": (styles.svg)}, React.createElement(Isvg.default, {"src": (icons[key])})),
      (
        React.createElement("div", {"className": (styles.badge)}, (@props.badge)) if @props.badge
      )
    )

module.exports = MenuItemComponent
