import React from 'react';
import { controls } from '@verdaccio/crminfo';

import { statusMapper } from 'components/rankingRulesModule/RankingModuleInfoBlock/QAInfo/QAInfoItem/constants';

import { TextArea, Links } from './components'
import ReportSelectors from './ReportSelectors'

import styles from './index.styl';

const ReportEditBody = ({ report, onAnyDataChange }) => {
  const { status, id, comment, isError } = report;

  const getContent = () => {
    if (status === 'comment') {
      return <TextArea isRequired isError={isError && !comment} value={comment} onChange={({ target }) => onAnyDataChange(id, { comment: target.value })} />
    }

    return <ReportSelectors report={report} onAnyDataChange={onAnyDataChange} />
  };

  const onRadioChange = (e) => {
    const { target: { name } } = e;
    onAnyDataChange(null, null, (__prevData) => {
      return __prevData.map(report => {
        if (report.id === id) {
          delete report['ranking_rule'];
          return {...report, ...{ status: name, comment: '', isError: false, ranking_rule_id: null, links: [] } }
        }
        return report;
      })
    });
  }

  return (
    <div className={styles.edit_body}>
      <div className={styles.edit_body_radio_block}>
        {
          Object.keys(statusMapper).map((type) => {
            return (
              <controls.Radiobutton
                key={`${type}_${id}`}
                value={type}
                name={type}
                checked={status === type}
                onChange={onRadioChange}
                id={`${id}`}
                className={`${id}`}
              >
              {statusMapper[type]}
              </controls.Radiobutton>
            )
          })
        }
      </div>
      {getContent()}
      <Links id={id} onAnyDataChange={onAnyDataChange} status={status} report={report} />
    </div>
  )
};

export default ReportEditBody;
