{ Link }                           = require 'react-router-dom'
browserHistory                     = require './../../../history'
config                              = require 'root/config'
classnames                         = require 'classnames'

AuthStore                          = require 'stores/AuthStore'
CustomerOrdersStore                = require 'stores/CustomerOrdersStore'
CRMInfoModuleStore                 = require 'stores/CRMInfoModuleStore'
ConversationItemsStore             = require 'stores/ConversationItemsStore'

conversationStyles                 = require '../ConversationComponent/conversation'
BaseComponent                      = require 'components/BaseComponent.cjsx'
SearchInputComponent               = require 'components/shared/SearchInputComponent/SearchInputComponent'
IconButtonWithTooltipComponent     = require 'components/shared/IconButtonWithTooltipComponent/IconButtonWithTooltipComponent'
LoadableWrapperComponent           = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
InterfaceActionCreator             = require 'actions/InterfaceActionCreator'

InfoModal                          = require 'components/shared/InfoModal'
ScheduledMessagesManagerComponent  = require 'components/ScheduledMessagesManagerComponent'
Select                             = require 'components/shared/Select'
TagsWarningComponent               = require 'components/tags/TagsWarningComponent'
TagsWarningDashboardComponent      = require 'components/tags/TagsWarningDashboardComponent'

constants                          = require 'constants/base'
CONVERSATION_STATUS                = require 'constants/ConversationStatuses'
{ ASSIGN_BUTTON_ACCESS, TAG_CHECKING_EXCEPT } = require 'constants/Agents'
{ TEXT_FIND_1, TEXT_FIND_2, TAG_DASHBOARD, TAGS_FOR_DASHBOARD, TAG_REFFERAL, CLIENT_TAG_EXCLUDE_FOR_REFERAL, CHAT_TAG_EXCLUDE_FOR_REFERAL, TAG_SUBSCRIPTION_OFFER } = require 'constants/conversations'

propTypes                          = require 'prop-types'
styles                             = require './conversation-header'

CONVERSATION_STATUS_ACTION =
  SHOW_MODAL_TAG_ACTION: 'SHOW_MODAL_TAG_ACTION'
  SHOW_MODAL_TAG_DASHBOARD_ACTION: 'SHOW_MODAL_TAG_DASHBOARD_ACTION'
  SHOW_MODAL_REFFERAL_WARNING: 'SHOW_MODAL_REFFERAL_WARNING'
  SHOW_MODAL_SUBSCRIPTION_WARNING: 'SHOW_MODAL_SUBSCRIPTION_WARNING'
  UPDATE_STATUS: 'UPDATE_STATUS'

class ConversationHeaderComponent extends BaseComponent
  constructor: (props) ->
    super(props)

    @state =
      showRefferal: false
      showOfferSubbscription: false

    @initComponent()

  @propTypes:
    folder: propTypes.string.isRequired
    updateConversationStatus: propTypes.func.isRequired
    conversation: propTypes.object.isRequired
    errors: propTypes.shape
      takeNew: propTypes.string
    onMessagesSearch: propTypes.func
    toggleAssignerHandler: propTypes.func
    toggleCustomerInfoHandler: propTypes.func.isRequired

  statuses: CONVERSATION_STATUS._ALL

  dependsOnStores: [AuthStore, CustomerOrdersStore, CRMInfoModuleStore]

  statusToLabel:
    "#{CONVERSATION_STATUS.NEW_MESSAGE}": 'New'
    "#{CONVERSATION_STATUS.OPENED}": 'Opened'
    "#{CONVERSATION_STATUS.CLOSED}": 'Closed'
    "#{CONVERSATION_STATUS.DORMANT}": 'Dormant'

  searchTextInMessage: (message) =>
    text = message?.text.toLowerCase()
    message?.type == 'message' && (text.indexOf(TEXT_FIND_1) > -1 || text.indexOf(TEXT_FIND_2) > -1)

  getActionOnChangeStatus: (status) =>
    action = {}
    showDashboardModal = false
    showModal = false
    hasKeyWords = false
    isLead = @props.conversation?.customer?.lead
    hasTags = @props.conversation.tags?.length > 0

    hasTagDashboard = @props.conversation.tags?.some (tag) -> tag.name == TAG_DASHBOARD
    hasTagForRefferal = @props.conversation.tags?.some (tag) -> tag.name == TAG_REFFERAL
    hasTagChatForRefferal = @props.conversation.tags?.some (chat_tag) -> CHAT_TAG_EXCLUDE_FOR_REFERAL.includes chat_tag.name
    hasTagClientForRefferal = @props.conversation?.customer?.tags?.some (client_tag) -> CLIENT_TAG_EXCLUDE_FOR_REFERAL.includes client_tag.name

    action.closeModal = hasTags && !hasTagDashboard
    showRefferalWarning = !hasTagChatForRefferal && !hasTagClientForRefferal && !@state.showRefferal

    hasTagSubscriptionOffer = @props.conversation.tags?.some (tag) -> tag.name == TAG_SUBSCRIPTION_OFFER
    showRefferalWarningWithMark = !hasTagSubscriptionOffer && CRMInfoModuleStore.payments?.paid_orders_count == 1

    # isLead = true
    # console.log('@props.conversation.tags: ', @props.conversation.tags)
    # console.log('hasTags: ', hasTags)
    # console.log('hasTag: ', hasTag)
    # console.log('isLead: ', isLead)
    # console.log('hasTagChatForRefferal: ', hasTagChatForRefferal)
    # console.log('hasTagClientForRefferal: ', hasTagClientForRefferal)
    # console.log('@state.showRefferal: ', @state.showRefferal)
    # console.log('showRefferalWarning: ', showRefferalWarning)
    # console.log('showRefferalWarningWithMark: ', showRefferalWarningWithMark)
    # console.log('CRMInfoModuleStore.payments?.paid_orders_count: ', CRMInfoModuleStore.payments?.paid_orders_count)
    # console.log('@props.conversation.tags: ', @props.conversation.tags)

    if @state.status != status
      action.name = CONVERSATION_STATUS_ACTION.UPDATE_STATUS

      if status == CONVERSATION_STATUS.CLOSED


        # if config.isAwesomeTutors
        #   action.name = CONVERSATION_STATUS_ACTION.UPDATE_STATUS

        if showRefferalWarningWithMark && !isLead && !@state.showOfferSubbscription && !config.isAwesomeTutors
          action.name = CONVERSATION_STATUS_ACTION.SHOW_MODAL_SUBSCRIPTION_WARNING
        
        else if !showRefferalWarningWithMark && showRefferalWarning && !isLead && !config.isAwesomeTutors
          action.name = CONVERSATION_STATUS_ACTION.SHOW_MODAL_REFFERAL_WARNING

        else
          if !hasTags
            action.name = CONVERSATION_STATUS_ACTION.SHOW_MODAL_TAG_ACTION

          if isLead
            conversationItems = ConversationItemsStore.conversationItemsSync
            hasKeyWords = conversationItems.some @searchTextInMessage

            if hasKeyWords
              if hasTagDashboard
                action.name = CONVERSATION_STATUS_ACTION.UPDATE_STATUS

              if (hasTags && !hasTagDashboard) || !hasTags
                action.name = CONVERSATION_STATUS_ACTION.SHOW_MODAL_TAG_DASHBOARD_ACTION
      else
        action.name = CONVERSATION_STATUS_ACTION.UPDATE_STATUS

    return action

  onCloseCovnersation: (option) =>
    option =
      label: "Closed",
      value: "closed"
    InterfaceActionCreator.closeModal
    return setTimeout(
      => @onStatusChange option, null, true
      10
    )

  onCloseRefferalWarning: =>
    option =
      label: "Closed",
      value: "closed"
    InterfaceActionCreator.closeModal()
    @setState
      showRefferal: true
    return setTimeout(
      => @onStatusChange option
      10
    )

  onCloseSubscriptionWarning: =>
    option =
      label: "Closed",
      value: "closed"
    InterfaceActionCreator.closeModal()
    @setState
      showOfferSubbscription: true
    return setTimeout(
      => @onStatusChange option
      10
    )


  onStatusChange: (option, action = null, noDashboard = false) =>
    status = option.value
    hasOrder = CustomerOrdersStore?.orders?.length > 0

    if !config.dashboardTags
      @props.updateConversationStatus(status)
    else
      action = @getActionOnChangeStatus(status)
      switch action.name
        when CONVERSATION_STATUS_ACTION.SHOW_MODAL_TAG_DASHBOARD_ACTION
          if noDashboard && !TAG_CHECKING_EXCEPT[AuthStore.user.role]
            InterfaceActionCreator.openModal TagsWarningComponent, {
              conversationId: @props.conversation?.id
              updateConversationStatus: @props.updateConversationStatus
              onClick: 
                @setState
                  showRefferal: false
                  showOfferSubbscription: false
            }
          if !noDashboard
            InterfaceActionCreator.openModal TagsWarningDashboardComponent, {
              conversationId: @props.conversation?.id
              updateConversationStatus: @props.updateConversationStatus
              closeModal: action.closeModal
              hasOrder: hasOrder
              onClick: @onCloseCovnersation
            }
        when CONVERSATION_STATUS_ACTION.SHOW_MODAL_TAG_ACTION
          if !TAG_CHECKING_EXCEPT[AuthStore.user.role]
            InterfaceActionCreator.openModal TagsWarningComponent, {
              conversationId: @props.conversation?.id
              updateConversationStatus: @props.updateConversationStatus
              onClick: 
                @setState
                  showRefferal: false
                  showOfferSubbscription: false
            }
        when CONVERSATION_STATUS_ACTION.SHOW_MODAL_REFFERAL_WARNING
          InterfaceActionCreator.openModal InfoModal, {
            text: "Don't forget to send Referral program"
            buttonTitle: 'Send RP'
            cancelButtonTitle: 'Close chat'
            onCancel: @onCloseRefferalWarning
          }

        when CONVERSATION_STATUS_ACTION.SHOW_MODAL_SUBSCRIPTION_WARNING
          InterfaceActionCreator.openModal InfoModal, {
            text: "Don't forget to offer Subscription"
            buttonTitle: 'Offer subscription'
            cancelButtonTitle: 'Close chat'
            onCancel: @onCloseSubscriptionWarning
          }
        when CONVERSATION_STATUS_ACTION.UPDATE_STATUS
          @setState
            showRefferal: false
            showOfferSubbscription: false
          InterfaceActionCreator.closeModal()
          @props.updateConversationStatus(status)
          

  remindersRoute: =>
    "/conversations/#{@props.folder}/#{@props.conversation?.id}/reminders"

  gotoReminder: =>
    browserHistory.default.push "/conversations/#{@props.folder}/#{@props.conversation?.id}/reminders"

  openScheduledMessages: =>
    browserHistory.default.push "/conversations/#{@props.folder}/#{@props.conversation?.id}/schedulers/#{@props.conversation?.customer?.id}"

  render: =>
    statusOptions = @statuses.map (s) =>
      label: @statusToLabel[s] || s
      value: s
    statusValue =
      label: @statusToLabel[@props.conversation.status] || @props.conversation.status
      value: @props.conversation.status

    (React.createElement("div", {"className": conversationStyles.header},
      React.createElement(SearchInputComponent, { \
        "onChange": (@props.onMessagesSearch),  \
        "onClear": (@props.onMessagesSearch),  \
        "placeholder": 'Search messages by key words',  \
        "className": (styles.search_messages),  \
        "inputClassName": (styles.search_input),  \
        "clearCross": (true),  \
        "autofocus": true
      },
        (
          if @props.searchEnabled
            length = @props.foundMessages.length
            React.createElement("div", {"className": (styles.messages_search_result_panel)},
              React.createElement(LoadableWrapperComponent, { \
                "containerClassName": (styles.search_loader_wrapper),  \
                "loading": (@props.searchLoading),  \
                "loader": (className: styles.search_loader)
              },
                (
                  if length > 0
                    React.createElement("div", {"className": (styles.navigation)},
                      React.createElement("div", { \
                        "className": (styles.prev),  \
                        "onClick": ( R.partial(@props.searchStepCallback, [-1]) )
                      }
                      ),
                      React.createElement("span", {"className": (styles.position)},
                        ( "#{@props.searchPosition}/#{length}" )
                      ),
                      React.createElement("div", { \
                        "className": (styles.next),  \
                        "onClick": ( R.partial(@props.searchStepCallback, [1]) )
                      })
                    )
                )
              )
            )
        )
      ),

      React.createElement(Select.default, { \
        "value": (statusValue),  \
        "options": (statusOptions),  \
        "onChange": (@onStatusChange),  \
        "className": (styles.select_state),  \
        "placeholder": 'Set state',  \
        "classNamePrefix": "ReactSelect"
      }),
      React.createElement(IconButtonWithTooltipComponent, { \
        "tooltip": 'Add reminder',  \
        "callback": (@gotoReminder),  \
        "className": (styles.reminder)
      },
        (if @props.conversation.customer?.active_reminders_count > 0
          React.createElement("div", {"className": (styles.button_badge)},
            (@props.conversation.customer?.active_reminders_count)
          )
        )
      ),
      React.createElement(IconButtonWithTooltipComponent, { \
        "tooltip": 'Schedule message',  \
        "callback": (@openScheduledMessages),  \
        "className": (styles.scheduled_message_button)
      },
        (if @props.conversation.customer?.not_completed_scheduled_messages?.length > 0
          React.createElement("div", {"className": (styles.button_badge)},
            (@props.conversation.customer?.not_completed_scheduled_messages.length)
          )
        )
      ),

      (if ASSIGN_BUTTON_ACCESS[AuthStore.user.role]
        React.createElement(IconButtonWithTooltipComponent, { \
          "tooltip": 'Assign conversation',  \
          "callback": (@props.toggleAssignerHandler),  \
          "className": (styles.assigner_button)
        })
      )
    ))

module.exports = ConversationHeaderComponent
