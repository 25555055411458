import { useMemo } from 'react';
import moment from 'moment';
import classnames from 'classnames';

import DropdownList from 'react-widgets/lib/DropdownList';

import styles from './agent-list-item';

const ShiftTimeDropDown = ({ minShiftTime, maxShiftTime, beginShiftTime, onChangeHandler, isChangedByAgent  }) => {
  const dates = useMemo(() => {
    const __dates = [];

    let minTime = moment(minShiftTime).toDate();
    const maxTimeMDate = moment(maxShiftTime).toDate();
    const maxTime = new Date(Math.floor(maxTimeMDate / 15 / 1000 / 60) * 15 * 60 * 1000);

    while (minTime < maxTime) {
      __dates.push({id: minTime, name: moment(minTime).format("HH:mm")});
      minTime = moment(minTime).add(15, 'm').toDate();
    }

    return __dates;
  }, [minShiftTime, maxShiftTime]);

  if (!beginShiftTime) return null;

  return (
    <DropdownList
      containerClassName={classnames(styles.datepicker, {[styles.datepicker_changed]: isChangedByAgent})}
      data={dates}
      dataKey='id'
      textField='name'
      defaultValue={{
        id: moment(beginShiftTime).toDate(),
        name: moment(beginShiftTime).format("HH:mm")
      }}
      value={{
        id: moment(beginShiftTime).toDate(),
        name: moment(beginShiftTime).format("HH:mm")
      }}
      onChange={onChangeHandler}
    />
  )
};

export default ShiftTimeDropDown;
