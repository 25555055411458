config = require 'root/config'

MqttStore = require 'stores/MqttStore'

class MqttGlobalStore extends MqttStore
  initConfig: =>
    @actionSuffix = '_GLOBAL'
    @mqttUrl = config.crminfo.apiHostSocket
    @needInitialSubscribe = false

module.exports = MqttGlobalStore