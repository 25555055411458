classnames = require 'classnames'

Guid            = require '../../../lib/Guid'
styles          = require './base-form'
BaseComponent   = require '../../BaseComponent'
{ mapIndexed } = require '../../../lib/utils'
Isvg = require 'react-inlinesvg'
deleteSvg = require 'components/shared/images/delete.svg'

propTypes = require 'prop-types'

class BaseFormComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @state = {}

  @propTypes:
    object: propTypes.object

  getState: (props = @props)->
    attributes = {}
    for attribute in @attributes
      attributes[attribute] = props.object?[attribute]

    state = { attributes }
    state.errors = @personalStore.errors if @personalStore?
    state

  # это говно
  componentWillReceiveProps: (nextProps) ->
    for attribute in @attributes
      @state.attributes[attribute] = nextProps.object?[attribute]

  handleAttribute: (attr, value) =>
    @state.attributes[attr] = value
    @forceUpdate()

  handleInput: (e, attr) =>
    value = e.target.value
    attributes = @state.attributes
    attributes[attr] = value

    @setState
      attributes: attributes

  handleTextarea: (e, attr) =>
    value = e.target.value
    attributes = @state.attributes
    attributes[attr] = value

    @setState
      attributes: attributes

  componentWillReceiveProps: (props) ->
    @setState @getState props

  renderInput: (attr, name, options = {}) ->
    id = Guid()
    handler = options.handler || _(@handleInput).partial(_, attr)
    value = if options.value then options.value() else @state.attributes[attr]
    type = options.type || 'text'
    item =
      React.createElement("input", { \
        "id": ( id ),  \
        "type": ( type ),  \
        "value": ( value ),  \
        "onChange": ( handler ),  \
        "className": ( styles.input ),  \
        "key": options.key
      })

    if options.onlyInput
      item
    else
      React.createElement("div", {"className": ( styles.group ), "key": options.key},
        React.createElement("label", {"className": ( styles.label ), "htmlFor": ( id )}, ( name )),
        React.createElement("div", {"className": ( styles.controls )},
          ( item )
        ),
        ( @renderErrorsBelow(attr) )
      )

  renderTextarea: (attr, name, options = {}) ->
    id = Guid()
    handler = options.handler || _(@handleTextarea).partial(_, attr)
    value = if options.value then options.value() else @state.attributes[attr]
    type = options.type || 'text'
    item =
      React.createElement("textarea", { \
        "id": ( id ),  \
        "value": ( value ),  \
        "onChange": ( handler ),  \
        "className": ( styles.textarea ),  \
        "rows": ( options.rows || 7 ),  \
        "key": options.key
      })

    if options.onlyTextarea
      item
    else
      React.createElement("div", {"className": ( styles.group ), "key": options.key},
        React.createElement("label", {"className": ( styles.label ), "htmlFor": ( id )},
          React.createElement("span", {"className": (styles.label_textarea)}, ( name )),
          (if options.delete
            React.createElement("span", { \
              "onClick": (options.delete),  \
              "className": (styles.delete)
            },
              React.createElement(Isvg.default, {"src": (deleteSvg)})
            )
          )
        ),
        React.createElement("div", {"className": ( styles.controls )},
          ( item )
        ),
        ( @renderErrorsBelow(attr) )
      )

  # todo use this unstead `options.onlyInput`
  renderGroup: (input, id, name) ->
    React.createElement("div", {"className": ( styles.group ), "key": options.key},
      React.createElement("label", {"className": ( styles.label ), "htmlFor": ( id )}, ( name )),
      React.createElement("div", {"className": ( styles.controls )},
        ( input )
      ),
      ( @renderErrorsBelow(attr) )
    )

  renderCheckbox: (attr, name, options = {}) ->
    id = Guid()
    value = if options.value then options.value() else @state.attributes[attr]
    handler = options.handler || _(@handleAttribute).partial(attr, !value)

    React.createElement("div", {"className": ( styles.group ), "key": options.key},
      React.createElement("input", {"type": "checkbox", "checked": value, "onChange": handler, "id": id}),
      React.createElement("label", {"className": (classnames(styles.label, styles.label_checkbox)), "htmlFor": ( id )}, ( name )),
      ( @renderErrorsBelow(attr) )
    )


  renderErrorsBelow: (attr, name = null) ->
    errors = @state.errors?[attr]
    if errors?
      React.createElement("div", {"className": styles.errors},
        (
          for error, index in errors
            error = "#{name} #{error}" if name
            React.createElement("div", {"key": index, "className": styles.error}, " ", ( error ), " ")
        )
      )

  onSelectChange: (handler, e) =>
    @actionCreator.removeErrors()
    console.log("123132")
    handler(e)

  renderSelect: (attr, name, collection, options = {}) =>
    id = Guid()
    handler = options.handler || _(@handleInput).partial(_, attr)
    value = if options.value then options.value() else @state.attributes[attr]
    item =
      React.createElement("select", { \
        "id": ( id ),  \
        "value": ( value ),  \
        "onChange": ( @onSelectChange.bind @, handler ),  \
        "className": ( styles.input )
      },
        (
          if options.withBlank
            React.createElement("option", null)
        ),
        (
          mapIndexed collection, (option, index) ->
            React.createElement("option", {"value": ( option.value ), "key": ( index )}, ( option.label ))
        )
      )

    if options.onlyInput
      item
    else
      React.createElement("div", {"className": ( styles.group )},
        React.createElement("label", {"className": ( styles.label ), "htmlFor": ( id )}, ( name )),
        React.createElement("div", {"className": ( styles.controls )},
          ( item )
        ),
        ( @renderErrorsBelow(attr) )
      )

  renderErrors: ->
    React.createElement("div", {"className": ( styles.errors )},
      (
         mapIndexed @state.errors, (message, index) ->
          React.createElement("div", {"key": ( index )}, ( message ))
      )
    )

  renderHint: (text) ->
    React.createElement("div", {"className": ( styles.hint )}, " ", (text), " ")

# SaveButtonPopup partial
  showPopup: (options) =>
    @setState saveButtonPopup: options
    clearTimeout(@saveButtonPopupTimeoutId)
    @saveButtonPopupTimeoutId = setTimeout =>
      @setState saveButtonPopup: undefined
    , 2000
  showSavedPopup: => @showPopup(message: 'Saved')
  showErrorPopup: => @showPopup(message: 'Error', type: 'error')

module.exports = BaseFormComponent
