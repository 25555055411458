import React, { useEffect } from 'react';
import {
  useRosterQuery
} from '@connect/connect-xmpp';


const XmppRosterData = ({ onRosterChange }) => {
  const { rosterData = [] } = useRosterQuery();

  useEffect(() => {
    onRosterChange(rosterData)
  }, [rosterData]);

  return null;

}

export default React.memo(XmppRosterData);
