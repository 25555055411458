BugForm = require('@verdaccio/bug-report-tool').default
{ Fragment } = require 'react';
config = require 'root/config'

BaseComponent = require 'components/BaseComponent'
styles  = require './extra-info.styl'
classnames = require 'classnames'
AuthStore = require 'stores/AuthStore'
OnlineStore = require 'stores/OnlineStore'
ProfileStore = require 'stores/ProfileStore'
ShiftAgentsStore = require 'stores/ShiftAgentsStore'
AgentsAPI = require 'api/AgentsAPI'
AuthActionCreator  = require 'actions/AuthActionCreator'
ProfileActionCreator  = require 'actions/ProfileActionCreator'
ShiftsActionCreator  = require 'actions/ShiftsActionCreator'
AgentsActionCreator = require 'actions/AgentsActionCreator'
ShiftAgentsActionCreator = require 'actions/ShiftAgentsActionCreator'
InterfaceActionCreator = require 'actions/InterfaceActionCreator'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
{ getToken } = require 'lib/tokenStorage'
SwitchComponent = require 'components/shared/SwitchComponent'
ConditionModal = require 'components/shared/ConditionModal'
{ urls, oauthEnabled, isProductionMode } = require '../../../config'
{ ROLES_ROUND_CUBE, ROLES_ALL_EMAILS_ROUND_CUBE, ROLES_ROUND_CUBE_URL, ROLES_ROUND_CUBE_EMAIL, TL_ROLE } = require 'constants/Agents'

crminfo = require '@verdaccio/crminfo'
{ WFHButtonBRB, WFHButtonRC } = crminfo.WFHTool
{ Button, ModalCondition } = crminfo.controls

START_SHIFT =
  'teamlead': 'Start shift'
  'second_teamlead': 'Start second shift'

class ExtraInfoComponent extends BaseComponent
  constructor: (props) ->
    super props
    @dropdownIdentifier = 'extra-info'
    @_isunmounted = false
    @initComponent()
    @state = 
      dropdown: false
      toggleSwitch: true
      isImmediatelyShowBugReport: false
  
  dependsOnStores: [AuthStore, ProfileStore, OnlineStore, ShiftAgentsStore]


  onMenuClick: =>
    return if @state.dropdown
    @setState dropdown: true
    document.addEventListener 'click', @onBlur

  onBlur: (e) =>
    e.stopPropagation()
    document.removeEventListener 'click', @onBlur
    @setState(dropdown: false) unless @_isunmounted

  onToggleWFHNotification: (value) =>
    @setState
      toggleSwitch: value

    { id } = AuthStore.user
    setTimeout(
      =>
        AgentsActionCreator.updateAgent(id, {wfh_notification: value})
      200
    )
    document.removeEventListener 'click', @onBlur
    document.addEventListener 'click', @onBlur


  componentDidMount: ->
    super()
    { wfh_notification = true } = AuthStore.user

    @setState
      toggleSwitch: wfh_notification


    setTimeout(
      ->
        ProfileActionCreator.get()
      0
    )


  componentWillUnmount: ->
    super()
    @_isunmounted = true
    document.removeEventListener 'click', @onBlur
    profile = ProfileStore.profile
    { role_names = [], id } = profile
    isTeamLead = role_names.includes 'csr_teamlead'

    setTimeout(
      ->
        ShiftAgentsActionCreator.unSubscribeGlobal(profile?.id)
      0
    )

  showBugForm: (status) =>
    agent = OnlineStore.agents.get(AuthStore.user.id)
    if (Boolean(status) && agent) then AgentsActionCreator.closeNotification agent.id
    
    @setState isImmediatelyShowBugReport: !!status, dropdown: false

  logout: ->
      AuthActionCreator.logout()

  closeModal: ->
    InterfaceActionCreator.closeModal()

  endShift: =>
    self = @
    profile = ProfileStore.profile
    { id } = profile
    InterfaceActionCreator.openModal ConditionModal, {
      text: 'Are you sure you want to end shift?'
      buttonTitle: 'End Shift and Log Out'
      cancelButtonTitle: 'Stay in the Shift'
      withIcon: true
      onCancel: () ->
        self.closeModal()
        self.logout()
      onClickAgree: () -> 
        self.closeModal()
        AgentsAPI.endShift(
          id,
          () ->
            self.logout()
        );
    }

  getAccessRoleRoundCubeEmails: (roles) =>
    emails = []

    (roles || []).forEach (role) -> 
      if ROLES_ALL_EMAILS_ROUND_CUBE.includes role
        ROLES_ROUND_CUBE[role].emails.forEach (email) ->
          if (!emails.includes email)
            if (config.isProductionMode && email != ROLES_ROUND_CUBE_EMAIL.STAGE_TEST1 && email != ROLES_ROUND_CUBE_EMAIL.STAGE_TEST2) || !config.isProductionMode
              emails = [...emails, email]

    return emails

  
  renderAppList: (apps) ->
    hostname = window.location.hostname
    token = getToken()

    return (
      React.createElement("ul", null,
        (apps.map (app, i) ->
          login_url_token = "#{app.front_app_uri}/login/#{token.split(' ')[1]}"
          login_url = if app.name == 'a4' then login_url_token else app.front_app_uri
          {host} = new URL(app.front_app_uri)

          if host != hostname
            React.createElement("li", {"key": (i)},
              React.createElement("a", {"href": (login_url), "target": '_blank', "className": (styles.link)}, (app.name))
            )
        )
      )
    )
  
  renderEmails: (emails) ->
    token = getToken()

    return (
      React.createElement("ul", null,
        (emails.map (email, i) ->
          login_url_token = "#{ROLES_ROUND_CUBE_URL[email].url}&jwt=#{token.split(' ')[1]}"
          React.createElement("li", {"key": (i)},
            React.createElement("a", {"href": (login_url_token), "target": '_blank', "className": (styles.link)}, "Go to ", (ROLES_ROUND_CUBE_URL[email].host), " email box")
          )
        )
      )
    )

  renderLogoutButtons: (apps) ->
    profile = ProfileStore.profile || {}
    { last_shift  } = AuthStore.user
    isAgentOnShift = false 
    isAgent = crminfo.utils.isAgentOrSale(
      {
        ...profile,
        roles: profile.role_names
      }
    )

    if isAgent
      isAgentOnShift = last_shift?.unique_clients_count > 0
   
    if !isAgent || !isAgentOnShift
     return (
      React.createElement("a", {"onClick": (@logout),   \
        "className": (classnames(
        styles.link,
        "#{styles.link_center}": !apps
        ))
      }, "Logout")
     )

    return (
      React.createElement("div", {"className": (classnames(
        styles.logout_btns_container,
        styles.link,
      ))},
        React.createElement(Button, {"onClick": (@logout)}, "Log Out"),
        React.createElement(Button, {"primary": true, "onClick": (@endShift)}, "End Shift Now")
      )
    )

  render: ->
    user = AuthStore.user
    return null unless user
    userImage = user.image || require 'components/shared/images/unknown.png'

    profile = ProfileStore.profile || {}
    apps = profile && profile.available_apps
    roles = profile && profile.role_names
    emails = @getAccessRoleRoundCubeEmails(roles)
    isTL = false
    (roles || []).forEach (role) ->
      if TL_ROLE.includes role
        isTL = true


    React.createElement(Fragment, null,
      React.createElement("div", {"id": (@dropdownIdentifier), "className": (styles.container), "onClick": (@onMenuClick)},
        React.createElement("img", {"className": (styles.circle), "src": (userImage)}),
        (if @state.dropdown
          React.createElement("div", {"className": (styles.dropdown_menu)},
            React.createElement(LoadableWrapperComponent, {"loading": (ProfileStore.loading)},
              (if user.available_role
                React.createElement("div", { \
                  "className": (styles.dropdown_element),  \
                  "onClick": (ShiftsActionCreator.createTeamleadShift)
                },
                  React.createElement("div", {"className": (classnames(
                    styles.link,
                    "#{styles.link_center}": !apps
                  ))},
                    (START_SHIFT[user.available_role])
                  )
                )
              ),
              React.createElement("div", {"className": (styles.dropdown_element)},
                  (if isTL
                    React.createElement(Fragment, null,
                      React.createElement("div", {"className": (styles.divider)},
                          React.createElement(WFHButtonRC, {"agents": (ShiftAgentsStore.agentsWFH)})
                      ),
                      React.createElement("div", {"className": (classnames(styles.divider, styles.switch))},
                          React.createElement("span", {"className": (styles.switch_name)}, "Organizational notifications"),
                          React.createElement(SwitchComponent, { \
                            "value": (@state.toggleSwitch),  \
                            "onToggle": (@onToggleWFHNotification)
                          })
                      )
                    )
                  ),

                  (if !isTL
                    React.createElement("div", {"className": (styles.divider)},
                      React.createElement(WFHButtonBRB, {"agents": (ShiftAgentsStore.agentsWFH)})
                    )
                  ),
                (if apps
                  @renderAppList apps
                ),
                (if emails
                  @renderEmails emails
                ),
                React.createElement("a", { \
                  "onClick": (@showBugForm),   \
                  "className": (classnames(
                  styles.link,
                  styles.link_no_transform,
                  "#{styles.link_center}": !apps
                  ))
                }, "Report a bug"),
                (@renderLogoutButtons apps)
              )
            )
          )
        )
      ),
      (@state.isImmediatelyShowBugReport && React.createElement(BugForm, {"immediatelyShowHandler": (@showBugForm), "isImmediatelyShow": (@state.isImmediatelyShowBugReport), "isProduction": (isProductionMode)}))
    )

module.exports = ExtraInfoComponent
