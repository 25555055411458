import React, { useContext, useState } from 'react';
import classnames from 'classnames';

import RankingRulesActionCreator from 'actions/RankingRulesActionCreator';
import RankingConversationsActionCreator from 'actions/RankingConversationsActionCreator';

import RankingRulesAPI from 'api/RankingRulesAPI';

import { QAInfoContext } from 'components/rankingRulesModule/RankingModuleInfoBlock/QAInfo/QAInfoContext';

import { Modal } from 'components/rankingRulesModule/RankingModuleInfoBlock/QAInfo/QAInfoItem/components';

import { statusMapper } from 'components/rankingRulesModule/RankingModuleInfoBlock/QAInfo/QAInfoItem/constants';
import { getQueryParams } from 'lib/utils';

import styles from './index.styl';

const ReportHeader = ({ report, onToggleReport, isEditMode, onDeleteReport, innerEditMode }) => {
  const { isOpen, status, id, target_id, target_type, created_at } = report;
  const statusHeader = statusMapper[status];

  const [isLoading, toggleIsLoading] = useState(false);

  const { setModalComponent, isUserValidRole } = useContext(QAInfoContext);

  const onDelete = () => {
    setModalComponent(null);
    if (isEditMode && !created_at) {
      onDeleteReport(id);
      return;
    }

    toggleIsLoading(true);

    RankingRulesAPI.deleteConversationRankingReport(id, () => {
      toggleIsLoading(false);
      setTimeout(() => {
        onDeleteReport(id);
        RankingRulesActionCreator.removeConversationRankedMessageReport(target_id, target_type, id);
        const params = getQueryParams(window.location.search.substring(1));
        if (params.agent_id && params.status == 'unranked') {
          RankingConversationsActionCreator.getConversations(params);
        }
      }, 1);
    });
  };

  const onDeleteIconClick = () => {
    setModalComponent(<Modal onClose={() => setModalComponent(null)} onSuccess={onDelete} header="Delete report" text="Are you sure you want to delete this report?" />)
  };

  return (
    <div className={classnames(styles.report_header, {[[styles['report_header--loading']]]: isLoading })}>
      <div className={styles.report_header_left_block}>
        <i onClick={() => onToggleReport(id)} className={classnames(styles.report_header_left_block__icon, {
          [[styles['report_header_left_block__icon--open']]]: isOpen,
        })} />
        <i className={classnames(styles.report_header_left_block__status, styles[`report_header_left_block__status--${status}`])} />
        <div className={styles.report_header_left_block__status_header}>{statusHeader}</div>
      </div>
      {isUserValidRole &&
        <i className={classnames(styles.report_header__delete_icon)} onClick={onDeleteIconClick} />
      }
    </div>
  )
};

export default ReportHeader;
