FOLDERS = [
  'unassigned'
  'my_conversations'
  'all_conversations'
]

GROUP_TYPES =
  empty:
    label: 'Choose type'
    value: 'empty'
  limitless:
    label: 'Limitless'
    value: 'limitless'
  limited:
    label: 'Partly limited'
    value: 'limited'
  strict_limited:
    label: 'Strictly limited'
    value: 'strict_limited'

TEXT_FIND_1 = 'dashboard'
TEXT_FIND_2 = 'online'
TAG_NO_NEED = 'no tag needed'
TAG_DASHBOARD = 'dashboard_submit'
TAG_REFFERAL = 'Referral program'
TAG_SUBSCRIPTION_OFFER = 'subscription_offered'

CLIENT_TAG_EXCLUDE_FOR_REFERAL = [
  'do_not_offer_referral'
  'NOreferral'
  'referralabuse'
  'referral_program_block'
]

CLIENT_TAG_EXCLUDE_FOR_APP = [
  'app_do_not_offer'
  'NO ACCOUNT'
]

CHAT_TAG_EXCLUDE_FOR_REFERAL = [
  'unsatisfied'
  'random'
  'Revision issue'
  'Refund issue'
  'No writer found issue'
  'extension'
  'declined'
  'Sorry bonus added'
  '$10 compensation'
]

TAGS_FOR_DASHBOARD = [
  {
    id: TAG_NO_NEED
    name: TAG_NO_NEED
    checked: false
  },
]

module.exports = {
  FOLDERS
  GROUP_TYPES
  TEXT_FIND_1
  TEXT_FIND_2
  TAG_DASHBOARD
  TAG_NO_NEED
  TAGS_FOR_DASHBOARD
  TAG_REFFERAL
  CLIENT_TAG_EXCLUDE_FOR_REFERAL
  CHAT_TAG_EXCLUDE_FOR_REFERAL
  TAG_SUBSCRIPTION_OFFER
  CLIENT_TAG_EXCLUDE_FOR_APP
}
