React = require 'react'
Button = require 'components/shared/Button'
AgentEditPopup = require 'components/agents/AgentEditPopupComponent'
AgentsActionCreator = require 'actions/AgentsActionCreator'
InterfaceActionCreator = require 'actions/InterfaceActionCreator'
validators = require 'lib/validators'
styles = require './agent-location-modal'

class AgentLocationModalComponent extends React.Component
  onSave: (inputs) =>
    inputs['updated_at'] = new Date().getTime()
    setTimeout(
      =>
        AgentsActionCreator.updateAgent(@props.data.id, {location: inputs})
        InterfaceActionCreator.closeModal('agentLocation')
      200
    )

  render: ->
    { location } = @props.data
    fields =
      country:
        value: location?.country
        placeholder: 'Country'
        validator: validators.validateOnAny
      region:
        value: location?.region
        placeholder: 'Region'
        validator: validators.validateOnAny
      city:
        value: location?.city
        placeholder: 'City'
        validator: validators.validateOnAny
    React.createElement(AgentEditPopup, { \
      "fields": (fields),  \
      "editedElement": ({clientWidth: -145, offsetTop: 0, clientHeight: 294}),  \
      "saveData": (@onSave),  \
      "enableInfo": true,  \
      "disableArrow": true,  \
      "disableCancel": true
    })

module.exports = AgentLocationModalComponent
