moment = require 'moment'

MessageComponent = require 'components/chat/conversation_items/MessageComponent/MessageComponent'
LogMessageComponent = require 'components/chat/conversation_items/LogMessageComponent/LogMessageComponent'
ReminderComponent = require 'components/chat/conversation_items/ReminderComponent/ReminderComponent'
PromptsComponent = require 'components/chat/conversation_items/MessageComponent/PromptsComponent/PromptsComponent.cjsx'
ScheduledMessageComponent = require 'components/chat/conversation_items/ScheduledMessageComponent'

CRMPluginActionCreator = require('actions/CRMPluginActionCreator').default
ImagePreviewActionCreator = require 'actions/ImagePreviewActionCreator'
ConversationsActionCreator = require 'actions/ConversationsActionCreator'

AuthStore = require 'stores/AuthStore'
OnlineStore = require 'stores/OnlineStore'
RankingStore = require 'stores/RankingStore'
RankingRulesStore = require 'stores/RankingRulesStore'
CustomersStore = require 'stores/CustomersStore'
ConversationsMyStore = require 'stores/ConversationsMyStore'
ConversationsAllStore = require 'stores/ConversationsAllStore'
RankingMessageStore = require 'stores/RankingMessageStore'
ConversationStore = require 'components/conversations/ConversationComponent/ConversationStore'

{ ROLES } = require 'constants/Agents'
{ menus, crmPluginEnabled, isGeekly } = require 'root/config'

InterSectionObserver = require('./InterSectionObserver').default

pluginConfigs = null
if crmPluginEnabled
  pluginConfigs = require('@verdaccio/crminfo').pluginConfigs


ConversationsActionCreator = new ConversationsActionCreator()

styles = require './conversation-items-list-view.styl'

accessConfig = {}
menus.forEach (item) ->
  if item.key == 'ranking' || item.key == 'page_ranking_tools'
    accessConfig =
      toEdit: item.accessToEdit || null
      another: item.anotherAccess || null
      toPage: item.accessToPage || null

propTypes = require 'prop-types'

itemTypes =
  message: (item, rankingOptions, isSelected, onlineAgents, credentials) ->
    { reports } = @props
    { created_at, inbound, channel = {} } = item
    lastReadTime = @state?.lastReadTime || ConversationStore?.lastReadTime

    { currentConversationId } = RankingRulesStore
    isRanked = false
    if reports && reports.message && currentConversationId && currentConversationId != @props.conversation.id
      isRanked = !!reports.message[item.id]
    else
      isRanked = RankingRulesStore.messages?.message && RankingRulesStore.messages?.message[item.id]
    # ignoring 'queued' and 'duplicate' statuses
    
    messageReadStatus = {}

    if (lastReadTime && !inbound && channel.service_name == 'Xmpp')
      utcCreatedAt = moment(created_at).utc()
      messageReadStatus.readed = utcCreatedAt.isBefore(lastReadTime)

      if (!messageReadStatus.readed)
        messageReadStatus.notReaded30Min = moment().utc().isAfter(utcCreatedAt.add(30, 'minutes'))

    if !item.channel?.service_name || !(item.channel?.service_name == 'Facebook' && (item.status in [1, 10]))
      React.createElement(MessageComponent, { \
        "onlineAgents": (onlineAgents),  \
        "key": (item.id),  \
        "ref": (item.id),  \
        "message": (item),  \
        "selected": (isSelected),  \
        "conversation": (@props.conversation),  \
        "rankingOptions": (rankingOptions),  \
        "credentials": (credentials),  \
        "useRankingRules": (!!@props?.useRankingRules),  \
        "isRanked": (isRanked),  \
        "messageReadStatus": (messageReadStatus)
      })
  log_message: (item, rankingOptions, isSelected, onlineAgents, credentials, openOrder) ->
    { reports } = @props
    { currentConversationId } = RankingRulesStore
    isRanked = false
    if reports && reports.log_message && currentConversationId && currentConversationId != @props.conversation.id
      isRanked = !!reports.log_message[item.id]
    else if currentConversationId && currentConversationId == @props.conversation.id
      isRanked = RankingRulesStore.messages?.log_message && RankingRulesStore.messages?.log_message[item.id]

    if @props.useRankingRules
      item.text = item.text.replace('order_link', 'tagname')

    React.createElement(LogMessageComponent, {  \
      "logMessage": (item),  \
      "key": (item.id),  \
      "ref": (item.id),  \
      "selected": (isSelected),  \
      "rankingOptions": (rankingOptions),  \
      "useRankingRules": (!!@props?.useRankingRules),  \
      "isRanked": (isRanked),  \
      "conversation": (@props.conversation),  \
      "openOrder": (openOrder)
    })
  reminder: (item, rankingOptions, isSelected, onlineAgents, credentials, openOrder) ->
    React.createElement(ReminderComponent, { \
      "reminder": (item),  \
      "key": (item.id),  \
      "ref": (item.id),  \
      "selected": (isSelected),  \
      "rankingOptions": (rankingOptions),  \
      "openOrder": (openOrder)
    })
  prompt: (item) ->
    React.createElement(PromptsComponent, { \
      "key": (item.id),  \
      "prompts": ([item]),  \
      "conversation": (@props.conversation)
    })
  scheduled_message: (item, rankingOptions, isSelected) ->
    # ignoring 'queued' and 'duplicate' statuses
    if !item.channel?.service_name || !(item.channel?.service_name == 'Facebook' && (item.status in [1, 10]))
      React.createElement(ScheduledMessageComponent, { \
        "key": (item.id),  \
        "ref": (item.id),  \
        "message": (item),  \
        "selected": (isSelected),  \
        "rankingOptions": (rankingOptions)
      })

MAX_MESSAGES_INDEX = 299

class ConversationItemsListView extends React.Component
  dependsOnStores: [OnlineStore, RankingStore, RankingRulesStore, AuthStore, RankingMessageStore]

  constructor: (props) ->
    super(props)
    @selectedItemId = null
    @itemsCount =
      convId: props.conversation.id
      lastMessageId: null
      count: 0
    @state =
      newMessagesCount: 0
      customer: CustomersStore?.customer
      credentials: null
      lastReadTime: null

  componentDidMount: =>
    @processOnItemSelectCallback() if !@alreadyBeenScrolled && @selectedItemId?
    setTimeout(
      =>
        ConversationsActionCreator.getFileUrls @props?.conversation.id
      10
    )

  mapCredential: (credentials) =>
    newCredentials = {}
    credentials.map (c) ->
      newCredentials[c.id] = c
    newCredentials

  componentDidUpdate: (prevProps, prevState) ->
    if @itemsCount.convId != @props.conversation.id
      setTimeout(
        -> ImagePreviewActionCreator.imageCheck({})
        0
      )
    
    if @state.customer != CustomersStore?.customer
      if CustomersStore?.customer?.credentials
        credentials = @mapCredential CustomersStore?.customer?.credentials
        @setState
          customer: CustomersStore?.customer
          credentials: credentials

    hasNewMessagesForNotFullList = @props.items.length < 300 && @itemsCount.count != @props.items.length
    hasNewMessagesForFullList = @props.items.length == 300 && @props.items[@props.items.length - 1].id != @itemsCount.lastMessageId
    if @props.onUpdateConversation && (hasNewMessagesForNotFullList || hasNewMessagesForFullList)
      if !@props.alreadyScrolled()
        @props.onSwitchDisallowScroll(false)
        timeoutNumber = 500
        timeoutNumber = 800 if @props.items[0]?.channel?.service_name == 'Facebook'

        { onUpdateConversation } = @props
        setTimeout onUpdateConversation, timeoutNumber
        @itemsCount =
          count: @props.items.length
          lastMessageId: @props.items[@props.items.length - 1]?.id
          convId: @props.conversation.id

        @setState newMessagesCount: 0 if @state.newMessagesCount > 0 || @itemsCount.convId != @props.conversation.id
      else
        @props.onSwitchDisallowScroll(true)
        lastMsgIndex = null
        if hasNewMessagesForFullList
          lastMsgIndex = @props.items.findIndex((item) => item.id == @itemsCount.lastMessageId)
        newMessagesCount = 0
        if hasNewMessagesForFullList
          newMessagesCount = @props.items.length - lastMsgIndex - 1 # last index = length - 1
        else
          newMessagesCount = @props.items.length - @itemsCount.count
        @setState newMessagesCount: newMessagesCount if @state.newMessagesCount != newMessagesCount && @itemsCount.convId == @props.conversation.id
        @setState newMessagesCount: 0 if @state.newMessagesCount > 0 && @itemsCount.convId != @props.conversation.id

    if !@alreadyBeenScrolled && @selectedItemId?
      @processOnItemSelectCallback()

  openThreads: (e) =>
    e.preventDefault()

    orderId = e.target.getAttribute 'data-order'

    if !orderId then return

    idMessage = e.target.getAttribute 'data-message'
    reminderConversationId = e.target.getAttribute 'href'

    if !reminderConversationId then return
    reminderConversationId = reminderConversationId.split('/');
    reminderConversationId = reminderConversationId[reminderConversationId.length - 1];
    entityKey = "conv-#{reminderConversationId}"

    if orderId && idMessage && crmPluginEnabled
      orderPlugin = if isGeekly then pluginConfigs.OrderPlugin else pluginConfigs.OrderCompactPlugin
      config = orderPlugin(
        orderId: orderId,
        options:
          isResizable: true
          entityKey: entityKey
          openThreads: true
          idMessage: idMessage
      )
      CRMPluginActionCreator.setConfig(
        config, entityKey, "#{entityKey}-order-#{orderId}", true
      )


  openOrder: (e) =>
    # e.preventDefault()
    # debugger;

    if @props?.useRankingRules then return

    orderId = e.target.getAttribute 'data-order'

    # if !orderId then return

    idMessage = e.target.getAttribute 'data-message'

    if idMessage
      e.preventDefault()
      @openThreads(e)
      return

    dataTarget = e.target.getAttribute 'data-target'
    reminderConversationId = e.target.getAttribute 'href'
    if !reminderConversationId then return
    reminderConversationId = reminderConversationId.split('/');
    reminderConversationId = reminderConversationId[reminderConversationId.length - 1];

    entityKey = "conv-#{reminderConversationId}"

    if orderId && crmPluginEnabled
      e.preventDefault()
      orderPlugin = if isGeekly then pluginConfigs.OrderPlugin else pluginConfigs.OrderCompactPlugin
      dataTarget = if dataTarget == 'progress_tab' then 'progress' else dataTarget;
      config = orderPlugin(
        orderId: orderId,
        options:
          isResizable: true
          entityKey: entityKey
          dataTarget: dataTarget,
          openTickets: dataTarget == 'tickets'
      )
      CRMPluginActionCreator.setConfig(
        config, entityKey, "#{entityKey}-order-#{orderId}", true
      )

  processOnItemSelectCallback: ->
    el = ReactDOM.findDOMNode @refs[@selectedItemId]
    @alreadyBeenScrolled = @selectedItemId
    @selectedItemId = null
    @props.onItemSelect? el

  onScrollToNewMessages: =>
    @props.onUpdateConversation() if @props.onUpdateConversation
    @setState newMessagesCount: 0
    @itemsCount =
      count: @props.items.length
      lastMessageId: @props.items[@props.items.length - 1]?.id
      convId: @props.conversation.id

  onUpdateLastReadTime: (_lastReadTime) => 
    @setState lastReadTime: _lastReadTime
    setTimeout(
      =>
        ConversationsActionCreator.setLastReadTime _lastReadTime
      10
    )

  renderScrollToNewMessage: =>
    return null if !@state.newMessagesCount
    React.createElement("div", {"className": (styles.scroll_to_bottom)},
      React.createElement("span", null,
        ("You have #{if @state.newMessagesCount > MAX_MESSAGES_INDEX then '300+' else @state.newMessagesCount} new messages. ")
      ),
      React.createElement("a", {"onClick": (@onScrollToNewMessages)}, "View")
    )

  render: ->
    onlineAgents = OnlineStore.agents
    @selectedItemId = null
    anySelected = false

    result = React.createElement("div", null,
      (@renderScrollToNewMessage()),
      React.createElement("div", null,
        (@props.items.map (item, index) =>
          if @props.selectedItem?
            objectId = @props.selectedItem.type + @props.selectedItem.id
            isSelected = objectId == item.id || objectId == item.item_type + item.id
            anySelected = true if isSelected
            @alreadyBeenScrolled = null if isSelected && @alreadyBeenScrolled != item.id
            @selectedItemId = item.id if isSelected

          rankingOptions = {}
          if RankingMessageStore.conversation && item.id
            rankingOptions.isMessagesRanking = true
            rankingOptions.isClickableForRank = !!RankingMessageStore.messages[item.id]
            rankingOptions.tagged = !!RankingMessageStore.messagesTagged[item.id]
            if rankingOptions.tagged
              rankingOptions.tagName = RankingMessageStore.messages[item.id].tag_name
              rankingOptions.tagGroup = RankingMessageStore.messages[item.id].tag_group
            rankingOptions.skipped = !!RankingMessageStore.messagesSkipped[item.id]
            rankingOptions.isRankSelectedMessage =
              RankingMessageStore.currentMessageId?.toString() == item.id.toString()
            
          else if RankingStore.estimation
            rankingMessageType = RankingStore.estimation.messageType || 'message'
            rankingMessageId = RankingStore.estimation.messageId || ''
            rankingConversationId = (RankingStore.estimation.conversation || {}).id || ''
            isRankSelectedMessage =
              rankingMessageId.toString() == item.id?.toString() && rankingMessageType == item.item_type
            isClickableForRank = false
            if item.conversation_id &&
               rankingConversationId.toString() == item.conversation_id.toString() &&
               ROLES[AuthStore.user.role].value in accessConfig.toEdit &&
               !item.inbound &&
               RankingStore.isSelectable
              isClickableForRank = true
            rankedBy = undefined
            if isRankSelectedMessage
              rankedBy = (RankingStore.estimation.user || {}).name

            rankingOptions.isClickableForRank = isClickableForRank
            rankingOptions.rankedBy = rankedBy
            rankingOptions.isRankSelectedMessage = isRankSelectedMessage
          
          isLast = @props.items.length - 1 == index
          itemTypes[item.item_type || item.type].call @, item, rankingOptions, isSelected, onlineAgents, @state.credentials, @openOrder
        ),
        React.createElement(InterSectionObserver, {"msgs": (@props.items), "conversation": (@props.conversation), "user": (AuthStore.user), "onUpdateLastReadTime": (@onUpdateLastReadTime)})
      )
    )
    @alreadyBeenScrolled = null if @alreadyBeenScrolled && !anySelected
    result

ConversationItemsListView.propTypes =
  items: propTypes.arrayOf(propTypes.object).isRequired
  selectedItem: propTypes.shape(
    id: propTypes.number.isRequired
  )
  onItemSelect: propTypes.func
  conversationId: propTypes.number

module.exports = ConversationItemsListView
