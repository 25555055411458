AgentsAPI           = require '../api/AgentsAPI'
ActionTypes = require 'constants/ActionTypes'
Dispatcher = require '../dispatcher/Dispatcher'


ShiftAgentsActionCreator =
  dashboardSubscribe: ->
    Dispatcher.dispatch
      type: ActionTypes.MQTT_SUBSCRIBE_GLOBAL
      payload:
        topic: "smstool/shift_agents/update"
        actionType: ActionTypes.MQTT_GLOBAL_WFH_TEAMLEAD_SHIFT_USERS_UPDATING
        initial: true
  dashboardUnSubscribe: ->
    Dispatcher.dispatch
      type: ActionTypes.MQTT_UNSUBSCRIBE_GLOBAL
      payload:
        topic: "smstool/shift_agents/update"
        actionType: ActionTypes.MQTT_GLOBAL_WFH_TEAMLEAD_SHIFT_USERS_UPDATING
  subscribe: ->
    Dispatcher.dispatch
      type: ActionTypes.MQTT_SUBSCRIBE
      payload:
        topic: "smstool/shift_users"
        actionType: ActionTypes.MQTT_SHIFT_USERS_UPDATING
        initial: true

  unSubscribe: ->
    Dispatcher.dispatch
      type: ActionTypes.MQTT_UNSUBSCRIBE
      payload:
        topic: "smstool/shift_users"
        actionType: ActionTypes.MQTT_SHIFT_USERS_UPDATING

  unSubscribeGlobal: (agentId) ->
    Dispatcher.dispatch
      type: ActionTypes.MQTT_UNSUBSCRIBE_GLOBAL
      payload:
        topic: "smstool/shift_agents/#{agentId}/update"
        actionType: ActionTypes.MQTT_GLOBAL_WFH_SHIFT_USERS_UPDATING

  unSubscribeTeamLeadGlobal: (agentId) ->
    Dispatcher.dispatch
      type: ActionTypes.MQTT_UNSUBSCRIBE_GLOBAL
      payload:
        topic: "smstool/shift_agents/update"
        actionType: ActionTypes.MQTT_GLOBAL_WFH_TEAMLEAD_SHIFT_USERS_UPDATING

  updateShift: () =>
    # Dispatcher.dispatch type: ActionTypes.MQTT_GLOBAL_WFH_SHIFT_USERS_UPDATING
    AgentsAPI.updateShift(
      (response) ->
        if response.success
          Dispatcher.dispatch
            type: ActionTypes.MQTT_GLOBAL_WFH_TEAMLEAD_SHIFT_USERS_UPDATING
            payload: response.data
    )

  sendInitial: () ->
    Dispatcher.dispatch
      type: ActionTypes.MQTT_PUBLISH_GLOBAL
      payload:
        topic: "smstool/shift_agents/update"
        initial: true

  pingAgent: () ->
    Dispatcher.dispatch
      type: ActionTypes.WFH_TEAMLEAD_USERS_PING



module.exports = ShiftAgentsActionCreator
