classnames = require 'classnames'

BaseComponent = require 'components/BaseComponent'
ResponseTimeStore = require './ResponseTimeStore'
ChartComponent = require '../../ChartComponent/ChartComponent'
{ buildOptions } = require 'lib/dashboard-utils'
MultistateSwitchComponent = require 'components/shared/MultistateSwitchComponent'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
LoaderComponent = require 'components/shared/LoaderComponent/LoaderComponent'
{ TIME_RANGES_PRESENTER, TIME_RANGES_REPRESENTER } = require 'constants/Dashboard'

styles = require '../dashboard.styl'

CHANNEL_LABELS =
  'phone': 'SMS-Chats',
  'xmpp': 'Web-Chats'

class ResponseTimeByChannelComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @state = {
      rangeLoading: null
      interval: null
    }
    @initComponent()

  getPersonalStoreClass: -> ResponseTimeStore

  componentDidMount: ->
    super()
    setTimeout(
      =>
        @actionCreator.get(channel_type: true)
      10
    )

  channelName: (channelType) ->
    CHANNEL_LABELS[channelType] || channelType

  getCurrentResponseTime: (channelType) =>
    data = @personalStore.channelType[channelType]
    xAxis: type: 'category'
    yAxis: {
      plotLines:[
        value: data.good_rt
        color: 'red'
        width: 2
        label: 
          text:'Good RT'
        zIndex: 4
      ]
      min: 0
      minRange: data.good_rt
    }
    series:[
      name: 'Agent:'
      colorByPoint: true
      data: data.current_rt
    ]
    tooltip:
      headerFormat: '<span style="font-size:11px">{point.key}</span><br>',
      pointFormat: '<span style="color:{point.color}"><b>{point.y:.2f}</b></span> minutes<br/>'
    chart: type: 'column'
    legend: enabled: false
    plotOptions:
      column:
        pointPadding: 0.2
        borderWidth: 0
    title: text: "Current #{@channelName(channelType)} Response time"
    yTitle: 'Minutes'

  getAnswerTimeInCurrentShiftByAgents: (channelType) =>
    data = @personalStore.channelType[channelType]
    xAxis: type: 'category'
    yAxis: {
      plotLines:[
        value: data.good_rt
        color: 'red'
        width: 2
        label: 
          text:'Good RT'
        zIndex: 4
      ]
      min: 0
      minRange: data.good_rt
    }
    series:[
      name: 'Agent:'
      colorByPoint: true
      data: data.shift_rt
      
    ]
    tooltip:
      headerFormat: '<span style="font-size:11px">{point.key}</span><br>',
      pointFormat: '<span style="color:{point.color}"><b>{point.y:.2f}</b></span> minutes<br/>'
    legend: enabled: false
    chart: type: 'column'
    plotOptions:
      column:
        pointPadding: 0.2
        borderWidth: 0
    title: text: "Shift #{@channelName(channelType)} Response time"
    yTitle: 'Minutes'

  onRangeToggle: (range) =>
    return if range == @state.interval
    @setState { rangeLoading: true, interval: range }
    @actionCreator.get(
      channel_type: true
      interval: TIME_RANGES_REPRESENTER[range]
      @onRequestEndCallback
      @onRequestEndCallback
    )

  onRequestEndCallback: => @setState rangeLoading: false

  onRefreshClick: =>
    @setState rangeLoading: null
    @actionCreator.get(interval: TIME_RANGES_REPRESENTER[@state.interval], channel_type: true)

  render: ->
    React.createElement("div", { \
      "className": (classnames styles.grid,
        "#{styles.grid__full_height}": @personalStore.loading
    )},
      React.createElement(LoadableWrapperComponent, {"loading": (@state.rangeLoading == null && @personalStore.loading)},
        (unless @state.rangeLoading == null && @personalStore.loading
          React.createElement("div", null,
            React.createElement("div", {"className": (styles.switch)},
              React.createElement(MultistateSwitchComponent, { \
                "defaultValue": (TIME_RANGES_PRESENTER[@personalStore.interval]),  \
                "values": (Object.keys TIME_RANGES_REPRESENTER),  \
                "onToggle": (@onRangeToggle),  \
                "fontStyle": (styles.switch_label)
              })
            ),
            (Object.keys(@personalStore.channelType).map (channelType) =>
              React.createElement("div", {"key": (channelType)},
                React.createElement("div", {"className": (styles.left_cell_of_grid)},
                  React.createElement("div", {"className": (styles.chart_container)},
                    React.createElement(ChartComponent, { \
                      "container": ("CurrentResponseTime#{channelType}"),  \
                      "options": (buildOptions @getCurrentResponseTime(channelType))
                    })
                  ),
                  (React.createElement(LoaderComponent, null) if @state.rangeLoading)
                ),
                React.createElement("div", {"className": (styles.right_cell_of_grid)},
                  React.createElement("div", {"className": (styles.chart_container)},
                    React.createElement(ChartComponent, { \
                      "container": ("AnswerTimeInCurrentShiftByAgents#{channelType}"),  \
                      "options": (buildOptions @getAnswerTimeInCurrentShiftByAgents(channelType))
                    })
                  )
                )
              )
            ),
            React.createElement("button", {  \
              "className": (styles.refresh),   \
              "onClick": (@onRefreshClick)
            }, """
              Refresh
""")
          )
        )
      )
    )
module.exports = ResponseTimeByChannelComponent
