import React, { useRef, useEffect } from 'react';
import moment from 'moment';

import {
  useChat,
  useLastUsersReadTimeQuery,
} from '@connect/connect-xmpp';

import styles from './conversation-items-list-view.styl';

const usePrevious = (value) => {
  const containerRef = useRef();
  useEffect(() => {
    containerRef.current = value;
  });
  return containerRef.current;
};

const InterSectionObserver = ({ conversation, user, msgs, onUpdateLastReadTime }) => {
  const { info, active_channel: activeChannel } = conversation;
  const { jabber_jid: jabberJid } = user;

  if (!info || !jabberJid || !activeChannel) return null;

  const { channel_type: channelType } = activeChannel;
  let node = (info[jabberJid] || {}).jabber_node;
  let to = (info[jabberJid] || {}).jabber_pubsub_host;
  const aiNode = (info[jabberJid] || {}).jabber_ai_node;
  const aiTo = (info[jabberJid] || {}).jabber_ai_pubsub_host;
  if (aiNode && aiTo && channelType == 'ai_xmpp') {
    node = aiNode
    to = aiTo
  }

  if (!node || !to) return null;

  const clientChatConfig = {
    node,
    to,
  };

  const { updateReadTimeSmsTool, isChatReady } = useChat({ variables: clientChatConfig, forceGetLastPage: true });
  const { lastUsersRead = {} } = useLastUsersReadTimeQuery(clientChatConfig);

  const elementRef = useRef();
  const containerRef = useRef({
    isChatReady,
    updateReadTimeSmsTool,
    observer: null,
  });
  const prevMsg = usePrevious(msgs);

  const observerCallback = ([entry]) => {
    if (!containerRef.current.isChatReady) return;

    if (!entry.isIntersecting) return

    containerRef.current.updateReadTimeSmsTool();
  };


  useEffect(() => {
    containerRef.current.observer = new IntersectionObserver(observerCallback, {
      threshold: 1.0,
    });
    containerRef.current.observer.observe(elementRef.current);

    return () => {
      containerRef.current.observer.disconnect();
    }
  }, []);

  useEffect(() => {
    containerRef.current.isChatReady = isChatReady;
    containerRef.current.updateReadTimeSmsTool = updateReadTimeSmsTool;
  }, [isChatReady, updateReadTimeSmsTool]);

  useEffect(() => {
    if (!prevMsg || !msgs) return;

    if (prevMsg.length !== msgs.length) {
      containerRef.current.observer.unobserve(elementRef.current);
      containerRef.current.observer.observe(elementRef.current);
    }
  }, [prevMsg, msgs]);

  useEffect(() => {
    if (!lastUsersRead) return;
    
    const lastUsersReadValues = Object.values(lastUsersRead) || [];

    if (lastUsersReadValues.length === 0) return;

    const minReadTime = Math.max(...lastUsersReadValues);
    onUpdateLastReadTime(minReadTime)
  }, [lastUsersRead])


  return <div className={styles.observer} ref={elementRef} />
};

export default InterSectionObserver;
