classnames = require 'classnames'
BaseComponent = require 'components/BaseComponent'
NotificationItem = require './NotificationItem'
AgentsActionCreator = require 'actions/AgentsActionCreator'
OnlineStore = require 'stores/OnlineStore'
styles = require './index.styl'

NOTIFICATION_HEIGHT = 130;
MARGIN_BETWEEN = 5

class TeamleadNotifications extends BaseComponent
  constructor: (props) ->
    super(props)
    @state =
      showCount: 1
      isOpen: false
    @initComponent()

  dependsOnStores: [OnlineStore]
  
  componentWillReceiveProps: (nextProps) =>
    windowHeight = window.innerHeight
    @setState
      showCount: Math.floor(windowHeight / (NOTIFICATION_HEIGHT + MARGIN_BETWEEN))

  toggleOpen: () =>
    @setState
      isOpen: !@state.isOpen

  onCloseNotification: (id) =>
    # AgentsActionCreator.closeNotification id
    # TODO: mb return this logic after
    # TODO: we move this logic to agent control to IdlePopup

  render: =>
    notifications = OnlineStore.dormant_list.slice(0, @state.showCount)

    if notifications.length > 0
      (
        React.createElement("div", {"className": (styles.container)},
          (if !@state.isOpen
            React.createElement("div", {"className": (styles.btn_less), "onClick": (() => @toggleOpen())}, """
              Show less """, React.createElement("i", {"className": (styles.icon_less)})
            )
          ),

          (if @state.isOpen
              React.createElement("div", {"className": (classnames styles.btn_less, styles.btn_less__active), "onClick": (() => @toggleOpen())}, """
                IDLE notifications """, React.createElement("i", {"className": (styles.icon_count)}, (notifications.length))
              )
          ),
          (if !@state.isOpen
            notifications.map (notification, i) =>
              React.createElement(NotificationItem, { \
                "key": (notification.id),  \
                "notification": (notification),  \
                "onClose": (@onCloseNotification)
              })
          )
        )
      )
    else
      null

module.exports = TeamleadNotifications
