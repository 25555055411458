classnames = require 'classnames'

BaseComponent = require 'components/BaseComponent'
UniqueClientsByAgentsStore = require './UniqueClientsByAgentsStore'
{ buildOptions } = require 'lib/dashboard-utils'
styles = require '../dashboard.styl'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
ChartComponent = require '../../ChartComponent/ChartComponent'
SHIFT_HOURS = 8.0


class UniqueClientsByAgentsComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  getPersonalStoreClass: -> UniqueClientsByAgentsStore

  componentDidMount: ->
    super()
    setTimeout(
      @actionCreator.get
      10
    )

  getUCPOPSOptions: =>
    xAxis: type: 'category'
    series:[
      name: 'Agent:'
      colorByPoint: true
      data: @personalStore.ucpops
    ]
    chart: type: 'column'
    legend: enabled: false
    tooltip:
      headerFormat: '<span style="font-size:11px">{point.key}</span><br>',
      pointFormat: '<span>Clients: <b>{point.y}</b></span><br/>'
    plotOptions:
      column:
        pointPadding: 0.2
        borderWidth: 0
    title: text: 'Unique Clients per Operator per Shift'
    yTitle: 'Count'

  render: ->
    React.createElement("div", {  \
      "className": (classnames styles.grid,
        "#{styles.grid__full_height}": @personalStore.loading
    )},
      React.createElement(LoadableWrapperComponent, {"loading": (@personalStore.loading)},
        (unless @personalStore.loading
          React.createElement("div", null,
            React.createElement("div", {"className": (styles.full_row_of_grid)},
              React.createElement("div", {"className": (styles.chart_container)},
                React.createElement(ChartComponent, { \
                  "container": 'UCPOPS',  \
                  "options": (buildOptions @getUCPOPSOptions())
                })
              )
            ),
            React.createElement("div", {"className": (styles.full_row_of_grid)},
              React.createElement("p", null, "Average # of unique clients per operator per shift"),
              React.createElement("div", {"className": (classnames(styles.totals, styles.totals__uc))},
                React.createElement("div", {"className": (styles.totals__row)},
                  React.createElement("div", {"className": (styles.totals__col)}, "Sales UCPH"),
                  React.createElement("div", {"className": (classnames(styles.totals__col, styles.totals__col_uc))}, "Sales UCPOPS"),
                  React.createElement("div", {"className": (classnames(styles.totals__col, styles.totals__col_uc))}, "CSR UCPH"),
                  React.createElement("div", {"className": (classnames(styles.totals__col, styles.totals__col_uc))}, "CSR UCPOPS")
                ),
                React.createElement("div", {"className": (styles.totals__row)},
                  React.createElement("div", {"className": (styles.totals__col)}, (@personalStore.sales_uc)),
                  React.createElement("div", {"className": (classnames(styles.totals__col, styles.totals__col_uc))}, (@personalStore.sales_uc * SHIFT_HOURS)),
                  React.createElement("div", {"className": (classnames(styles.totals__col, styles.totals__col_uc))}, (@personalStore.csr_uc)),
                  React.createElement("div", {"className": (classnames(styles.totals__col, styles.totals__col_uc))}, (@personalStore.csr_uc * SHIFT_HOURS))
                )
              )
            ),

            React.createElement("button", {  \
              "className": (styles.refresh),   \
              "onClick": (@actionCreator.get)
            }, """
              Refresh
""")
          )
        )
      )
    )
module.exports = UniqueClientsByAgentsComponent
