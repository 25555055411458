_ = require 'underscore'
uuid = require 'uuid'
classnames = require 'classnames'

config = require 'root/config'
styles = require 'components/shared/BaseFormComponent/base-form'
myStyles = require './channel-form'
AuthStore = require 'stores/AuthStore'
BaseFormComponent = require 'components/shared/BaseFormComponent/BaseFormComponent'
SaveButtonComponent = require 'components/shared/SaveButtonComponent/SaveButtonComponent'
ChannelFormStore = require './ChannelFormStore'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'

class ChannelFormComponent extends BaseFormComponent
  constructor: (props) ->
    super(props)
    @authFields = {
      Twilio: [
        ["account_sid", 'Account sid']
        ["auth_token", 'Auth Token']
        ["credential_value", 'Credential value']
        ["number_id", 'Number ID']
      ]
      Facebook: [
        ["page_access_token", "Page access token"]
        ["payload_access_token", "Payload access token"]
        ["app_id", "App ID"]
        ["app_token", "App Token"]
        ["welcome_text", "Welcome Text"]
        ["verify_token", "Verify token (you can use a generated token or change it to your own)", => @state.attributes.auth_values.verify_token]
      ]
      Custom: []
      Customerio: []
      Mynerdify: [
        ['api_secret', 'API Secret']
      ]
      Bandwidth: [
        ["account_id", "AccountID"]
        ["application_id", "ApplicationID"]
        ["api_token", "Token"]
        ["api_secret", "Secret"]
        ["credential_value", 'Credential value']
      ]
      Xmpp: [
        ["xmpp_url", "XMPP URL"]
        ["credential_value", "Credential value"]
      ]
      AiXmpp: [
        ["xmpp_url", "AI XMPP URL"]
        ["credential_value", "Credential value"]
      ]
    }
    @initComponent()

  attributes: ['id', 'credential_value', 'name', 'service_name', 'country_type', 'auth_values', 'company_guid']

  getPersonalStoreClass: -> ChannelFormStore

  getState: (props = @props, state) ->
    state = super(props, state)
    _(state).extend
      user: AuthStore.user
      attributes: if props.new then @defaults() else _.extend({}, @defaults(), @personalStore.channel)
      loading: @personalStore.loading

  getDataKey: =>
    @props.match.params.id

  requestData: =>
    if @props.match.params.id
      _.defer @actionCreator.get, @props.match.params.id
    else
      _.defer @actionCreator.new

  options: [
    { value: 'Test', label: 'Test' },
    { value: 'Twilio', label: 'Twilio' },
    { value: 'Facebook', label: 'Facebook' },
    { value: 'Custom', label: 'Custom' },
    { value: 'Customerio', label: 'Customerio' },
    { value: 'Bandwidth', label: 'Bandwidth' },
    { value: 'Xmpp', label: 'Xmpp' },
    { value: 'AiXmpp', label: 'AiXmpp' },
    { value: 'Mynerdify', label: 'MyNerdify' }
  ]

  defaults: ->
    service_name: 'Twilio'
    country_type: 'TYPE1'
    auth_values: { account_sid: "", auth_token: "", credential_value: "", number_id: "" }
    name: ''
    disabled: false
    is_hidded: false

  handlerAuthValues: (e, attr) =>
    attributes = @state.attributes
    attributes.auth_values = attributes.auth_values || {}
    # console.log e.target.value
    if attr == 'name' || attr == 'highlight_color' || attr == 'country_type'
      attributes[attr] = e.target.value
    else
      attributes.auth_values[attr] = e.target.value
    @setState attributes: attributes

  selectHandler: (e) =>
    newState = @state

    newState.attributes.service_name = e.target.value
    if e.target.value == 'Facebook'
      newState.attributes.auth_values = newState.attributes.auth_values || {}
      newState.attributes.auth_values.verify_token = uuid.v1()

    @setState newState

  getterAuthValues: (attr) =>
    if attr == 'name' || attr == 'highlight_color' || attr == 'country_type'
      @state.attributes[attr]
    else
      @state.attributes.auth_values?[attr]

  submitForm: (e) =>
    if @state.attributes.id
      @actionCreator.update @state.attributes.id, @state.attributes, @showSavedPopup, @showErrorPopup
    else
      @actionCreator.create @state.attributes, @showSavedPopup, @showErrorPopup
    e.preventDefault()

  render: ->
    React.createElement("div", {"className": ( classnames styles.scroll_container, myStyles.container )},
      React.createElement("div", {"className": ( styles.container )},
        React.createElement(LoadableWrapperComponent, {"loading": ( @state.loading.get || @state.loading.createOrUpdate )},
          React.createElement("form", {"onSubmit": ( @submitForm )},
            ( @renderErrors() ),
            React.createElement("h3", {"className": ( styles.first_title )}, " Basic information "),
            ( @renderSelect('service_name', 'Service', @options, { handler: @selectHandler }) ),
            ( @renderInput('name', 'Name',
                handler: _(@handlerAuthValues).partial(_, 'name'),
                value: if !@props.new && @getterAuthValues('name') then _(@getterAuthValues).partial('name') else getValue
                key: 'name'
            ) ),
            ( @renderInput('country_type', 'CountryType',
                handler: _(@handlerAuthValues).partial(_, 'country_type'),
                value: if !@props.new && @getterAuthValues('country_type') then _(@getterAuthValues).partial('country_type') else getValue
                key: 'country_type'
            ) ),
            ( @renderInput('highlight_color', 'Highlight color',
                handler: _(@handlerAuthValues).partial(_, 'highlight_color'),
                value: if !@props.new && @getterAuthValues('highlight_color') then _(@getterAuthValues).partial('highlight_color') else getValue
                key: 'highlight_color'
            ) ),
            (
              authFields = @authFields[@state.attributes?.service_name]
              if authFields?
                for fieldsOptions in authFields
                  [ attr, name, getValue ] = fieldsOptions
                  @renderInput attr, name,
                    handler: _(@handlerAuthValues).partial(_, attr),
                    value: if !@props.new && @getterAuthValues(attr) then _(@getterAuthValues).partial(attr) else getValue
                    key: attr
            ),
            ( @renderCheckbox('disabled', 'Disabled') ),
            ( @renderCheckbox('is_hidded', 'Hidden') ),
            React.createElement("hr", {"className": ( styles.hr )}),
            (
              if @state.attributes.id
                switch @state.attributes?.service_name
                  when 'Twilio'
                    React.createElement("div", {"className": ( styles.hints )},
                      ( @renderHint('Please set up Twilio webhook url to:') ),
                      ( @renderHint("#{config.protocol}://#{config.domain}/api/webhooks/twilio/#{@state.attributes.company_guid}/receive") )
                    )
                  when 'Test'
                    React.createElement("div", {"className": ( styles.hints )},
                      ( @renderHint('Test guid:') ),
                      ( @renderHint(@state.attributes.company_guid) )
                    )
                  when 'Facebook'
                    React.createElement("div", {"className": ( styles.hints )},
                      ( @renderHint('Please set up Facebook webhook url to:') ),
                      ( @renderHint("https://#{config.domain}/api/webhooks/facebook/#{@state.attributes.company_guid}/receive") )
                    )
                  when 'Custom'
                    React.createElement("div", {"className": ( styles.hints )},
                      ( @renderHint('Our webhook url to use this for custom service:') ),
                      ( @renderHint("#{config.protocol}://#{config.domain}/api/webhooks/custom/#{@state.attributes.company_guid}/receive") )
                    )
                  when 'Customerio'
                    React.createElement("div", {"className": ( styles.hints )},
                      ( @renderHint('Our webhook url to use this for custom service:') ),
                      ( @renderHint("#{config.protocol}://#{config.domain}/api/webhooks/customerio/#{@state.attributes.company_guid}/receive") )
                    )
                  when 'Bandwidth'
                    React.createElement("div", {"className": ( styles.hints )},
                      ( @renderHint('Our webhook url to use this for bandwidth service:') ),
                      ( @renderHint("#{config.protocol}://#{config.domain}/api/webhooks/bandwidth/#{@state.attributes.company_guid}/receive") )
                    )
                  when 'Xmpp'
                    React.createElement("div", {"className": ( styles.hints )},
                      ( @renderHint('Our webhook url to use this for xmpp service:') ),
                      ( @renderHint("#{config.protocol}://#{config.domain}/api/webhooks/xmpp/#{@state.attributes.company_guid}/receive") )
                    )
                  when 'AiXmpp'
                    React.createElement("div", {"className": ( styles.hints )},
                      ( @renderHint('Our webhook url to use this for ai_xmpp service:') ),
                      ( @renderHint("#{config.protocol}://#{config.domain}/api/webhooks/ai_xmpp/#{@state.attributes.company_guid}/receive") )
                    )
                  when 'Mynerdify'
                    React.createElement("div", {"className": ( styles.hints )},
                      ( @renderHint('Our webhook url to use this for mynerdify service:') ),
                      ( @renderHint("#{config.protocol}://#{config.domain}/api/webhooks/mynerdify/#{@state.attributes.company_guid}/receive") )
                    )
            ),
            React.createElement("div", {"className": ( styles.form_group )},
              React.createElement(SaveButtonComponent, { \
                "type": ( if @state.errors then 'tryAgain' else 'normal' ),  \
                "popup": @state.saveButtonPopup,  \
                "process": @state.loading.createOrUpdate
              })
            )
          )
        )
      )
    )

module.exports = ChannelFormComponent
