R = require 'ramda'
_str = require 'underscore.string'
moment = require 'moment'
CONVERSATION_STATUS = require '../constants/ConversationStatuses.coffee'
{
  ROLE_SORTING_ORDER,
  STATUS_SORTING_ORDER,
  ROLES,
  ROLE_STATS_SORTING_ORDER,
  SHIFT_STATUS_SORTING_ORDER,
  IDLE_SORTING_ORDER
} = require '../constants/Agents'
config = require '../config'
HTML5Backend = require 'react-dnd-html5-backend'
ReactDnD = require 'react-dnd'

# underscore-style map with index
# eg. mapIndexed [1,2,3], (x, index) -> x * index
# @see http://ramdajs.com/0.19.1/docs/#addIndex
mapIndexed = R.ifElse(R.is(Array),
  R.converge(R.addIndex(R.map), [R.nthArg(1), R.nthArg(0)]),
  R.always([])
)

# normalize array to hash with key obtained from applying fn to array item
normalizeArrayBy = (fn) -> R.compose(R.fromPairs,
  R.map(R.converge(R.pair, [fn, R.identity])))

# merge two arrays using fn to identify uniq items
mergeArraysBy = (fn) -> R.compose(
  R.values,
  R.converge(R.merge, [
    R.compose(normalizeArrayBy(fn), R.nthArg(0)),
    R.compose(normalizeArrayBy(fn), R.nthArg(1))
  ])
)

# eg. [{ id: 1, a: 1 }, { id: 1, a: 2}] -> [{ id: 1, a: 2 }]
mergeArraysById = mergeArraysBy(R.prop('id'))

# eg.
fuzzyScopeMatch = (scope, object) ->
  for scopeKey, scopeValue of scope
    return false unless R.has(scopeKey, object)

    objectValue = object[scopeKey]

    if R.type(scopeValue) == 'Array'
      return false unless R.contains(objectValue, scopeValue)
    else if R.type(objectValue) == 'Array'
      return false unless R.contains(scopeValue, objectValue)
    else
      return false unless R.equals(objectValue, scopeValue)
  true

# add item to list if list not contains item and remove item from list otherwise
# eg. toggleItemInList(1, [1, 2, 3]) -> [2, 3]
#     toggleItemInList(0, [1, 2, 3]) -> [0, 1, 2, 3]
#     toggleItemInList({id: 1}, [{id: 1}, {id: 2}, {id: 3}]) -> [{id: 2}, {id: 3}]
toggleItemInList = R.ifElse(
  R.contains,
  R.converge(R.without, [R.compose(R.of, R.nthArg(0)), R.nthArg(1)]),
  R.converge(R.union,   [R.compose(R.of, R.nthArg(0)), R.nthArg(1)])
)

# eg. { clientId: 1, serverName: 'main' } -> { client_id: 1, server_name: 'main' }
underscoreParams = R.pipe R.toPairs, R.map(R.adjust _str.underscored, 0), R.fromPairs

# eg. { client_id: 1, server_name: 'main' } -> { clientId: 1, serverName: 'main' }

camelcaseParams = R.pipe R.toPairs, R.map(R.adjust _str.camelize, 0), R.fromPairs

# eg. { clientId: 1, serverName: 'main' } -> "client_id=1,server_name=main"
paramsToChannel = R.pipe underscoreParams, R.toPairs, R.sortBy(R.prop(0)), R.map(R.join('=')), R.join(',')

CONVERSATION_SORT_ORDER =
    new_message: 1
    opened: 2
    dormant: 3
    closed: 4

conversationsComparator = R.comparator (a, b) =>
  a_last_message_created_at = a.last_message?.created_at
  b_last_message_created_at = b.last_message?.created_at

  # 1. unassigned first
  cond1 = (a, b) ->
    (a.agents.length == 0) && (b.agents.length > 0)

  # 2. if both is unassigned with the same status, most awaiting first
  cond2 = (a, b) ->
    (a.agents.length == 0) && (b.agents.length == 0) && (a.status == b.status) &&
    a.awaiting_from < b.awaiting_from

  # 3. if both is unassigned with the same status and awaiting time, keep order by id
  cond3 = (a, b) ->
    (a.agents.length == 0) && (b.agents.length == 0) && (a.status == b.status) &&
    (a.awaiting_from == b.awaiting_from) && (a.id < b.id)

  # 4. if both is unassigned, keep order by status
  cond4 = (a, b) ->
    (a.agents.length == 0) && (b.agents.length == 0) &&
    (CONVERSATION_SORT_ORDER[a.status] < CONVERSATION_SORT_ORDER[b.status])

  # 5. if both is unassigned, with the same status, keep order by id
  cond5 = (a, b) ->
    (a.agents.length == 0) && (b.agents.length == 0) &&
    (CONVERSATION_SORT_ORDER[a.status] == CONVERSATION_SORT_ORDER[b.status]) && (a.id < b.id)

  # 6. if both is assigned, keep order by status
  cond6 = (a, b) ->
    (a.agents.length > 0) && (b.agents.length > 0) &&
    (CONVERSATION_SORT_ORDER[a.status] < CONVERSATION_SORT_ORDER[b.status])

  # 7. if both is assigned new messages, most waiting first
  cond7 = (a, b) ->
    (a.agents.length > 0) && (b.agents.length > 0) &&
    (a.status == b.status == 'new_message') &&
    a.awaiting_from < b.awaiting_from

  # 8. if both is assigned new messages, with the same last message time, keep order by id
  cond8 = (a, b) ->
    (a.agents.length > 0) && (b.agents.length > 0) &&
    (a.status == b.status == 'new_message') &&
    (a.awaiting_from == b.awaiting_from) && (a.id < b.id)

  # 9. if both is assigned opened messages, older conversation first
  cond9 = (a, b) ->
    (a.agents.length > 0) && (b.agents.length > 0) &&
    (a.status == b.status == 'opened') &&
    a_last_message_created_at > b_last_message_created_at

  # 10. if both is assigned opened messages, with the same last message time, keep order by id
  cond10 = (a, b) ->
    (a.agents.length > 0) && (b.agents.length > 0) &&
    (a.status == b.status == 'opened') &&
    (a_last_message_created_at == b_last_message_created_at) && (a.id < b.id)

  # 11. if both is assigned dormant messages, older conversation first
  cond11 = (a, b) ->
    (a.agents.length > 0) && (b.agents.length > 0) &&
    (a.status == b.status == 'dormant') &&
    a_last_message_created_at > b_last_message_created_at

  # 12. if both is assigned dormant messages, with the same last message time, keep order by id
  cond12 = (a, b) ->
    (a.agents.length > 0) && (b.agents.length > 0) &&
    (a.status == b.status == 'dormant') &&
    (a_last_message_created_at == b_last_message_created_at) && (a.id < b.id)

  # 13. if both is assigned closed messages, older conversation first
  cond13 = (a, b) ->
    (a.agents.length > 0) && (b.agents.length > 0) &&
    (a.status == b.status == 'closed') &&
    a.updated_at > b.updated_at

  # 14. if both is assigned closed messages, with the same update time, keep order by id
  cond14 = (a, b) ->
    (a.agents.length > 0) && (b.agents.length > 0) &&
    (a.status == b.status == 'closed') &&
    (a.updated_at == b.updated_at) && (a.id < b.id)

  cond1(a,b) || cond2(a,b) || cond3(a,b) || cond4(a,b) || # cond5(a,b) ||
  cond6(a,b) || cond7(a,b) || cond8(a,b) || cond9(a,b) || cond10(a,b) ||
  cond11(a,b) || cond12(a,b) || cond13(a,b) || cond14(a,b)

conversationSortingRule = (a, b) ->
  if a.agents.length == 0 && b.agents.length == 0
    if a.weight > b.weight
      return -1
    if a.weight < b.weight
      return 1
    conversationsComparator(a, b) ? -1 : 1
  else
    conversationsComparator(a, b) ? -1 : 1

sortConversations = (conversations) ->
  conversations.sort conversationSortingRule

sortConversationsByUpdatedAt = R.sort R.comparator((a, b) ->
  Date.parse(a.updated_at) > Date.parse(b.updated_at)
)

agentStatus = (agent, onlineAgents) ->
  return 'offline' if !agent

  onlineAgent = onlineAgents.get(agent.id)
  return 'offline' unless onlineAgent

  d = (Date.now() - Date.parse(onlineAgent.last_seen)) / (60 * 1000)
  if d <= 1
    'online'
  else if d > 1 && d <= 3
    'dormant'
  else if d > 3
    'offline'

sortTagsByFrequency = R.compose(
  R.reverse,
  R.sortBy(R.prop('usage_frequency'))
)

conversationIsClosed = (c) -> c.status == CONVERSATION_STATUS.CLOSED
conversationIsNotClosed = (c) -> c.status != CONVERSATION_STATUS.CLOSED

capitalizeFirstLetter = (word) ->
  first = word[0].toUpperCase()
  "#{first}#{word.substr(1).toLowerCase()}"

formatFromSecondsToTime = (seconds) ->
  "#{Number.parseInt  seconds/60}:#{Number.parseFloat(seconds % 60 / 100).toFixed(2).split('.')[1]}"

formatNumberToMoney = (number) ->
  "$#{Number.parseFloat(number).toFixed 2}"

formatNumberToPercent = (number) ->
  "#{number}%"

getQueryParams = (string) ->
  vars = string.split '&'
  params = {}
  vars.forEach (param) ->
    pair = param.split '='
    if pair.length == 2
      key = decodeURI pair[0]
      value = decodeURI pair[1]
      if params[key] && Array.isArray(params[key])
        params[key].push value
      else if params[key]
        params[key] = [params[key]]
        params[key].push value
      else
        params[key] = value

  params

buildQueryParamsString = (params, newMode=false) ->
  string = ''
  Object.keys params
    .forEach (key) ->
      if Array.isArray params[key]
        params[key].forEach (value) ->
          if newMode
            string += "#{key}[]=#{value}&"
          else
            string += "#{key}=#{value}&"
      else
        string += "#{key}=#{params[key]}&"
  string.slice 0, string.length - 1

qaDashboardSortingRule = (orderField, order, a, b) ->
  lowerA = a[orderField].toString().toLowerCase()
  lowerB = b[orderField].toString().toLowerCase()
  if order == 'asc'
    if lowerA > lowerB then 1 else -1
  else
    if lowerB > lowerA then 1 else -1

shiftAgentsSortingRule = (a, b) ->
  paramsForA = _buildShiftAgentsSortingParams a
  paramsForB = _buildShiftAgentsSortingParams b
  for i in [0...paramsForA.length]
    if paramsForA[i] > paramsForB[i]
      return 1
    if paramsForA[i] < paramsForB[i]
      return -1
  0

assignedOperatorsSortingRule = (a, b) ->
  paramsForA = _buildAssignedSortingParams a
  paramsForB = _buildAssignedSortingParams b
  for i in [0...paramsForA.length]
    if paramsForA[i] > paramsForB[i]
      return 1
    if paramsForA[i] < paramsForB[i]
      return -1
  0

ROLES_COUNT = Object.keys(ROLES).length
STATUS_COUNT = Object.keys(SHIFT_STATUS_SORTING_ORDER).length

_buildShiftAgentsSortingParams = (agent) ->
  [
    SHIFT_STATUS_SORTING_ORDER[agent.status] || STATUS_COUNT
    IDLE_SORTING_ORDER[agent.is_dormant] || 0
    ROLE_SORTING_ORDER[agent.role] || ROLES_COUNT
  ]

_buildAssignedSortingParams = (agent) ->
  [
    ROLE_SORTING_ORDER[agent.role] || ROLES_COUNT
    agent.active_conversations_count
    agent.ucpops || 0
    ROLE_STATS_SORTING_ORDER[agent.role] || ROLES_COUNT
    "#{agent.first_name.toLowerCase()}#{agent.last_name.toLowerCase()}"
  ]

calculateTimerValue = (completeTime) ->
  return null unless completeTime

  startTime = new moment()
  endTime = new moment(completeTime)
  diff = endTime.diff(startTime)
  if diff > 0
    moment.utc(diff).format('D[d] H[h]')
  else
    'passed'

arrayDifference = (array) ->
  rest = Array.prototype.concat.apply(Array.prototype, Array.prototype.slice.call(arguments, 1));

  containsEquals = (obj, target) ->
    return false if obj == null
    return _.any(obj, (value) -> _.isEqual(value, target))

  return _.filter(array, (value) -> !containsEquals(rest, value))

buildCRMInfoRequestData = (customer, showDrafts) ->
  data = customer_id: customer.id
  if showDrafts?
    data.show_drafts = showDrafts
  customer.credentials.forEach (credential) ->
    data["#{credential.credential_type}[]"] = [] unless data["#{credential.credential_type}[]"]
    value = if credential.channel_id
      "#{credential.channel_id},#{credential.value}"
    else
      credential.value
    data["#{credential.credential_type}[]"].push value

  data

oauthRedirect = (params) ->
  { authApiHost } = config
  path = "?redirect_url=#{Buffer.from(window.location.href).toString('base64')}"
  if params && params.logout
    path =  '/logout'
  localStorage.clear()
  window.location = "#{authApiHost}#{path}"

REACT_DND_CONTEXT = null
reactDndContext = (component) ->
  if !REACT_DND_CONTEXT
    REACT_DND_CONTEXT = ReactDnD.DragDropContext(HTML5Backend.default)
  return REACT_DND_CONTEXT(component)

checkBreakIsExpired = (breakAt) ->
  eventTime = moment.unix(breakAt).add(15, 'minutes')
  diffTimeCountdown = moment(eventTime).diff(moment())

  if (diffTimeCountdown < 0)
    return true

checkRollCallIsExpired = (recreatedAt) ->
  eventTime = moment.unix(recreatedAt).add(10, 'minutes')
  diffTimeCountdown = moment(eventTime).diff(moment())

  if (diffTimeCountdown < 0)
    return true

module.exports = {
  camelcaseParams
  conversationsComparator
  fuzzyScopeMatch
  mapIndexed
  mergeArraysById
  paramsToChannel
  sortConversations
  sortConversationsByUpdatedAt
  toggleItemInList
  underscoreParams
  agentStatus
  sortTagsByFrequency
  conversationIsClosed
  conversationIsNotClosed
  capitalizeFirstLetter
  formatFromSecondsToTime
  formatNumberToMoney
  formatNumberToPercent
  buildQueryParamsString
  getQueryParams
  qaDashboardSortingRule
  assignedOperatorsSortingRule
  shiftAgentsSortingRule
  calculateTimerValue
  arrayDifference
  buildCRMInfoRequestData
  oauthRedirect
  reactDndContext
  checkBreakIsExpired
  checkRollCallIsExpired
}
