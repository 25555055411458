moment = require 'moment'
{ Link } = require 'react-router-dom'

AbstractStatisticComponent = require '../AbstractStatisticComponent'
{formatFromSecondsToTime,
 formatNumberToMoney,
 formatNumberToPercent} = require 'lib/utils'
styles = require 'components/shared/BaseAgentStatisticComponent/base-agent-statistic'
{ BAD_TAGS, SALES_BAD_TAGS } = require 'constants/Ranking'


class MonthlyStatisticComponent extends AbstractStatisticComponent

  constructor: (props)->
    super(props)
    @tableFields = {}
    @tableFields.date =
      label: 'Month'
      presenter: (value) -> moment(value, 'YYYY-MM-DD').format 'DD MMM YYYY'
      handlers: {}
    @tableFields.shifts_count =
      label: '# Shifts'
      presenter: (value) -> value
      handlers: {}
    @tableFields.average_response_time =
      label: 'Average RT'
      presenter: (value) -> formatFromSecondsToTime value
      handlers: {}
    @tableFields.unique_clients_count =
      label: '# Unique Clients'
      presenter: (value) -> value
      handlers: {}
    @tableFields.avg_unique_clients_count =
      label: 'Avg UC per hour'
      presenter: (value) -> value
      handlers: {}
    @tableFields.ranked =
      label: '# Ranked'
      presenter: (value) -> value
      handlers: {}
    @tableFields.ranked_percent =
      label: '% Ranked'
      presenter: (value) -> formatNumberToPercent value
      handlers: {}
    if props.agent.role == 'sales' then @tableFields.strikes =
      label: '# Strikes'
      presenter: (value, month) =>
        React.createElement(Link, { \
          "className": (styles.link),  \
          "to": ("/ranking?status=ranked&quality=bad&end_date=#{moment(month.date, 'YYYY-MM-DD').format 'YYYY-MM-DD'}&range_type=monthly&agent_id=#{props.agent.id}")
        },
          (month.conversations_info?.strikes_count)
        )
      handlers: {}
    @tableFields.strikes_absolute =
      label: '% Strikes'
      presenter: (value, month) -> formatNumberToPercent Math.round(month.strikes)
      handlers:
        onMouseEnter: @onStrikesHover
        onMouseLeave: @onStrikesBlur
    @tableFields.salary =
      label: 'Salary (shifts)'
      presenter: (value) -> formatNumberToMoney value
      handlers: {}
    @tableFields.approved_bonus =
      label: 'Approved Bonus'
      presenter: (value) -> formatNumberToMoney value
      handlers: {}
    @tableFields.total =
      label: 'Total'
      presenter: (value) -> formatNumberToMoney value
      handlers: {}
    @tableFields.ranking_role_message_score =
      label: 'Avr. score (chat)'
      presenter: (value, month) =>
        if (value)
          React.createElement(Link, { \
            "className": (styles.link),  \
            "to": ("/ranking?end_date=#{moment(month.date, 'YYYY-MM-DD').format 'YYYY-MM-DD'}&range_type=monthly&agent_id=#{props.agent.id}")
          },
            (value)
          )
        else
          React.createElement("span", null, "–")
      handlers: {}
    @tableFields.ranking_role_log_message_score =
      label: 'Avr. score (order)'
      presenter: (value, month) =>
        if (value)
          React.createElement(Link, { \
            "className": (styles.link),  \
            "to": ("/ranking?end_date=#{moment(month.date, 'YYYY-MM-DD').format 'YYYY-MM-DD'}&range_type=monthly&agent_id=#{props.agent.id}")
          },
            (value)
          )
        else
          React.createElement("span", null, "–")
      handlers: {}

    if props.agent.role == 'sales'
      delete @tableFields.approved_bonus
      delete @tableFields.total

    @type = 'monthly'
    @initComponent()

  onStrikesHover: (e) =>
    strikedValue = Number.parseInt e.target.innerHTML
    if strikedValue > 0
      @refs.strikedHeader.innerHTML = formatNumberToPercent strikedValue
      strikedData = JSON.parse(e.target.closest('.row').getAttribute('data-value')).striked
      strikedBody = React.createElement("div", null,
        (Object.keys strikedData
          .map (key, i) ->
            React.createElement("p", {"key": (i), "className": (styles['content__strikes-popup-row'])},
              (BAD_TAGS[key]?.label || SALES_BAD_TAGS[key]?.label),
              React.createElement("span", {"className": (styles['content__strikes-popup-row_data'])},
                (formatNumberToPercent strikedData[key])
              )
            )
        )
      )
      ReactDOM.render strikedBody, @refs.strikedBody
      style = "
        display: inline-block;
        bottom: #{document.body.clientHeight - e.pageY - $('.profile').scrollTop()}px;
        right: #{document.body.clientWidth - e.pageX - $('.profile').scrollLeft()}px;
      "
      @refs.strikedPopup.setAttribute 'style', style

  onStrikesBlur: (e) =>
    @refs.strikedPopup.removeAttribute 'style'
    ReactDOM.render null, @refs.strikedBody

module.exports = MonthlyStatisticComponent
