BaseStore = require './BaseStore'
OnlineStore = require './OnlineStore'
ActionTypes = require 'constants/ActionTypes'
{ shiftAgentsSortingRule, agentStatus, checkBreakIsExpired, checkRollCallIsExpired } = require 'lib/utils'
moment = require 'moment'


class ShiftAgentsStore extends BaseStore
  constructor: ->
    super()
    @agents = {}
    @agentsUid = {}
    @agentsWFH = {}
    @wfh_loading = false
    @rollCallsNames = []
    @list = []
    @loading = false
    @registerActions()

  _registerToActions: ({ type, payload }) =>
    switch type
      when ActionTypes.MQTT_SHIFT_USERS_REQUEST
        @agents = {}
        @agentsUid = {}
        @loading = true
        @emitChange()
      when ActionTypes.MQTT_SHIFT_USERS_UPDATING_INITIAL
        @agents = {}
        @agentsUid = {}
        @loading = true
        @emitChange()
      when ActionTypes.MQTT_GLOBAL_WFH_SHIFT_USERS_UPDATING_INITIAL
        @agentsWFH = {}
        @wfh_loading = true
        @list = []
        @emitChange()
      when ActionTypes.MQTT_GLOBAL_WFH_SHIFT_USERS_UPDATING
        # console.log 'payload.message agent: ', payload.message
        Object.keys(payload.message).forEach (id) =>
          if payload.message[id]
            @agentsWFH[id] = {}
            Object.keys(payload.message[id]).forEach (key) =>
              @agentsWFH[id][key] = payload.message[id][key]
              @agentsWFH[id].uid = Number.parseInt id
              { break_at, rollcalls } = @agentsWFH[id]
              if rollcalls
                rollCallsKeys = Object.keys(rollcalls)
                rollCallsKeys.forEach (key) =>
                  if !@rollCallsNames.includes key then @rollCallsNames.push key
                  { recreated_at } = rollcalls[key]
                  isExpiredRollCall = if recreated_at then checkRollCallIsExpired recreated_at else false
                  @agentsWFH[id].rollcalls[key].isExpiredRollCall = isExpiredRollCall
            delete @agentsWFH[id] unless @agentsWFH[id].id
          else
            delete @agentsWFH[id]
        @emitChange()

      when ActionTypes.MQTT_GLOBAL_WFH_TEAMLEAD_SHIFT_USERS_UPDATING
        # console.log 'payload.message teamlead: ', payload.message
        Object.keys(payload.message).forEach (id) =>
          if payload.message[id]
            @agentsWFH[id] ||= {}
            Object.keys(payload.message[id]).forEach (key) =>
              @agentsWFH[id][key] = payload.message[id][key]
              @agentsWFH[id].uid = Number.parseInt id
              if @agentsUid[id]
                @agentsWFH[id].smstool_agent = @agentsUid[id]
                @agentsWFH[id].status = agentStatus(@agentsUid[id], OnlineStore.agents)
              { break_at, rollcalls } = @agentsWFH[id]

              if rollcalls
                rollCallsKeys = Object.keys(rollcalls)
                rollCallsKeys.forEach (key) =>
                  if !@rollCallsNames.includes(key)
                    @rollCallsNames.push(key)
                  { recreated_at } = rollcalls[key]
                  isExpiredRollCall = false
                  if recreated_at
                    isExpiredRollCall = checkRollCallIsExpired(recreated_at)
                  @agentsWFH[id].rollcalls[key].isExpiredRollCall = isExpiredRollCall
              @agentsWFH[id].isExpired = false
              if break_at
                @agentsWFH[id].isExpired = checkBreakIsExpired(break_at)

            delete @agentsWFH[id] unless @agentsWFH[id].id
          else
            delete @agentsWFH[id]

        @list = Object.keys(@agentsWFH).map (id) =>
          @agentsWFH[id]

        @wfh_loading = false
        @emitChange()
  
      when ActionTypes.MQTT_GLOBAL_WFH_TEAMLEAD_SHIFT_USERS_UPDATING_INITIAL
        @agentsWFH = {}
        @wfh_loading = true
        @list = []
        @emitChange()

      when ActionTypes.WFH_TEAMLEAD_USERS_PING
        Object.keys(@agentsWFH).forEach (id) =>
          { break_at, rollcalls } = @agentsWFH[id]
          if rollcalls
            rollCallsKeys = Object.keys(rollcalls)
            rollCallsKeys.forEach (key) =>
              if !@rollCallsNames.includes key then @rollCallsNames.push key
              { recreated_at } = rollcalls[key]
              isExpiredRollCall = if recreated_at then checkRollCallIsExpired recreated_at else false
              @agentsWFH[id].rollcalls[key].isExpiredRollCall = isExpiredRollCall
          @agentsWFH[id].isExpired = if break_at then checkBreakIsExpired break_at else false

        # console.log '@rollCallsNames 2: ', @rollCallsNames

        @emitChange()

      # smstool agents
      when ActionTypes.MQTT_SHIFT_USERS_UPDATING
        Object.keys(payload.message).forEach (id) =>
          if payload.message[id]
            @agents[id] ||= {}
            Object.keys(payload.message[id]).forEach (key) =>
              @agents[id][key] = payload.message[id][key]
            delete @agents[id] unless @agents[id].id
          else
            delete @agents[id]

        @agentsUid = {}
        Object.keys(@agents).forEach (id) =>
          uid = Number.parseInt @agents[id].uid
          @agentsUid[uid] = @agents[id]

        Object.keys(@agentsUid).forEach (id) =>
          if @agentsWFH[id]
            @agentsWFH[id].smstool_agent = @agentsUid[id]
            @agentsWFH[id].status = agentStatus(@agentsUid[id], OnlineStore.agents)

        @list = Object.keys(@agentsWFH).map (id) =>
          # { rollcalls, break_at } = @agentsWFH[id]
          # if rollcalls
          #   rollCallsKeys = Object.keys(rollcalls)
          #   rollCallsKeys.forEach (key) =>
          #     if !@rollCallsNames.includes(key)
          #       @rollCallsNames.push(key)
          #     { recreated_at } = rollcalls[key]
          #     isExpiredRollCall = false
          #     if recreated_at
          #       isExpiredRollCall = checkRollCallIsExpired(recreated_at)
          #     @agentsWFH[id].rollcalls[key].isExpiredRollCall = isExpiredRollCall
          # if break_at
          #   @agentsWFH[id].isExpired = checkBreakIsExpired(break_at)

          @agentsWFH[id]

        @loading = false if payload.initial
        @emitChange()


module.exports = new ShiftAgentsStore()
