import React, { useState, useEffect, useRef } from 'react';
import uuid from 'uuid';

import styles from './index.styl';

function getMappedLinks() {
  const { report: { links = [] } } = this;

  return links.map(link => {
    return {
      id:  uuid.v4(), text: link
    };
  });
};

const Links = ({ id, status, onAnyDataChange, report }) => {
  const [links, updateLinks] = useState(getMappedLinks.bind({ report }) || [{}]);

  const linksRef = useRef({
    isFirstRender: true,
    prevStatus: status,
  });

  const onLinkChange = (e) => {
    const { target: { value, name } } = e;
    updateLinks((__prevData) => {
      return __prevData.map(data => {
        if (data.id === name) {
          return {...data, text: value }
        }
        return data
      });
    });
  };

  const onLinkBlur = (e) => {
    const { target: { validity = {}, name, value } } = e;

    if (!value) return;

    if (!validity.valid) {
      onDeleteLink(name);
    }
  }

  const onAddNewLink = () => {
    const isSomeEmpty = links.some(link => !link.text);

    if (isSomeEmpty) return;
  
    updateLinks((__prevData) => {
      return [...__prevData, { id:  uuid.v4(), text: ''}]
    });
  };

  const onDeleteLink = (id) => {
    updateLinks((__prevData) => {
      return __prevData.filter(link => link.id !== id);
    })
  };

  useEffect(() => {
    if (linksRef.current.isFirstRender) {
      linksRef.current.isFirstRender = false;
      return;
    }

    onAnyDataChange(id, { links: links.map(link => link.text) });
  }, [links]);

  useEffect(() => {
    if (linksRef.current.prevStatus !== status) {
      updateLinks([]);
      linksRef.current.prevStatus = status;
    }
  }, [status]);

  return (
    <div className={styles.links_container}>
      {
        links.map((link) => {
          const { text, id } = link;

          if (!id) return null;
          return (
            <div className={styles.edit_body_link} key={id}>
              <input pattern="(http|https|ftp)://.*" onBlur={onLinkBlur}  placeholder="https://" className={styles.edit_body_link__input} name={id} type="url" value={text} onChange={onLinkChange} />
              <i className={styles.edit_body_link__icon} onClick={() => onDeleteLink(id)} />
            </div>
          );
        })
      }
      {
        links.length <= 4 && (
          <div className={styles.links_container__add_link} onClick={onAddNewLink}>+ Add link</div>
        )
      }
    </div>
  );
};

export default Links;
