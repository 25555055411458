{ Link } = require 'react-router-dom'

browserHistory = require 'root/history'
BaseComponent = require 'components/BaseComponent'
ChannelListComponent = require '../ChannelListComponent/ChannelListComponent'
dashboardStyles = require 'components/DashboardComponent/dashboard'
ChannelsActionCreator = require 'actions/ChannelsActionCreator'
ChannelsStore = require 'stores/ChannelsStore'
AuthStore = require 'stores/AuthStore'
{ menus } = require 'root/config'
{ ROLES } = require 'constants/Agents'

styles = require './channels.styl'

accessConfig = {}
menus.forEach (item) ->
  if item.key == 'rotations'
    accessConfig =
      toPage: item.accessToPage || null

class ChannelsComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @state =
      limit: 30
      page: 0
      dataReady: false
    @initComponent()

  dependsOnStores: [ChannelsStore]

  componentDidMount: ->
    super()
    if (ROLES[AuthStore.user.role].value in accessConfig.toPage) && AuthStore.user.system_editor
      setTimeout(
        => ChannelsActionCreator.getList { page: @state.page, per_page: @state.limit }
        10
      )
    else
      browserHistory.default.push '/'

  onPaginateClick: ({ selected }) =>
    page = selected
    ChannelsActionCreator.getList { page: page, per_page: @state.limit }
    @setState { page }

  render: ->
    React.createElement("div", null,
      React.createElement("div", {"className": (styles.header)},
        React.createElement("div", {"className": (styles.buttons)},
          React.createElement(Link, {"to": '/channels/new', "className": (dashboardStyles.button_add)},
            React.createElement("i", {"className": ("#{dashboardStyles.button_icon} fa fa-plus-circle")}),
            React.createElement("span", {"className": (dashboardStyles.button_label)}, " Add Channel ")
          )
        )
      ),

      React.createElement(ChannelListComponent, { \
        "error": (ChannelsStore.error),  \
        "loading": (ChannelsStore.loading),  \
        "channels": (ChannelsStore.channels),  \
        "pagesCount": (Math.ceil(ChannelsStore.total / @state.limit)),  \
        "paginateClickHandler": (@onPaginateClick)
      })
    )

module.exports = ChannelsComponent
