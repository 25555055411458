ActionTypes = require 'constants/ActionTypes'
BaseStore = require './BaseStore'


class OnlineStore extends BaseStore
  constructor: ->
    super()
    @agents = new Map()
    @dormant_list = []
    @loading = false
    @registerActions()

  _registerToActions: ({ type, payload }) =>
    switch type
      when ActionTypes.MQTT_ONLINE_USERS_REQUEST
        @loading = true
        @dormant_list = []
        @agents = new Map()
        @emitChange()

      when ActionTypes.MQTT_ONLINE_USERS_UPDATING_INITIAL
        @loading = true
        @dormant_list = []
        @agents = new Map()
        @emitChange()

      when ActionTypes.CLOSE_IDLE_NOTIFICATION
        agent = @agents.get(payload.id)
        if agent && payload.data.success
          agent.is_dormant = false
          @dormant_list = []
          @agents.forEach (agent, id) =>
            if agent.is_dormant
              @dormant_list.push(agent)
          @emitChange()

      when ActionTypes.MQTT_ONLINE_USERS_UPDATING
        Object.keys(payload.message).forEach (id) =>
          if payload.message[id]
            agent = @agents.get(id)
            agent = {} unless agent
            Object.keys(payload.message[id]).forEach (key) =>
              agent[key] = payload.message[id][key]
            id = parseInt(id)
            @agents.set(id, agent)
            @agents.delete(id) unless agent.id
            agent = @agents.get(id)
          else
            @agents.delete(id)
        @dormant_list = []
        @agents.forEach (agent, id) =>
          if agent.is_dormant
            @dormant_list.push(agent)
        @loading = false if payload.initial
        @emitChange()

module.exports = new OnlineStore()
