config                              = require '/src/config';
BaseComponent                      = require 'components/BaseComponent'
onClickOutside                     = require 'react-onclickoutside'
ADDITIONAL_ORDER_MENU              = require 'constants/additionalOrderMenu'
styles                             = require './tab-options'

class OrderActionListComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  handleClickOutside: (e) =>
    @props.handleClickOutside()

  render: =>
    { hasPaidOrder, hasTag, isMynerdify, isXmpp, withMandatorySubscription = false } = @props
    # templates = if hasPaidOrder then ADDITIONAL_ORDER_MENU._ALL else ADDITIONAL_ORDER_MENU._NO_PAID
    templates = []
    if !hasPaidOrder
      templates = ADDITIONAL_ORDER_MENU._NO_PAID
    if isMynerdify && hasPaidOrder
      templates = ADDITIONAL_ORDER_MENU._MY_NERDIFY
    else if hasPaidOrder && !hasTag
      templates = ADDITIONAL_ORDER_MENU._NO_TAG
    else if hasPaidOrder && hasTag
      templates = ADDITIONAL_ORDER_MENU._HAS_TAG

    if isXmpp && hasPaidOrder
      templates = ADDITIONAL_ORDER_MENU._XMPP

    if withMandatorySubscription
      templates = [...ADDITIONAL_ORDER_MENU._OFFER_SUBSCRIPTION, ...templates]

    React.createElement("div", {"className": (styles.dropdown)},
      (
        for type in templates
          React.createElement("div", { \
            "key": type,  \
            "onClick": ( _.partial(@props.onItemClick, type) ),  \
            "className": (styles.dropdown_item)
          },
            React.createElement("img", {"src": (require "components/shared/images/#{ADDITIONAL_ORDER_MENU._ICON[type]}.svg")}),
            ( type )
          )
      )
    )

module.exports = onClickOutside.default(OrderActionListComponent)
