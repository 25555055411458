import React, { Fragment, useEffect, useState } from 'react';
import { controls } from '@verdaccio/crminfo';

import classnames from 'classnames';
import moment from 'moment';
import uuid from 'uuid';
import _ from 'lodash';

import InterfaceActionCreator from 'actions/InterfaceActionCreator';
import InfoModal from 'components/shared/InfoModal';

import styles from './styles.styl'

const DEFAULT_SORT = 'asc'
const SORT_ORDER = {
  'asc': 'desc',
  'desc': 'asc'
};

const COLUMN_FIELD_VAR = {
  ID: 'ID',
  TEAM: 'Team',
  PILLAR: 'Pillar',
  ISSUE_TYPE: 'Issue Type',
  RULE: 'Rule',
  SEVERITY: 'Severity',
  WHY_IMPORTANT: 'Why Important',
  AFFECTS_THE_CLIENT: 'Affects the client',
  STATUS: 'Status',
  LOGS: 'Logs',
  ACTIONS: 'Actions',
}

const COLUMN_FIELD_NAMES = Object.values(COLUMN_FIELD_VAR);

const SORTING_PARAMS = [
  {
    title: 'Sort from created',
    name: 'created_at',
  },
  {
    title: 'Sort from archived',
    name: 'archived_at',
  },
  {
    title: 'Sort from restore',
    name: 'restore_at',
  },
]

const ARR_SORT_PRIORITY_FIELD = ['pillar', 'issue_type', 'priority', 'created_at', 'archived_at', 'restored_at', 'rule', 'description'];

const RankingRulesTableComponent = ({ rules, getList, params, updateRule, deleteRule, editRule, duplicateRule, isResetfilters, setResetFilter }) => {
  const [isOpen, setOpen] = useState(false)
  const [paramsFields, setParamsFields] = useState(params)
  const [sortingFields, setSortingFields] = useState({
    sort_by: params.sort_by || [],
    sort_order: params.sort_order || []
  })


  useEffect(() => {
    setParamsFields(__prevData => ({ ...__prevData, ...paramsFields, ...params }));
  }, [params]);

  useEffect(() => {
    if (isResetfilters) {
      setSortingFields(__prevData => ({ sort_by: [], sort_order: [] }));
      setParamsFields(__prevData => ({ ...__prevData, ...paramsFields, sort_by: [], sort_order: [] }));
      setResetFilter(false);
    }
  }, [isResetfilters]);

  const COLUMN_FIELD = {
    [COLUMN_FIELD_VAR.ID]: {
      name: 'id',
      formatter: (item) => <div className={styles.value}>{item.id}</div>,
      align: 'center',
      width: '50px',
    },
    [COLUMN_FIELD_VAR.TEAM]: {
      name: 'team',
      formatter: (item) => <div className={styles.value}>{item.team}</div>,
      align: 'center',
      width: '80px',
    },
    [COLUMN_FIELD_VAR.PILLAR]: {
      name: 'pillar',
      formatter: (item) => <div className={styles.value}>{item.pillar}</div>,
      align: 'center',
      width: '150px',
    },
    [COLUMN_FIELD_VAR.ISSUE_TYPE]: {
      name: 'issue_type',
      formatter: (item) => <div className={styles.value}>{item.issue_type}</div>,
      align: 'center',
      width: '150px',
    },
    [COLUMN_FIELD_VAR.RULE]: {
      name: 'rule',
      formatter: (item) => <div className={styles.value}>{item.rule}</div>,
      align: 'left',
      width: '350px',
    },
    [COLUMN_FIELD_VAR.SEVERITY]: {
      name: 'priority',
      formatter: (item) => <div className={styles.value}><i className={classnames(styles.priority, { [styles.low]: item.priority === 'low', [styles.medium]: item.priority === 'medium', [styles.high]: item.priority === 'high' })}>{item.priority}</i></div>,
      align: 'center',
      width: '80px',
    },
    [COLUMN_FIELD_VAR.WHY_IMPORTANT]: {
      name: 'description',
      formatter: (item) => <div className={styles.value}>{item.description}</div>,
      align: 'left',
      width: '350px',
    },
    [COLUMN_FIELD_VAR.AFFECTS_THE_CLIENT]: {
      name: 'is_client_affects',
      formatter: (item) => <div className={styles.value}>{item.is_client_affects ? 'yes' : 'no'}</div>,
      align: 'center',
      width: '150px',
    },
    [COLUMN_FIELD_VAR.STATUS]: {
      name: 'status',
      formatter: (item) => <div className={classnames(styles.value, { [styles.archived_value]: item.status === 'archived', [styles.active_value]: item.status === 'active' })}>{item.status}</div>,
      align: 'center',
      width: '100px',
    },

    [COLUMN_FIELD_VAR.LOGS]: {
      formatter: (item) => {
        return (
          <div className={styles.value}>
            <div>Created: {moment(item.created_at).format('DD MMMM, YYYY / HH:mm')}</div>
            {item.archived_at &&
              <div>Archived: {moment(item.archived_at).format('DD MMMM, YYYY / HH:mm')}</div>
            }
            {item.restored_at &&
              <div>Restored: {moment(item.restored_at).format('DD MMMM, YYYY / HH:mm')}</div>
            }
          </div>
        )
      },
      width: '250px',
    },
    [COLUMN_FIELD_VAR.ACTIONS]: {
      name: 'Actions',
      formatter: (item) => {
        return (
          <div className={styles.value}>
            {!item.is_using !== 'edit' &&
              <button className={classnames(styles.icon, styles.edit)}  onClick={() => editRule(item.id)} />
            }
            {item.status !== 'archived' && item.is_using &&
              <button className={classnames(styles.icon, styles.archived)} onClick={() => updateRule(item.id, { status: 'archived' })} />
            }
            {item.status === 'archived' &&
              <button className={classnames(styles.icon, styles.restore)} onClick={() => showModalRestore(item.id, { status: 'active' })} />
            }
            {!item.is_using &&
              <button className={classnames(styles.icon, styles.delete)} onClick={() => showModalDelete(item.id)} />
            }
            <button className={classnames(styles.icon, styles.duplicate)} onClick={() => duplicateRule(item.id)} />
          </div>
        )
      },
      align: 'center',
      width: '200px',
    }
  }

  const onSortBy = (field) => {
    const idx = (sortingFields.sort_by || []).indexOf(field);
    const newSortingFields = _.cloneDeep(sortingFields);

    if (idx > -1) {
      const sortOrder = sortingFields.sort_order[idx];
      if (sortOrder === DEFAULT_SORT) {
        delete newSortingFields.sort_by[idx]
        delete newSortingFields.sort_order[idx]
      } else {
        newSortingFields.sort_order[idx] = SORT_ORDER[sortOrder];
      }
    } else {
      newSortingFields.sort_by = [...newSortingFields.sort_by, field];
      newSortingFields.sort_order = [...newSortingFields.sort_order, SORT_ORDER[DEFAULT_SORT]];
    }
    
    newSortingFields.sort_by = newSortingFields.sort_by.slice(-3);
    newSortingFields.sort_order = newSortingFields.sort_order.slice(-3);
    setSortingFields(newSortingFields);

    let mapSortingFields = {
      ...paramsFields,
      sort_by: [],
      sort_order: []
    }

    ARR_SORT_PRIORITY_FIELD.map((sortPriority, idx) => {
      const index = (newSortingFields.sort_by || []).indexOf(sortPriority);
      if (index > -1) {
        mapSortingFields.sort_by = [...mapSortingFields.sort_by, newSortingFields.sort_by[index]]
        mapSortingFields.sort_order = [...mapSortingFields.sort_order, newSortingFields.sort_order[index]]
      }
    })

    newSortingFields.sort_by.map((field, idx) => {
      const index = (mapSortingFields.sort_by || []).indexOf(field);
      if (index === -1) {
        mapSortingFields.sort_by = [...mapSortingFields.sort_by, newSortingFields.sort_by[idx]]
        mapSortingFields.sort_order = [...mapSortingFields.sort_order, newSortingFields.sort_order[idx]]
      }
    })

    const { page, per_page, status, team, pillar, issue_type, priority, created_at, archived_at, restored_at, is_client_affects } = paramsFields;
    mapSortingFields = {
      ...mapSortingFields,
      page,
      per_page,
      status,
      team,
      pillar,
      issue_type,
      priority,
      is_client_affects,
      created_at,
      archived_at,
      restored_at,
    }

    setParamsFields(mapSortingFields);
    getList(mapSortingFields);
  }

  const renderSortIcon = (item) => {
    const field = COLUMN_FIELD[item].name;
    const idx = (paramsFields.sort_by || []).indexOf(field);
    const sortOrder = idx > -1 ? paramsFields.sort_order[idx] : null;

    switch (item) {
      case 'Logs':
        return (
          <Fragment>
            <controls.ButtonDropdown link className={styles.btn_dropdown} onClick={() => setOpen(true)} empty>...</controls.ButtonDropdown>
            <controls.Dropdown.Dropdown onOpenChange={setOpen} isOpen={isOpen} positionRight>
              <controls.Dropdown.Panel>
                <div>
                  {SORTING_PARAMS.map(sortField => {
                    const field = sortField.name;
                    const idx = (paramsFields.sort_by || []).indexOf(field);
                    const sortOrder = idx > -1 ? paramsFields.sort_order[idx] : null;

                    return (
                      <div key={field}>
                        {sortField.title}
                        <div className={styles.icon_wrap} onClick={() => onSortBy(field)} key={sortField.title}>
                          <i className={classnames(styles.icon_sort, { [styles.up]: !sortOrder || sortOrder === 'asc' })} />
                          <i className={classnames(styles.icon_sort, { [styles.down]: !sortOrder || sortOrder === 'desc' })} />
                        </div>
                      </div>
                    )
                  })}
                </div>
              </controls.Dropdown.Panel>
            </controls.Dropdown.Dropdown>
          </Fragment>
        );

      case 'Actions':
        return null;

      default:
        return (
          <div className={styles.icon_wrap} onClick={() => onSortBy(field)}>
            <i className={classnames(styles.icon_sort, { [styles.up]: !sortOrder || sortOrder === 'asc' })} />
            <i className={classnames(styles.icon_sort, { [styles.down]: !sortOrder || sortOrder === 'desc' })} />
          </div>
        )
    };
  }

  const showModalRestore = (id, params) => {
    InterfaceActionCreator.openModal(InfoModal, {
      text: "Are you sure you want to restore the rule?",
      buttonTitle: 'No',
      cancelButtonTitle: 'Yes',
      onCancel: () => {
        updateRule(id, params),
        InterfaceActionCreator.closeModal()
      },
    });
  }

  const showModalDelete = (id) => {
    InterfaceActionCreator.openModal(InfoModal, {
      text: "Are you sure you want to delete the rule?",
      buttonTitle: 'No',
      cancelButtonTitle: 'Yes',
      onCancel: () => {
        deleteRule(id)
        InterfaceActionCreator.closeModal()
      },
    });
  }
  
  return (
    <div className={styles.container}>
      <table className={styles.table_rules} width="100%">
        <thead>
          <tr>
            {COLUMN_FIELD_NAMES.map(key => (
              <td key={key} align={COLUMN_FIELD[key].align} width={COLUMN_FIELD[key].width}>
                <div>
                  {key}
                  {renderSortIcon(key)}
                </div>
              </td>
            ))
            }
          </tr>
        </thead>
        <tbody>
          {rules && rules.map(rule => (
            <tr key={uuid.v4()}>
              {COLUMN_FIELD_NAMES.map(key => (
                <td key={uuid.v4() + key} align={COLUMN_FIELD[key].align}>
                  {COLUMN_FIELD[key].formatter(rule)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default RankingRulesTableComponent;
