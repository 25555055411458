BaseComponent = require 'components/BaseComponent'

class SoundNotification extends BaseComponent
  constructor: (props) ->
    super(props)
    soundRef = null
    @initComponent()

  componentDidMount: =>
    super()
    @soundRef.play()

  render: =>
    React.createElement("audio", {"ref": ((ref) => @soundRef = ref)},
      React.createElement("source", {"src": (require 'root/assets/media/bell_notification.mp3')})
    )

module.exports = SoundNotification
