BaseComponent = require '../../BaseComponent'
classnames    = require 'classnames'
countdown     = require 'countdown'
moment        = require 'moment'
styles        = require './awaiting-time.styl'

config = require 'root/config'
propTypes = require 'prop-types'

# units = countdown.DAYS | countdown.HOURS | countdown.MINUTES
units = countdown.MINUTES

class AwaitingTimeComponent extends BaseComponent

  @propTypes:
    className: propTypes.string
    awaitingFrom: propTypes.oneOfType [
      propTypes.object.isRequired
      propTypes.string.isRequired
    ]

  constructor: (props) ->
    super(props)
    @state = R.merge @state, awaitingFrom: props.awaitingFrom
    @initComponent()

  componentWillReceiveProps: (props) =>
    @setState awaitingFrom: props.awaitingFrom

  componentWillMount: =>
    @intervals = []

  componentWillUnmount: =>
    @intervals.forEach(clearInterval)

  componentDidMount: =>
    @setInterval(@tick, 60000)
    @tick()

  setInterval: =>
    @intervals.push(setInterval.apply(null, arguments))

  tick: =>
    @forceUpdate()

  awaitingFrom: =>
    try
      countdown.setLabels 'ms|s| min|hr|d|wk|mth', 'ms|s| min|hr|d|wk|mth', ' ', ' ','0 min'
      countdown(new Date(), Date.parse(@state.awaitingFrom), units).toString()
    catch err
      console.error(err.message)
      return 'x min'

  render: =>
    baseName = styles.container
    if @props.isUnread && config.showUnreadMessages
      baseName = styles.container__unread
    className = classnames(baseName, @props.className)

    React.createElement("div", {"className": ( className ), "title": ( 'Awaiting time' )},
      ( @awaitingFrom() )
    )

module.exports = AwaitingTimeComponent
