{ ConnectProvider } = require '@connect/connect-xmpp';
{ Fragment, Component } = require 'react';

config = require 'root/config'

BaseComponent = require './components/BaseComponent'
XmppData = require './components/XmppData'

AuthStore = require 'stores/AuthStore'

class Xmpp extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  dependsOnStores: [AuthStore]

  render: ->
    { children } = @props
    { user } = AuthStore

    if !user || !user.jabber_password
      React.createElement(Fragment, null, ( children ))

    else
      config = 
        userJid: user.jabber_jid
        password: user.jabber_password
      React.createElement(ConnectProvider, { \
        "xmppConfig": (config),  \
        "isProduction": (config.isProductionMode),  \
        "countOfReqMessages": (1),  \
        "resetTypingMs": (30000)
      },
        React.createElement(Fragment, null, ( children )),
        React.createElement(XmppData, null)
      )
      

module.exports = Xmpp