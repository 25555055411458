config                             = require '/src/config';
BaseComponent                     = require 'components/BaseComponent'
crminfo                           = require '@verdaccio/crminfo'
styles                            = require './tab-options'
sitesConstants                    = require 'constants/Sites'
_                                 = require 'lodash'

OrderActionListComponent          = require './OrderActionListComponent'
CRMInfoModuleStore                = require 'stores/CRMInfoModuleStore'
ADDITIONAL_ORDER_MENU             = require 'constants/additionalOrderMenu'
{ getToken }                      = require 'lib/tokenStorage'

{ CLIENT_TAG_EXCLUDE_FOR_APP }    = require 'constants/conversations'
{ ADDITIONAL_MENU_KEYS }          = require 'constants/templateMessagesKeyValues'
MARKS                             = require 'constants/marks'

TYPE_SUBSCRIPTION =
  qe_basic_trial: 'TRIAL'
  qe_basic: 'BASIC'

class TabOptionsComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @state =
      showDropdown: false
      userData: null
      userId: CRMInfoModuleStore?.payments?._id
      isApp: CRMInfoModuleStore?.payments?.exp_data?.app2_scale == MARKS.APP_OFFER
    @initComponent()
    
    dependsOnStores: [CRMInfoModuleStore]

  handleClickOutside: =>
    @setState showDropdown: !@state.showDropdown

  getUserData: =>
    idUser = CRMInfoModuleStore?.payments?._id
    headers =
      method: 'GET'
      Authorization: getToken()
      'Content-Type': 'application/json'

    fetch("https://ps.dsh-agency.com/data_mart/#{idUser}", headers)
      .then (response) =>
        if response.ok
          response.json()
          .then (data) => @setState userData: data
      .catch (e) => console.error e

  componentDidUpdate: (prevProps, prevState) =>
    if !@state.userId && !!CRMInfoModuleStore?.payments?._id
      @setState userId: CRMInfoModuleStore?.payments?._id
      @setState isApp: CRMInfoModuleStore?.payments?.exp_data?.app2_scale == MARKS.APP_OFFER
      @getUserData()
      
  componentDidMount: =>
    if @state.userId && @state.isApp
      @getUserData()

  onItemClick: (type, e) =>
    isMynerdifyNotApp = (@props.offer?.typeMessage == ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.TYPE2 || @props.offer?.typeMessage == ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.TYPE1)
    isFacebookChannel = @props.offer.serviceName == 'Facebook'
    hasClientTag = CRMInfoModuleStore?.currentCustomer?.tags?.some (client_tag) -> CLIENT_TAG_EXCLUDE_FOR_APP.includes client_tag.name
    notAppAndroid = @state.userData?.mobile_os_family != 'android'
    hasMark = CRMInfoModuleStore?.payments?.exp_data?.app2_scale == MARKS.NO_APP_OFFER
    hasPaidOrder = CRMInfoModuleStore.payments?.isLead || false
    emailSupport = 'done@gonerdify.com'

    if config.isAwesomeTutors
      emailSupport = 'support@awesome-tutors.com'


    if type != ADDITIONAL_ORDER_MENU.NEW
      if (type == ADDITIONAL_ORDER_MENU.DETAILS_REQUEST || type == ADDITIONAL_ORDER_MENU.TASK_DETAILS_REQUEST || type == ADDITIONAL_ORDER_MENU.DASHBOARD_TASK_NO_LOGIN || type == ADDITIONAL_ORDER_MENU.DASHBOARD_TASK) && (@props.offer.serviceName == 'Mynerdify' || @props.offer.serviceName == 'Xmpp')
        typeMessage = ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.Mynerdify
        typeMessage = if @props.offer.serviceName == 'Mynerdify' then ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.Mynerdify else ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.Xmpp

      else typeMessage = @props.offer.typeMessage

      if type == ADDITIONAL_ORDER_MENU.OFFER_NO_DETAIL || type == ADDITIONAL_ORDER_MENU.OFFER_DETAIL
        if !hasMark && (isMynerdifyNotApp || isFacebookChannel) && !hasClientTag && notAppAndroid && !config.isAwesomeTutors
          typeMessage = ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.withMark

      requiredSubscription = CRMInfoModuleStore?.payments?.required_subscription
      if (type == ADDITIONAL_ORDER_MENU.OFFER_SUBSCRIPTION && config?.isQuizEasy && requiredSubscription)
        typeMessage = TYPE_SUBSCRIPTION[requiredSubscription]

      if !typeMessage then return

      initTemplate = _.cloneDeep ADDITIONAL_ORDER_MENU._MESSAGE[type][typeMessage]

      messages = initTemplate.messages
      # replace = initTemplate.templates

      #US, FB, [MyNerdify] — TYPE_1
      #CA и AU — TYPE_2

      newMessages = messages;

      #[link to cabinet]
      linkTo = CRMInfoModuleStore.payments?.OrderLink

      #[file sharing link]
      fileSharingLink = CRMInfoModuleStore.payments?.CustomerSharingLink

      #[subscription link]
      subscriptionLink = CRMInfoModuleStore.payments?.SubscriptionLink

      account = if config.isAwesomeTutors then 'Awesome Tutors' else 'Nerdify'
      accountTask = if config.isAwesomeTutors then 'the best expert' else 'a perfect Nerd'

      values =
        [ADDITIONAL_MENU_KEYS.LINK_TO_CABINET]: linkTo
        [ADDITIONAL_MENU_KEYS.FILE_SHARING_LINK]: fileSharingLink
        [ADDITIONAL_MENU_KEYS.SUBSCRIPTION_LINK]: subscriptionLink
        [ADDITIONAL_MENU_KEYS.EMAIL_SUPPORT]: emailSupport
        [ADDITIONAL_MENU_KEYS.ACCOUNT]: account
        [ADDITIONAL_MENU_KEYS.ACCOUNT_TASK]: accountTask

      for i in [0...messages.length]
        message = messages[i]
        for k in [0...message.length]
          mes = message[k]
          Object.keys values
            .map (key, i) ->
              mes = mes.replace(key, values[key])
          newMessages[i][k] = mes

    switch type
      when ADDITIONAL_ORDER_MENU.NEW then @props.new.onNewOrder()
      when ADDITIONAL_ORDER_MENU.OFFER_DETAIL then @props.offer.onOpenMessageForm(type, newMessages)
      when ADDITIONAL_ORDER_MENU.OFFER_NO_DETAIL then @props.offer.onOpenMessageForm(type, newMessages)
      when ADDITIONAL_ORDER_MENU.DETAILS_REQUEST then @props.offer.onOpenMessageForm(type, newMessages)
      when ADDITIONAL_ORDER_MENU.TASK_DETAILS_REQUEST then @props.offer.onOpenMessageForm(type, newMessages)
      when ADDITIONAL_ORDER_MENU.DASHBOARD_TASK_NO_LOGIN then @props.offer.onOpenMessageForm(type, newMessages)
      when ADDITIONAL_ORDER_MENU.DASHBOARD_TASK then @props.offer.onOpenMessageForm(type, newMessages)
      when ADDITIONAL_ORDER_MENU.OFFER_SUBSCRIPTION then @props.offer.onOpenMessageForm(type, newMessages)

  onOpenDropDown: (e) =>
    @setState showDropdown: true

  render: ->
    hasTag = false
    if CRMInfoModuleStore.currentCustomer
      hasTag = CRMInfoModuleStore.currentCustomer.tags.filter((t) -> t.name.toLowerCase() == 'no account').length > 0
    hasPaidOrder = CRMInfoModuleStore.payments?.isLead || false
    isUK = CRMInfoModuleStore.payments?.site_acronym == sitesConstants.TEXT_NERDY_UK
    requiredSubscription = CRMInfoModuleStore?.payments?.required_subscription
    withMandatorySubscription = CRMInfoModuleStore?.payments?.exp_data?.subscription_qe == MARKS.MANDATORY_SUBSCRIPTION && config?.isQuizEasy && requiredSubscription

    isMynerdify = @props.offer?.serviceName == 'Mynerdify'
    isXmpp = @props.offer?.serviceName == 'Xmpp' && config?.xmpp

    React.createElement("div", {"className": (styles.container)},
      (if @props.all
        React.createElement("span", {"className": (styles.all), "onClick": (@props.all.onClick)},
          React.createElement("img", { \
            "src": (require 'components/shared/images/all.svg'),  \
            "className": (styles.icon)
          }),
          (@props.all.text)
        )
      ),
      (if @props.new
        # <span className={styles.new} onClick={if canSentMessage then @onOpenDropDown else @props.new.onNewOrder}>
        React.createElement("span", {"className": (styles.new), "onClick": (if !isUK then @onOpenDropDown else @props.new.onNewOrder)},
          React.createElement("img", { \
            "src": (require 'components/shared/images/add.svg'),  \
            "className": (styles.icon)
          }),
          (@props.new.text),
          (
            @state.showDropdown &&  
              React.createElement(OrderActionListComponent, { \
                "onItemClick": @onItemClick,  \
                "handleClickOutside": @handleClickOutside,  \
                "hasPaidOrder": hasPaidOrder,  \
                "hasTag": (hasTag),  \
                "isMynerdify": (isMynerdify),  \
                "isXmpp": (isXmpp),  \
                "withMandatorySubscription": (withMandatorySubscription)
              })
          )
        )
      )
    )

module.exports = TabOptionsComponent
