ShiftAgentsActionCreator = require 'actions/ShiftAgentsActionCreator'

ping = ->
  ShiftAgentsActionCreator.pingAgent()

agentPing = _.once ->
  ping()
  setInterval ping, 30 * 1000

module.exports = agentPing
