ActionTypes = require 'constants/ActionTypes'
RankingAPI = require 'api/RankingAPI'
Dispatcher = require 'root/dispatcher/Dispatcher'


RankingActionCreator =
  openConversation: (conversation) ->
    Dispatcher.dispatch type: ActionTypes.RANKING_OPEN_CONVERSATION, payload: conversation

  closeConversation: (conversation) ->
    Dispatcher.dispatch type: ActionTypes.RANKING_CLOSE_CONVERSATION, payload: conversation

  startRanking: -> Dispatcher.dispatch type: ActionTypes.RANKING_START

  stopRanking: ->
    setTimeout ->
        Dispatcher.dispatch type: ActionTypes.RANKING_STOP
      10

  changeEstimation: (isSelectable) ->
    Dispatcher.dispatch
      type: ActionTypes.RANKING_CHANGE_ESTIMATION
      payload:
        isSelectable: isSelectable

  savedEstimation: (isSelectable) ->
    Dispatcher.dispatch
      type: ActionTypes.RANKING_RETURN_TO_SAVED
      payload:
        isSelectable: isSelectable

  selectRankedMessage: (messageId, messageType) ->
    Dispatcher.dispatch
      type: ActionTypes.RANKING_SELECT_MESSAGE,
      payload: {
        messageId,
        messageType
      }

  submitValue: (data, onSuccessCallback) ->
    Dispatcher.dispatch type: ActionTypes.RANKING_SUBMIT_REQUESTING
    msg = 'An error occurred while saving ranking for conversation'
    RankingAPI.postConversationEstimate data,
      ({success}) ->
        if success
          Dispatcher.dispatch type: ActionTypes.RANKING_SUBMIT_SUCCESS, payload: data.approve_data
          onSuccessCallback()
        else
          console.error msg
          Dispatcher.dispatch
            type: ActionTypes.RANKING_SUBMIT_FAILURE
            payload: msg
      (error) ->
        console.error msg, error
        Dispatcher.dispatch
          type: ActionTypes.RANKING_SUBMIT_FAILURE
          payload: msg

  closeAllConversation: -> Dispatcher.dispatch type: ActionTypes.RANKING_CLOSE_ALL_CONVERSATION

module.exports = RankingActionCreator
