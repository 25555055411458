import React, { useLayoutEffect, useState, useEffect, useRef } from 'react';
import { controls, RankingReportPlugin } from '@verdaccio/crminfo';

import ConversationsAPI from 'api/ConversationsAPI';
import RankingRulesAPI from 'api/RankingRulesAPI';

import InterfaceActionCreator from  'actions/InterfaceActionCreator';

import { isGeekly } from  'root/config';

import styles from './index.styl';

const RANKING_REPORT_RENDER = Symbol.for('ranking_report_render');

if (window[RANKING_REPORT_RENDER] === undefined) {
  window[RANKING_REPORT_RENDER] = true;
}

const RankingReportPage = ({ match }) => {
  const { params: { id } } = match;

  const [isLoading, toggleIsLoading] = useState(true);
  const [conversation, setConversation] = useState(null);
  const [reports, setReports] = useState(null);

  const [error, setError] = useState(false);

  useLayoutEffect(() => {
    setTimeout(() => {
      InterfaceActionCreator.hideAllAppDetails();
    }, 1);
  }, []);


  useEffect(() => {
    if (!id) return;

    if (window[RANKING_REPORT_RENDER]) {
      window[RANKING_REPORT_RENDER] = false;
      return;
    }

    ConversationsAPI.get(id, (data) => {
      setConversation(data);
      RankingRulesAPI.getConversationRankingReports(id, (data) => {
        setReports(data.messages);
        toggleIsLoading(false);
      }, () => {
        setError(true);
      })
    }, () => {
      setError(true);
    });
  }, []);

  if (!id || error) return (
    <div className={styles.no_data_container}>
        <div>Error while loading ranking reports</div>
    </div>
  );

  if (isLoading) {
    return (
      <div className={styles.no_data_container}>
        <controls.Loader />
      </div>
    ) 
  }

  return (
    <div className={styles.ranking_report_page}>
      <RankingReportPlugin data={{ messages: reports, conversation: conversation, options: { isGeekly, isSinglePage: true } }} />
    </div>
  )

};

export default RankingReportPage;
