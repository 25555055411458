import React, { useEffect, useState, useRef } from 'react';
import uuid from 'uuid';

import RankingRulesActionCreator from 'actions/RankingRulesActionCreator';

import { ReportHeader, ReportBody, ReportEditBody, EditFooter, Tabs, ReportLog } from './components';
import { CollapsedBox } from '../index';

function initReports() {
  const { message, isEditMode, isInnerEdit = false } = this;
  if (isEditMode) {
    const id = uuid.v4();
    return [
      {
        status: 'issue',
        isOpen: true,
        target_type: message.target_type,
        target_id: message.target_id,
        links: [],
        info: {
          target_text: message.text,
          created_at: message.created_at,
          nativeText: message.nativeText,
        },
        id,
        comment: '',
        ranking_rule_id: null,
      }
    ]
  }

  return message.map(m => ({ ...m, ...{ isOpen: isInnerEdit, isInnerEdit: isInnerEdit, tab: 'Score', logs: [], noLogs: false } }));
};

const Body = ({ message, isEditMode, isOpen, innerEditMode, offEditMode, isAllUnfolds }) => {
  const { target_id, target_type } = message;
  const [reports, updateReports] = useState(initReports.bind({ message, isEditMode }));

  const bodyRef = useRef({
    target_id: `${target_id}_${target_type}`,
    reportLog: [],
    firstRender: true,
  })

  const onToggleReport = (id) => {
    updateReports(__prevData => {
      return __prevData.map(report => {
        if (report.id === id) {
          return {...report, ...{ isOpen: !report.isOpen }}
        }

        return report;
      })
    })
  };

  const onAnyDataChange = (id, newData = {}, customHandler) => {
    if (customHandler) {
      updateReports(customHandler);
      return;
    }

    updateReports(__prevData => {
      return __prevData.map(report => {
        if (report.id === id) {
          return { ...report, ...newData }
        }
        return report;
      })
    })
  };

  const onAddNewReport = () => {
    const __isEditMode = innerEditMode || isEditMode;
    let __message = message;
    if (innerEditMode) {
      __message = {...message[0], text: message[0].info.target_text, created_at: (message[0].info.created_at || '')};

    }
    updateReports(__prevData => {
      return [...__prevData, ...initReports.call({ message: __message, isEditMode: __isEditMode })]
    });
  };

  const onDeleteReport = (id) => {
    if (reports.length === 1) {
      const { target_id } = reports[0];
      if (!isEditMode) return;
      setTimeout(() => {
        RankingRulesActionCreator.selectRankedMessage({
          id: target_id
        });
      }, 1);
    }
    updateReports(__prevData => {
      return __prevData.filter(report => report.id !== id);
    });
  };

  const onLogLoaded = (data) => {
    bodyRef.current.reportLog = data;
  }

  const offEditModeBody = () => {
    updateReports(initReports.call({ message }));
    offEditMode();
  }

  const onSelectedTab = (tab, id) => {
    onAnyDataChange(id, {
      tab,
    });
  }

  useEffect(() => {
    if (!isEditMode) return;

    const { target_type: __target_type, target_id: __target_id } = message;
    const id = `${target_id}_${target_type}`;

    if (bodyRef.current.target_id && bodyRef.current.target_id !== id) {
      bodyRef.current.target_id = id;
      bodyRef.current.reportLog = [];
      updateReports(initReports.call({ message, isEditMode }));
    }
  }, [isEditMode, message]);

  useEffect(() => {
    if (!innerEditMode) return;

    bodyRef.current.reportLog = [];
    updateReports(initReports.call({ message, isInnerEdit: true }));
  }, [innerEditMode]);

  useEffect(() => {
    if (bodyRef.current.firstRender) {
      bodyRef.current.firstRender = false;
      return;
    }

    if (Array.isArray(message)) {
      updateReports(initReports.call({ message }));
    }
  }, [message]);

  useEffect(() => {
    if (isAllUnfolds === undefined) return;
    
    updateReports(reports.map(report => ({...report, isOpen: isAllUnfolds })));
  }, [isAllUnfolds]);
  

  return (
    <div>
      {reports.map((report) => 
        (
          <div key={report.id}>
            <ReportHeader report={report} onToggleReport={onToggleReport} innerEditMode={innerEditMode} isEditMode={innerEditMode || isEditMode} onDeleteReport={onDeleteReport} />
            <CollapsedBox isOpen={report.isOpen}>
              {
                innerEditMode || isEditMode ? (
                  <ReportEditBody report={report} onAnyDataChange={onAnyDataChange} />
                ) : (
                  <>
                    <Tabs selectedTab={report.tab} setSelectedTab={onSelectedTab} id={report.id} />
                    {
                      report.tab === 'Log' && <ReportLog onAnyDataChange={onAnyDataChange} report={report} onLogLoaded={onLogLoaded} />
                    }
                    {
                      report.tab === 'Score' && <ReportBody report={report} />
                    }
                  </>
                )
              }
            </CollapsedBox>
          </div>
        )
      )}
      {
        (innerEditMode || isEditMode) && (
          <EditFooter offEditMode={offEditModeBody} innerEditMode={innerEditMode} onAddNewReport={onAddNewReport} reports={reports} onAnyDataChange={onAnyDataChange} />
        )
      }
    </div>
  )
};

export default Body;
