GuidedActionCreator = require 'actions/abstract/GuidedActionCreator'
ActionTypes = require 'constants/ActionTypes'
DashboardAPI = require 'api/DashboardAPI'

class ConversationEachHourByChannelActionCreator extends GuidedActionCreator
  get: =>
    @dispatch type: ActionTypes.CONVERSATION_EACH_HOUR_BY_CHANNEL_REQUESTING

    DashboardAPI.getConversationsEachHourByChannel(
      (json) =>
        if json.success
          @dispatch
            type: ActionTypes.CONVERSATION_EACH_HOUR_BY_CHANNEL_SUCCESS
            payload: json.data
        else
          @dispatch
            type: ActionTypes.CONVERSATION_EACH_HOUR_BY_CHANNEL_ERROR
            payload: json.error
      (response) =>
        msg = 'An error occurred while getting last days by shift statistic'
        @dispatch
          type: ActionTypes.CONVERSATION_EACH_HOUR_BY_CHANNEL_ERROR
          payload: msg
    )

module.exports = ConversationEachHourByChannelActionCreator
