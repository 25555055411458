ProfileAPI = require 'api/ProfileAPI'
Dispatcher = require '../dispatcher/Dispatcher'
ActionTypes = require 'constants/ActionTypes'

ProfileActionCreator =
  get: ->
    Dispatcher.dispatch type: ActionTypes.PROFILE_GET_REQUESTING

    ProfileAPI.get(
      (json) ->
        if json
          Dispatcher.dispatch
            type: ActionTypes.PROFILE_GET_SUCCESS
            payload: json
          
          {role_names, id} = json
          isTeamLead = role_names.includes 'csr_teamlead'
          
          if isTeamLead
            Dispatcher.dispatch
              type: ActionTypes.MQTT_SUBSCRIBE_GLOBAL
              payload:
                topic: "smstool/shift_agents/update"
                actionType: ActionTypes.MQTT_GLOBAL_WFH_TEAMLEAD_SHIFT_USERS_UPDATING
                initial: true
          else
            Dispatcher.dispatch
              type: ActionTypes.MQTT_SUBSCRIBE_GLOBAL
              payload:
                topic: "smstool/shift_agents/#{json.id}/update"
                actionType: ActionTypes.MQTT_GLOBAL_WFH_SHIFT_USERS_UPDATING
                initial: true

        else
          Dispatcher.dispatch
            type: ActionTypes.PROFILE_GET_FAILURE
            payload: 'An error occurred while getting self data'

      (error) ->
        console.error error
        Dispatcher.dispatch
          type: ActionTypes.PROFILE_GET_FAILURE
            payload: 'An error occurred while getting self data'
    )
    

module.exports = ProfileActionCreator
