{ Fragment, Component } = require 'react';
browserHistory = require './../../../history'

AuthStore = require 'stores/AuthStore'

BaseComponent = require '../../BaseComponent'
RankingRulesComponent = require('../RankingRulesComponent').default

{ menus } = require 'root/config'

accessConfig = {}
menus.forEach (item) ->
  if item.key == 'ranking_rules'
    accessConfig =
      toPage: item.accessToPage || null

class RankingRulesPageComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  componentWillMount: ->
    if accessConfig.toPage
      if accessConfig.toPage.indexOf(AuthStore.user.role) == -1
        browserHistory.default.push '/'

  render: ->
    React.createElement(RankingRulesComponent, null)

module.exports = RankingRulesPageComponent
