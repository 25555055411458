_ = require 'underscore'
{ Link } = require 'react-router-dom'

browserHistory = require '../../../history'

SearchInputComponent = require 'components/shared/SearchInputComponent/SearchInputComponent'
BaseComponent = require 'components/BaseComponent'
CONVERSATION_STATUS = require 'constants/ConversationStatuses'
CustomersAPI = require 'api/CustomersAPI'
CustomerListComponent = require '../CustomerListComponent/CustomerListComponent'
CustomersStore = require 'stores/CustomersStore'
CustomersActionCreator = require 'actions/CustomersActionCreator'

AuthStore = require 'stores/AuthStore'

{ ROLES } = require 'constants/Agents'
{ menus } = require 'root/config'

styles = require './customers.styl'

denieConfig = menus.reduce(
  (result, item) ->
    if item.key == 'customers'
      result.roles = item.deniedAccessToPage
    result
  {}
)

class CustomersComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @state =
      query: ''
      limit: 10
      offset: 0
      init: true
      customers: []
      searchInit: true
      searchLoading: true
      searchEnabled: true
      loading: false
      foundedIds: []
    @initComponent()

  dependsOnStores: [CustomersStore]

  componentWillMount: ->
    if denieConfig.roles && denieConfig.roles.length > 0 && denieConfig.roles.includes(ROLES[AuthStore.user.role].value)
        browserHistory.default.push '/'

  componentDidMount: ->
    super()
    setTimeout(
      =>
        CustomersActionCreator.getList(
          {
            query_string: @state.query
            limit: @state.limit
            offset: @state.offset
          }
          true
        )
      10
    )

  getState: ->
    {
      init: !CustomersStore.customers.length
      customers: CustomersStore.customers
      loading: CustomersStore.customerListLoading
      error: CustomersStore.customerListError
      isFinish: CustomersStore.total <= @state.offset
    }

  onSearch: (query) =>
    CustomersActionCreator.getList(
      {
        query_string: query
        limit: @state.limit
        offset: 0
      }
      true
    )
    searchEnabled = query?
    @setState
      searchEnabled: query?
      query: query
      searchInit: query?
      limit: 10
      offset: 0

  showMore: () =>
    offset = @state.offset + @state.limit
    CustomersActionCreator.getList(
      {
        query_string: @state.query
        limit: @state.limit
        offset
      }
      false
    )
    @setState {
      offset
      searchLoading: true
    }

  render: ->
    React.createElement("div", {"className": (styles.container)},
      React.createElement("div", {"className": (styles.header)},
        (
          React.createElement("div", {"className": (styles.searchline)},
            React.createElement(SearchInputComponent, { \
              "defaultValue": (scope?.queryString),  \
              "onChange": (@onSearch),  \
              "onClear": (R.partial(@onSearch, [''])),  \
              "placeholder": "Search by tags, statuses, order#",  \
              "clearCross": (true),  \
              "folder": (@props.folder),  \
              "autofocus": true
            })
          )
        ),
        (if @state.searchEnabled
          React.createElement("div", {"className": styles.search_result_amount},
            ("Results: #{@state.customers.length}#{if @state.customers.length >= @state.limit then "+" else ""}")
          )
        ),
        React.createElement("div", {"className": (styles.buttons)},
          React.createElement(Link, {"className": (styles.button_add), "to": '/customers/new'},
            React.createElement("div", {"className": (styles.button_icon)}, React.createElement("div", {"className": (styles.plus)})),
            React.createElement("span", {"className": (styles.button_label)}, """
              Add new client
""")
          )
        )
      ),
      React.createElement(CustomerListComponent, { \
        "customers": (@state.customers),  \
        "loading": (@state.init && @state.loading),  \
        "showMoreLoading": (!@state.init && @state.loading && @state.searchEnabled),  \
        "error": (@state.error),  \
        "loadMore": (@showMore),  \
        "finish": (@state.isFinish)
      })
    )

module.exports = CustomersComponent
