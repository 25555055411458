import React from 'react';
import styles from './styles.styl'


const ChatSuggestionPopup = ({ onClose }) => {
  return (
    <div className={styles.modal}>
      <i className={styles.close} onClick={() => onClose()} />
      <h3 className={styles.title}>Team Player Alert!</h3>
      <p className={styles.text}>You have the lowest number of chats - please, support your teammates by taking a new chat :)</p>
    </div>
  );
}

export default ChatSuggestionPopup;
