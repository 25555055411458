BaseStore    = require './BaseStore'
ActionTypes  = require '../constants/ActionTypes'

actionsMap =
  "#{ActionTypes.AGENT_STATISTIC_REQUESTING}": ->
    @loading = true
    @error = ''
    @emitChange()
  "#{ActionTypes.AGENT_STATISTIC_SUCCESS}": (data) ->
    @loading = false
    @statistic = data.statistic
    @pageCount = data.pageCount
    @emitChange()
  "#{ActionTypes.AGENT_STATISTIC_FAIL}": (message) ->
    @loading = false
    @statistic = []
    @error = message
    @emitChange()
  "#{ActionTypes.AGENT_STATISTIC_CLEAR}": ->
    @error = ''
    @statistic = []
    @emitChange()
  "#{ActionTypes.AGENT_STATISTIC_UPDATE_REQUESTING}": ->
    @error = ''
    @loading = true
    @emitChange()
  "#{ActionTypes.AGENT_STATISTIC_UPDATE_SUCCESS}": (data) ->
    @loading = false
    @statistic.forEach (shift) ->
      if shift.id == data.id
        shift[data.changedField] = data.value 
        if data.changedField == 'end_shift_time'
          shift.is_changed_by_agent ||= {}
          shift.is_changed_by_agent['end_shift_time'] = true
        if data.changedField == 'begin_shift_time'
          shift.is_changed_by_agent ||= {}
          shift.is_changed_by_agent['begin_shift_time'] = true
    @emitChange()
  "#{ActionTypes.AGENT_STATISTIC_UPDATE_FAIL}": (error) ->
    @statistic = []
    @error = error
    @loading = false
    @emitChange()

class AgentStatisticStore extends BaseStore

  constructor: ->
    super()
    @loading = false
    @statistic = []
    @pageCount = 0
    @error = ''
    @registerActions()

  _registerToActions: ({ type, payload }) =>
    actionsMap[type].call @, payload if actionsMap[type]

module.exports = new AgentStatisticStore()
