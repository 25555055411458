config = require '../config'

CustomersAPI =
  getList: (scope, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.customers.getList
      type: 'GET'
      data: scope
      success: successCallback
      error: errorCallback
      timeout: 5000

  get: (id, data, success, error) ->
    $.ajax {
      url: config.urls.customers.get(id)
      type: 'GET'
      data
      success
      error
      timeout: 5000
    }

  create: (attributes, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.customers.create
      data: JSON.stringify(attributes)
      contentType: 'application/json'
      type: 'POST'
      success: successCallback
      error: errorCallback
      timeout: 5000

  update: ({ id, attributes }, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.customers.update(id)
      data: JSON.stringify(attributes)
      contentType: 'application/json'
      type: 'PUT'
      success: successCallback
      error: errorCallback
      timeout: 5000

  batchDestroy: ({ ids, menu }, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.customers.batchDestroy
      contentType: 'application/json'
      data: JSON.stringify({ ids: ids })
      type: 'DELETE'
      success: successCallback
      error: errorCallback

  getPagesCount: ({ per_page }, successCallback, errorCallback) ->
    $.ajax
      url: config.urls.customers.pages_count
      type: 'GET'
      data: { per_page }
      success: successCallback
      error: errorCallback
      timeout: 5000

  startConversation: ({id}, success, error) ->
    $.ajax {
      url: config.urls.customers.startConversation(id)
      type: 'POST',
      success,
      error
    }

  mergeCustomers: ({id, replica_ids}, success, error) =>
    $.ajax {
      url: config.urls.customers.mergeCustomers(id),
      data: JSON.stringify({replica_ids}),
      contentType: 'application/json',
      type: 'POST',
      success,
      error,
      timeout: 5000
    }

  unmergeCustomers: ({id, replica_ids}, success, error) =>
    $.ajax {
      url: config.urls.customers.unmergeCustomers(id),
      data: JSON.stringify({replica_ids}),
      contentType: 'application/json',
      type: 'POST',
      success,
      error,
      timeout: 5000
    }

  getCustomerOrders: (url, data, success, error) ->
    $.ajax {
      url,
      type: 'GET'
      data
      success
      error
      timeout: 10000
    }

  getCRMInfo: (url, success, error) ->
    $.ajax {
      url
      type: 'GET'
      contentType: 'application/json'
      success
      error
      timeout: 20000
    }
  checkSameCustomers: (clientId, success, error) =>
    $.ajax {
      url: config.urls.customers.checkSameCustomers(clientId)
      type: 'GET'
      contentType: 'application/json'
      success
      error
      timeout: 15000
    }
  getCustomersIds: (clientsIds, success, error) ->
    $.ajax {
      url: config.urls.customers.getCustomersIds
      type: 'POST'
      data: JSON.stringify(clientsIds)
      contentType: 'application/json'
      success
      error
      timeout: 15000
    }

module.exports = CustomersAPI
