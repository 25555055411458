browserHistory = require './../history'
config = require '../config'
_ = require 'underscore'
Dispatcher = require '../dispatcher/Dispatcher'
ActionTypes = require 'constants/ActionTypes'
ConversationsAPI = require 'api/ConversationsAPI.coffee'
AgentsActionCreator = require 'actions/AgentsActionCreator'
GuidedActionCreator = require './abstract/GuidedActionCreator'
MessagesActionCreator = require './MessagesActionCreator'
AuthStore = require 'stores/AuthStore.coffee'
CustomersAPI = require 'api/CustomersAPI.coffee'
{ localStorageKey } = require 'constants/CRMPlugin'


class ConversationsActionCreator extends GuidedActionCreator
  getList: (scope) =>
    @dispatch type: ActionTypes.CONVERSATIONS_GET_LIST_REQUESTING, payload: { scope }
    ConversationsAPI.getList scope,
      (conversations) =>
        @dispatch
          type: ActionTypes.CONVERSATIONS_GET_LIST_SUCCESS
          payload: { conversations }
      (error) =>
        @dispatch
          type: ActionTypes.CONVERSATIONS_GET_LIST_FAILURE
          error: true
          payload: { error }

  getFileUrls: (id) =>
    @dispatch type: ActionTypes.GET_FILE_URLS_REQUESTING
    ConversationsAPI.urls id,
      (data) =>
        if data.urls && data.urls.length > 0
          @dispatch
            type: ActionTypes.GET_FILE_URLS_SUCCESS
            payload: { urls: data.urls, conversation: id }
        else
          @dispatch type: ActionTypes.GET_FILE_URLS_ERROR

  downloadAll: (id) =>
    @dispatch type: ActionTypes.DOWNLOAD_ALL_REQUESTING
    ConversationsAPI.inboundUrls id,
      (data) =>
        if data.urls && data.urls.length > 0
          archiveTitle = "conversation_#{id}_archive"
          scope = { title: archiveTitle, urls: data.urls }
          ConversationsAPI.downloadAll scope,
            (data) =>
              if data && data.link
                window.open(config.urls.fileServer.link(data.link), '_blank')
              @dispatch type: ActionTypes.DOWNLOAD_ALL_SUCCESS
            (error) =>
              @dispatch
                type: ActionTypes.DOWNLOAD_ALL_FAILURE
                error: true
                payload: { error }
        else
          @dispatch type: ActionTypes.DOWNLOAD_ALL_SUCCESS
      (error) =>
        @dispatch
          type: ActionTypes.DOWNLOAD_ALL_FAILURE
          error: true
          payload: { error }

  downloadFile: (url, name) =>
    @dispatch type: ActionTypes.DOWNLOAD_FILE_REQUESTING
    ConversationsAPI.downloadFile url, name,
      (data) =>
        if data && data.link
          window.open(data.link, '_blank')
        @dispatch type: ActionTypes.DOWNLOAD_FILE_SUCCESS
      (error) =>
        @dispatch
          type: ActionTypes.DOWNLOAD_FILE_FAILURE
          error: true
          payload: { error }

  getMyList: (scope) =>
    @dispatch type: ActionTypes.CONVERSATIONS_MY_GET_LIST_REQUESTING, payload: { scope }
    ConversationsAPI.getList scope,
      (conversations) =>
        @dispatch
          type: ActionTypes.CONVERSATIONS_MY_GET_LIST_SUCCESS
          payload: { conversations }
      (error) =>
        @dispatch
          type: ActionTypes.CONVERSATIONS_MY_GET_LIST_FAILURE
          error: true
          payload: { error }

  getAllList: (scope) =>
    @dispatch type: ActionTypes.CONVERSATIONS_ALL_GET_LIST_REQUESTING, payload: { scope }
    ConversationsAPI.getList scope,
      (conversations) =>
        @dispatch
          type: ActionTypes.CONVERSATIONS_ALL_GET_LIST_SUCCESS
          payload: { conversations }
      (error) =>
        @dispatch
          type: ActionTypes.CONVERSATIONS_ALL_GET_LIST_FAILURE
          error: true
          payload: { error }

  setLastReadTime: (time) ->
    @dispatch type: ActionTypes.CONVERSATIONS_SET_LAST_READ_TIME, payload: { time }

  # GET
  get: (id, current, isQuietly) =>
    if !isQuietly
      @dispatch type: ActionTypes.CONVERSATIONS_GET_REQUESTING, payload: id
    ConversationsAPI.get id,
      (conversation) =>
        @dispatch type: ActionTypes.CONVERSATIONS_GET_SUCCESS, payload: { conversation }
        @dispatch
          type: ActionTypes.MQTT_UNSUBSCRIBE_ANY
          payload:
            topic: "smstool/customers/#{conversation.customer_id}/active_conversation"
            topicMask: "smstool/customers/+/active_conversation"
            actionType: ActionTypes.MQTT_ACTIVE_CONVERSATION_UPDATING
        @dispatch
          type: ActionTypes.MQTT_UNSUBSCRIBE_ANY
          payload:
            topic: "smstool/customers/#{conversation.customer_id}/active_conversation/customer"
            topicMask: "smstool/customers/+/active_conversation/customer"
            actionType: ActionTypes.MQTT_ACTIVE_CONVERSATION_CUSTOMER_UPDATING
        @dispatch
          type: ActionTypes.MQTT_UNSUBSCRIBE_ANY
          payload:
            topic: "smstool/customers/#{conversation.customer_id}/active_conversation/conversation_items"
            topicMask: "smstool/customers/+/active_conversation/conversation_items"
            actionType: ActionTypes.MQTT_CONVERSATION_ITEMS_UPDATING
        @dispatch
          type: ActionTypes.MQTT_SUBSCRIBE
          payload:
            topic: "smstool/customers/#{conversation.customer_id}/active_conversation"
            actionType: ActionTypes.MQTT_ACTIVE_CONVERSATION_UPDATING
        @dispatch
          type: ActionTypes.MQTT_SUBSCRIBE
          payload:
            topic: "smstool/customers/#{conversation.customer_id}/active_conversation/customer"
            actionType: ActionTypes.MQTT_ACTIVE_CONVERSATION_CUSTOMER_UPDATING
        @dispatch
          type: ActionTypes.MQTT_SUBSCRIBE
          payload:
            topic: "smstool/customers/#{conversation.customer_id}/active_conversation/conversation_items"
            actionType: ActionTypes.MQTT_CONVERSATION_ITEMS_UPDATING
            initial: true
      (error) => 
        @dispatch type: ActionTypes.CONVERSATIONS_GET_FAILURE, error: true, payload: error
        @dispatch type: ActionTypes.CUSTOMER_ORDERS_FAILURE, error: true, payload: 'An error occurred while getting customer orders'
        @dispatch type: ActionTypes.CUSTOMER_CRM_FAILURE, error: true, payload: 'An error occurred while getting CRM data'

  # CREATE
  create: (attributes) =>
    @dispatch type: ActionTypes.CONVERSATIONS_CREATE_REQUESTING, payload: attributes
    ConversationsAPI.create attributes,
      (conversation) =>
        @dispatch type: ActionTypes.CONVERSATIONS_CREATE_SUCCESS, payload: conversation
        browserHistory.default.push "/conversations/all_conversations/#{conversation.id}"
      (error) => @dispatch type: ActionTypes.CONVERSATIONS_CREATE_FAILURE, error: true, payload: error

  # UPDATE
  update: (id, updateAttributes) =>
    @dispatch type: ActionTypes.CONVERSATION_UPDATE_REQUESTING, payload: { id, updateAttributes }
    ConversationsAPI.update id, updateAttributes,
      (conversation) =>
        if _.isEqual { status: 'closed' }, updateAttributes
          allConfigs = localStorage.getItem(localStorageKey)
          try
            allConfigs = JSON.parse(allConfigs) || {}
          catch err
            allConfigs = {}
          delete allConfigs["conv-#{id}"]
          localStorage.setItem(localStorageKey, JSON.stringify(allConfigs))
          @dispatch type: ActionTypes.CONVERSATION_UPDATE_SUCCESS, payload: { conversation, updateClosed: true }
        else
          @dispatch type: ActionTypes.CONVERSATION_UPDATE_SUCCESS, payload: { conversation }
      (error) => @dispatch type: ActionTypes.CONVERSATION_UPDATE_FAILURE, error: true, payload: { error, updateAttributes }

  # UPDATE CHANNEL
  updateChannel: (id, channel) =>
    @dispatch type: ActionTypes.CONVERSATION_CHANNEL_UPDATE, payload: { id, channel }

  saveLocal: (conversations) =>
    conversations = _.flatten [conversations]
    @dispatch
      type: ActionTypes.CONVERSATIONS_SAVE_LOCAL
      payload: { conversations }

  # DESTROY
  batchDestroy: (ids, menu) =>
    @dispatch type: ActionTypes.CONVERSATIONS_BATCH_DESTROY_REQUESTING, payload: { ids, menu }
    ConversationsAPI.batchDestroy { ids, menu },
      (data) =>
        @dispatch
          type: ActionTypes.CONVERSATIONS_BATCH_DESTROY_SUCCESS
          payload: data
        browserHistory.default.push('/conversations')
      (error) => @dispatch type: ActionTypes.CONVERSATIONS_BATCH_DESTROY_FAILURE, error: true, payload: error.responseJSON

  assignMostWaiting: =>
    agent_id = AuthStore.user.id

    onError = (error) =>
      @dispatch
        type: ActionTypes.CONVERSATIONS_ASSIGN_MOST_WAITING_FAILURE
        error: true
        payload: error
      setTimeout ConversationsActionCreator.hideMostWaitingError, 2500

    @dispatch type: ActionTypes.CONVERSATIONS_ASSIGN_MOST_WAITING_REQUESTING
    ConversationsAPI.assignMostWaiting agent_id,
      (response) =>
        if response.success
          conversation = response.conversation
          @dispatch
            type: ActionTypes.CONVERSATIONS_ASSIGN_MOST_WAITING_SUCCESS
            payload: conversation
          browserHistory.default.push "/conversations/my_conversations/#{conversation.id}"
        else
          onError(response.failure)
      (error) =>
        onError(error.responseJSON)

  rejectReservation: (conversationId) =>
    @dispatch type: ActionTypes.CONVERSATIONS_REJECT_RESERVATION_REQUESTING
    ConversationsAPI.rejectReservation(JSON.stringify({ conversation_id: conversationId }),
      (json) =>
        if json.success
          @dispatch type: ActionTypes.CONVERSATIONS_REJECT_RESERVATION_SUCCESS
        else
          @dispatch
            type: ActionTypes.CONVERSATIONS_REJECT_RESERVATION_FAILURE
            payload: json.error
      =>
        @dispatch
          type: ActionTypes.CONVERSATIONS_REJECT_RESERVATION_FAILURE
          payload: 'An error occurred while reject reservation of conversation'
    )


  hideMostWaitingError: =>
    @dispatch type: ActionTypes.CONVERSATIONS_ASSIGN_MOST_WAITING_FAILURE_HIDE

  assignToAgent: (conversation_ids, agent_ids) =>
    @dispatch type: ActionTypes.CONVERSATIONS_ASSIGN_TO_AGENT_REQUESTING
    ConversationsAPI.assignToAgent { conversation_ids, agent_ids },
      (json) =>
        @dispatch type: ActionTypes.CONVERSATIONS_ASSIGN_TO_AGENT_SUCCESS
      (error) =>
        @dispatch
          type: ActionTypes.CONVERSATIONS_ASSIGN_TO_AGENT_FAILURE
          error: true
          payload: error.responseJSON

  unassignAgent: (conversation_id, agent_id) =>
    @dispatch type: ActionTypes.CONVERSATIONS_UNASSIGN_AGENT_REQUESTING, payload: { agent_id }
    ConversationsAPI.unassignAgent { conversation_id, agent_id },
      (conversation) =>
        @dispatch
          type: ActionTypes.CONVERSATIONS_UNASSIGN_AGENT_SUCCESS
          payload: { conversation }
      (errors) =>
        @dispatch
          type: ActionTypes.CONVERSATIONS_UNASSIGN_AGENT_FAILURE
          error: true
          payload: errors

  clearCurrentConversation: () =>
    @dispatch type: ActionTypes.CONVERSATIONS_CLEAR_CURRENT_CONVERSATION

  searchMessages: (conversationId, queryString, searchType) =>
    # TODO: Remove using of another action creator!!!
    new MessagesActionCreator(@guid).getList { conversationId, queryString, searchType }

  startConversationByCustomerId: (id, onSuccess) ->
    Dispatcher.dispatch type: ActionTypes.CUSTOMER_START_CONVERSATION_REQUESTING, payload: { id }
    CustomersAPI.startConversation { id },
      (response) =>
        { conversation } = response
        Dispatcher.dispatch type: ActionTypes.CUSTOMER_START_CONVERSATION_SUCCESS, payload: { conversation }
        onSuccess?(conversation)
      (error) => Dispatcher.dispatch type: ActionTypes.CUSTOMER_START_CONVERSATION_FAILURE, payload: error.responseJSON

  getClosedConversations: (conversationId) ->
    Dispatcher.dispatch type: ActionTypes.CONVERSATIONS_CLOSED_REQUESTING
    ConversationsAPI.getClosedConversations conversationId,
      (json) ->
        if json.success
          Dispatcher.dispatch(
            type: ActionTypes.CONVERSATIONS_CLOSED_SUCCESS
            payload: json.data
          )
        else
          Dispatcher.dispatch(
            type: ActionTypes.CONVERSATIONS_CLOSED_ERROR
            payload: json.error
          )
      (error) ->
        Dispatcher.dispatch(
          type: ActionTypes.CONVERSATIONS_CLOSED_ERROR
          payload: 'An error occurred while fetching closed conversations'
        )

  getClosedConversationsForPreview: (conversationId, date) ->
    Dispatcher.dispatch
      type: ActionTypes.CONVERSATIONS_CLOSED_RANGE_REQUESTING
      payload: date

    splitDate = date.split('-')
    data = {
      year: splitDate[0]
      month: splitDate[1]
    }
    ConversationsAPI.getClosedConversationsForPreview(
      conversationId
      data
      (json) ->
        if json.success
          Dispatcher.dispatch
            type: ActionTypes.CONVERSATIONS_CLOSED_RANGE_SUCCESS
            payload:
              data: json.data
              range: date
        else
          Dispatcher.dispatch(
            type: ActionTypes.CONVERSATIONS_CLOSED_RANGE_FAILURE
            payload:
              msg: json.error
              range: date
          )
      (error) ->
        Dispatcher.dispatch(
          type: ActionTypes.CONVERSATIONS_CLOSED_RANGE_FAILURE
          payload:
            msg: 'An error occurred while fetching closed conversations'
            range: date
        )
    )

  deferDormantStatus: (conversationId) ->
    ConversationsAPI.deferDormantStatus(conversationId)

  clearFilterByAgent: () ->
    Dispatcher.dispatch
      type: ActionTypes.CONVERSATIONS_FILTER_AGENT
      payload: null

  filterByAgent: (agentId) ->
    Dispatcher.dispatch
      type: ActionTypes.CONVERSATIONS_FILTER_AGENT
      payload: agentId
    unless agentId
      agentId = '+'
      Dispatcher.dispatch
        type: ActionTypes.MQTT_UNSUBSCRIBE_ANY
        payload:
          topic: "smstool/active_conversations/+/#{agentId}"
          topicMask: "smstool/active_conversations/+/+"
          actionType: ActionTypes.MQTT_ALL_ACTIVE_CONVERSATIONS_UPDATING
      Dispatcher.dispatch
        type: ActionTypes.MQTT_SUBSCRIBE
        payload:
          topic: "smstool/all_conversations/+"
          actionType: ActionTypes.MQTT_ALL_ACTIVE_CONVERSATIONS_UPDATING
          initial: true
    else
      Dispatcher.dispatch
        type: ActionTypes.MQTT_UNSUBSCRIBE
        payload:
          topic: "smstool/all_conversations/+"
          actionType: ActionTypes.MQTT_ALL_ACTIVE_CONVERSATIONS_UPDATING
      Dispatcher.dispatch
        type: ActionTypes.MQTT_UNSUBSCRIBE_ANY
        payload:
          topic: "smstool/active_conversations/+/#{agentId}"
          topicMask: "smstool/active_conversations/+/+"
          actionType: ActionTypes.MQTT_ALL_ACTIVE_CONVERSATIONS_UPDATING
      Dispatcher.dispatch
        type: ActionTypes.MQTT_SUBSCRIBE
        payload:
          topic: "smstool/active_conversations/+/#{agentId}"
          actionType: ActionTypes.MQTT_ALL_ACTIVE_CONVERSATIONS_UPDATING
          initial: true

  searchReset: ->
    Dispatcher.dispatch type: ActionTypes.CONVERSATIONS_SEARCH_RESET

  searchMyReset: ->
    Dispatcher.dispatch type: ActionTypes.CONVERSATIONS_MY_SEARCH_RESET

  searchAllReset: ->
    Dispatcher.dispatch type: ActionTypes.CONVERSATIONS_ALL_SEARCH_RESET

  setDefaultAllState: ->
    Dispatcher.dispatch type: ActionTypes.CONVERSATIONS_SET_DEFAULT_ALL_STATE

  setDefaultMyState: ->
    Dispatcher.dispatch type: ActionTypes.CONVERSATIONS_SET_DEFAULT_MY_STATE

  setDefaultUnassignedState: ->
    Dispatcher.dispatch type: ActionTypes.CONVERSATIONS_SET_DEFAULT_UNASSIGNED_STATE

  subscribeMyConversations: (agentId) ->
    Dispatcher.dispatch
      type: ActionTypes.MQTT_SUBSCRIBE
      payload:
        topic: "smstool/active_conversations/+/#{agentId}"
        actionType: ActionTypes.MQTT_MY_ACTIVE_CONVERSATIONS_UPDATING
        initial: true

  unSubscribeMyConversations: (agentId) ->
    Dispatcher.dispatch
      type: ActionTypes.MQTT_UNSUBSCRIBE
      payload:
        topic: "smstool/active_conversations/+/#{agentId}"
        actionType: ActionTypes.MQTT_MY_ACTIVE_CONVERSATIONS_UPDATING

  subscribeAllConversations: () ->
    Dispatcher.dispatch
      type: ActionTypes.MQTT_SUBSCRIBE
      payload:
        topic: "smstool/all_conversations/+"
        actionType: ActionTypes.MQTT_ALL_ACTIVE_CONVERSATIONS_UPDATING
        initial: true

  unSubscribeAllConversations: () ->
    Dispatcher.dispatch
      type: ActionTypes.MQTT_UNSUBSCRIBE
      payload:
        topic: "smstool/all_conversations/+"
        actionType: ActionTypes.MQTT_ALL_ACTIVE_CONVERSATIONS_UPDATING

  subscribeUnassignedConversations: () ->
    Dispatcher.dispatch
      type: ActionTypes.MQTT_SUBSCRIBE
      payload:
        topic: "smstool/unassigned_conversations/+"
        actionType: ActionTypes.MQTT_UNASSIGNED_ACTIVE_CONVERSATIONS_UPDATING
        initial: true

  unSubscribeUnassignedConversations: () ->
    Dispatcher.dispatch
      type: ActionTypes.MQTT_UNSUBSCRIBE
      payload:
        topic: "smstool/unassigned_conversations/+"
        actionType: ActionTypes.MQTT_UNASSIGNED_ACTIVE_CONVERSATIONS_UPDATING

  subscribeDashboardConversations: () ->
    Dispatcher.dispatch
      type: ActionTypes.MQTT_SUBSCRIBE
      payload:
        topic: "smstool/dashboard_conversations"
        actionType: ActionTypes.MQTT_DASHBOARD_CONVERSATIONS_UPDATING
        initial: true

  unSubscribeDashboardConversations: () ->
    Dispatcher.dispatch
      type: ActionTypes.MQTT_UNSUBSCRIBE
      payload:
        topic: "smstool/dashboard_conversations"
        actionType: ActionTypes.MQTT_DASHBOARD_CONVERSATIONS_UPDATING

module.exports = ConversationsActionCreator
