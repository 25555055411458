moment = require 'moment'
PersonalizedStore = require 'stores/abstract/PersonalizedStore'
ConversationsActionCreator = require 'actions/ConversationsActionCreator'
ActionTypes = require 'constants/ActionTypes'
ConversationsAPI = require 'api/ConversationsAPI.coffee'

addClosedConversation = (closedConversations, conversation) ->
  isAlreadyClosed = closedConversations.filter (c) ->
    c.id == conversation.id
  isAlreadyClosed = !!isAlreadyClosed.length
  closedConversations.push conversation unless isAlreadyClosed

class ConversationStore extends PersonalizedStore
  constructor: ->
    super()
    @conversation = undefined
    @loading =
      conversation: false
      searchMessages: false
      closedLoading: false
      updating: false
    @errors =
      takeNew: null
      loadErrror: false
    @foundMessages = []

    @conversationId = null

    @groupedPreviews = []
    @closedPreviewsLoading = false
    @closedRangeLoading = {}
    @closedRangeErrors = {}

    @lastReadTime = null

    @state = {
      openConversationLoading: false
    }
    @openConversation = null
    @registerActions()

  addConversationToGroup: (conversation) =>
    lastIndex = @groupedPreviews.length - 1
    if lastIndex > -1
      unless @groupedPreviews[lastIndex].conversations
        @groupedPreviews[lastIndex].conversations = []
      @groupedPreviews[lastIndex].conversations.push conversation

  getActionCreator: ->
    new ConversationsActionCreator()

  _registerToActions: ({ type, payload, guid }) =>
    updatedConversation = if @conversation && payload
      if payload.conversation && payload.conversation.id == @conversation.id
        payload.conversation
      else if payload.conversations
        _.findWhere payload.conversations, id: @conversation.id
      else
        null

    switch type
      when ActionTypes.MQTT_ACTIVE_CONVERSATION_UPDATING
        if Object.keys(payload.message).length > 0
          @openConversation ||= {}
          Object.keys(payload.message).forEach (key) =>
            @openConversation[key] = payload.message[key]
          if payload.message.last_channel || payload.message.last_message 
            if @conversation
              @conversation['active_channel'] = payload.message?.last_channel || payload.message?.last_message?.channel
            if @openConversation
              @openConversation['active_channel'] = payload.message?.last_channel || payload.message?.last_message?.channel
          @openConversation = null unless @openConversation.id
        else
          if @openConversation
            @openConversation.status = 'closed'
            @addConversationToGroup(@openConversation)
            if @conversation && @openConversation.id == @conversation.id
              @conversation.status = 'closed'
            @openConversation = null
        @emitChange()

      when ActionTypes.MQTT_ACTIVE_CONVERSATION_CUSTOMER_UPDATING
        return unless @openConversation
        @openConversation.customer ||= {}
        Object.keys(payload.message).forEach (key) =>
          @openConversation.customer[key] = payload.message[key]
        @emitChange()

      when ActionTypes.CONVERSATIONS_SET_LAST_READ_TIME
        @lastReadTime = payload?.time
        @emitChange()

      when ActionTypes.CONVERSATIONS_CLOSED_REQUESTING
        @loading.closedLoading = true
        @groupedPreviews = []
        @emitChange()

      when ActionTypes.CONVERSATIONS_CLOSED_SUCCESS
        @loading.closedLoading = false
        @groupedPreviews = payload
        @emitChange()

      when ActionTypes.CONVERSATIONS_CLOSED_ERROR
        @loading.closedLoading = false
        @emitChange()

      when ActionTypes.CONVERSATION_UPDATE_REQUESTING
        @loading.updating = true
        @emitChange()

      when ActionTypes.CONVERSATION_UPDATE_SUCCESS
        @conversation = updatedConversation
        @loading.updating = false
        @emitChange()

      when ActionTypes.CONVERSATION_UPDATE_FAILURE
        @loading.updating = false
        @emitChange()

      when ActionTypes.CONVERSATION_CHANNEL_UPDATE
        return unless @openConversation
        @conversation['active_channel'] = payload.channel
        @openConversation['active_channel'] = payload.channel
        @emitChange()

      when ActionTypes.CONVERSATIONS_CLOSED_RANGE_REQUESTING
        @closedRangeLoading[payload.range] = true
        delete @closedRangeErrors[payload.range]
        @emitChange()
      when ActionTypes.CONVERSATIONS_CLOSED_RANGE_SUCCESS
        delete @closedRangeLoading[payload.range]
        @groupedPreviews = @groupedPreviews.map (preview) ->
          return preview if preview.date != payload.range
          preview.conversations = payload.data
          preview
        @emitChange()
      when ActionTypes.CONVERSATIONS_CLOSED_RANGE_FAILURE
        delete @closedRangeLoading[payload.range]
        @closedRangeErrors[payload.range] = payload.error
        @emitChange()

      when ActionTypes.CONVERSATIONS_GET_REQUESTING
        @loading.conversation = true
        @errors.loadErrror = false
        @conversation = undefined
        @conversationId = payload
        @emitChange()

      when ActionTypes.CONVERSATIONS_GET_SUCCESS
        return if @conversationId != payload.conversation.id

        @loading.conversation = false
        @conversation = payload.conversation
        @openConversation = payload.conversation.customer.opened_conversation
        @conversation['active_channel'] = payload.conversation.last_channel
        if @openConversation
          @openConversation['active_channel'] = payload.conversation.last_channel
        @emitChange()

      when ActionTypes.CONVERSATIONS_GET_FAILURE
        @loading.conversation = false
        @conversation = undefined
        @errors.loadErrror = true
        @emitChange()

    if @isPersonalAction(guid) || updatedConversation
      switch type

        when ActionTypes.CONVERSATIONS_CLEAR_CURRENT_CONVERSATION
          @conversation = undefined
          @emitChange()

        when ActionTypes.CONVERSATIONS_SAVE_LOCAL
          @conversation = updatedConversation
          @emitChange()

        when ActionTypes.CONVERSATIONS_UNASSIGN_AGENT_REQUESTING
          @conversation.users = _.reject @conversation.users, (obj) -> obj.id == payload.agent_id
          @emitChange()

        when ActionTypes.CONVERSATIONS_UNASSIGN_AGENT_SUCCESS
          @conversation = updatedConversation
          @emitChange()

        when ActionTypes.CONVERSATIONS_ASSIGN_TO_AGENT_SUCCESS
          @conversation = updatedConversation
          @emitChange()

        when ActionTypes.CONVERSATIONS_ASSIGN_MOST_WAITING_FAILURE
          @errors.takeNew = payload
          @emitChange()

        when ActionTypes.CONVERSATIONS_ASSIGN_MOST_WAITING_FAILURE_HIDE
          @errors.takeNew = null
          @emitChange()

        when ActionTypes.MESSAGES_GET_LIST_REQUESTING
          @loading.searchMessages = true
          @emitChange()

        when ActionTypes.MESSAGES_GET_LIST_SUCCESS
          @loading.searchMessages = false
          @foundMessages = payload.messages
          @emitChange()

module.exports = new ConversationStore()
