import CRMInfoModuleStore from 'stores/CRMInfoModuleStore';
import CustomerOrdersStore from 'stores/CustomerOrdersStore';

import Dispatcher from '../dispatcher/Dispatcher';

import {
  getCRMInfo,
  checkSameCustomers,
  getCustomersIds,
  getCustomerOrders
} from '../api/CustomersAPI';

import ActionTypes from '../constants/ActionTypes';


const mapGetCommunicatedData = data => {
  const { results = [] } = data;

  return results.map(item => {
    const { 
      _id: entityId,
      created_at: lastMessageCreatedAt,
      to_user: lastMessageTo,
      order_info: { number },
      sender_name: lastMessageFrom,
    } = item;
    return {
      entityId,
      lastMessageCreatedAt,
      lastMessageFrom,
      lastMessageTo,
      number,
    }
  });
};

const mapGetCRMInfoData = data => {
  const { 
    cabinet_link: CabinetLink,
    order_link: OrderLink,
    order_list_link: OrderlistLink,
    pricing_link: pricingLink,
    site: site_acronym,
    ref_link: referralLink,
    subscription_link: SubscriptionLink,
    referral_code: clientKey,
    _id: clientId,
    paid_orders_count: paidOrdersCount,
    balance,
    currency_symbol: currencySymbol,
    available_balance: availableBalance,
    exp_data: expData
  } = data;
  ['balance', 'available_balance', 'cabinet_link', 'order_link', 'pricing_link', 'site', 'ref_link', 'subscription_link', 'referral_code'].forEach(item => delete data[item]);
  return {
    CabinetLink,
    OrderLink,
    OrderlistLink,
    pricingLink,
    site_acronym,
    referralLink,
    SubscriptionLink,
    clientKey,
    clientId,
    isLead: !!paidOrdersCount || false,
    balance: `${currencySymbol}${Number(availableBalance || balance).toFixed(2)}`,
    ...data,
  }
}

const CRMInfoModuleActionCreator = {
  getCustomerData(customer, infoUrl, communicationUrl, options) {
    if (options == null) {
      options = {};
    }

    if ((customer && customer.id !== (CRMInfoModuleStore.currentCustomer || {}).id) || options.forceUpdate) {
      const errMsg = 'An error occurred while getting CRM data';

      Dispatcher.dispatch({
        type: ActionTypes.CUSTOMER_CRM_REQUESTING,
        payload: {
          customer
        }
      });

      const requests = [];

      requests.push(getCRMInfo(infoUrl));
      requests.push(getCRMInfo(communicationUrl));

      return Promise.all(requests).then((values = []) => { 
        const { 0: crmInfoJson, 1: communicationJson } = values;

        if (!crmInfoJson || !communicationJson) {
          return Dispatcher.dispatch({
            type: ActionTypes.CUSTOMER_CRM_FAILURE,
            payload: json.error || errMsg
          });
        }

        Dispatcher.dispatch({
          type: ActionTypes.CUSTOMER_CRM_SUCCESS,
          payload: {
            data: mapGetCRMInfoData(crmInfoJson),
            customer
          }
        });

        Dispatcher.dispatch({
          type: ActionTypes.CUSTOMER_COMMUNICATIONS_SUCCESS,
          payload: {
            data: mapGetCommunicatedData(communicationJson),
            customer
          }
        });
      })
      .catch((errors) => Dispatcher.dispatch({
        type: ActionTypes.CUSTOMER_CRM_FAILURE,
        payload: errMsg
      }));
    }
  },
  get(customer, url, options) {
    if (options == null) {
      options = {};
    }
    if ((customer.id !== (CRMInfoModuleStore.currentCustomer || {}).id) || options.forceUpdate) {
      const errMsg = 'An error occurred while getting CRM data';
      Dispatcher.dispatch({
        type: ActionTypes.CUSTOMER_CRM_REQUESTING,
        payload: {
          customer
        }
      });
      return getCRMInfo(
        url,
        function (json) {
          if (json) {
            return Dispatcher.dispatch({
              type: ActionTypes.CUSTOMER_CRM_SUCCESS,
              payload: {
                data: mapGetCRMInfoData(json),
                customer
              }
            });
          } else {
            return Dispatcher.dispatch({
              type: ActionTypes.CUSTOMER_CRM_FAILURE,
              payload: json.error || errMsg
            });
          }
        },
        error => Dispatcher.dispatch({
          type: ActionTypes.CUSTOMER_CRM_FAILURE,
          payload: errMsg
        }));
    }
  },

  clearOrders() {
    Dispatcher.dispatch({
      type: ActionTypes.CUSTOMER_ORDERS_CLEAR,
    });
  },

  clearCustomerData() {
    Dispatcher.dispatch({
      type: ActionTypes.CUSTOMER_CRM_CLEAR,
    });
  },

  clearAllData() {
    Dispatcher.dispatch({
      type: ActionTypes.CUSTOMER_CLEAR,
    });
    Dispatcher.dispatch({
      type: ActionTypes.CUSTOMER_CRM_CLEAR,
    });
    Dispatcher.dispatch({
      type: ActionTypes.CUSTOMER_ORDERS_CLEAR,
    });
  },

  setError() {
    Dispatcher.dispatch({
      type: ActionTypes.CUSTOMER_CRM_FAILURE,
      payload: 'No CRM data for this customer'
    });
  },

  getCommunications(customer, url, options) {
    if ((customer.id !== (CRMInfoModuleStore.currentCustomer || {}).id) || options.forceUpdate) {
      const errMsg = 'An error occurred while getting CRM data';
      Dispatcher.dispatch({
        type: ActionTypes.CUSTOMER_CRM_REQUESTING,
        payload: {
          customer
        }
      });
      return getCRMInfo(
        url,
        function (json) {
          if (json) {
            return Dispatcher.dispatch({
              type: ActionTypes.CUSTOMER_COMMUNICATIONS_SUCCESS,
              payload: {
                data: mapGetCommunicatedData(json),
                customer
              }
            });
          } else {
            return Dispatcher.dispatch({
              type: ActionTypes.CUSTOMER_CRM_FAILURE,
              payload: json.error || errMsg
            });
          }
        },
        error => Dispatcher.dispatch({
          type: ActionTypes.CUSTOMER_CRM_FAILURE,
          payload: errMsg
        }));
    }
  },

  getOrders(customer, url, options) {
    if (options == null) {
      options = {};
    }
    if ((customer.id !== (CustomerOrdersStore.currentCustomer || {}).id) || options.forceUpdate) {
      const errMsg = 'An error occurred while getting customer orders';
      Dispatcher.dispatch({
        type: ActionTypes.CUSTOMER_ORDERS_REQUESTING,
        payload: {
          customer
        }
      });
      return getCustomerOrders(
        url, {},
        function (json) {
          if (json) {
            return Dispatcher.dispatch({
              type: ActionTypes.CUSTOMER_ORDERS_SUCCESS,
              payload: {
                data: json,
                customer
              }
            });
          } else {
            return Dispatcher.dispatch({
              type: ActionTypes.CUSTOMER_ORDERS_FAILURE,
              payload: json.error || errMsg
            });
          }
        },
        error => Dispatcher.dispatch({
          type: ActionTypes.CUSTOMER_ORDERS_FAILURE,
          payload: errMsg
        }));
    }
  },

  setOrdersError() {
    Dispatcher.dispatch({
      type: ActionTypes.CUSTOMER_ORDERS_FAILURE,
      payload: 'No CRM data for this customer'
    });
  },

  getWithTags(data, url, search) {
    const errMsg = 'An error occurred while getting CRM data';
    Dispatcher.dispatch({
      type: ActionTypes.CUSTOMER_CRM_WITH_TAGS_REQUESTING
    });
    return getCustomerOrders(
      url, {},
      function (json) {
        if (json) {
          return Dispatcher.dispatch({
            type: ActionTypes.CUSTOMER_CRM_WITH_TAGS_SUCCESS,
            payload: json
          });
        } else {
          return Dispatcher.dispatch({
            type: ActionTypes.CUSTOMER_CRM_WITH_TAGS_FAILURE,
            payload: json.error || errMsg
          });
        }
      },
      error => Dispatcher.dispatch({
        type: ActionTypes.CUSTOMER_CRM_WITH_TAGS_FAILURE,
        payload: errMsg
      }));
  },
  //   getWithTags: (url, data) ->
  //     errMsg = 'An error occurred while getting CRM data'
  //     Dispatcher.dispatch type: ActionTypes.CUSTOMER_CRM_WITH_TAGS_REQUESTING
  //     getCRMInfo(
  //       url
  //       data
  //       (json) ->
  //         if json.success
  //           Dispatcher.dispatch
  //             type: ActionTypes.CUSTOMER_CRM_WITH_TAGS_SUCCESS
  //             payload: json.data
  //         else
  //           Dispatcher.dispatch
  //             type: ActionTypes.CUSTOMER_CRM_WITH_TAGS_FAILURE
  //             payload: json.error || errMsg
  //       (error) ->
  //         Dispatcher.dispatch
  //           type: ActionTypes.CUSTOMER_CRM_WITH_TAGS_FAILURE
  //           payload: errMsg
  //     )

  getSameCustomers(clientId, success, fail) {
    const errMsg = 'An error occurred while getting CRM data';
    return checkSameCustomers(
      clientId,
      function (json) {
        if (json.results) {
          if (success) {
            return success(json.results);
          }
        } else {
          if (fail) {
            return fail(errMsg);
          }
        }
      },
      function (error) {
        if (fail) {
          return fail(error);
        }
      });
  },
  getCustomersIdsFromClientsIds(clientIds, success, fail) { // get SMSTool customers ids from crm clients ids
    const errMsg = 'An error occurred while getting customers ids';
    return getCustomersIds(
      clientIds,
      function (json) {
        if (json) {
          if (success) {
            return success(json);
          }
        } else {
          if (fail) {
            return fail(errMsg);
          }
        }
      },
      function (error) {
        if (fail) {
          return fail(error);
        }
      });
  }
};

export default CRMInfoModuleActionCreator;