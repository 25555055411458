import React, { useState } from 'react';
import classnames from 'classnames';

import { utils } from '@verdaccio/crminfo';

import { statusMapper } from 'components/rankingRulesModule/RankingModuleInfoBlock/QAInfo/QAInfoItem/constants';

import styles from './index.styl';

const HEAD_BLOCK_MAP = [
  {
    title: 'Type',
    getData: (report) => statusMapper[report.status],
    updateMapper: 'status'
  },
  {
    title: 'Pillar',
    getData: (report) => report.ranking_rule.pillar,
    showOption: (report) => Boolean(report.ranking_rule),
    updateMapper: 'pillar'
  },
  {
    title: 'Issue',
    getData: (report) => report.ranking_rule.issue_type,
    showOption: (report) => Boolean(report.ranking_rule),
    updateMapper: 'issue_type'
  }
];

const ReportBody = ({ report, isLog = false }) => {
  const { ranking_rule, comment, links = [], created_at, author, logType, changedFields = [] } = report;
  const [showFullRule, toggleShowFullRule] = useState(false);
  const [showFullComment, toggleShowFullComment] = useState(false);

  const onToggleShowFullRule = () => {
    toggleShowFullRule(!showFullRule);
  }

  const onToggleShowFullComment = () => {
    toggleShowFullComment(!showFullComment);
  }
  
  return (
    <div className={classnames(styles.report_body, {[[styles['report_body--is_log']]]: isLog})}>
      {
        isLog && (
          <div className={styles.report_body_log_title}>
            <div className={classnames(styles.report_body_log_title__type_tag, styles[`report_body_log_title__type_tag--${logType}`])}>
              {logType}
            </div>
            <div className={styles.report_body_log_title__author}>
              <div>{`${utils.formatTime(created_at, 'ydt')}`}</div>
              <i className={styles.report_body_log_title__author__point}/>
              <div className={styles.report_body_log_title__author__name}>{author}</div>
            </div>
          </div>
        )
      }
      <div className={styles.report_body_head_block}>
        {HEAD_BLOCK_MAP.map((head) => {
          const { showOption = null, getData, title, updateMapper } = head;

          if (showOption && !showOption(report)) return null;

          const value = getData(report);
          return (
            <div className={styles.report_body_head_block_item} key={title}>
              <div className={styles.report_body_head_block_item__title}>{title}</div>
              <div className={classnames(styles.report_body_head_block_item__value, {[[styles['report_body--changed']]]: changedFields.includes(updateMapper)})}>{value}</div>
            </div>
          );
        })}
      </div>
      {
        Boolean(ranking_rule) && (
          <div className={styles.report_body_ranking_rule}>
            {
              !isLog && (
              <div 
                className={classnames(styles.report_body_ranking_rule__priority, styles[`report_body_ranking_rule__priority--${ranking_rule.priority}`])}>
                  {`${ranking_rule.priority} severity`}
              </div>
              )
            }
            <div className={classnames(styles.report_body_ranking_rule__rule, {[[styles['report_body_ranking_rule__rule--full']]]: showFullRule, [[styles['report_body--changed']]]: changedFields.includes('rule') })}>
              <span className={styles.report_body__label}>Rule:</span>{ranking_rule.rule.split('\n').map((item, index) => <div key={index}>{item}</div>)}
            </div>
              {
                Boolean(ranking_rule.rule.length > 60) && (
                  <div className={classnames(styles.report_body_ranking_rule__show_m_l)} onClick={onToggleShowFullRule}>{`Show ${showFullRule ? ' less': ' more'}`}</div>
                )
              }
          </div>
        )
      }
      { links && links.length > 0 && (
        <div>
          <div className={styles.report_body__label}>Links:</div>
          {(links || []).map((link, index) => <a key={index} href={link} target="_blank" className={classnames(styles.report_body__link, {[[styles['report_body--changed']]]: changedFields.includes('links')})}>{link}</a>)}
        </div>
      )
      }
      {
        Boolean(comment) && 
        (
          <div>
            <div className={styles.report_body__label}>Comment:</div>
            <div className={classnames(styles.report_body__comment, {[[styles['report_body__comment--full']]]: showFullComment, [[styles['report_body--changed']]]: changedFields.includes('comment') })}>{comment}</div>
            {
              Boolean(comment.length > 60) && (
                <div className={classnames(styles.report_body_ranking_rule__show_m_l)} onClick={onToggleShowFullComment}>{`Show ${showFullComment ? ' less': ' more'}`}</div>
              )
            }
          </div>
        )
      }
    </div>
  )
};

export default ReportBody;
