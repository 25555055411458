classnames = require 'classnames'
Autocomplete = require 'components/shared/Autocomplete'
styles = require './agent-edit-popup'
{ AUTOCOMPLETE } = require 'constants/Agents'

class AgentEditPopup extends React.Component

  constructor: (props) ->
    super()
    errors = {}
    values = {}
    Object.keys props.fields
      .forEach (key) ->
        values[key] = props.fields[key].value || ''
        errors[key] = props.fields[key].validator props.fields[key].value || ''
    @state =
      errors: errors
      values: values
      country: props.fields.country?.value

  onInputHandler: (key, value) =>
    country = @state.country
    country = value if key == 'country'
    error = @props.fields[key].validator value
    newErrorState = Object.assign @state.errors, "#{key}": error
    values = Object.assign @state.values, "#{key}": value
    @setState errors: newErrorState, values: values, country: country

  isSaveDisabled: =>
    state = @state
    errors = Object.keys @state.errors
      .filter (key) ->
        state.errors[key]
    !!errors.length

  getCountryKey: (key) =>
    @state.country + key

  saveData: =>
    @props.saveData(@state.values)

  render: ->
    fields = @props.fields
    { disableCancel, enableInfo, disableArrow } = @props
    that = @
    style =
      left: @props.editedElement.clientWidth - 300
      top: (@props.editedElement.offsetTop - @props.editedElement.clientHeight / 2)
    React.createElement("div", {"style": (style), "className": (styles.popup)},
      React.createElement("div", {"className": (styles.popup__content)},
        React.createElement("div", null,
          (if enableInfo
            React.createElement("p", {"key": (1), "className": (styles.popup__info)}, """
              Hey there!""", React.createElement("br", null), """
              You’ve indicated the following as your current location:
""")
          ),
          (Object.keys fields
            .map (key, i) =>
              countryKey = @getCountryKey(key)
              React.createElement("div", {"key": (i), "className": (styles.popup__field)},
                React.createElement(Autocomplete, { \
                  "maxLength": (255),  \
                  "name": (key),  \
                  "onChange": (that.onInputHandler),  \
                  "placeholder": (fields[key].placeholder),  \
                  "defaultValue": (fields[key].value),  \
                  "suggestions": (AUTOCOMPLETE[key] || AUTOCOMPLETE[countryKey] || [])
                }),
                React.createElement("span", {"className": (styles.popup__error)},
                  (that.state.errors[key])
                )
              )
          ),
          (if enableInfo
            React.createElement("p", {"key": (2), "className": (styles.popup__info)}, """
              Please update this information in case it is not relevant anymore.""", React.createElement("br", null), """
              Thank you and have a great shift ahead!
""")
          ),
          React.createElement("div", {"className": (styles.popup__buttons)},
            (unless disableCancel
              React.createElement("button", { \
                "className": (styles.popup__button), "onClick": (@props.closePopup)
              }, """
                Cancel
""")
            ),
            React.createElement("button", { \
              "className": (classnames styles.popup__button, styles.popup__button_save,
                "#{styles.popup__button_disabled}": @isSaveDisabled()
              ),  \
              "onClick": (@saveData unless @isSaveDisabled())
            }, """
              Save
""")
          )
        ),
        React.createElement("div", null,
          React.createElement("iframe", {"src": "https://docs.google.com/forms/d/e/1FAIpQLSeU0v9a23aJRvsIHoE---YAaK5InzZ9icsYHI0ZOeL-JBQgDA/viewform?embedded=true", "width": "640", "height": "289", "frameBorder": "0", "marginHeight": "0", "marginWidth": "0"}, "Loading...")
        )
      ),
      (if !disableArrow
        React.createElement("div", {"className": (styles.popup_arrow)})
      )
    )

module.exports = AgentEditPopup
