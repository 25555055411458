BaseComponent = require 'components/BaseComponent'
SoundNotification = require './SoundNotification'
AgentsActionCreator = require 'actions/AgentsActionCreator'
OnlineStore = require 'stores/OnlineStore'
AuthStore = require 'stores/AuthStore'
styles = require './idle-popup.styl'


class IdlePopup extends BaseComponent
  constructor: (props) ->
    super(props)
    @initComponent()

  dependsOnStores: [OnlineStore]

  onCloseNotification: (id) =>
    AgentsActionCreator.closeNotification id

  render: =>
    if AuthStore.user
      agent = OnlineStore.agents.get(AuthStore.user.id)
      minutesAway = Math.round((new Date() - Date.parse(agent?.last_activity_at)) / (60 * 1000))
      if agent && agent.is_dormant
        (
          React.createElement("div", {"className": (styles.container)},
            (if AuthStore.user.idle_sound
              React.createElement(SoundNotification, null)
            ),
            React.createElement("div", {"className": (styles.modal)},
              React.createElement("span", {"className": (styles.danger_panel)}, "IDLE Notification"),
              React.createElement("p", {"className": (styles.text)}, "You have been inactive for ", React.createElement("b", {"className": (styles.minutes)}, (minutesAway), " minutes")),
              React.createElement("button", {"className": (styles.btn), "onClick": (() => @onCloseNotification(agent.id))}, "Confirm presence")
            )
          )
        )
      else
        null
    else
      null

module.exports = IdlePopup