classnames = require 'classnames'
{Link} = require 'react-router-dom'

BaseAgentStatisticComponent = require '../../shared/BaseAgentStatisticComponent'
{formatNumberToPercent} = require '../../../lib/utils'
QADashboardActionCreator = require '../../../actions/QADashboardActionCreator'
QADashboardStore = require '../../../stores/QADashboardStore'
CustomSelectComponent = require 'components/shared/CustomSelectComponent'
baseStyles = require '../../shared/BaseAgentStatisticComponent/base-agent-statistic'
styles = require './qa-statistic'
{getQueryParams} = require '../../../lib/utils'
LoadableWrapperComponent = require '../../shared/LoadableWrapperComponent/LoadableWrapperComponent'
{TO_BE_RANKED_PERCENT} = require '../../../constants/Ranking'

class QAStatisticComponent extends BaseAgentStatisticComponent

  constructor: (props) ->
    super(props)
    that = @
    @tableFields =
      full_name:
        label: 'Operators'
        presenter: (value, agent) ->
          params = getQueryParams window.location.search.replace '?'
          React.createElement(Link, { \
            "className": (baseStyles.link),  \
            "to": ("/agents/#{agent.id}/shifts")
          },
            (value)
          )
        addBodyCss: -> styles.fullname
        addHeadCss: styles.fullname
        handlers: {}
        isSortable: true
      unique_clients_count:
        label: '# Unique Clients'
        presenter: (value) -> value
        handlers: {}
        isSortable: true
      conversations_count:
        label: '# Conversations'
        presenter: (value) -> value
        handlers: {}
        isSortable: true
      unranked:
        label: '# Unranked'
        presenter: (value, agent) =>
          React.createElement(Link, { \
            "className": (baseStyles.link),  \
            "to": ("/ranking?agent_id=#{agent.id}&end_date=#{@props.endDate}&status=unranked&range_type=#{@props.rangeType}")
          },
            (value)
          )
        addBodyCss: -> baseStyles['color-blue']
        handlers: {}
        isSortable: true
      ranked:
        label: '# Ranked'
        presenter: (value, agent) =>
          React.createElement(Link, { \
            "className": (baseStyles.link),  \
            "to": ("/ranking?agent_id=#{agent.id}&end_date=#{@props.endDate}&status=ranked&range_type=#{@props.rangeType}")
          },
            (value)
          )
        addBodyCss: -> baseStyles['color-blue']
        handlers: {}
        isSortable: true
      ranked_percent:
        label: '% Ranked'
        presenter: (value) -> formatNumberToPercent value
        handlers: {}
        isSortable: true
      to_rank:
        label: '# To Rank'
        presenter: (value) -> if value == 0 then '-' else value
        handlers: {}
        isSortable: true
      striked:
        label: '# Striked'
        addBodyCss: -> baseStyles['color-blue']
        presenter: (value, agent) =>
          params = getQueryParams window.location.search.replace '?'
          React.createElement(Link, { \
            "className": (baseStyles.link),  \
            "to": ("/ranking?agent_id=#{agent.id}&end_date=#{@props.endDate}&status=ranked&quality=bad&range_type=#{@props.rangeType}")
          },
            (value)
          )
        handlers: {}
        isSortable: true
      striked_percent:
        label: '% Striked'
        presenter: (value) -> formatNumberToPercent value
        handlers: {}
        isSortable: true
      ranking_role_message_score_avg:
        label: 'Avg. chat score'
        presenter: (value) -> value
        handlers: {}
        isSortable: true
      ranking_role_log_message_score_avg:
        label: 'Avg. order score'
        presenter: (value) -> value
        handlers: {}
        isSortable: true
    @storeData = QADashboardStore
    @pageCount = 0
    @onOrderChangeHandler = props.onOrderChange
    @rowStyle = (row) ->
      styles.success_row if row.to_rank <= row.ranked
    @initComponent()

  dependsOnStores: [QADashboardStore]

  onChangeTeamlead: (agent, endDate, teamleadId) ->
    QADashboardActionCreator.changeTeamlead(agent, agent.id, endDate, teamleadId)

module.exports = QAStatisticComponent
