export default {
  // host for API server
  apiHost: 'https://operator.tn-company.com',
  apiHostTickets: 'https://tickettool-stage.dsh-agency.com',

  // version of used API
  apiVersionPartV1: '/api/v1',
  apiVersionPart: '/api/v3',
	apiVersionPartV4: '/api/v4',

  writerToolSite: 'https://myaccount-stage.lrwriters.com',
  graderToolSite: 'https://gradertool-stage.lrwriters.com',

  apiHostAuthBack: 'https://auth-back-stage.dsh-agency.com',

  a4Host: 'https://operator.tn-company.com',
  a5Host: 'https://a5-stage.dsh-agency.com',

  mynerdifyLink: 'http://mynerdify-stage.dsh-agency.com',
  //  geeklyLabSite: 'http://geeklylab-stage.dsh-agency.com',
  //  geeklyHubSite: 'http://geeklyhub-stage.dsh-agency.com',
  geeklyLabSite: 'https://stage.geeklylab.com',
  geeklyHubSite: 'https://stage.geeklyhub.net',

  homeworkLabSite: 'http://homeworklab-stage.dsh-agency.com',

  fileServerUrl: 'https://n4bybpe0o7.execute-api.eu-west-1.amazonaws.com/dev',

  apiHostSocket: 'wss://mqtt-stage.dsh-agency.com:8084/mqtt',
  clientChatMessages: 'https://xmpp-nt.dsh-agency.com/nodes/get_items',
  clientChatFiles: 'https://xmpp-nt.dsh-agency.com/nodes/get_files',
  removeClientChatFiles: 'https://xmpp-nt.dsh-agency.com/nodes/remove_files',
  restoreClientChatFiles: 'https://xmpp-nt.dsh-agency.com/nodes/restore_files',

  apiHostSmsTool: 'https://connect-stage.dsh-agency.com',
  baseUrlWFH: 'https://connect-stage.dsh-agency.com',
  apiVersionSmsTool: '/api',

  OCRProxy: 'https://api-stage.tn-company.com/h2c',
  OCRResults: 'https://api-stage.tn-company.com/ocrspace/parse/image',

  isSmsTool: true,
};
