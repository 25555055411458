introText = require 'constants/introjs'
config = require 'root/config'
introJs = require('intro.js/intro.js')

CONVERSATION_AFTER_ACTIONS =
  setOptions: (intro) ->
    return intro
      .setOption('disableInteraction', true)
      .setOption('exitOnOverlayClick', false)
      .setOption('exitOnEsc', false)
      .setOption('overlayOpacity', 0.3)
      .setOption('hidePrev', true)

  logout: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '.extra-info-dropdown_element__IgVgx'
            intro: introText.ranking.step4
            highlightClass: 'introjs-highlightClass'
          }])
          .start()
      500
    )

  rankingMessage: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.logout(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()

        goodRanking = document.querySelectorAll(
          '[class*="ranking-conversation-item-conversation__conversation-info-tag_good"]'
        )[0]
        goodRanking = goodRanking.parentElement.parentElement.parentElement
        goodRanking.click()
      100
    )
    setTimeout(
      ->
        targetElement.click()
      500
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '.message-wrap_estimate__30HPu'
            intro: introText.ranking.step7
          },{
            element: '.ranking-status-comment__textarea__3rF-Q'
            intro: introText.ranking.step8
          },{
            element: '.extra-info-container__3TXFX'
            intro: ''
          }])
          .start()
      2000
    )

  rankingStatus: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.rankingMessage(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        badRanking = document.querySelectorAll(
          '.ranking-conversation-item-conversation__conversation-info-tag_bad__1Hpk2'
        )[0]
        if badRanking
          badRanking = badRanking.parentElement.parentElement.parentElement
        newIntro
          .addSteps([{
            element: '.ranking-filter-selectors-container__3m9n1'
            intro: introText.ranking.step3
          },{
            element: '.ranking-conversations-conversations__list__2NBJC'
            intro: introText.ranking.step5
          },{
            element: badRanking
            intro: introText.ranking.step6
          },{
            element: badRanking
            intro: ''
          }])
          .start()
      1000
    )

  ranking: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.rankingStatus(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '.app-component-menuContainer__36Jx3 a[title="Ranking"]'
            intro: introText.ranking.step1
          },{
            element: '.calendar-slider__2l7Yv'
            intro: introText.ranking.step2
          },{
            element: '.ranking-filter-filters__VSBvu'
            intro: ''
          }])
          .start()
      2000
    )

  dashboard: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.ranking(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '.app-component-menuContainer__36Jx3 a[title="Dashboard"]'
            intro: introText.dashboard.step1
          },{
            element: '.app-component-menuContainer__36Jx3 a[title="Ranking"]'
            intro: ''
          }])
          .start()
      2000
    )

  agents: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.dashboard(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '.agent-profile-wrapper-content__3HBuM'
            intro: introText.agents.step1
          },{
            element: '.base-agent-statistic-head__2dslZ tr td:nth-child(8)'
            intro: introText.agents.step2
          },{
            element: '.app-component-menuContainer__36Jx3 a[title="Dashboard"]'
            intro: ''
          }])
          .start()
      2500
    )

  customer: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.agents(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '.customer-form-conversation_link__3pxHr'
            intro: introText.customers.step3
          },{
            element: '.app-component-menuContainer__36Jx3 a[title="Agents"]'
            intro: ''
          }])
          .start()
      1000
    )

  customers: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.customer(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
      100
    )
    setTimeout(
      ->
        document.querySelectorAll(
          '.app-component-menuContainer__36Jx3 a[title="Customers"]'
        )[0].click()
      400
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '.app-component-menuContainer__36Jx3 a[title="Customers"]'
            intro: introText.customers.step1
          },{
            element: '.search-input-root__3lmmU'
            intro: introText.customers.step2
          },{
            element: document.querySelectorAll(
              '.customer-list-container__1Ei5r .list-item-container__1Smft'
            )[0]
            intro: ''
          }])
          .start()
      1200
    )

  communication: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.customers(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        document.querySelectorAll(
          '.payments_crm_info_module .info-block-title_container__3CGtP'
        )[0].click()
      500
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '.communication_crm_info_module'
            intro: introText.communication.step1
          },{
            element: document.querySelectorAll(
              '.ticketing_crm_info_module .info-block-title_container__3CGtP'
            )[0]
            intro: ''
          }])
          .start()
      1000
    )

  payments: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.communication(@, targetElement)
          return false
        true
      )
    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        element = document.querySelectorAll(
          '[class^="message-form-text_area"]'
        )[0]
        element.value = ''
        event = new Event('input', { bubbles: true })
        event.simulated = true
        element.dispatchEvent(event)
      200
    )

    setTimeout(
      ->
        document.querySelectorAll(
          '.payments_crm_info_module [class^="info-block-title_container"]'
        )[0].click()
        document.querySelectorAll(
          '.orders_crm_info_module [class^="info-block-title_container"]'
        )[0].click()
      500
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '.payments_crm_info_module'
            intro: introText.conversation.step62
          },{
            element: '.payments_crm_info_module ' +
                     '[class^="customer-payments-balance_container"]'
            intro: introText.conversation.step63
          },{
            element: '.payments_crm_info_module [class^="tab-options-all"]'
            intro: introText.conversation.step64
          },{
            element: document.querySelectorAll(
              '.payments_crm_info_module [class^="customer-payments-links_container"]'
            )[0]
            intro: introText.conversation.step65
          },{
            element: document.querySelectorAll(
              '.payments_crm_info_module [class^="customer-payments-links_container"]'
            )[1]
            intro: introText.conversation.step66
          },{
            element: document.querySelectorAll(
              '.communication_crm_info_module [class^="info-block-title_container"]'
            )[0]
            intro: ''
          }])
          .start()
      1000
    )

  complexOrderEnd: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.payments(@, targetElement)
          return false

        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class^="order-details__dropdown_container"]'
            intro: introText.conversation.step612
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="cross-icon__container"]'
            intro: ''
          }])
          .start()
      1000
    )

  complexOrder: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.complexOrderEnd(@, targetElement)
          return false

        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        document.querySelectorAll('[class^="customer-orders-text_container"]')[0].click()
      1000
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class*="order-details__order_detail_complex"]'
            intro: introText.conversation.step611
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class*="order-details__order_detail_complex"]'
            intro: ''
          }])
          .start()
      3000
    )

  minOrder: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.complexOrder(@, targetElement)
          return false

        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class^="plugins-container__header"]'
            intro: introText.conversation.step6101
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="plugins-container__close"] span',
            intro: ''
          }])
          .start()
      500
    )

  updateOrderButton4:  (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.minOrder(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
      100
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: document.querySelectorAll('[class^="payment-info__row"] [class^="tooltip__container"]')[0]
            intro: introText.conversation.stepx11
            highlightClass: 'introjs-highlightClass'
          },{
            element: document.querySelectorAll(
              '[class^="payment-info__payment_checkbox"]'
            )[0]
            intro: introText.conversation.step607
            highlightClass: 'introjs-highlightClass'
          },{
            element: document.querySelectorAll(
              '[class^="comments-for-writers__comments_for_writers_title"]'
            )[0].parentElement
            intro: introText.conversation.step609
            highlightClass: 'introjs-highlightClass'
          },{
            element: document.querySelectorAll(
              '[class^="upload-container__container"]'
            )[0].parentElement.parentElement
            intro: introText.conversation.step6010
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="plugins-container__minimize"] span',
            intro: introText.conversation.step610
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="plugins-container__minimize"] span',
            intro: ''
          }])
          .start()
      300
    )

  updateOrderButton3:  (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.updateOrderButton4(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        document.querySelectorAll('[class^="addons__item"] label')[1].click()
      300
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class^="dropdown__content"]'
            intro: introText.conversation.stepx10
            highlightClass: 'introjs-highlightClass'
          }, {
            element: '[class^="plugins-container__content_container"]'
            intro: ''
          }])
          .start()
      2500
    )


  updateOrderButton2: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.updateOrderButton3(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class^="dropdown__content"]'
            intro: introText.conversation.stepx9
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="addons__container"] [class^="button__container"]',
            intro: ''
          }])
          .start()
      300
    )

  descriptionOrder: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.updateOrderButton2(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        element = targetElement
        element.value = 'Test test test test'
        event = new Event('input', { bubbles: true  })
        event.simulated = true
        element.dispatchEvent(event)
      200
    )

    setTimeout(
      ->
        element = targetElement
        event = new Event('blur')
        event.simulated = true
        event.relatedTarget = document.querySelectorAll('[class^="order-description__order_description_text"] .editableTextBtn')[0]
        element.dispatchEvent(event)
      600
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class*="editable-text__value_length"]'
            intro: introText.conversation.stepx6
            highlightClass: 'introjs-highlightClass'
          },{
            element: document.querySelectorAll('[class^="editable-text__container"] .editableTextBtn')[1]
            intro: introText.conversation.stepx7
            highlightClass: 'introjs-highlightClass'
          },{
            element: document.querySelectorAll('[class^="order-details__order_details_container"]')[0].parentElement
            intro: introText.conversation.stepy3
            highlightClass: 'introjs-highlightClass'
          },{
            element: document.querySelectorAll('[class^="tooltip__container"]')[1]
            intro: introText.conversation.stepx8
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="payment-info__balance"]'
            intro: introText.conversation.step605
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="discount-input__container"]'
            intro: introText.conversation.step702
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="addons__container"] [class^="button__container"]',
            intro: ''
          }])
          .start()
      3500
    )

  englishType2: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.descriptionOrder(@, targetElement)
          return false

        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      200
    )

    setTimeout(
      ->
        document.querySelectorAll('[class^="select-list__item"] a')[0].click()
      400
    )

    setTimeout(
      ->
        targetElement.click()
      1600
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class^="dropdown__content"]'
            intro: introText.conversation.stepx5
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class*="order-description__comment_text"]'
            intro: ''
          }])
          .start()
      1800
    )

  englishType: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.englishType2(@, targetElement)
          return false

        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      200
    )

    setTimeout(
      ->
        document.querySelectorAll('[class^="select-list__item"] a')[0].click()
      400
    )

    setTimeout(
      ->
        targetElement.click()
      1500
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class^="dropdown__content"]'
            intro: introText.conversation.stepx4
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="english-type-dropdown__text"]'
            intro: ''
          }])
          .start()
      1600
    )

  deadlineOrder: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.englishType(@, targetElement)
          return false

        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class^="editable-remain-time__container"]'
            intro: introText.conversation.stepx3
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="order-format-dropdown__text"]'
            intro: ''
          }])
          .start()
      500
    )

  firstDeadline: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.deadlineOrder(@, targetElement)
          return false

        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class^="calendar__container"]'
            intro: introText.conversation.stepx2
            highlightClass: 'introjs-highlightClass'
          },{
            element: document.querySelectorAll('[class^="deadline__deadline_editable"]')[1]
            intro: ''
          }])
          .start()
      500
    )

  additionalTask: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.firstDeadline(@, targetElement)
          return false

        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        document.querySelectorAll('[class^="order-types-dropdown__list_item"]')[0].click()
      200
    )

    setTimeout(
      ->
        document.querySelectorAll('[class^="order-types-dropdown__list_item"]')[1].click()
      300
    )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      500
    )

    setTimeout(
      ->
        document.querySelectorAll('[class^="order-details__dropdown_container"]')[0].setAttribute('style', 'z-index: 102!important')
        document.querySelectorAll('[class^="order-types-dropdown__list_item"]')[0].click()
      600
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class^="order-types-dropdown__nested_list"]'
            intro: introText.conversation.stepy2
            highlightClass: 'introjs-highlightClass'
          },{
            element: document.querySelectorAll('[class^="deadline__deadline_editable"]')[0]
            intro: ''
          }])
          .start()
      700
    )

  calendarOrder: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.additionalTask(@, targetElement)
          return false

        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )
    setTimeout(
      ->
        targetElement.click()
      400
    )
    setTimeout(
      ->
        element = document.querySelectorAll('[class^="editable-text__textarea"]')[0]
        element.value = 'TEST'
        event = new Event('input', { bubbles: true  })
        event.simulated = true
        element.dispatchEvent(event)
      700
    )
    setTimeout(
      ->
        element = document.querySelectorAll('[class^="editable-text__textarea"]')[0]
        event = new Event('blur')
        event.simulated = true
        event.relatedTarget = document.querySelectorAll('.editableTextBtn')[0]
        element.dispatchEvent(event)
      1000
    )
    setTimeout(
      ->
        document.querySelectorAll('[class^="order-details__order_detail"]')[2].click()
      1600
    )
    setTimeout(
      ->
        document.querySelectorAll('[class^="order-types-dropdown__list_item"]')[2].click()
      1700
    )
    setTimeout(
      ->
        document.querySelectorAll('[class^="subject-dropdown__text"]')[0].click()
      2500
    )
    setTimeout(
      ->
        document.querySelectorAll('[class^="select-list__item"] a')[0].click()
      2800
    )

    targetElement = document.querySelectorAll('[class^="deadline__deadline_editable"]')[0]
    setTimeout(
      ->
        targetElement.click()
      3200
    )

    setTimeout(
      ->
        calendar = document.querySelectorAll('[class^="calendar__date_part"]')[0]
        rows = calendar.children
        columns = rows[rows.length - 1].children
        columns[columns.length - 1].click()
      3300
    )

    setTimeout(
      ->
        document.querySelectorAll('[class^="calendar__submit_button"]')[0].click()
      3400
    )

    setTimeout(
      ->
        document.querySelectorAll('[class^="order-details__order_detail_stages"]')[0].click()
      3450
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class^="order-types-dropdown__container"]'
            intro: introText.conversation.stepy1
            highlightClass: 'introjs-highlightClass'
          },{
            element: document.querySelectorAll('[class^="order-details__order_detail_stages"]')[0]
            intro: ''
          }])
          .start()
      3500
    )

  newOrder: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.calendarOrder(@, targetElement)
          return false

        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )

    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '[class^="plugins-container__container"]'
            intro: introText.conversation.step58
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="plugins-container__title"]'
            intro: introText.conversation.step59
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="order-plugin__statuses"]'
            intro: introText.conversation.step60
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="plugins-container__content_container"]'
            intro: introText.conversation.stepx1
            highlightClass: 'introjs-highlightClass'
          },{
            element: '[class^="order-details__order_title_text"]'
            intro: ''
          }])
          .start()
      3000
    )

  ordersTooltip: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.newOrder(@, targetElement)
          return false

        true
      )
    setTimeout(
      ->
        intro.exit()
        event = new MouseEvent('mouseover', {
          'view': window
          'bubbles': true
          'cancelable': true
        })
        targetElement.dispatchEvent(event)
      100
    )
    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: document.querySelectorAll(
              '.orders_crm_info_module [class^="customer-orders-note"]'
            )[0]
            intro: introText.conversation.step42
          },{
            element: '.orders_crm_info_module [class^="tab-options-new"]'
            intro: ''
          }])
          .start()
      300
    )

  orders: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.ordersTooltip(@, targetElement)
          return false

        true
      )
    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )
    setTimeout(
      ->
        document.querySelectorAll(
          '.orders_crm_info_module [class^="info-block-title_container"]'
        )[0].click()
        document.querySelectorAll(
          '.client_info_crm_info_module [class^="info-block-title_container"]'
        )[0].click()
      500
    )
    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '.orders_crm_info_module'
            intro: introText.conversation.step35
          },{
            element: '.orders_crm_info_module [class^="tab-options-all"]'
            intro: introText.conversation.step37
          },{
            element: '.orders_crm_info_module [class^="info-block-title_group"]'
            intro: introText.conversation.step36
            highlightClass: 'introjs-highlightClass'
          },{
            element: document.querySelectorAll(
              '.orders_crm_info_module [class^="customer-orders-title"]'
            )[0]
            intro: introText.conversation.step38
          },{
            element: document.querySelectorAll(
              '.orders_crm_info_module [class^="customer-orders-title"]'
            )[0]
            intro: introText.conversation.step39
          },{
            element: document.querySelectorAll(
              '.orders_crm_info_module [class^="customer-orders-status_text"]'
            )[0]
            intro: introText.conversation.step40
          },{
            element: document.querySelectorAll(
              '.orders_crm_info_module [class^="customer-orders-text_container"]'
            )[0]
            intro: ''
          }])
          .start()
      1000
    )

  crm: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .addSteps([{
        element: '[class^="plugins-container__container"]'
        intro: introText.conversation.step33
        highlightClass: 'introjs-highlightClass'
      },{
        element: '[class^="cross-icon__container"]'
        intro: ''
      }])
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.orders(@, targetElement)
          return false

        true
      )
    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )
    setTimeout(
      ->
        newIntro.start()
      1000
    )

  search: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .addSteps([{
        element: '.conversation-header-search_messages__7tpVZ'
        intro: introText.conversation.step25
      },{
        element: '.base-info-container__1f4ky'
        intro: introText.conversation.step26
      },{
        element: document.querySelectorAll('[class^="info-block-title_container"]')[1]
        intro: introText.conversation.step27
      },{
        element: '.customer-card-credentials-form-button_new__-n9Dc'
        intro: introText.conversation.step28
      },{
        element: document.querySelectorAll('.customer-card-optout__2sITB')[0]
        intro: introText.conversation.step29
      },{
        element: document.querySelectorAll('.customer-card-optout__2sITB')[1]
        intro: introText.conversation.step30
      },{
        element: '.tags_section'
        intro: introText.conversation.step31
      },{
        element: '.comments_section'
        intro: introText.conversation.step34
      },{
        element: '.button-button__tF-Eo'
        intro: introText.conversation.step32
      },{
        element: '.button-button__tF-Eo'
        intro: ''
      }])
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.crm(@, targetElement)
          return false

        true
      )
    setTimeout(
      ->
        intro.exit()
      100
    )
    setTimeout(
      ->
        newIntro.start()
      500
    )

  scheduled: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .addSteps([{
        element: '.conversation-header-scheduled_message_button__M9mXt'
        intro: introText.conversation.step23
      },{
        element: '.conversation-header-scheduled_message_button__M9mXt'
        intro: ''
      }])
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.search(@, targetElement)
          return false

        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )
    setTimeout(
      ->
        newIntro.start()
      500
    )

  reminders: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .addSteps([{
        element: '.modal-modal__3nVDR'
        intro: introText.conversation.step22
        highlightClass: 'introjs-highlightClass'
      },{
        element: '.modal-cross__24fpP'
        intro: ''
      }])
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep 
          CONVERSATION_AFTER_ACTIONS.scheduled(@, targetElement)
          return false

        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )
    setTimeout(
      ->
        newIntro.start()
      500
    )

  tagsGroup: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .addSteps([{
        element: '.hash-tags-container__3UJva'
        intro: introText.conversation.step16
      },{
        element: '.emoji-picker-container__3OALB'
        intro: introText.conversation.step17
      },{
        element: '.assigned-agents-container__36Qqv'
        intro: introText.conversation.step18
      },{
        element: '.channels-container__3gVLW'
        intro: introText.conversation.step19
      },{
        element: '.conversation-header-select_state__Pfs0C'
        intro: introText.conversation.step20
      },{
        element: '.conversation-header-reminder__1gsDn'
        intro: introText.conversation.step21
      },{
        element: '.conversation-header-reminder__1gsDn'
        intro: ''
      }])
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep 
          CONVERSATION_AFTER_ACTIONS.reminders(@, targetElement)
          return false

        true
      )
    setTimeout(
      ->
        intro.exit()
      100
    )
    setTimeout(
      ->
        targetElement.click()
      300
    )
    setTimeout(
      ->
        document.querySelectorAll('.hash-tag-list-item-data__3YmoQ')[0].click()
      1000
    )
    setTimeout(
      ->
        newIntro.start()
      3000
    )

  tags: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .addSteps([{
        element: '.hash-tag-list-list__2ZxH6'
        intro: introText.conversation.step15
      },{
        element: '.conversation-tags-container__1jtaf'
        intro: ''
      }])
      .onbeforechange((targetElement) ->
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.tagsGroup(@, targetElement)
          return false
        true
      )
    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )
    setTimeout(
      ->
        newIntro.start()
      1000
    )

  replyTemplates: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .addSteps([{
        element: '.reply-answers-header__1_dg8'
        intro: introText.conversation.step12
      },{
        element: '.message-form-text_area__1Fq5k'
        intro: introText.conversation.step13
      },{
        element: '.conversation-tags-container__1jtaf'
        intro: introText.conversation.step14
      },{
        element: '.conversation-tags-container__1jtaf'
        intro: ''
      }])
      .onbeforechange((targetElement) ->
        if @_currentStep == 1
          targetElement.value = 'Selected reply template message'
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.tags(@, targetElement)
          return false
        true
      )

    setTimeout(
      ->
        intro.exit()
        targetElement.click()
        newIntro.start()
      100
    )
  conversation: (intro, targetElement) ->
    newIntro = CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
      .onbeforechange((targetElement) ->
        if @_currentStep == 5
          elements = document.querySelectorAll(
            '.previous-conversation-title__m1Pqs'
          )
          elements[elements.length - 1].click()
        if @_currentStep == 6
          elements = document.querySelectorAll(
            '.previous-conversation-title__m1Pqs'
          )
          elements[elements.length - 1].click()
        if @_introItems.length - 1 == @_currentStep
          CONVERSATION_AFTER_ACTIONS.replyTemplates(@, targetElement)
          return false
        true
      )
    setTimeout(
      ->
        intro.exit()
        targetElement.click()
      100
    )
    setTimeout(
      ->
        newIntro
          .addSteps([{
            element: '.conversation-container__FvHPv'
            intro: introText.conversation.step1
          },{
            element: document.querySelectorAll('.message-outbox__2ApNn')[0]
            intro: introText.conversation.step2
          },{
            element: document.querySelectorAll('.message-inbox__tSyJ6')[0]
            intro: introText.conversation.step3
          },{
            element: document.querySelectorAll(
                '.message-outbox__2ApNn .message-small__1Imei'
              )[0]
            intro: introText.conversation.step4
          },{
            element: document.querySelectorAll('.log-message-root__1IQpi')[0]
            intro: introText.conversation.step5
          },{
            element: document.querySelectorAll(
              '.conversation-scroll__2OAmT div'
            )[0]
            intro: introText.conversation.step6
          },{
            element: '.conversation-form__1xP97'
            intro: introText.conversation.step7
          },{
            element: '.conversation-resizable_border___W0zm'
            intro: introText.conversation.step8
          },{
            element: '.message-form-submit__2gbat'
            intro: introText.conversation.step9
          },{
            element: '.file-uploader-upload_button__3KWuW'
            intro: introText.conversation.step10
          },{
            element: '.reply-template-selector-open_button__3UnqA'
            intro: introText.conversation.step11
          },{
            element: '.reply-template-selector-open_button__3UnqA'
            intro: ''
          }])

          .start()
      2300
    )

module.exports =
  initMyConversations: () ->
    if config.introEnabled
      setTimeout(
        ->
          CONVERSATION_AFTER_ACTIONS.setOptions(introJs())
            .addSteps([{
              element: '.navigation'
              intro: introText.myConversations.menu
              highlightClass: 'introjs-highlightClass'
            },{
              element: document.querySelectorAll('.menu-svg__3xnUk')[0]
              intro: introText.myConversations.myMenu
            },{
              element: '.take-new-button-button__Vce1I'
              intro: introText.myConversations.takeNew
            },{
              element: '.take-new-conversation-stats__Z4M6X'
              intro: introText.myConversations.statistic
            },{
              element: '.conversation-list-item-container__3hfNB'
              intro: introText.myConversations.shortcut
            },{
              element: document.querySelectorAll(
                '.conversation-list-item-container__3hfNB'
              )[0]
              intro: ''
            }])
            .onbeforechange((targetElement) ->
              if @_introItems.length - 1 == @_currentStep
                CONVERSATION_AFTER_ACTIONS.conversation(@, targetElement)
                return false
              true
            )
            .start()
        2500
      )
