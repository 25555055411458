import React, { useState, useRef, useEffect, useContext } from 'react';
import classnames from 'classnames';

import RankingRulesAPI from 'api/RankingRulesAPI'

import RankingRulesActionCreator from 'actions/RankingRulesActionCreator';
import RankingConversationsActionCreator from 'actions/RankingConversationsActionCreator';

import { QAInfoContext } from 'components/rankingRulesModule/RankingModuleInfoBlock/QAInfo/QAInfoContext';

import { Modal } from 'components/rankingRulesModule/RankingModuleInfoBlock/QAInfo/QAInfoItem/components';
import { getQueryParams } from 'lib/utils';

import styles from './index.styl';

const Header = ({ onToggleOpened, isOpen, massageText, isEditMode, message, onEditMode, innerEditMode }) => {
  const [showMenu, toggleShowMenu] = useState(false);
  const [isLoading, toggleIsLoading] = useState(false);
  const menuRef = useRef(null);
  const { setModalComponent, isUserValidRole } = useContext(QAInfoContext);

  const onToggleShowMenu = () => {
    toggleShowMenu(!showMenu);
  }

  const onDelete = () => {
    setModalComponent(null);
    toggleIsLoading(true);

    const { target_id, target_type } = message[0];

    RankingRulesAPI.deleteConversationRankingReports({ target_id, target_type }, () => {
      toggleIsLoading(false);
      setTimeout(() => {
        RankingRulesActionCreator.removeConversationRankedMessageReports(target_id, target_type);
        const params = getQueryParams(window.location.search.substring(1));
        if (params.agent_id && params.status == 'unranked') {
          RankingConversationsActionCreator.getConversations(params);
        }
      }, 1);
    });
  };

  const onDeleteMenuClick = () => {
    setModalComponent(<Modal onClose={() => setModalComponent(null)} onSuccess={onDelete} header="Delete all reports" text="Do you really want to delete all reports?" />)
  }

  const onEditMenuClick = () => {
    if (innerEditMode) return;
    
    onEditMode();
  }

  useEffect(() => {
    const outClickHandler = (e) => {
      const { target } = e;
      
      if (!menuRef || !menuRef.current) return;

      if (menuRef.current.contains(target)) return;

      toggleShowMenu(!showMenu);
    };
    if (!showMenu) {
      document.removeEventListener('click', outClickHandler);
      return;
    }
    document.addEventListener('click', outClickHandler);

    return () => {
      document.removeEventListener('click', outClickHandler);
    }
  }, [showMenu]);

  return (
    <div className={classnames(styles.header, {[[styles['header--loading']]]: isLoading })}>
        <div className={styles.header_collapse_item} onClick={onToggleOpened}>
          <i className={classnames(styles.header_collapse_item__arrow, {[[styles['header_collapse_item__arrow--open']]]: isOpen})} />
          <div className={classnames(styles.header_collapse_item__message_text, {[[styles['header_collapse_item__message_text--long']]]: !isUserValidRole})}>{massageText}</div>
        </div>
        {
          !isEditMode && isUserValidRole && (
            <i className={styles.header_menu_icon} onClick={onToggleShowMenu}>
              {showMenu && (
              <div className={styles.header_menu_menu} ref={menuRef}>
                <div className={styles.header_menu_menu__item} onClick={onDeleteMenuClick}>Delete</div>
                <div className={classnames(styles.header_menu_menu__item, {[[styles['header_menu_menu__item--disable']]]: innerEditMode })} onClick={onEditMenuClick}>Edit</div>
              </div>
              )}
            </i>
          )
        }
      </div>
  )
};

export default Header;
