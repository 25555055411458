browserHistory = require './../../../history'

PersonalizedStore = require 'stores/abstract/PersonalizedStore'
ChannelFormActionCreator = require './ChannelFormActionCreator'
ActionTypes = require 'constants/ActionTypes'

class ChannelFormStore extends PersonalizedStore

  constructor: ->
    super()
    @channel = {}
    @loading =
      get: false
      createOrUpdate: false
    @errors = null
    @registerActions()

  getActionCreator: => new ChannelFormActionCreator()

  _registerToActions: ({ guid, type, payload }) =>
    return unless @isPersonalAction(guid)

    switch type
      when ActionTypes.CHANNEL_FORM_NEW
        @errors = null
        @channel = {}
        @emitChange()

      when ActionTypes.CHANNEL_FORM_GET_REQUESTING
        @errors = null
        @loading.get = true
        @emitChange()
      when ActionTypes.CHANNEL_FORM_GET_SUCCESS
        @errors = null
        @loading.get = false
        @channel = payload
        @emitChange()
      when ActionTypes.CHANNEL_FORM_GET_FAILURE
        @loading.get = false
        @emitChange()

      when ActionTypes.CHANNELS_CREATE_REQUESTING, ActionTypes.CHANNELS_UPDATE_REQUESTING
        @loading.createOrUpdate = true
        @emitChange()
      when ActionTypes.CHANNELS_CREATE_SUCCESS
        @errors = null
        @loading.createOrUpdate = false
        @channel = payload
        @emitChange()
        browserHistory.default.push({ pathname: "/channels/#{payload.id}" })
      when ActionTypes.CHANNELS_UPDATE_SUCCESS
        @errors = null
        @loading.createOrUpdate = false
        @channel = payload
        @emitChange()
      when ActionTypes.CHANNELS_CREATE_FAILURE, ActionTypes.CHANNELS_UPDATE_FAILURE
        @loading.createOrUpdate = false
        @channel = payload.attributes
        @errors = payload.errors
        @emitChange()
      when ActionTypes.CHANNELS_REMOVE_ERRORS
        @errors = null
        @emitChange()

module.exports = ChannelFormStore
