config = require '../config';
ADDITIONAL_ORDER_MENU_QUIZ = require './additionalOrderMenu.quiz';

ADDITIONAL_ORDER_MENU =
  OFFER_NO_DETAIL: 'Offer cabinet (no details)'
  OFFER_DETAIL: 'Offer cabinet (details sent)'
  NEW: 'Create new order'
  DETAILS_REQUEST: 'Task details request '
  TASK_DETAILS_REQUEST: 'Task details request'
  DASHBOARD_TASK_NO_LOGIN: 'Dashboard task (no login)'
  DASHBOARD_TASK: 'Dashboard task (have login)'

ADDITIONAL_ORDER_MENU._TYPE_MESSAGES =
  TYPE1: 'TYPE1'
  TYPE2: 'TYPE2'
  Mynerdify: 'Mynerdify'
  Xmpp: 'Xmpp'
  withMark: 'withMark'

ADDITIONAL_ORDER_MENU._ALL = [
  ADDITIONAL_ORDER_MENU.OFFER_NO_DETAIL
  ADDITIONAL_ORDER_MENU.OFFER_DETAIL
  ADDITIONAL_ORDER_MENU.NEW
]

ADDITIONAL_ORDER_MENU._NO_PAID = [
  ADDITIONAL_ORDER_MENU.DETAILS_REQUEST
  ADDITIONAL_ORDER_MENU.NEW
]

ADDITIONAL_ORDER_MENU._NO_TAG = [
  ADDITIONAL_ORDER_MENU.OFFER_NO_DETAIL
  ADDITIONAL_ORDER_MENU.OFFER_DETAIL
  ADDITIONAL_ORDER_MENU.DASHBOARD_TASK_NO_LOGIN
  ADDITIONAL_ORDER_MENU.DASHBOARD_TASK
  ADDITIONAL_ORDER_MENU.NEW
]

ADDITIONAL_ORDER_MENU._HAS_TAG = [
  ADDITIONAL_ORDER_MENU.TASK_DETAILS_REQUEST
  ADDITIONAL_ORDER_MENU.DASHBOARD_TASK_NO_LOGIN
  ADDITIONAL_ORDER_MENU.DASHBOARD_TASK
  ADDITIONAL_ORDER_MENU.NEW
]

ADDITIONAL_ORDER_MENU._MY_NERDIFY  = [
  ADDITIONAL_ORDER_MENU.TASK_DETAILS_REQUEST
  ADDITIONAL_ORDER_MENU.DASHBOARD_TASK_NO_LOGIN
  ADDITIONAL_ORDER_MENU.DASHBOARD_TASK
  ADDITIONAL_ORDER_MENU.NEW
]

ADDITIONAL_ORDER_MENU._XMPP  = [
  ADDITIONAL_ORDER_MENU.TASK_DETAILS_REQUEST
  ADDITIONAL_ORDER_MENU.DASHBOARD_TASK_NO_LOGIN
  ADDITIONAL_ORDER_MENU.DASHBOARD_TASK
  ADDITIONAL_ORDER_MENU.NEW
]

ADDITIONAL_ORDER_MENU._ICON =
  "#{ADDITIONAL_ORDER_MENU.OFFER_NO_DETAIL}": 'iconChat'
  "#{ADDITIONAL_ORDER_MENU.NEW}": 'iconAdd'
  "#{ADDITIONAL_ORDER_MENU.OFFER_DETAIL}": 'iconChat'
  "#{ADDITIONAL_ORDER_MENU.DETAILS_REQUEST}": 'iconChat'
  "#{ADDITIONAL_ORDER_MENU.TASK_DETAILS_REQUEST}": 'iconChat'
  "#{ADDITIONAL_ORDER_MENU.DASHBOARD_TASK_NO_LOGIN}": 'iconChat'
  "#{ADDITIONAL_ORDER_MENU.DASHBOARD_TASK}": 'iconChat'


ADDITIONAL_ORDER_MENU._MESSAGE =
  "#{ADDITIONAL_ORDER_MENU.DETAILS_REQUEST}": {
    "#{ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.TYPE1}": {
      messages: [
        ['Can you please give me more info about your task? If you have guidelines from your professor, you can snap a photo of them and send it to me here. Alternatively, you can write in your own words what you want us to do :)'],
      ]
      templates: []
    }
    "#{ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.TYPE2}": {
      messages: [
        ['Can you tell me the topic of your task?\n\rIf you have guidelines from your professor, you can share them via an upload link: [file sharing link], or send them to \n\r[email support]. \n\rAlternatively, you can write in your own words what you need from us :)']
      ]
      templates: [
        '[]'
        '[file sharing link]'
      ]
    }
    "#{ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.Mynerdify}": {
      messages: [
        ['Can you please give me more info about your task?\n\rIf you have guidelines from your professor, you can send them to me here as a file or a photo. Or you can describe what you need in your own words.']
      ]
      templates: []
    }
    "#{ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.Xmpp}": {
      messages: [
        ['Sure! If you have guidelines from your professor, you can send them to me here as a file or a photo. Or you can describe what you need in your own words.']
      ]
      templates: []
    }
  }
  "#{ADDITIONAL_ORDER_MENU.OFFER_NO_DETAIL}": {
    "#{ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.TYPE1}": {
      messages: [
        ['Sure! You can simply place a new order in your [account] account:\n\r[link to cabinet]']
        ['Alternatively, you can send pics with task instructions right here or describe what you need in your own words.\n\rAlso, if you have any files to share, use this upload link:\n\r[file sharing link]\n\ror send them to [email support] - whatever is more convenient for you.']
      ]
      # templates: [
      #   '[link to cabinet]',
      #   '[file sharing link]'
      # ]
    }
    "#{ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.TYPE2}": {
      messages: [
        ['Sure! You can simply place a new order in your [account] account:\n\r[link to cabinet]']
        ['Alternatively, you can reply here with what you want us to do. If you have any files to share, use this upload link:\n\r[file sharing link]\n\ror send them to [email support] - whatever is more convenient for you.']
      ]
      # templates: [
      #   '[link to cabinet]',
      #   '[file sharing link]'
      # ]
    }
    "#{ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.withMark}": {
      messages: [
        ['Sure! You can also download [account] App for iOS to place new tasks faster:\n\rhttps://app.nerdifyit.com/dl/qh74']
        ['Alternatively, you can send pics with task instructions right here or describe what you need in your own words.\n\rAlso, if you have any files to share, use this upload link:\n\r[file sharing link]\n\ror send them to [email support] - whatever is more convenient for you.']
      ]
      # templates: [
      #   '[link to cabinet]',
      #   '[file sharing link]'
      # ]
    }
  }
  "#{ADDITIONAL_ORDER_MENU.OFFER_DETAIL}": {
      "#{ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.TYPE1}": {
        messages: [
          ['Got the instructions! Please, give me a few mins to check them carefully and I\'ll get back to you. Alternatively, you can simply go to your [account] account to place a new order: [link to cabinet]']
        ]
        # templates: [
        #   '[link to cabinet]'
        # ]
    }
      "#{ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.TYPE2}": {
        messages: [
          ['Got the instructions! Please, give me a few mins to check them carefully and I\'ll get back to you. Alternatively, you can simply go to your [account] account to place a new order: [link to cabinet]']
        ]
        # templates: [
        #   '[link to cabinet]'
        # ]
    }
      "#{ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.withMark}": {
        messages: [
          ['Got the instructions! Please give me a few min to check them carefully and I’ll get back to you. Meanwhile, you can get [account] App for iOS to place new tasks and track your task progress:\n\rhttps://app.nerdifyit.com/dl/qh74. \n\rIt’s a lot quicker and I guarantee it’ll only upgrade your experience!']
        ]
        # templates: [
        #   '[link to cabinet]'
        # ]
    }
  }
  "#{ADDITIONAL_ORDER_MENU.DASHBOARD_TASK_NO_LOGIN}": {
      "#{ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.TYPE1}": {
        messages: [
          ['We will be really happy to help you out with this task!\n\rYou can provide me with your login details, and I will check out the task details and find [account_task] for your task.\n\rAlternatively, you can send me pics of your task right here :)']
        ]
        # templates: []
      }
      "#{ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.TYPE2}": {
        messages: [
          ['We would be more than happy to help you out with this task!\n\rIf you can please provide me with your login details, I will check out the task details and find [account_task] for you.\n\rAlternatively, if you have any files/pics to share, you can use this upload link:\n\r[file sharing link] or send them to [email support] - whatever is more convenient for you :)']
        ]
        # templates: [
        #   '[]'
        #   '[file sharing link]'
        # ]
    }
      "#{ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.Mynerdify}": {
        messages: [
          ['We will be really happy to help you out with this task!\n\rYou can provide me with your login details, and I will check out the task details and find [account_task] for your task.\n\rAlternatively, you can send me pics of your task right here :) ']
        ]
        # templates: []
    }
      "#{ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.Xmpp  }": {
        messages: [
          ['We will be really happy to help you out with this task! \n\rYou can provide me with your login details, and I will check out the task details and find [account_task] for your task. \n\rAlternatively, you can send me pics of your task right here :)']
        ]
        # templates: [
        #   '[]'
        #   '[file sharing link]'
        # ]
    }
  }
  "#{ADDITIONAL_ORDER_MENU.DASHBOARD_TASK}": {
      "#{ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.TYPE1}": {
        messages: [
          ['We will be really happy to help you out with this task!\n\rIf you don\'t mind, I\'ll check out the task details on your dashboard; just give me XX minutes for that.\n\rAlternatively, you can send me pics of your task right here :)']
        ]
        # templates: []
    }
      "#{ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.TYPE2}": {
        messages: [
          ['We would be more than happy to help you out with this task!\n\rIf you don\'t mind, I\'ll check out the task details on your dashboard; just give me XX minutes for that.\n\rAlternatively, if you have any files/pics to share, you can use this upload link: [file sharing link]  or send them to [email support] - whatever is more convenient for you :)']
        ]
        # templates: [
        #   '[]'
        #   '[file sharing link]'
        # ]
    }
      "#{ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.Mynerdify}": {
        messages: [
          ['We will be really happy to help you out with this task!\n\rIf you don\'t mind, I\'ll check out the task details on your dashboard; just give me XX minutes for that. Alternatively, you can send me pics of your task right here :)']
        ]
        # templates: []
    }
      "#{ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.Xmpp}": {
        messages: [
          ['We will be really happy to help you out with this task! \n\rIf you don\'t mind, I\'ll check out the task details on your dashboard; just give me XX minutes for that. Alternatively, you can send me pics of your task right here :)']
        ]
        # templates: []
    }
  }
  "#{ADDITIONAL_ORDER_MENU.TASK_DETAILS_REQUEST}": {
      "#{ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.TYPE1}": {
        messages: [
          ['Sure! You can send pics with task instructions right here or describe what you need in your own words.\n\rAlso, if you have any files to share, use this upload link: [file sharing link] or send them to [email support] - whatever is more convenient for you.']
        ]
        # templates: [
        #   '[]'
        #   '[file sharing link]'
        # ]
    }
      "#{ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.TYPE2}": {
        messages: [
          ['Sure! You can write what you need here.\n\rIf you have any files to share, use this upload link: [file sharing link] or send them to [email support] - whatever is more convenient for you.']
        ]
        # templates: [
        #   '[]'
        #   '[file sharing link]'
        # ]
    }
      "#{ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.Mynerdify}": {
        messages: [
          ['Sure! If you have guidelines from your professor, you can send them to me here as a file or a photo. Or you can describe what you need in your own words.  ']
        ]
        # templates: []
    }
    "#{ADDITIONAL_ORDER_MENU._TYPE_MESSAGES.Xmpp}": {
      messages: [
        ['Sure! If you have guidelines from your professor, you can send them to me here as a file or a photo. Or you can describe what you need in your own words.']
      ]
      # templates: []
    }
  }

module.exports = if config?.crminfo?.templateMessages == 'templateMessages.quiz' then ADDITIONAL_ORDER_MENU_QUIZ else ADDITIONAL_ORDER_MENU 