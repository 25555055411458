PersonalizedStore = require 'stores/abstract/PersonalizedStore'
ConversationEachHourByChannelActionCreator = require './ConversationEachHourByChannelActionCreator'
ActionTypes = require 'constants/ActionTypes'

actionsMap = 
  "#{ActionTypes.CONVERSATION_EACH_HOUR_BY_CHANNEL_REQUESTING}": ->
    @loading = true
    @error = null
    @emitChange()
  "#{ActionTypes.CONVERSATION_EACH_HOUR_BY_CHANNEL_SUCCESS}": (data) ->
    @loading = false
    @channelType = data.channel_type
    @emitChange()
  "#{ActionTypes.CONVERSATION_EACH_HOUR_BY_CHANNEL_ERROR}": (error) ->
    @loading = false
    @error = error
    @emitChange()


class ConversationEachHourByChannelStore extends PersonalizedStore
  constructor: ->
    super()
    @loading = false
    @channelType = {}
    @registerActions()

  getActionCreator: -> new ConversationEachHourByChannelActionCreator

  _registerToActions: ({ type, payload, guid }) =>
    return unless @isPersonalAction(guid)
    actionsMap[type].call(@, payload) if actionsMap[type]

module.exports = ConversationEachHourByChannelStore
