ADDITIONAL_ORDER_MENU_QUIZ =
  NEW: 'Create new order'
  DETAILS_REQUEST: 'Task details request '
  TASK_DETAILS_REQUEST: 'Task details request'
  DASHBOARD_TASK_NO_LOGIN: 'Dashboard task (no login)'
  DASHBOARD_TASK: 'Dashboard task (have login)'
  OFFER_SUBSCRIPTION: 'Offer subscription'

ADDITIONAL_ORDER_MENU_QUIZ._TYPE_MESSAGES =
  TYPE1: 'TYPE1'
  TYPE2: 'TYPE2'
  BASIC: 'BASIC'
  TRIAL: 'TRIAL'

ADDITIONAL_ORDER_MENU_QUIZ._ALL = [
  ADDITIONAL_ORDER_MENU_QUIZ.NEW
]

ADDITIONAL_ORDER_MENU_QUIZ._NO_PAID = [
  ADDITIONAL_ORDER_MENU_QUIZ.DETAILS_REQUEST
  ADDITIONAL_ORDER_MENU_QUIZ.NEW
]

ADDITIONAL_ORDER_MENU_QUIZ._NO_TAG = [
  ADDITIONAL_ORDER_MENU_QUIZ.DASHBOARD_TASK_NO_LOGIN
  ADDITIONAL_ORDER_MENU_QUIZ.DASHBOARD_TASK
  ADDITIONAL_ORDER_MENU_QUIZ.NEW
]

ADDITIONAL_ORDER_MENU_QUIZ._HAS_TAG = [
  ADDITIONAL_ORDER_MENU_QUIZ.TASK_DETAILS_REQUEST
  ADDITIONAL_ORDER_MENU_QUIZ.DASHBOARD_TASK_NO_LOGIN
  ADDITIONAL_ORDER_MENU_QUIZ.DASHBOARD_TASK
  ADDITIONAL_ORDER_MENU_QUIZ.NEW
]

ADDITIONAL_ORDER_MENU_QUIZ._MY_NERDIFY = [
  ADDITIONAL_ORDER_MENU_QUIZ.DASHBOARD_TASK
  ADDITIONAL_ORDER_MENU_QUIZ.NEW
]
ADDITIONAL_ORDER_MENU_QUIZ._OFFER_SUBSCRIPTION = [
  ADDITIONAL_ORDER_MENU_QUIZ.OFFER_SUBSCRIPTION
]

ADDITIONAL_ORDER_MENU_QUIZ._ICON =
  "#{ADDITIONAL_ORDER_MENU_QUIZ.NEW}": 'iconAdd'
  "#{ADDITIONAL_ORDER_MENU_QUIZ.DETAILS_REQUEST}": 'iconChat'
  "#{ADDITIONAL_ORDER_MENU_QUIZ.TASK_DETAILS_REQUEST}": 'iconChat'
  "#{ADDITIONAL_ORDER_MENU_QUIZ.DASHBOARD_TASK_NO_LOGIN}": 'iconChat'
  "#{ADDITIONAL_ORDER_MENU_QUIZ.DASHBOARD_TASK}": 'iconChat'
  "#{ADDITIONAL_ORDER_MENU_QUIZ.OFFER_SUBSCRIPTION}": 'iconChat'


ADDITIONAL_ORDER_MENU_QUIZ._MESSAGE =
  "#{ADDITIONAL_ORDER_MENU_QUIZ.DETAILS_REQUEST}": {
    "#{ADDITIONAL_ORDER_MENU_QUIZ._TYPE_MESSAGES.TYPE1}": {
      messages: [
        ['Could you please share the details of your task? You can text me what you need to get done or just send a pic with instructions right here :) If you have any files at hand you can send them via this upload link: [file sharing link] or to support@quiz-easy.com.'],
      ]
      templates: [
        '[]'
        '[file sharing link]'
      ]
    }
    "#{ADDITIONAL_ORDER_MENU_QUIZ._TYPE_MESSAGES.TYPE2}": {
      messages: [
        ['What is the topic of your task? You can text me what you need to get done or just send a pic with instructions right here :) If you have any files at hand you can share them via this upload link: [file sharing link] or send to support@quiz-easy.com']
      ]
      templates: [
        '[]'
        '[file sharing link]'
      ]
    }
  }
  "#{ADDITIONAL_ORDER_MENU_QUIZ.DASHBOARD_TASK_NO_LOGIN}": {
      "#{ADDITIONAL_ORDER_MENU_QUIZ._TYPE_MESSAGES.TYPE1}": {
        messages: [
          ['We will be really happy to help you out with this task!\n\rYou can provide me with your login details, and I will check out the task details and find a qualified expert for your task.\n\rAlternatively, you can send me pics of your task right here :)']
        ]
        templates: []
      }
      "#{ADDITIONAL_ORDER_MENU_QUIZ._TYPE_MESSAGES.TYPE2}": {
        messages: [
          ['We would be more than happy to help you out with this task!\n\rIf you can please provide me with your login details, I will check out the task details and find a qualified expert for you.\n\rAlternatively, if you have any files/pics to share, you can use this upload link:\n\r[file sharing link] or send them to support@quiz-easy.com — whatever is more convenient for you :)']
        ]
        templates: [
          '[]'
          '[file sharing link]'
        ]
    }
  }
  "#{ADDITIONAL_ORDER_MENU_QUIZ.DASHBOARD_TASK}": {
      "#{ADDITIONAL_ORDER_MENU_QUIZ._TYPE_MESSAGES.TYPE1}": {
        messages: [
          ['We will be really happy to help you out with this task!\n\rIf you don\'t mind, I\'ll check out the task details on your dashboard; just give me XX minutes for that.\n\rAlternatively, you can send me pics of your task right here :)']
        ]
        templates: []
    }
      "#{ADDITIONAL_ORDER_MENU_QUIZ._TYPE_MESSAGES.TYPE2}": {
        messages: [
          ['We would be more than happy to help you out with this task!\n\rIf you don\'t mind, I\'ll check out the task details on your dashboard; just give me XX minutes for that.\n\rAlternatively, if you have any files/pics to share, you can use this upload link: [file sharing link]  or send them to support@quiz-easy.com — whatever is more convenient for you :)']
        ]
        templates: [
          '[]'
          '[file sharing link]'
        ]
    }
      "#{ADDITIONAL_ORDER_MENU_QUIZ._TYPE_MESSAGES.Mynerdify}": {
        messages: [
          ['We will be really happy to help you out with this task!\n\rIf you don\'t mind, I\'ll check out the task details on your dashboard; just give me XX minutes for that. Alternatively, you can send me pics of your task right here :)']
        ]
        templates: []
    }
  }
  "#{ADDITIONAL_ORDER_MENU_QUIZ.TASK_DETAILS_REQUEST}": {
      "#{ADDITIONAL_ORDER_MENU_QUIZ._TYPE_MESSAGES.TYPE1}": {
        messages: [
          ['No prob, you can just tell me what you need in your own words.\n\rIf you have any files at hand you can share them via this upload link: [file sharing link] or send to support@quiz-easy.com — whatever is more convenient for you.']
        ]
        templates: [
          '[]'
          '[file sharing link]'
        ]
    }
      "#{ADDITIONAL_ORDER_MENU_QUIZ._TYPE_MESSAGES.TYPE2}": {
        messages: [
          ['No prob! You can send pics/photos with instructions right here or describe what you need to get done.\n\rIf you have any files at hand you can share them via this upload link: [file sharing link] or send to support@quiz-easy.com — whatever is more convenient for you.']
        ]
        templates: [
          '[]'
          '[file sharing link]'
        ]
    }
  }
  "#{ADDITIONAL_ORDER_MENU_QUIZ.OFFER_SUBSCRIPTION}": {
    "#{ADDITIONAL_ORDER_MENU_QUIZ._TYPE_MESSAGES.BASIC}": {
        messages: [
          ['You can re-subscribe to QuizEasy and get full access to the service following this link: [subscription link] \n\rOnce your subscription is activated, you\'ll be charged 27 USD each month until you cancel. You can cancel your subscription anytime!']
        ]
    }
    "#{ADDITIONAL_ORDER_MENU_QUIZ._TYPE_MESSAGES.TRIAL}": {
        messages: [
          ['You can subscribe to QuizEasy and get full access to the service following this link: [subscription link] \n\rAfter your two-week free trial ends, you\'ll be charged 27 USD per month until you cancel. You can cancel your subscription anytime!']
        ]
    }
  }

module.exports = ADDITIONAL_ORDER_MENU_QUIZ