moment = require('moment')
styles = require './notification-item.styl'

NotificationItem = ({ notification, onClose, marginBetween }) ->
    minutesAway = Math.round((new Date() - Date.parse(notification.last_activity_at)) / (60 * 1000))

    return (
      React.createElement("div", {"className": (styles.container)},
        React.createElement("div", {"className": (styles.body)},
          React.createElement("span", {"className": (styles.last_time)}, (moment(notification.last_activity_at).format('HH:mm'))),
          React.createElement("h3", {"className": (styles.name)}, ("#{notification.first_name} #{notification.last_name}")),
          React.createElement("p", {"className": (styles.status)}, ("has been inactive for #{minutesAway} minutes"))
        )
      )
    )

module.exports = NotificationItem
