import React, { useState, useEffect } from 'react';

import styles from './index.styl';

const BannerAppNerdyChatStatus = ({ conversation, activeChannel, conversationId }) => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(activeChannel === 'Nerdify Chat' || activeChannel === 'NerdifyApp 2.0 Chat')
  }, [activeChannel]);

  useEffect(() => {
    const hasClientTagAppInstaller = conversation && conversation.customer.tags.some((tag) => tag.name === 'app2_installed');
    setIsOpen(activeChannel === 'Nerdify Chat' || activeChannel === 'NerdifyApp 2.0 Chat' || hasClientTagAppInstaller)
  }, []);


  if (isOpen) {
    return (
      <div className={styles.banner}>
        <i className={styles.close} onClick={() => setIsOpen(false)} />
        <div className={styles.title}><i className={styles.icon_attention} /><span>Nerdify App 2.0 chat</span> channel temporarily NOT working.</div>
        <div className={styles.subtitle}>Follow this steps:</div>
        <ol>
          <li>Check user's channel</li>
          <li>If user's channel is Nerdify App 2.0 chat change it to the US Nerdify App SMS</li>
        </ol>
        <p className={styles.notes}>The application will be available soon. We are working on it</p>
        <button className={styles.btn} onClick={() => setIsOpen(false)}>Go it</button>
      </div>
    )
  }

  return null;
};

export default BannerAppNerdyChatStatus;
