import React, { useEffect, useRef, useState } from 'react';
import { controls } from '@verdaccio/crminfo';
import config from 'root/config';
import _ from 'lodash';
import { unstable_batchedUpdates } from 'react-dom';

import classnames from 'classnames';

import { getToken } from 'lib/tokenStorage'
import { buildQueryParamsString } from 'lib/utils';
import RankingRulesStore from 'stores/RankingRulesStore';
import AuthStore from 'stores/AuthStore';

import styles from './styles.styl'

const PRIORITY = {
  HIGH: 'high',
  MEDIUM: 'medium',
  LOW: 'low',
}

const DEFAULT_PARAMS = { filter_name: ['pillar', 'issue_type', 'rule'] }

const configRules = {
  field: 'rule',
}

const configSort = [
  { priority: { high: 0, medium: 1, low: 3 } },
];

const configGroupRules = {
  fieldGroup: 'priority',
  valuesGroup: {
    high: {
      name: 'High Severity',
      color: '#E95A1C'
    },
    medium: {
      name: 'Medium Severity',
      color: '#DAB42B'
    },
    low: {
      name: 'Low Severity',
      color: '#12A054'
    },
  },
}

const RankingRuleDropdownContainer = ({ reportStatus, onChange, isError, report }) => {

  const role = _.get(RankingRulesStore, 'currentConversation.approve_data.user_role', '');
  const team = role === 'sales' ? 'SALES' : 'CSR'

  const [loading, setLoading] = useState({
    pillarList: false,
    issueTypeList: false,
    ruleList: false,
  })

  const [pillar, setPillar] = useState('')
  const [pillarList, setPillarList] = useState([])

  const [issueType, setIssueType] = useState(null)
  const [issueTypeList, setIssueTypeList] = useState([])

  const [activeRule, setRule] = useState(null)
  const [ruleList, setRuleList] = useState([])
  const [idRule, setIdRule] = useState(null)


  const setInitData = useRef({
    pillar: true,
    issueType: true,
    mainFilter: null,
    pillarAutoSet: false,
    issueTypeAutoSet: false,
  });


  const getData = (params, entitys = []) => {
    entitys.forEach((entity) => {
      setLoading(__prevData => ({ ...__prevData, [entity]: true }));
    })
    let queryParams = `?${buildQueryParamsString({ ...params, team }, true)}`;

    const headers = {
      method: 'GET',
      Authorization: getToken(),
      'Content-Type': 'application/json',
    };

    fetch(config.urls.rankingRules.filterRules(queryParams), {
      headers,
    }).then((response) => {
      if (response) {
        if (response.ok) {
          response.json().then((result) => {
            const { pillar: pillarResponse, issue_type: issueTypeResponse, rule } = result || {};
            unstable_batchedUpdates(() => {
              pillarResponse && setPillarList(pillarResponse);
              issueTypeResponse && setIssueTypeList(issueTypeResponse);
              rule && setRuleList(rule);

              if (rule && rule.length === 1) {
                onSetRule(null, rule[0]);
              } else if (pillarResponse && pillarResponse.length === 1 || (issueTypeResponse && issueTypeResponse.length === 1)) {
                if (pillarResponse && pillarResponse.length === 1) {
                  setInitData.current.pillarAutoSet = pillarResponse[0] !== pillar;
                  setPillar(pillarResponse[0]);
                }
                if (issueTypeResponse && issueTypeResponse.length === 1) {
                  setInitData.current.issueTypeAutoSet = issueTypeResponse[0] !== issueType;
                  setIssueType(issueTypeResponse[0]);
                }
              }

              console.log(entitys);

              entitys.forEach((entity) => {
                setLoading(__prevData => ({ ...__prevData, [entity]: false }));
              });
            });
          });
        }
      }
    })
      .catch(e => console.error(e));
  }

  const renderPriority = (priority = '') => {
    return (
      <span className={classnames(styles.priority, {
        [styles.high]: priority === PRIORITY.HIGH,
        [styles.medium]: priority === PRIORITY.MEDIUM,
        [styles.low]: priority === PRIORITY.LOW
      })} />
    );
  };

  const clearData = () => {
    setPillar('');
    setIssueType(null);
    setIssueTypeList([]);
    setRule(null);
    setRuleList([]);
    setIdRule(null);
    setInitData.current.mainFilter = null;
  };

  const onSetRule = (r, __parseRule = null) => {
    let parseRule = __parseRule;
    if (!__parseRule) {
      parseRule = JSON.parse(r);
    }
    setRule(parseRule);
    setIdRule(parseRule.id);
    const { pillar: rulePilar, issue_type: issueTypeRule } = parseRule;
    setInitData.current.pillarAutoSet = pillar !== rulePilar;
    setInitData.current.issueTypeAutoSet = issueTypeRule !== issueType;
    setPillar(rulePilar);
    setIssueType(issueTypeRule);
  }

  const renderRuleItem = (r) => {
    const parseRule = JSON.parse(r);
    return (
      <>
        {renderPriority(parseRule.priority)}
        {parseRule.rule}
      </>
    )
  }

  const renderRuleGroup = (group) => {
    const groupData = configGroupRules.valuesGroup[group];
    return (
      <div style={{ color: groupData.color }} className={styles.group_name}>{groupData.name}</div>
    )
  }

  useEffect(() => {
    if (pillar) {
      if (setInitData.current.pillarAutoSet) {
        setInitData.current.pillarAutoSet = false;
        return;
      }

      const methods = ['ruleList'];
      const params = {
        pillar: encodeURIComponent(pillar),
        filter_name: ['rule'],
      };

      if (!setInitData.current.mainFilter || setInitData.current.mainFilter === 'pillar') {
        setInitData.current.mainFilter = 'pillar';
        setIssueType(null);
        setIssueTypeList([]);
        params.filter_name.push('issue_type')
        methods.push('issueTypeList');
      } else {
        params.issue_type = encodeURIComponent(issueType);
      }

      setRule(null);
      setRuleList([]);
      setIdRule(null);

      getData(params, methods);
    }
  }, [pillar]);

  useEffect(() => {
    if (issueType) {
      if (setInitData.current.issueTypeAutoSet) {
        setInitData.current.issueTypeAutoSet = false;
        return;
      }

      const methods = ['ruleList'];
      const params = {
        issue_type: encodeURIComponent(issueType),
        filter_name: ['rule']
      };

      if (!setInitData.current.mainFilter || setInitData.current.mainFilter === 'issueType') {
        setInitData.current.mainFilter = 'issueType';
        setPillar('');
        setPillarList([]);
        params.filter_name.push('pillar')
        methods.push('pillarList');
      } else {
        params.pillar = encodeURIComponent(pillar);
      }

      setRule(null);
      setRuleList([]);
      setIdRule(null);

      getData(params, methods)
    }
  }, [issueType]);

  useEffect(() => {
    clearData();
    getData(DEFAULT_PARAMS, ['pillarList', 'issueTypeList', 'ruleList']);
  }, [reportStatus]);

  useEffect(() => {
    onChange({
      activeRule: activeRule,
      idRule: idRule,
    });
  }, [activeRule, idRule]);

  useEffect(() => {
    if (!report) return;
    if (!report.ranking_rule_id) return;
    if (ruleList.length === 0) return;
    if (setInitData.current.ranking_rule_id) return;

    setInitData.current.ranking_rule_id = report.ranking_rule_id
    const rule = ruleList.find(r => r.id === report.ranking_rule_id);
    onSetRule(null, rule);

  }, [report, ruleList]);

  useEffect(() => {
    getData(DEFAULT_PARAMS, ['pillarList', 'issueTypeList', 'ruleList']);
  }, []);

  return (
    <div className={styles.container_dropdown}>
      <div className={styles.dropdown_item}>
        <controls.TypeaheadDropdown name='pillar' value={pillar} placeholder='Select pillar' isError={isError && !pillar} list={pillarList} loading={loading.pillarList} onClick={setPillar} />
      </div>

      <div className={styles.dropdown_item}>
        <controls.TypeaheadDropdown name='issueType' value={issueType} placeholder='Select issue type' isError={isError && !issueType} list={issueTypeList} loading={loading.issueTypeList} onClick={setIssueType} />
      </div>

      <div className={styles.dropdown_item}>
        <controls.TypeaheadDropdown
          name='rule'
          value={activeRule}
          placeholder='Select rule'
          isError={isError && !activeRule}
          list={ruleList}
          loading={loading.ruleList}
          onClick={onSetRule}
          renderElement={renderRuleItem}
          renderGroup={renderRuleGroup}
          configElement={configRules}
          configGroup={configGroupRules}
          configSort={configSort}
        />
      </div>
    </div>
  );
}

export default RankingRuleDropdownContainer;
