BaseStore = require './BaseStore'
ActionTypes = require '../constants/ActionTypes'
config = require 'root/config'

actionsMap =
  "#{ActionTypes.CUSTOMER_CRM_REQUESTING}": ({ customer }) ->
    @loading = true
    @error = null
    @comments = null
    @payments = null
    @communication = null
    @externalClientId = null
    @currentCustomer = customer
    @emitChange()
  "#{ActionTypes.CUSTOMER_CRM_CLEAR}": () ->
    @comments = null
    @payments = null
    @communication = null
    @currentCustomer = null
    @externalClientId = null
    @error = null
    @emitChange()
  "#{ActionTypes.CUSTOMER_CRM_SUCCESS}": ({ data = {}, customer }) ->
    return if !@currentCustomer || !customer
    return if customer.id != @currentCustomer.id
    @loading = false
    @payments = data
    link = data['OrderLink'] || config.fileSharingLink
    if link && !link.includes 'http'
      link = "http://#{link}"
    if link && customer.upload_id
      try
        url = new URL(link)
        @payments['CustomerSharingLink'] = "#{url.origin}/files/#{customer.upload_id}"
      catch
    @externalClientId = data.clientId
    @emitChange()
  "#{ActionTypes.CUSTOMER_COMMUNICATIONS_SUCCESS}": ({ data = [], customer }) ->
    return if !@currentCustomer || !customer
    return if customer.id != @currentCustomer.id
    @loading = false
    @communication = data
    @emitChange()
  "#{ActionTypes.CUSTOMER_CRM_FAILURE}": (error) ->
    @loading = false
    @error = error
    @emitChange()


class CRMInfoModuleStore extends BaseStore
  constructor: ->
    super()
    @comments = null
    @payments = null
    @communication = null
    @loading = false
    @error = null
    @externalClientId = null
    @currentCustomer = null
    @registerActions()

  _registerToActions: ({ type, payload }) =>
    actionsMap[type].call @, payload if actionsMap[type]

module.exports = new CRMInfoModuleStore()
