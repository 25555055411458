import React, { useContext, useState } from 'react';
import { controls } from '@verdaccio/crminfo';
import classnames from 'classnames';

import { getQueryParams } from 'lib/utils';
import RankingRulesAPI from 'api/RankingRulesAPI';
import RankingRulesActionCreator from 'actions/RankingRulesActionCreator';

import ConversationsActionCreator from 'actions/ConversationsActionCreator';
import RankingConversationsActionCreator from 'actions/RankingConversationsActionCreator';

import { QAInfoContext } from 'components/rankingRulesModule/RankingModuleInfoBlock/QAInfo/QAInfoContext';
import { Modal } from 'components/rankingRulesModule/RankingModuleInfoBlock/QAInfo/QAInfoItem/components';

import styles from './index.styl';

const validationMap = {
  issue: report => Boolean(report.ranking_rule_id),
  good_job: () => true,
  comment: report => Boolean(report.comment),
};

const EditFooter = ({ onAddNewReport , reports, onAnyDataChange, innerEditMode, offEditMode }) => {

  const { setModalComponent, currentConversationId } = useContext(QAInfoContext);
  const [isLoading, toggleIsLoading] = useState(false);

  const { target_type, target_id } = (reports || [])[0] || {}

  const getApiObject = (report) => {
    const __report = { ...report };
    ['id', 'isError', 'isOpen', 'ranking_rule', 'isInnerEdit', 'logs', 'noLogs', 'tab'].forEach(item => {
      if (item === 'id' && __report.created_at) return;
      
      delete __report[item]
    });

    return __report
  }

  const onDelete = () => {
    setModalComponent(null);
    RankingRulesActionCreator.selectRankedMessage({
      id: target_id
    });
  }

  const onExitEditMode = () => {
    setModalComponent(null);
    offEditMode();
  }
  
  const onCancelClick = () => {
    if (innerEditMode) {
      setModalComponent(<Modal btnsNames={['No, Keep editing', 'Yes, exit Edit mode']} onClose={() => setModalComponent(null)} onSuccess={onExitEditMode} header="Exit Edit mode" text="Do you really want to exit Edit mode? Your changes will not be saved." />)
      return;
    }

    setModalComponent(<Modal onClose={() => setModalComponent(null)} onSuccess={onDelete} header="Delete all reports" text="Do you really want to delete all reports?" />)
  };

  const onSaveClick = () => {
    let isValid = true;
    reports.forEach(report => {
      const { status, id } = report;
      const __isValid = validationMap[status](report);

      if (!__isValid) {
        isValid = false;
        onAnyDataChange(id, {
          isOpen: true,
          isError: true,
        });
      }
    });

    if (!isValid) return;

    toggleIsLoading(true);

    RankingRulesAPI.createConversationRankingReports({
      conversation_ranking_rules: reports.map(report => ({
        conversation_id: currentConversationId,
        ...getApiObject(report)
      }))
    }, (result) => {
      toggleIsLoading(false);

      if (innerEditMode) offEditMode();
      
      const __reports = result[target_type][target_id];
      RankingRulesActionCreator.createConversationRankedMessageReports(target_id, target_type, __reports)
      const conversationsActionCreator = new ConversationsActionCreator();
      conversationsActionCreator.get(currentConversationId, null, true);
      const params = getQueryParams(window.location.search.substring(1));
      if (params.agent_id && params.status == 'unranked') {
        RankingConversationsActionCreator.getConversations(params);
      }
    });

  } 

  return (
    <div className={classnames(styles.edit_footer, {[[styles['edit_footer--loading']]]: isLoading })}>
      {
        reports.length <= 6 && (
          <div className={styles.edit_footer_add_new_report} onClick={onAddNewReport}>
            <i className={styles.edit_footer_add_new_report__icon} />
            <div className={styles.edit_footer_add_new_report__text}>Add another report</div>
          </div>
        )
      }
      <div className={styles.edit_footer_btn_block}>
        <controls.Button onClick={onCancelClick}>
          Cancel
        </controls.Button>
        <controls.Button primary onClick={onSaveClick}>
          {isLoading ? 'Saving ...' : `${innerEditMode ? 'Save changes' : 'Save'}`}
        </controls.Button>
      </div>
    </div>
  )
};

export default EditFooter;
