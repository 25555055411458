import React from 'react';
import classnames from 'classnames';

import styles from './styles.styl';

const Tabs = ({ selectedTab, setSelectedTab, id }) => {
  return (
    <div className={styles.tabs}>
      <div className={classnames(styles.tabs__item, {[[styles['tabs__item--selected']]]: selectedTab === 'Score'})} onClick={() => setSelectedTab('Score', id)}>Score</div>
      <div className={classnames(styles.tabs__item, {[[styles['tabs__item--selected']]]: selectedTab === 'Log'})} onClick={() => setSelectedTab('Log', id)}>Log</div>
    </div>
  )
};

export default Tabs;
