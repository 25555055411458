module.exports = {
  'CONVERSATIONS_SET_LAST_READ_TIME'
  'CLOSE_IDLE_NOTIFICATION'
  'CLOSE_BURGER_MENU'

  'CONVERSATION_EACH_HOUR_BY_CHANNEL_REQUESTING'
  'CONVERSATION_EACH_HOUR_BY_CHANNEL_SUCCESS'
  'CONVERSATION_EACH_HOUR_BY_CHANNEL_ERROR'

  'ROTATIONS_GET_LIST_REQUESTING'
  'ROTATIONS_GET_LIST_SUCCESS'
  'ROTATIONS_GET_LIST_FAILURE'
  'ROTATION_GET_REQUESTING'
  'ROTATION_GET_SUCCESS'
  'ROTATION_GET_FAILURE'
  'ROTATION_CREATE_REQUESTING'
  'ROTATION_CREATE_SUCCESS'
  'ROTATION_CREATE_FAILURE'
  'ROTATION_DELETE_REQUESTING'
  'ROTATION_DELETE_SUCCESS'
  'ROTATION_DELETE_FAILURE'
  'ROTATION_UPDATE_REQUESTING'
  'ROTATION_UPDATE_SUCCESS'
  'ROTATION_UPDATE_FAILURE'
  'ROTATION_FORM_CLEAR'

  'LANDINGS_GET_LIST_REQUESTING'
  'LANDINGS_GET_LIST_SUCCESS'
  'LANDINGS_GET_LIST_FAILURE'
  'LANDING_GET_REQUESTING'
  'LANDING_GET_SUCCESS'
  'LANDING_GET_FAILURE'
  'LANDING_CREATE_REQUESTING'
  'LANDING_CREATE_SUCCESS'
  'LANDING_CREATE_FAILURE'
  'LANDING_UPDATE_REQUESTING'
  'LANDING_UPDATE_SUCCESS'
  'LANDING_UPDATE_FAILURE'
  'LANDING_FORM_CLEAR'

  'MQTT_CONNECT'
  'MQTT_PUBLISH'
  'MQTT_SUBSCRIBE'
  'MQTT_UNSUBSCRIBE'
  'MQTT_UNSUBSCRIBE_ANY'
  'MQTT_INITIAL'
  'MQTT_CONNECT_GLOBAL'
  'MQTT_PUBLISH_GLOBAL'
  'MQTT_SUBSCRIBE_GLOBAL'
  'MQTT_UNSUBSCRIBE_GLOBAL'
  'MQTT_UNSUBSCRIBE_ANY_GLOBAL'
  'MQTT_INITIAL_GLOBAL'
  'MQTT_PLAY_SOUND'
  'MQTT_NOTIFICATION'
  'MQTT_USER_UPDATING'
  'MQTT_SYSTEM_INFO_UPDATING'
  'MQTT_COUNTERS_REQUEST'
  'MQTT_COUNTERS_UPDATING'
  'MQTT_COUNTERS_UPDATING_INITIAL'
  'MQTT_ONLINE_USERS_REQUEST'
  'MQTT_ONLINE_USERS_UPDATING'
  'MQTT_ONLINE_USERS_UPDATING_INITIAL'
  'MQTT_DASHBOARD_USERS_REQUEST'
  'MQTT_DASHBOARD_USERS_UPDATING'
  'MQTT_DASHBOARD_USERS_UPDATING_INITIAL'
  'MQTT_ASSIGN_USERS_REQUEST'
  'MQTT_ASSIGN_USERS_UPDATING'
  'MQTT_ASSIGN_USERS_UPDATING_INITIAL'
  'MQTT_SHIFT_USERS_REQUEST'
  'MQTT_GLOBAL_SHIFT_USERS_UPDATING'
  'MQTT_SHIFT_USERS_UPDATING'
  'MQTT_SHIFT_USERS_UPDATING_INITIAL'
  'MQTT_ACTIVE_CONVERSATION_UPDATING'
  'MQTT_MY_ACTIVE_CONVERSATIONS_REQUEST'
  'MQTT_MY_ACTIVE_CONVERSATIONS_UPDATING'
  'MQTT_MY_ACTIVE_CONVERSATIONS_UPDATING_INITIAL'
  'MQTT_ALL_ACTIVE_CONVERSATIONS_REQUEST'
  'MQTT_ALL_ACTIVE_CONVERSATIONS_UPDATING'
  'MQTT_ALL_ACTIVE_CONVERSATIONS_UPDATING_INITIAL'
  'MQTT_UNASSIGNED_ACTIVE_CONVERSATIONS_REQUEST'
  'MQTT_UNASSIGNED_ACTIVE_CONVERSATIONS_UPDATING'
  'MQTT_UNASSIGNED_ACTIVE_CONVERSATIONS_UPDATING_INITIAL'
  'MQTT_DASHBOARD_CONVERSATIONS_REQUEST'
  'MQTT_DASHBOARD_CONVERSATIONS_UPDATING'
  'MQTT_DASHBOARD_CONVERSATIONS_UPDATING_INITIAL'
  'MQTT_ACTIVE_CONVERSATION_CUSTOMER_UPDATING'
  'MQTT_CONVERSATION_ITEMS_UPDATING'

  'MQTT_GLOBAL_WFH_SHIFT_USERS_UPDATING'
  'MQTT_GLOBAL_WFH_SHIFT_USERS_UPDATING_INITIAL'
  'MQTT_GLOBAL_WFH_TEAMLEAD_SHIFT_USERS_UPDATING'
  'MQTT_GLOBAL_WFH_TEAMLEAD_SHIFT_USERS_UPDATING_INITIAL'
  'WFH_TEAMLEAD_USERS_PING'

  'AUTH_VALIDATE_TOKEN_REQUESTING'
  'AUTH_VALIDATE_TOKEN_SUCCESS'
  'AUTH_VALIDATE_TOKEN_FAILURE'

  'AGENTS_UPDATE_STATUS_REPLY_REQUESTING',
  'AGENTS_UPDATE_STATUS_REPLY_SUCCESS',
  'AGENTS_UPDATE_STATUS_REPLY_FAILURE',

  'PROFILE_GET_REQUESTING'
  'PROFILE_GET_SUCCESS'
  'PROFILE_GET_FAILURE'
  'QA_UPDATE_TEAMLEAD_REQUESTING'
  'QA_UPDATE_TEAMLEAD_SUCCESS'
  'QA_UPDATE_TEAMLEAD_FAIL'

  'AGENTS_NEW'
  'AGENTS_GET_LIST_REQUESTING'
  'AGENTS_GET_LIST_SUCCESS'
  'AGENTS_GET_LIST_FAIL'
  'AGENTS_LIST_SET_SEARCH_FILTERS'
  'AGENTS_GET_REQUESTING'
  'AGENTS_GET_SUCCESS'
  'AGENTS_GET_FAILURE'
  'AGENTS_GET_PAGES_COUNT_REQUESTING'
  'AGENTS_GET_PAGES_COUNT_SUCCESS'
  'AGENTS_GET_PAGES_COUNT_FAILURE'
  'AGENTS_CREATE_REQUESTING'
  'AGENTS_CREATE_SUCCESS'
  'AGENTS_CREATE_FAILURE'
  'AGENTS_UPDATE_REQUESTING'
  'AGENTS_UPDATE_SUCCESS'
  'AGENTS_UPDATE_FAILURE'
  'AGENTS_BATCH_DESTROY_REQUESTING'
  'AGENTS_BATCH_DESTROY_SUCCESS'
  'AGENTS_BATCH_DESTROY_FAILURE'
  'AGENTS_UPDATE_AVATAR_REQUESTING'
  'AGENTS_UPDATE_AVATAR_SUCCESS'
  'AGENTS_UPDATE_AVATAR_FAILURE'

  'AGENT_STATISTIC_REQUESTING'
  'AGENT_STATISTIC_SUCCESS'
  'AGENT_STATISTIC_FAIL'
  'AGENT_STATISTIC_CLEAR'
  'AGENT_REMOVE_ERRORS'
  'AGENT_STATISTIC_UPDATE_REQUESTING'
  'AGENT_STATISTIC_UPDATE_SUCCESS'
  'AGENT_STATISTIC_UPDATE_FAIL'

  'AGENTS_LIST_SET_PAGE'

  'CHANNELS_NEW'
  'CHANNELS_GET_LIST_REQUESTING'
  'CHANNELS_GET_LIST_SUCCESS'
  'CHANNELS_GET_LIST_FAILURE'
  'CHANNELS_GET_REQUESTING'
  'CHANNELS_GET_SUCCESS'
  'CHANNELS_GET_FAILURE'
  'CHANNELS_GET_PAGES_COUNT_REQUESTING'
  'CHANNELS_GET_PAGES_COUNT_SUCCESS'
  'CHANNELS_GET_PAGES_COUNT_FAILURE'
  'CHANNELS_CREATE_REQUESTING'
  'CHANNELS_CREATE_SUCCESS'
  'CHANNELS_CREATE_FAILURE'
  'CHANNELS_UPDATE_REQUESTING'
  'CHANNELS_UPDATE_SUCCESS'
  'CHANNELS_UPDATE_FAILURE'
  'CHANNELS_BATCH_DESTROY_REQUESTING'
  'CHANNELS_BATCH_DESTROY_SUCCESS'
  'CHANNELS_BATCH_DESTROY_FAILURE'
  'CHANNEL_FORM_NEW'
  'CHANNEL_FORM_GET_REQUESTING'
  'CHANNEL_FORM_GET_SUCCESS'
  'CHANNEL_FORM_GET_FAILURE'
  'CHANNELS_REMOVE_ERRORS'

  'CUSTOMERS_NEW'
  'CUSTOMERS_GET_LIST_REQUESTING'
  'CUSTOMERS_GET_LIST_SUCCESS'
  'CUSTOMERS_GET_LIST_FAILURE'
  'CUSTOMERS_GET_REQUESTING'
  'CUSTOMERS_GET_SUCCESS'
  'CUSTOMERS_GET_FAILURE'
  'CUSTOMERS_GET_PAGES_COUNT_REQUESTING'
  'CUSTOMERS_GET_PAGES_COUNT_SUCCESS'
  'CUSTOMERS_GET_PAGES_COUNT_FAILURE'
  'CUSTOMERS_UPDATE_REQUESTING'
  'CUSTOMERS_UPDATE_SUCCESS'
  'CUSTOMERS_UPDATE_FAILURE'
  'CUSTOMERS_BATCH_DESTROY_REQUESTING'
  'CUSTOMERS_BATCH_DESTROY_SUCCESS'
  'CUSTOMERS_BATCH_DESTROY_FAILURE'
  'CUSTOMERS_EDIT_CREDENTIALS_CHANGE'
  'CUSTOMERS_EDIT_CREDENTIALS_PUSH'
  'CUSTOMERS_EDIT_CREDENTIALS_REMOVE'
  'CUSTOMER_START_CONVERSATION_REQUESTING'
  'CUSTOMER_START_CONVERSATION_SUCCESS'
  'CUSTOMER_START_CONVERSATION_FAILURE'
  'CUSTOMERS_MERGE_REQUEST'
  'CUSTOMERS_MERGE_SUCCESS'
  'CUSTOMERS_MERGE_FAILURE'
  'CUSTOMERS_UNMERGE_REQUEST'
  'CUSTOMERS_UNMERGE_SUCCESS'
  'CUSTOMERS_UNMERGE_FAILURE'
  'CUSTOMERS_SEARCH_REQUESTING'
  'CUSTOMERS_SEARCH_SUCCESS'
  'CUSTOMERS_SEARCH_FAILURE'
  'CUSTOMERS_SEARCH_CLEAR'

  'CUSTOMERS_CREATE_SUCCESS'
  'CUSTOMERS_CREATE_FAILURE'

  'CUSTOMER_CRM_REQUESTING'
  'CUSTOMER_CRM_SUCCESS'
  'CUSTOMER_COMMUNICATIONS_SUCCESS'
  'CUSTOMER_CRM_FAILURE'
  'CUSTOMER_CRM_CLEAR'

  'CUSTOMER_ORDERS_REQUESTING'
  'CUSTOMER_ORDERS_SUCCESS'
  'CUSTOMER_ORDERS_SOCKET_SUCCESS'
  'CUSTOMER_ORDERS_FAILURE'

  'CUSTOMER_CRM_WITH_TAGS_REQUESTING'
  'CUSTOMER_CRM_WITH_TAGS_SUCCESS'
  'CUSTOMER_CRM_WITH_TAGS_FAILURE'

  'MULTISELECT_TOGGLE'
  'MULTISELECT_TOGGLE_INDEX'
  'ASSIGN_SELECTOR_TOGGLE'
  'ASSIGN_SELECTOR_SELECT_AGENT'

  'CREATE_FAILURE'
  'UPDATE_FAILURE'
  'AGENTS_ADD'

  'CONVERSATIONS_SET_SCOPE'
  'CONVERSATIONS_GET_LIST_REQUESTING'
  'CONVERSATIONS_GET_LIST_SUCCESS'
  'CONVERSATIONS_GET_LIST_FAILURE'
  'CONVERSATIONS_GET_OPENED'
  'CONVERSATIONS_MY_GET_LIST_REQUESTING'
  'CONVERSATIONS_MY_GET_LIST_SUCCESS'
  'CONVERSATIONS_MY_GET_LIST_FAILURE'
  'CONVERSATIONS_ALL_GET_LIST_REQUESTING'
  'CONVERSATIONS_ALL_GET_LIST_SUCCESS'
  'CONVERSATIONS_ALL_GET_LIST_FAILURE'
  'CONVERSATIONS_GET_REQUESTING'
  'CONVERSATIONS_GET_SUCCESS'
  'CONVERSATIONS_GET_FAILURE'
  'CONVERSATIONS_CREATE_REQUESTING'
  'CONVERSATIONS_CREATE_SUCCESS'
  'CONVERSATIONS_CREATE_FAILURE'
  'CONVERSATIONS_BATCH_DESTROY_REQUESTING'
  'CONVERSATIONS_BATCH_DESTROY_SUCCESS'
  'CONVERSATIONS_BATCH_DESTROY_FAILURE'
  'CONVERSATIONS_ASSIGN_TO_AGENT_REQUESTING'
  'CONVERSATIONS_ASSIGN_TO_AGENT_SUCCESS'
  'CONVERSATIONS_ASSIGN_TO_AGENT_FAILURE'
  'CONVERSATIONS_UNASSIGN_AGENT_REQUESTING'
  'CONVERSATIONS_UNASSIGN_AGENT_SUCCESS'
  'CONVERSATIONS_UNASSIGN_AGENT_FAILURE'
  'CONVERSATIONS_SAVE_LOCAL'
  'CONVERSATIONS_CLEAR_CURRENT_CONVERSATION'
  'CONVERSATIONS_ASSIGN_MOST_WAITING_REQUESTING'
  'CONVERSATIONS_ASSIGN_MOST_WAITING_SUCCESS'
  'CONVERSATIONS_ASSIGN_MOST_WAITING_FAILURE'
  'CONVERSATIONS_ASSIGN_MOST_WAITING_FAILURE_HIDE'
  'CONVERSATION_UPDATE_REQUESTING'
  'CONVERSATION_UPDATE_SUCCESS'
  'CONVERSATION_UPDATE_FAILURE'
  'CONVERSATION_CHANNEL_UPDATE'
  'CONVERSATIONS_MESSAGES_LOAD_ALL_REQUESTING'
  'CONVERSATIONS_MESSAGES_LOAD_ALL_SUCCESS'
  'CONVERSATIONS_FILTER_AGENT'
  'CONVERSATIONS_FILTER_AGENT_INIT'
  'CONVERSATIONS_SEARCH_RESET'
  'CONVERSATIONS_MY_SEARCH_RESET'
  'CONVERSATIONS_ALL_SEARCH_RESET'

  'CONVERSATION_ITEMS_GET_LIST_REQUESTING'
  'CONVERSATION_ITEMS_GET_LIST_SUCCESS'
  'CONVERSATION_ITEMS_GET_LIST_FAILURE'
  'CONVERSATION_ITEMS_GET_SYNC_LIST'
  'CONVERSATION_ITEMS_CLEARE_STORE'
  'CONVERSATION_ITEMS_CLEARE_CURRENT_STORE'

  'CONVERSATIONS_SET_DEFAULT_MY_STATE'
  'CONVERSATIONS_SET_DEFAULT_ALL_STATE'
  'CONVERSATIONS_SET_DEFAULT_UNASSIGNED_STATE'

  'CONVERSATIONS_CLOSED_REQUESTING'
  'CONVERSATIONS_CLOSED_SUCCESS'
  'CONVERSATIONS_CLOSED_ERROR'
  'CONVERSATIONS_CLOSED_RANGE_REQUESTING'
  'CONVERSATIONS_CLOSED_RANGE_SUCCESS'
  'CONVERSATIONS_CLOSED_RANGE_FAILURE'
  'CONVERSATIONS_CLOSED_ROLL_DOWN'

  'CONVERSATIONS_REJECT_RESERVATION_REQUESTING'
  'CONVERSATIONS_REJECT_RESERVATION_SUCCESS'
  'CONVERSATIONS_REJECT_RESERVATION_FAILURE'

  'CONVERSATION_MOCK_SHOW'
  'CONVERSATION_MOCK_HIDE'

  'MESSAGES_GET_LIST_REQUESTING'
  'MESSAGES_GET_LIST_SUCCESS'
  'MESSAGES_GET_LIST_FAILURE'
  'MESSAGES_SAVE_LOCAL'
  'MESSAGES_SEND_REQUESTING'
  'MESSAGES_SEND_SUCCESS'
  'MESSAGES_SEND_FAILURE'
  'MESSAGES_INIT_LIST'
  'MESSAGES_LOAD_CHUNK_REQUESTING'
  'MESSAGES_LOAD_CHUNK_SUCCESS'
  'MESSAGES_LOAD_CHUNK_FAILURE'
  'MESSAGES_LOAD_ALL_REQUESTING'
  'MESSAGES_LOAD_ALL_SUCCESS'
  'MESSAGES_LOAD_ALL_FAILURE'

  'CABLE_SET'
  'CABLE_SET_ERROR'

  'TAGS_GET_LIST_REQUESTING'
  'TAGS_GET_LIST_SUCCESS'
  'TAGS_GET_LIST_FAILURE'
  'TAGS_GET_GROUP_LIST_REQUESTING'
  'TAGS_GET_GROUP_LIST_SUCCESS'
  'TAGS_GET_GROUP_LIST_FAILURE'
  'TAGS_ADD_EMPTY_FIELD'
  'TAGS_CREATE_REQUESTING'
  'TAGS_CREATE_SUCCESS'
  'TAGS_CREATE_FAILURE'
  'TAGS_UPDATE_REQUESTING'
  'TAGS_UPDATE_SUCCESS'
  'TAGS_UPDATE_FAILURE'
  'TAGS_DESTROY_REQUESTING'
  'TAGS_DESTROY_SUCCESS'
  'TAGS_DESTROY_FAILURE'
  'TAGS_MARK_DELETED_REQUESTING'
  'TAGS_MARK_DELETED_SUCCESS'
  'TAGS_MARK_DELETED_FAILURE'

  'TAG_GROUPS_GET_REQUESTING'
  'TAG_GROUPS_GET_SUCCESS'
  'TAG_GROUPS_GET_FAILURE'

  'TAG_LIST_REQUESTING'
  'TAG_LIST_SUCCESS'
  'TAG_LIST_FAILURE'

  'TAG_GROUP_CREATION_REQUESTING'
  'TAG_GROUP_CREATION_SUCCESS'
  'TAG_GROUP_CREATION_FAILURE'

  'TAG_GROUP_UPDATE_REQUESTING'
  'TAG_GROUP_UPDATE_SUCCESS'
  'TAG_GROUP_UPDATE_FAILURE'

  'TAG_GROUP_DELETE_REQUESTING'
  'TAG_GROUP_DELETE_SUCCESS'
  'TAG_GROUP_DELETE_FAILURE'

  'TAGS_BY_GROUP_REQUESTING'
  'TAGS_BY_GROUP_SUCCESS'
  'TAGS_BY_GROUP_FAILURE'

  'TAGS_FOR_ORDER_REQUESTING'
  'TAGS_FOR_ORDER_SUCCESS'
  'TAGS_FOR_ORDER_FAILURE'

  'TAGS_PREINSTALLED_FOR_CONV_REQUESTING'
  'TAGS_PREINSTALLED_FOR_CONV_SUCCESS'
  'TAGS_PREINSTALLED_FOR_CONV_FAILURE'
  'TAGS_PREINSTALLED_TARGET_CREATE_REQUESTING'
  'TAGS_PREINSTALLED_TARGET_CREATE_SUCCESS'
  'TAGS_PREINSTALLED_TARGET_CREATE_FAILURE'
  'TAGS_PREINSTALLED_TARGET_DESTROY_REQUESTING'
  'TAGS_PREINSTALLED_TARGET_DESTROY_SUCCESS'
  'TAGS_PREINSTALLED_TARGET_DESTROY_FAILURE'

  'CREDENTIALS_GET_LIST_REQUESTING'
  'CREDENTIALS_GET_LIST_SUCCESS'
  'CREDENTIALS_GET_LIST_FAILURE'
  'CREDENTIALS_SET_LIST'
  'CREDENTIAL_GET_DECRYPTED_REQUESTING'
  'CREDENTIAL_GET_DECRYPTED_SUCCESS'
  'CREDENTIAL_GET_DECRYPTED_FAILURE'
  'CREDENTIAL_CREATE_REQUESTING'
  'CREDENTIAL_CREATE_SUCCESS'
  'CREDENTIAL_CREATE_FAILURE'
  'CREDENTIAL_UPDATE_REQUESTING'
  'CREDENTIAL_UPDATE_SUCCESS'
  'CREDENTIAL_UPDATE_FAILURE'
  'CREDENTIAL_DESTROY_REQUESTING'
  'CREDENTIAL_DESTROY_SUCCESS'
  'CREDENTIAL_DESTROY_FAILURE'

  'CONVERSATIONS_INFO_UPDATE_STATE'
  'CONVERSATIONS_INIT_LIST'

  'CONVERSATION_TEMPLATES_SET_TEMPLATE'
  'CONVERSATION_TEMPLATES_CLEAR_TEMPLATE'

  'TAG_TARGET_CREATE_REQUESTING'
  'TAG_TARGET_CREATE_SUCCESS'
  'TAG_TARGET_CREATE_FAILURE'
  'TAG_TARGET_DESTROY_REQUESTING'
  'TAG_TARGET_DESTROY_SUCCESS'
  'TAG_TARGET_DESTROY_FAILURE'

  # private for customer form
  'CUSTOMER_GET_REQUESTING'
  'CUSTOMER_GET_SUCCESS'
  'CUSTOMER_GET_FAILURE'
  'CUSTOMER_UPDATE_REQUESTING'
  'CUSTOMER_UPDATE_SUCCESS'
  'CUSTOMER_UPDATE_FAILURE'
  'CUSTOMER_CREATE_REQUESTING'
  'CUSTOMER_CREATE_SUCCESS'
  'CUSTOMER_CREATE_FAILURE'
  'CUSTOMER_CLEAR'
  'CUSTOMER_ORDERS_CLEAR'

  'SETTINGS_GET_REQUESTING'
  'SETTINGS_GET_SUCCESS'
  'SETTINGS_GET_FAILURE'

  'SETTINGS_UPDATE_REQUESTING'
  'SETTINGS_UPDATE_SUCCESS'
  'SETTINGS_UPDATE_FAILURE'

  'SETTINGS_GET_BY_TYPE_REQUESTING'
  'SETTINGS_GET_BY_TYPE_SUCCESS'
  'SETTINGS_GET_BY_TYPE_FAILURE'

  'PROMPTS_EDIT'
  'PROMPTS_UPDATE_REQUESTING'
  'PROMPTS_UPDATE_SUCCESS'
  'PROMPTS_UPDATE_FAILURE'

  'RANKING_OPEN_CONVERSATION'
  'RANKING_CLOSE_CONVERSATION'
  'RANKING_START'
  'RANKING_CHANGE_ESTIMATION'
  'RANKING_RETURN_TO_SAVED'
  'RANKING_STOP'
  'RANKING_SELECT_MESSAGE'
  'RANKING_SUBMIT_REQUESTING'
  'RANKING_SUBMIT_SUCCESS'
  'RANKING_SUBMIT_FAILURE'

  'RANKING_CONVERSATIONS_REQUESTING'
  'RANKING_CONVERSATIONS_SUCCESS'
  'RANKING_CONVERSATIONS_FAILURE'
  'RANKING_CLOSE_ALL_CONVERSATION'

  'RANKING_RULES_OPEN_CONVERSATION'
  'RANKING_RULES_CONVERSATIONS_REQUESTING'
  'RANKING_RULES_CONVERSATIONS_SUCCESS'
  'RANKING_RULES_CONVERSATIONS_FAILURE'
  'RANKING_RULES_SELECT_MESSAGE'

  'RANKING_CONVERSATIONS_MESSAGE_DELETE_REPORT'
  'RANKING_CONVERSATIONS_MESSAGE_ADD_REPORTS'
  'RANKING_CONVERSATIONS_MESSAGE_DELETE_REPORTS'

  'RANKING_RULES_REQUESTING'
  'GET_RANKING_RULES_REQUESTING'
  'GET_RANKING_RULES_SUCCESS'
  'GET_RANKING_RULES_FAILURE'

  'UPDATE_RANKING_RULE_SUCCESS'
  'UPDATE_RANKING_RULE_FAILURE'

  'UPDATE_RANKING_RULES_SUCCESS'
  'DELETE_RANKING_RULES_SUCCESS'
  'UPDATE_RANKING_RULES_FAILURE'
  'DELETE_RANKING_RULES_FAILURE'

  'DELETE_RANKING_RULE_SUCCESS'
  'DELETE_RANKING_RULE_FAILURE'

  'RANKING_RULES_CLOSE_ALL_CONVERSATION'

  'QA_GET_STATISTIC_REQUESTING'
  'QA_GET_STATISTIC_SUCCESS'
  'QA_GET_STATISTIC_FAIL'
  'QA_CHANGE_ORDER'

  'DASHBOARD_REQUESTING'
  'DASHBOARD_SUCCESS'
  'DASHBOARD_ERROR'

  'RESPONSE_TIME_STAT_REQUESTING'
  'RESPONSE_TIME_STAT_SUCCESS'
  'RESPONSE_TIME_STAT_ERROR'
  'WAITING_TIME_STAT_REQUESTING'
  'WAITING_TIME_STAT_SUCCESS'
  'WAITING_TIME_STAT_ERROR'
  'LAST_DAYS_STAT_REQUESTING'
  'LAST_DAYS_STAT_SUCCESS'
  'LAST_DAYS_STAT_ERROR'
  'GOOD_RT_WT_REQUESTING'
  'GOOD_RT_WT_SUCCESS'
  'GOOD_RT_WT_ERROR'
  'UNIQUE_CLIENTS_BY_AGENTS_REQUESTING'
  'UNIQUE_CLIENTS_BY_AGENTS_SUCCESS'
  'UNIQUE_CLIENTS_BY_AGENTS_ERROR'
  'AGENT_CHATS_REQUESTING'
  'AGENT_CHATS_SUCCESS'
  'AGENT_CHATS_ERROR'
  'CONVERSATIONS_EACH_HOUR_REQUESTING'
  'CONVERSATIONS_EACH_HOUR_SUCCESS'
  'CONVERSATIONS_EACH_HOUR_ERROR'

  'CSV_EXPORT_REQUESTING'
  'CSV_EXPORT_SUCCESS'
  'CSV_EXPORT_FAILURE'

  'UCPOPS_REQUESTING'
  'UCPOPS_SUCCESS'
  'UCPOPS_ERROR'

  'GROUPS_GET_REQUESTING'
  'GROUPS_GET_SUCCESS'
  'GROUPS_GET_FAILURE'

  'GROUP_CREATE_REQUESTING'
  'GROUP_CREATE_SUCCESS'
  'GROUP_CREATE_FAILURE'

  'GROUP_DELETE_REQUESTING'
  'GROUP_DELETE_SUCCESS'
  'GROUP_DELETE_FAILURE'

  'GROUP_COMPOSITION_REQUESTING'
  'GROUP_COMPOSITION_SUCCESS'
  'GROUP_COMPOSITION_FAILURE'

  'GROUP_UPDATE_REQUESTING'
  'GROUP_UPDATE_SUCCESS'
  'GROUP_UPDATE_FAILURE'

  'GROUPS_PAGES_REQUESTING'
  'GROUPS_PAGES_SUCCESS'
  'GROUPS_PAGES_FAILURE'

  'GROUP_GET_USERS_REQUESTING'
  'GROUP_GET_USERS_SUCCESS'
  'GROUP_GET_USERS_FAILURE'

  'GROUP_GET_CHANNELS_REQUESTING'
  'GROUP_GET_CHANNELS_SUCCESS'
  'GROUP_GET_CHANNELS_FAILURE'

  'GROUP_OPTIONS_REQUESTING'
  'GROUP_OPTIONS_SUCCESS'

  'INTERFACE_OPEN_MODAL'
  'INTERFACE_CLOSE_MODAL'

  'INTERFACE_OPEN_LOCKED_MODAL'
  'INTERFACE_CLOSE_LOCKED_MODAL'

  'INTERFACE_OPEN_POPUP'
  'INTERFACE_CLOSE_POPUP'
  'INTERFACE_HIDE_ALL_APP_DETAILS'

  'REPLY_ANSWERS_CREATE_REQUESTING'
  'REPLY_ANSWERS_CREATE_SUCCESS'
  'REPLY_ANSWERS_CREATE_FAILURE'
  'REPLY_ANSWERS_GET_REQUESTING'
  'REPLY_ANSWERS_GET_SUCCESS'
  'REPLY_ANSWERS_GET_FAILURE'
  'REPLY_ANSWERS_UPDATE_REQUESTING'
  'REPLY_ANSWERS_UPDATE_SUCCESS'
  'REPLY_ANSWERS_UPDATE_FAILURE'
  'REPLY_ANSWERS_DELETE_REQUESTING'
  'REPLY_ANSWERS_DELETE_SUCCESS'
  'REPLY_ANSWERS_DELETE_FAILURE'
  'REPLY_ANSWERS_FAVORITE_REQUESTING'
  'REPLY_ANSWERS_FAVORITE_SUCCESS'
  'REPLY_ANSWERS_FAVORITE_FAILURE'

  'REPLY_GROUPS_GET_REQUESTING'
  'REPLY_GROUPS_GET_SUCCESS'
  'REPLY_GROUPS_GET_FAILURE'
  'REPLY_GROUPS_MORE_GET_REQUESTING'
  'REPLY_GROUPS_MORE_GET_SUCCESS'
  'REPLY_GROUPS_MORE_GET_FAILURE'
  'REPLY_GROUPS_CREATE_REQUESTING'
  'REPLY_GROUPS_CREATE_SUCCESS'
  'REPLY_GROUPS_CREATE_FAILURE'
  'REPLY_GROUPS_DELETE_REQUESTING'
  'REPLY_GROUPS_DELETE_SUCCESS'
  'REPLY_GROUPS_DELETE_FAILURE'
  'REPLY_GROUPS_RESET'


  'CREATE_TEAMLEAD_SHIFT_REQUESTING'
  'CREATE_TEAMLEAD_SHIFT_FAILURE'
  'CREATE_TEAMLEAD_SHIFT_SUCCESS'

  'CUSTOMER_TICKETS_GET_REQUESTING'
  'CUSTOMER_TICKETS_GET_SUCCESS'
  'CUSTOMER_TICKETS_GET_FAILURE'

  'ADMIN_REMINDERS_GET_REQUESTING'
  'ADMIN_REMINDERS_GET_SUCCESS'
  'ADMIN_REMINDERS_GET_FAILURE'
  'ADMIN_REMINDERS_BY_DAY_GET_REQUESTING'
  'ADMIN_REMINDERS_BY_DAY_GET_SUCCESS'
  'ADMIN_REMINDERS_BY_DAY_GET_FAILURE'
  'ADMIN_REMINDERS_UPDATE_REQUESTING'
  'ADMIN_REMINDERS_UPDATE_SUCCESS'
  'ADMIN_REMINDERS_UPDATE_FAILURE'
  'ADMIN_REMINDERS_DESTROY_REQUESTING'
  'ADMIN_REMINDERS_DESTROY_SUCCESS'
  'ADMIN_REMINDERS_DESTROY_FAILURE'

  'ADMIN_SCHEDULED_MESSAGES_GET_REQUESTING'
  'ADMIN_SCHEDULED_MESSAGES_GET_SUCCESS'
  'ADMIN_SCHEDULED_MESSAGES_GET_FAILURE'
  'ADMIN_SCHEDULED_MESSAGES_BY_DAY_GET_REQUESTING'
  'ADMIN_SCHEDULED_MESSAGES_BY_DAY_GET_SUCCESS'
  'ADMIN_SCHEDULED_MESSAGES_BY_DAY_GET_FAILURE'
  'ADMIN_SCHEDULED_MESSAGES_UPDATE_REQUESTING'
  'ADMIN_SCHEDULED_MESSAGES_UPDATE_SUCCESS'
  'ADMIN_SCHEDULED_MESSAGES_UPDATE_FAILURE'
  'ADMIN_SCHEDULED_MESSAGES_DESTROY_REQUESTING'
  'ADMIN_SCHEDULED_MESSAGES_DESTROY_SUCCESS'
  'ADMIN_SCHEDULED_MESSAGES_DESTROY_FAILURE'

  'ADMIN_CUSTOMERIO_EVENTS_GET_REQUESTING'
  'ADMIN_CUSTOMERIO_EVENTS_GET_SUCCESS'
  'ADMIN_CUSTOMERIO_EVENTS_GET_FAILURE'
  'ADMIN_CUSTOMERIO_EVENTS_BY_TEXT_GET_REQUESTING'
  'ADMIN_CUSTOMERIO_EVENTS_BY_TEXT_GET_SUCCESS'
  'ADMIN_CUSTOMERIO_EVENTS_BY_TEXT_GET_FAILURE'
  'ADMIN_CUSTOMERIO_EVENTS_PAUSE_REQUESTING'
  'ADMIN_CUSTOMERIO_EVENTS_PAUSE_SUCCESS'
  'ADMIN_CUSTOMERIO_EVENTS_PAUSE_FAILURE'
  'ADMIN_CUSTOMERIO_EVENTS_DESTROY_REQUESTING'
  'ADMIN_CUSTOMERIO_EVENTS_DESTROY_SUCCESS'
  'ADMIN_CUSTOMERIO_EVENTS_DESTROY_FAILURE'

  'SLA_TRACKING_REQUESTING'
  'SLA_TRACKING_SUCCESS'
  'SLA_TRACKING_FAILURE'

  'MESSAGE_ERRORS_REQUESTING'
  'MESSAGE_GLOSSARY_ERRORS_REQUESTING'
  'MESSAGE_ERRORS_SUCCESS'
  'MESSAGE_GLOSSARY_ERRORS_SUCCESS'
  'MESSAGE_ERRORS_FAILURE'

  'ANOMALY_TAKE_NEW_CLICK'

  'SCHEDULED_MESSAGES_UNCOMPLETED_REQUESTING'
  'SCHEDULED_MESSAGES_UNCOMPLETED_SUCCESS'
  'SCHEDULED_MESSAGES_UNCOMPLETED_FAILURE'
  'SCHEDULED_MESSAGE_CREATE_REQUESTING'
  'SCHEDULED_MESSAGE_CREATE_SUCCESS'
  'SCHEDULED_MESSAGE_CREATE_FALIURE'
  'SCHEDULED_MESSAGE_DESTROY_REQUESTING'
  'SCHEDULED_MESSAGE_DESTROY_SUCCESS'
  'SCHEDULED_MESSAGE_DESTROY_FALIURE'
  'SCHEDULED_MESSAGE_UPDATE_REQUESTING'
  'SCHEDULED_MESSAGE_UPDATE_SUCCESS'
  'SCHEDULED_MESSAGE_UPDATE_FALIURE'

  'FLAGS_CHANGE'
  'FLAGS_SYNC'

  'DOWNLOAD_ALL_REQUESTING'
  'DOWNLOAD_ALL_SUCCESS'
  'DOWNLOAD_ALL_FAILURE'

  'DOWNLOAD_FILE_REQUESTING'
  'DOWNLOAD_FILE_SUCCESS'
  'DOWNLOAD_FILE_FAILURE'

  'RANKING_MESSAGE_REQUESTING'
  'RANKING_MESSAGE_SUCCESS'
  'RANKING_MESSAGE_FAILURE'

  'RANKING_MESSAGE_GROUPS_REQUESTING'
  'RANKING_MESSAGE_GROUPS_SUCCESS'
  'RANKING_MESSAGE_GROUPS_FAILURE'

  'RANKING_MESSAGE_RANK_REQUESTING'
  'RANKING_MESSAGE_SKIP'

  'IMAGES_PREVIEW_CHECK'

  'GET_FILE_URLS_REQUESTING'
  'GET_FILE_URLS_SUCCESS'
  'GET_FILE_URLS_ERROR'
  'FILE_DETAIL_PREVIEW_OPENED'

  'ORDERS_SET_PAGE'

  'SET_XMPP_ACTION'
  'SET_XMPP_ROSTERS'
  'SET_OPERATOR_TYPING'
  'SET_OPERATOR_MANUAL_TYPING'

  'REPLY_GROUPS_UPDATE_REQUESTING'
  'REPLY_GROUPS_UPDATE_SUCCESS'
  'REPLY_GROUPS_UPDATE_FAILURE'
  'REPLY_ANSWERS_VISIBILITY_REQUESTING'
  'REPLY_ANSWERS_VISIBILITY_SUCCESS'
  'REPLY_ANSWERS_VISIBILITY_FAILURE'

  'UPDATE_EDITING_PARAMS'

  'SELECT_TAB'

}
