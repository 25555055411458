classnames = require 'classnames'

styles = require './credentials-form'
InlineTextInput = require 'components/shared/InlineTextInput/InlineTextInput'
CREDENTIAL_TYPE = require 'constants/CredentialTypes'
CREDITALS_PATTERNS = require 'constants/CredentialPaterns'

propTypes = require 'prop-types'

class CredentialsFormComponent extends React.Component
  @propTypes:
    credentials: propTypes.arrayOf(propTypes.object).isRequired
    type: propTypes.string
    onSubmit: propTypes.func
    onDestroy: propTypes.func
    onDecryptCredential: propTypes.func

  render: ->
    React.createElement("div", null,
      (if @props.credentials?
        for credential, index in @props.credentials
          credential &&
          React.createElement(CredentialFormComponent, { \
            "key": (index),  \
            "credential": (credential),  \
            "className": (@props.className),  \
            "onDecryptCredential": (
              R.partial(@props.onDecryptCredential, [@props.type, index]) if @props.onDecryptCredential
            ),  \
            "onSubmit": (R.partial(@props.onSubmit, [@props.type, index]) if @props.onSubmit),  \
            "onDestroy": (R.partial(@props.onDestroy, [@props.type, index]) if @props.onDestroy),  \
            "disabled": (@props.disabled)
          })
      )
    )

onChooseClick = (onSubmit, isChosen, e) ->
  e.stopPropagation()    
  onSubmit(null, !isChosen)

getChooseComponent = (isChosen, isEncrypted, onSubmit) ->
  (isEncrypted && !isChosen) ||
  (React.createElement("i", { \
    "onClick": (if isEncrypted then null else onChooseClick.bind null, onSubmit, isChosen),  \
    "className": (classnames(styles.chosen_control,
      "#{styles.chosen_active}": isChosen
    )) 
  }))

CredentialFormComponent = (props) ->
  { credential } = props
  React.createElement("div", {"className": (styles.container)},
    React.createElement("div", {"className": (styles.field_container)},
      React.createElement("div", { \
        "className": (classnames(
          props.className
          styles.credential_icon
          styles["credential_icon_#{credential.credential_type}"]
        ))
      }),
      (
        if props.disabled
          React.createElement("span", {"className": (props.className)}, (credential.value))
        else
          React.createElement(InlineTextInput, {  \
            "className": (props.className),  \
            "value": (credential.value),  \
            "onSubmit": (props.onSubmit),  \
            "onDelete": (props.onDestroy),  \
            "isDecrypted": (credential.isDecrypted),  \
            "onDecryptCredential": (props.onDecryptCredential),  \
            "textAddition": ((isEncrypted) => getChooseComponent(credential.is_chosen, isEncrypted, props.onSubmit)),  \
            "disabled": (!CREDENTIAL_TYPE._EDITABLE[credential.credential_type]),  \
            "pattern": (CREDITALS_PATTERNS[credential.credential_type]),  \
            "id": (credential.id),  \
            "isValidEmail": (credential.isValid)
          })
      )
    )
  )

CredentialFormComponent.propTypes =
  credential: propTypes.object.isRequired
  onSubmit: propTypes.func
  onDestroy: propTypes.func
  onDecryptCredential: propTypes.func

module.exports = CredentialsFormComponent
