XMPPActionCreator = require 'actions/XMPPActionCreator'

EmojiPicker = require('emojione-picker/lib/picker').default

styles = require './emoji-picker.styl'

class EmojiPeackerComponent extends React.Component
  constructor: ->
    super()
    @state = open: false

  componentWillUnmount: () ->
    setTimeout(
      ->
        XMPPActionCreator.setManualTyping false
      0
    )

  openPicker: =>
    document.addEventListener 'click', @closePicker
    @setState open: true
    setTimeout(
      ->
        XMPPActionCreator.setManualTyping true
      0
    )

  closePicker: (e) =>
    element = e.target.parentElement
    isShouldBeClosed = true
    
    while element
      isShouldBeClosed = false if element.getAttribute('id') == 'emoji-picker-container'
      element = element.parentElement

    if isShouldBeClosed 
      document.removeEventListener 'click', @closePicker 
      @setState open: false
      setTimeout(
        ->
          XMPPActionCreator.setManualTyping false
        0
      )
  
  parseUnicodeString: (str) ->
    codes = str.split('-')
    emoji = codes.map (code) ->
      String.fromCodePoint Number.parseInt code, 16
    emoji.join('')

  onEmojiClick: (data) =>
    nativeEmojiFromUTF16 = @parseUnicodeString data.unicode
    @props.onSelectEmoji nativeEmojiFromUTF16

  render: ->
    React.createElement("div", {"className": (styles.container)},
      React.createElement("img", {  \
        "src": (require './images/smile.svg'),   \
        "className": (styles.icon),   \
        "onClick": (@openPicker),  \
        "alt": '🙂'
      }),
      (if @state.open
        React.createElement("div", {"id": 'emoji-picker-container', "className": (styles.picker)},
          React.createElement(EmojiPicker, {"onChange": (@onEmojiClick)})
        )
      )
    )

module.exports = EmojiPeackerComponent
