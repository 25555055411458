browserHistory                            = require './../../../history'
ReactPaginate                             = require 'react-paginate'
_                                         = require 'underscore'
deepcopy                                  = require 'deepcopy'
moment                                    = require 'moment'
classnames                                = require 'classnames'
{ menus, GMTOffset }                      = require 'root/config'
{ getWeekByDate, generateNewRangeByType } = require 'lib/calendar-calculator'

BaseComponent                             = require 'components/BaseComponent'
RankingFilterComponent                    = require 'components/ranking/RankingFilterComponent'
RankingStatisticComponent                 = require 'components/ranking/RankingStatisticComponent'
RankingConversationItemComponent          = require '../RankingConversationItemComponent'

AuthStore                                 = require 'stores/AuthStore'
RankingConversationsStore                 = require 'stores/RankingConversationsStore'

TagsActionCreator                         = require 'actions/TagsActionCreator'
RankingConversationsActionCreator         = require 'actions/RankingConversationsActionCreator'

LoadableWrapperComponent                  = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
paginationStyles                          = require 'components/shared/styles/pagination.styl'

{ STATUSES_RANKING_RULE, QUALITY, BAD_TAGS, CONVERSATIONS_ORDERING, CONVERSATIONS_PER_PAGE, TO_BE_RANKED_PERCENT } = require 'constants/Ranking'
{ ROLES } = require 'constants/Agents'
{ SHIFT_TYPES } = require 'constants/Shifts'

{ getQueryParams, buildQueryParamsString } = require 'lib/utils'

styles = require './ranking-conversations'

accessConfig = {}
menus.forEach (item) ->
  if item.key == 'page_ranking_tools'
    accessConfig =
      toEdit: item.accessToEdit || []
      another: item.anotherAccess || []

RANGE_TYPES = ['weekly', 'daily', 'monthly']

class RankingRulesConversationsComponent extends BaseComponent

  constructor: (props) ->
    super(props)
    userRole = ROLES[AuthStore.user.role].value
    @access = if accessConfig.toEdit || accessConfig.another
      userRole in accessConfig.toEdit || userRole in accessConfig.another
    else
      false
    params = getQueryParams window.location.search.substring 1
    filters = {}
    
    if params.status == 'ranked' || (Array.isArray(params.status) && params.status.includes 'ranked')
      __params = new URLSearchParams(window.location.search.substring 1)
      __params.delete('status')
      window.location.href = "#{window.location.origin}#{window.location.pathname}?#{__params}"
      return
    
    filters = @parseFilter params, 'status', filters, 'status', STATUSES_RANKING_RULE
    filters = @parseFilter params, 'quality', filters, 'quality', QUALITY
    filters = @parseFilter params, 'bad_tag', filters, 'badTag', BAD_TAGS

    shiftType = undefined
    if params.shift_type
      if params.shift_type.push
        shiftType = {}
        params.shift_type.forEach (shift) ->
          indexOfShift = SHIFT_TYPES.indexOf(shift.toLowerCase())
          shiftType[indexOfShift] = true if indexOfShift != -1
      else
        indexOfShift = SHIFT_TYPES.indexOf(params.shift_type.toLowerCase())
        shiftType =
          "#{indexOfShift}": true if indexOfShift != -1

    conversationTag = undefined
    if params.conversation_tag
      if params.conversation_tag.push
        conversationTag = {}
        params.conversation_tag.forEach (tag) ->
          conversationTag[tag] = true
      else
        conversationTag =
          "#{params.conversation_tag}": true

    tagsGroupId = undefined
    if params.tags_group_id
      if params.tags_group_id.push
        tagsGroupId = {}
        params.tags_group_id.forEach (tag) ->
          tagsGroupId[tag] = true
      else
        tagsGroupId =
          "#{params.tags_group_id}": true

    clientTag = undefined
    if params.client_tag
      if params.client_tag.push
        clientTag = {}
        params.client_tag.forEach (tag) ->
          clientTag[tag] = true
      else
        clientTag =
          "#{params.client_tag}": true

    rangeType = 'weekly'
    unless RANGE_TYPES.indexOf(params.range_type) == -1
      rangeType = params.range_type

    @agentsIds = []
    if @access
      if params.agent_id
        if params.agent_id.push
          @agentsIds = params.agent_id.map (agentId) ->
            Number.parseInt agentId
        else
          @agentsIds.push Number.parseInt params.agent_id
    else
      @agentsIds = [AuthStore.user.id]
    agents = @agentsIds.map (id) -> { id }

    page = Number.parseInt params.page
    if !page || page < 0
      page = 0

    perPage = Number.parseInt params.per_page || CONVERSATIONS_PER_PAGE

    @state = {
      agents
      filters
      page
      perPage
      shiftType
      rangeType
      conversationTag
      clientTag
      tagsGroupId
    }

    @state.order = (CONVERSATIONS_ORDERING[params.order] || {}).value if @agentsIds.length == 1

    dateRange = null
    beginDate = new moment params.begin_date
    endDate = new moment params.end_date
    dates = generateNewRangeByType rangeType, beginDate, endDate, true
    @state.beginDate = dates.beginDate
    @state.endDate = dates.endDate

    @conversations = []
    @initComponent()

  dependsOnStores: [AuthStore, RankingConversationsStore]

  componentWillMount: ->
    if accessConfig.toPage
      unless ROLES[AuthStore.user.role].value in accessConfig.toPage
        browserHistory.default.push '/'

  componentDidMount: ->
    { state, downloadConversations } = @
    setTimeout(
      ->
        data =
          sourceType: 'orders'
          type: 'conversation'
        TagsActionCreator.getGroupList data
      15
    )
    setTimeout(
      ->
        TagsActionCreator.getList()
        downloadConversations state
      10
    )
    super()

  componentWillUpdate: ->
    if RankingConversationsStore.saveConversationIndex
      RankingConversationsStore.saveConversationIndex = false
      @nextConversationId = @getIdOfNextConversation()

    if RankingConversationsStore.shouldUpdateCounts
      RankingConversationsStore.shouldUpdateCounts = false
      clearTimeout @timeoutId
      state = @state
      @conversationId = @props.match.params.id
      @timeoutId = setTimeout(
        @goToNewConversation.bind @
        1000
      )

    @conversations = RankingConversationsStore.conversations
    if @state.order && (@state.agents || {}).length == 1
      @conversations = _.sortBy(@conversations, @state.order).reverse()

    if @agentsIds
      if RankingConversationsStore.isAgentsDownloaded && RankingConversationsStore.agents.length
        @agentsIds = undefined
        @setState(agents: RankingConversationsStore.agents)
      else if RankingConversationsStore.isAgentsDownloaded
        @agentsIds = undefined

  downloadConversations: (state) =>
    params = @mapStateToParams state
    queryParams = "?#{buildQueryParamsString params}"
    window.history.replaceState null, null, queryParams
    RankingConversationsActionCreator.getConversations params

  mapStateToParams: (state) ->
    params = {
      rule_ranking: true,
    }
    params.status = Object.keys(state.filters.status) if state.filters.status
    params.quality = Object.keys(state.filters.quality) if state.filters.quality
    params.bad_tag = Object.keys(state.filters.badTag) if state.filters.badTag

    if state.shiftType
      params.shift_type = Object.keys state.shiftType
        .map (shift) -> SHIFT_TYPES[shift]

    params.conversation_tag = Object.keys(state.conversationTag) if state.conversationTag
    params.client_tag = Object.keys(state.clientTag) if state.clientTag
    params.tags_group_id = Object.keys(state.tagsGroupId) if state.tagsGroupId


    params.range_type = state.rangeType
    params.begin_date = state.beginDate
    params.end_date = state.endDate

    if state.order
      params.order = state.order
    else
      params.page = state.page
      params.per_page = state.perPage

    if state.agents
      params.agent_id = state.agents.map (agent) -> agent.id

    params

  parseFilter: (params, paramsField, filters, filtersField, constants) ->
    if params[paramsField]
      if params[paramsField].push
        filters[filtersField] = {}
        params[paramsField].forEach (item) ->
          filters[filtersField][item.toLowerCase()] = true if constants[item.toLowerCase()]
      else
        filters[filtersField] =
          "#{params[paramsField].toLowerCase()}": true if constants[params[paramsField].toLowerCase()]
    filters

  getIdOfNextConversation: =>
    nextId = null
    indexOfCurrent = @findIndexOfCurrentConversation()
    if indexOfCurrent != null
      startIndex = if indexOfCurrent == @conversations.length - 1
        0
      else
        indexOfCurrent + 1
      index = @findIndexOfNextConversation startIndex, indexOfCurrent
      nextId = (@conversations[index] || {}).id || null
    nextId

  findIndexOfNextConversation: (index, indexOfCurrent) =>
    return null if index == indexOfCurrent
    if @conversations[index].approve_data.status == STATUSES_RANKING_RULE.unranked.value
      return index
    else if index == @conversations.length - 1
      index = 0
      return @findIndexOfNextConversation index, indexOfCurrent

    return @findIndexOfNextConversation index + 1, indexOfCurrent

  findIndexOfCurrentConversation: =>
    paramsId = Number.parseInt @props.match.params.id
    i = 0
    indexOfCurrent = null
    while i < @conversations.length
      if paramsId == @conversations[i].id
        indexOfCurrent = i
        i = @conversations.length
      i++
    indexOfCurrent

  isCountsEnabled: () =>
    isOneAgentSelected = (@state.agents || []).length == 1
    isRightFilters = _.isEqual(
      @state.filters,
      status:
        "#{STATUSES_RANKING_RULE.unranked.value}": true
    )
    withoutAnotherFilters = !@state.shiftType && !@state.conversationTag && !@state.clientTag && !@state.tagsGroupId
    isOneAgentSelected && isRightFilters && withoutAnotherFilters

  goToNextUnrankedConversation: () =>
    nowConversationId = Number @props.match.params.id
    conversationId = null
    if !RankingConversationsStore.conversations
      return

    allUnrankedConversations = RankingConversationsStore.conversations.filter((conversation) -> conversation.approve_data && conversation.approve_data.rule_ranking && conversation.approve_data.rule_ranking.status == 'unranked')
    
    if allUnrankedConversations.length == 0 
      return

    if !nowConversationId
      conversationId = allUnrankedConversations[0].id
    else
      conversationindex = allUnrankedConversations.findIndex((conversation) => conversation.id == nowConversationId)
      if conversationindex >= allUnrankedConversations.length - 1 
        return
      if conversationindex < 0
        conversationId = allUnrankedConversations[0].id
      else
        conversationId = allUnrankedConversations[conversationindex + 1].id

    link = "/page_ranking_tools/#{conversationId}#{window.location.search}"
    browserHistory.default.push link

  goToNewConversation: =>
    if RankingConversationsStore.loading
      clearTimeout(@timeoutId)
      @timeoutId = setTimeout(
        @goToNewConversation.bind @
        500
      )
      return
    unless RankingConversationsStore.conversations.length
      page = if @state.page then @state.page - 1 else 0
      @setState { page }
    else
      if @conversationId == @props.match.params.id && @nextConversationId != null
        browserHistory.default.push "/page_ranking_tools/#{@nextConversationId}#{window.location.search}"

  onChangeFiltersHandler: (
    filters, shiftType, order, conversationTag, clientTag, tagsGroupId, beginDate, endDate
  ) =>
    # agentsIds = @state.agents.map((agent) -> agent.id) if @state.agents
    state = Object.assign @state, {
      filters
      shiftType
      order
      conversationTag
      clientTag
      tagsGroupId
      beginDate
      endDate
      page: 0
    }
    @downloadConversations state
    @setState state

  onAgentsFilterHandler: (agents) =>
    newAgents = agents
    states = @state
    delete states.order unless agents.length == 1
    if agents.length
      states.agents = newAgents
      newAgents = newAgents.map (agent) -> agent.id
    else
      delete states.agents
      newAgents = undefined

    states.page = 0
    @downloadConversations states
    @setState states

  onPageChange: ({ selected }) =>
    { state } = @
    unless selected == state.page
      state.page = selected
      @downloadConversations state
      @setState state

  onRangeTypeChange: (type) =>
    { state } = @
    if state.rangeType == 'monthly'
      date = moment(state.beginDate).add(2, 'weeks')
      dates = generateNewRangeByType type, date, date, true
    else
      dates = generateNewRangeByType type, state.beginDate, state.endDate, true

    state.rangeType = type
    state.beginDate = dates.beginDate
    state.endDate = dates.endDate
    @downloadConversations state
    @setState state

  isActiveLink: (link) -> window.location.pathname == link

  onConversationClick: (e, link) ->
    e.preventDefault()
    browserHistory.default.push link

  render: ->
    queryParams = window.location.search
    conversationsHeight = @refs.filters.clientHeight if @refs.filters
    { isActiveLink, onConversationClick, goToNextUnrankedConversation } = @

    React.createElement("div", {"className": (styles.container)},
      React.createElement("div", {"ref": "filters"},
        React.createElement(RankingFilterComponent, { \
          "filters": (@state.filters),  \
          "onChangeFiltersHandler": (@onChangeFiltersHandler),  \
          "onAgentsFilterHandler": (@onAgentsFilterHandler),  \
          "beginDate": (@state.beginDate),  \
          "endDate": (@state.endDate),  \
          "agents": (@state.agents || []),  \
          "shiftType": (@state.shiftType),  \
          "rangeType": (@state.rangeType),  \
          "onRangeTypeChangeHandler": (@onRangeTypeChange),  \
          "order": (@state.order),  \
          "conversationTags": (RankingConversationsStore.conversationTags),  \
          "clientTags": (RankingConversationsStore.clientTags),  \
          "groupTags": (RankingConversationsStore.groupTags),  \
          "conversationTag": (@state.conversationTag),  \
          "clientTag": (@state.clientTag),  \
          "tagsGroupId": (@state.tagsGroupId),  \
          "access": (@access),  \
          "statuses": (STATUSES_RANKING_RULE),  \
          "onNextUnranked": (goToNextUnrankedConversation)
        }),
        (if @isCountsEnabled()
          { rankingStats } = RankingConversationsStore
          React.createElement(RankingStatisticComponent, { \
            "total": (rankingStats.total),  \
            "ranked": (rankingStats.ranked),  \
            "toRank": (rankingStats.toBeRanked)
          })
        )
      ),
      React.createElement("div", { \
        "style": (height: "calc(100% - #{conversationsHeight}px)"),  \
        "className": (styles.conversations)
      },
        (if RankingConversationsStore.error
          React.createElement("p", null, (RankingConversationsStore.error))
        else
          React.createElement(LoadableWrapperComponent, {"loading": (RankingConversationsStore.loading)},
            React.createElement("ul", {"className": (styles.conversations__list)},
              (@conversations.map (conversation, i) ->
                link = "/page_ranking_tools/#{conversation.id}#{queryParams}"
                pathname = "/page_ranking_tools/#{conversation.id}"

                React.createElement("li", {"key": (i)},
                  React.createElement("a", { \
                    "href": (link),  \
                    "onClick": ((e) -> onConversationClick(e, link)),  \
                    "className": (classnames(
                      styles.conversations__link
                      "#{styles.conversations__link_active}": isActiveLink(pathname)
                    ))
                  },
                    React.createElement(RankingConversationItemComponent, {"conversation": (conversation), "useRankingRules": (!!@props?.useRankingRules)})
                  )
                )
              )
            ),
            (if RankingConversationsStore.pageCount > 1 && !@state.order && @conversations.length
              React.createElement("div", {"className": (styles.conversations__pagination)},
                React.createElement(ReactPaginate.default, { \
                  "previousLabel": 'previous',  \
                  "nextLabel": 'next',  \
                  "pageCount": (RankingConversationsStore.pageCount),  \
                  "marginPagesDisplayed": (2),  \
                  "pageRangeDisplayed": (4),  \
                  "onPageChange": (@onPageChange),  \
                  "initialPage": (@state.page),  \
                  "pageClassName": (paginationStyles.page),  \
                  "nextClassName": (paginationStyles.page),  \
                  "previousClassName": (paginationStyles.page),  \
                  "activeClassName": (paginationStyles.active),  \
                  "pageLinkClassName": (paginationStyles.link),  \
                  "nextLinkClassName": (paginationStyles.link),  \
                  "previousLinkClassName": (paginationStyles.link),  \
                  "containerClassName": (paginationStyles.container),  \
                  "breakClassName": (paginationStyles.page)
                })
              )
            )
          )
        )
      )
    )

module.exports = RankingRulesConversationsComponent
