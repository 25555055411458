import React, { useState, useEffect } from 'react';

import { controls } from '@verdaccio/crminfo';

import RankingRulesAPI from 'api/RankingRulesAPI';

import styles from './styles.styl';

import ReportBody from '../ReportBody';

const ReportLog = ({ report, onAnyDataChange }) => {
  const { id, logs = [], noLogs } = report;
  const isReportHaveLogs = logs.length > 0;
  const [isLoading, toggleIsLoading] = useState(!isReportHaveLogs && !noLogs);
  const [reportLogs, setReportLogs] = useState(logs);
  const [isError, setError] = useState(false);


  const loadData = async () => {
    toggleIsLoading(true);
    RankingRulesAPI.getReportLogs(id, (response = {}) => {
      const { logs = [] } = response;

      if (logs.length === 0) {
        onAnyDataChange(id, {
          noLogs: true,
        });

        return;
      }

      const __logs = logs.reduce((prev, next) => {
        const {
          log_type, 
          info: {
            owner: {
              first_name,
              last_name,
            },
            changed_fields: changedFields = {},
            new_report: newReport,
            old_report: oldReport,
          },
          id,
          created_at
        } = next;

        let data = {
          ...newReport,
          created_at,
          logId: id,
          logType: log_type,
          author: (`${first_name} ${last_name}` || 'operator')
        };

        if (log_type !== 'create') {
          const __changedFields = Object.keys(changedFields).reduce((prev, next) => {
            if (next === 'ranking_rule_id') {
              if (changedFields[next][0] === null && changedFields[next][1] !== null) {
                prev.push('pillar', 'issue_type');
              } else if (changedFields[next][0] !== null && changedFields[next][1] !== null) {
                const { ranking_rule: newRanking_rule = {} } = newReport;
                const { ranking_rule: oldRanking_rule = {} } = oldReport;
                if (newRanking_rule.issue_type !== oldRanking_rule.issue_type) {
                  prev.push('issue_type');
                }
                if (newRanking_rule.pillar !== oldRanking_rule.pillar) {
                  prev.push('pillar');
                }
              }
              prev.push('rule');
            }
            else {
              prev.push(next);
            }

            return prev;
          }, []);
          data = {
            ...data,
            changedFields: __changedFields
          };
        }

        prev.push(data);

        return prev;
      }, []);

      setReportLogs(__logs);
      toggleIsLoading(false);
      onAnyDataChange(id, { logs: __logs });
    }, () => {
      setError(true);
      toggleIsLoading(false);
    });
  }

  useEffect(() => {
    if (isReportHaveLogs || noLogs) return;
  
    loadData();
  }, []);

  if (isError) {
    return (
      <div className={styles.no_data_container}>
        <i className={styles.no_data_container__sad_icon} />
        <div className={styles.no_data_container__text}>Oops...an error accured</div>
      </div>
    )
  }

  if (noLogs) {
    return (
      <div className={styles.no_data_container}>
        <div className={styles.no_data_container__text}>Sorry no logs for this report</div>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className={styles.no_data_container}>
        <controls.Loader smLoader />
        <div className={styles.no_data_container__text}>Loading from server...</div>
      </div>
    ) 
  }

  return reportLogs.map(report => <ReportBody key={report.logId} report={report} isLog />)
};

export default ReportLog;
