module.exports =
  CONVERSATION_TAGS_TYPE: 'conversation'
  CUSTOMER_TAGS_TYPE: 'customer'
  SYSTEM_TAGS_SOURCE: 'system'
  ORDERS_TAGS_SOURCE: 'orders'
  CUSTOMER_TAGS_NO_ACCOUNT: 'no account'
  CUSTOMER_TAGS_NO_REFERRAL: 'noreferral'
  SUBSCRIPTION_MAPPER: {
    'subscription_nerdify_basic': 'subscription_nerdify_premium_upd',
    'subscription_nerdify_basic_trial': 'subscription_nerdify_premium_trial_upd',
    'subscription_nerdify_premium': 'subscription_nerdify_elite_upd',
    'subscription_nerdify_premium_trial': 'subscription_nerdify_elite_trial_upd',
  }
