classnames = require 'classnames'

BaseComponent = require 'components/BaseComponent'
WaitingTimeStore = require './WaitingTimeStore'
ChartComponent = require '../../ChartComponent/ChartComponent'
{ buildOptions, getShiftStartTime } = require 'lib/dashboard-utils'
{ SECONDS_IN_MINUTE } = require 'constants/base'
styles = require '../dashboard.styl'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
MultistateSwitchComponent = require 'components/shared/MultistateSwitchComponent'
{ TIME_RANGES_PRESENTER, TIME_RANGES_REPRESENTER } = require 'constants/Dashboard'
LoaderComponent = require 'components/shared/LoaderComponent/LoaderComponent'

CHANNEL_LABELS =
  'phone': 'SMS-Chats',
  'xmpp': 'Web-Chats'

class WaitingTimeByChannelComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @state = {
      rangeLoading: null
      interval: null
    }
    @initComponent()

  getPersonalStoreClass: -> WaitingTimeStore

  componentDidMount: ->
    super()
    setTimeout(
      =>
        @actionCreator.get(channel_type: true)
      10
    )

  channelName: (channelType) ->
    CHANNEL_LABELS[channelType] || channelType

  getCurrentWaitingTime: (channelType) =>
    data = @personalStore.channelType[channelType]

    xAxis: 
      categories: ['sales', 'agents']
    yAxis: 
      plotLines: [
        value: data.good_wt
        color: 'red'
        width: 2
        label: 
          text: 'Good WT'
        zIndex: 4
      ]
      min: 0
      minRange: data.good_wt
    series: [
      name: 'Average waiting time'
      data: [data.current_wt.sales, data.current_wt.agents]
    ]
    chart: type: 'column'
    legend: enabled: false
    plotOptions:
      column:
        pointPadding: 0.2
        borderWidth: 0
        colors: ['#8711f5', '#5fa6e3']
        colorByPoint: true
    title: text: "Current #{@channelName(channelType)} Waiting Time"
    yTitle: 'Minutes'

  getShiftWaitingTime: (channelType) =>
    data = @personalStore.channelType[channelType]

    xAxis: 
      categories: ['sales', 'agents']
    yAxis:
      plotLines: [
        value: data.good_wt
        color: 'red'
        width: 2
        label: 
          text: 'Good WT'
        zIndex: 4
      ]
      min: 0
      minRange: data.good_wt
    series: [
      name: 'Average waiting time'
      data: [data.shift_wt.sales, data.shift_wt.agents]
    ]
    chart: type: 'column'
    legend: enabled: false
    plotOptions:
      column:
        pointPadding: 0.2
        borderWidth: 0
        colors: ['#8711f5', '#5fa6e3']
        colorByPoint: true
    title: text: "Shift #{@channelName(channelType)} Waiting Time"
    yTitle: 'Minutes'

  onRangeToggle: (range) =>
    return if range == @state.interval
    @setState { rangeLoading: true, interval: range }
    @actionCreator.get(
      channel_type: true
      interval: TIME_RANGES_REPRESENTER[range]
      @onRequestEndCallback
      @onRequestEndCallback
    )

  onRequestEndCallback: => @setState rangeLoading: false

  onRefreshClick: =>
    @setState rangeLoading: null
    @actionCreator.get(
      channel_type: true
      interval: TIME_RANGES_REPRESENTER[@state.interval]
    )

  render: ->
    React.createElement("div", {  \
      "className": (classnames styles.grid,
        "#{styles.grid__full_height}": @personalStore.loading
    )},
      React.createElement(LoadableWrapperComponent, {"loading": (@state.rangeLoading == null && @personalStore.loading)},
        (unless @state.rangeLoading == null && @personalStore.loading
          React.createElement("div", null,
            React.createElement("div", {"className": (styles.switch)},
              React.createElement(MultistateSwitchComponent, { \
                "defaultValue": (TIME_RANGES_PRESENTER[@personalStore.interval]),  \
                "values": (Object.keys TIME_RANGES_REPRESENTER),  \
                "onToggle": (@onRangeToggle),  \
                "fontStyle": (styles.switch_label)
              })
            ),
            (Object.keys(@personalStore.channelType).map (channelType) =>
              React.createElement("div", {"key": (channelType)},
                React.createElement("div", {"className": (styles.left_cell_of_grid)},
                  React.createElement("div", {"className": (styles.chart_container)},
                    React.createElement(ChartComponent, { \
                      "container": ("CurrentWaitingTime#{channelType}"),  \
                      "options": (buildOptions @getCurrentWaitingTime(channelType))
                    })
                  ),
                  (React.createElement(LoaderComponent, null) if @state.rangeLoading)
                ),
                React.createElement("div", {"className": (styles.right_cell_of_grid)},
                  React.createElement("div", {"className": (styles.chart_container)},
                    React.createElement(ChartComponent, { \
                      "container": ("ShiftWaitingTime#{channelType}"),  \
                      "options": (buildOptions @getShiftWaitingTime(channelType))
                    })
                  )
                )
              )
            ),
            React.createElement("button", {  \
              "className": (styles.refresh),   \
              "onClick": (@onRefreshClick)
            }, """
              Refresh
""")
          )
        )
      )
    )
module.exports = WaitingTimeByChannelComponent
