Highcharts = require 'highcharts'
{ NavLink, Route, Switch, Redirect } = require 'react-router-dom'
classnames = require 'classnames'

browserHistory = require './../../../history'
config = require 'root/config'

styles = require './dashboard'
BaseComponent = require 'components/BaseComponent'
ConversationsListWithAverageResponseTimeComponent = require '../ConversationsListWithAverageResponseTimeComponent/ConversationsListWithAverageResponseTimeComponent'
UtcOffsetAPI = require 'api/UtcOffsetAPI'
LoadableWrapperComponent = require 'components/shared/LoadableWrapperComponent/LoadableWrapperComponent'
DashboardTabsStore = require './DashboardTabsStore'

AuthStore = require 'stores/AuthStore'
{ ROLES, ALLOW_ACCESS_ERRORS_ROLES } = require 'constants/Agents'

LastDaysByShiftComponent = require '../DashboardTabs/LastDaysByShiftComponent'
ResponseTimeComponent = require '../DashboardTabs/ResponseTimeComponent'
ConversationEachHourByChannelComponent = require '../DashboardTabs/ConversationEachHourByChannelComponent'
if config.analyticByChannelType
  ResponseTimeComponent = require '../DashboardTabs/ResponseTimeComponent/ResponseTimeByChannelComponent'
WaitingTimeComponent = require '../DashboardTabs/WaitingTimeComponent'
if config.analyticByChannelType
  WaitingTimeComponent = require '../DashboardTabs/WaitingTimeComponent/WaitingTimeByChannelComponent'
ConversationEachHourComponent = require '../DashboardTabs/ConversationEachHourComponent'
ActiveStatusConversationByAgentsComponent = require '../DashboardTabs/ActiveStatusConversationByAgentsComponent'
PercentOfGoodRTAndWTComponent = require '../DashboardTabs/PercentOfGoodRTAndWTComponent'
UniqueClientsByAgentsComponent = require '../DashboardTabs/UniqueClientsByAgentsComponent'
AgentChatsComponent = require '../DashboardTabs/AgentChatsComponent'
SLATrackingComponent = require '../DashboardTabs/SLATrackingComponent'
MessageErrorsComponent = require '../DashboardTabs/MessageErrorsComponent'
ErrorsGlossary = require('../DashboardTabs/MessageErrorsComponent/ErrorsGlossary').default
SLALeadTrackingComponent = require '../DashboardTabs/SLALeadTrackingComponent'

ROUTES_LENGTH_FOR_DASHBOARD_ROOT = 2

class DashboardTabsComponent extends BaseComponent
  constructor: (props) ->
    super(props)
    @state =
      offsetLoaded: false
      offset: 3
      loading: true
    @initComponent()

  getPersonalStoreClass: -> DashboardTabsStore

  componentDidMount: ->
    @getUTCOffset()
    super()

  getUTCOffset: =>
    UtcOffsetAPI.getOffset(
      (success) =>
        @setState success
        @setState offsetLoaded: true
      (error) =>
       console.log(error)
    )

  buildLinkUrl: (path) -> "/dashboard/#{path}"

  buildTabsMap: ->
    tabsMap =
      last_days_by_shift: 'Last Days by Shift'
      response_time: 'Response Time'
      waiting_time: 'Waiting Time'
      conversations_each_hour: 'Conversations Each Hour'
      active_conversations_by_agents: 'Active Conversations by Agents'
      unique_clients_by_agents: 'Unique Clients by Agents'
      agent_chats_statistic: "Agent's Chats Statistic"
    tabsMap.conversations_each_hour_by_channel = 'SMS-Tool Workload' if config.analyticByChannelType
    tabsMap.sla_tracking = 'SLA Tracking statistic' if AuthStore.user.role == ROLES.manager.value
    tabsMap.sla_lead_tracking = 'SLA Lead Tracking statistic' if AuthStore.user.role == ROLES.manager.value
    tabsMap.message_errors = 'Message Errors' if ALLOW_ACCESS_ERRORS_ROLES[AuthStore.user.role]
    tabsMap

  render: ->
    Highcharts.setOptions global:
      useUTC: true
      timezoneOffset: -60 * @state.offset

    React.createElement("div", {"className": styles.container},
      React.createElement("div", {"className": (styles.chart)},
        React.createElement(Switch, null,
          React.createElement(Route, {"path": "/dashboard/last_days_by_shift", "render": (() => React.createElement(LastDaysByShiftComponent, {"now": (@state.now), "offset": (@state.offset)}))}),
          React.createElement(Route, {"path": "/dashboard/response_time", "component": (ResponseTimeComponent)}),
          React.createElement(Route, {"path": "/dashboard/waiting_time", "component": (WaitingTimeComponent)}),
          React.createElement(Route, {"path": "/dashboard/conversations_each_hour", "component": (ConversationEachHourComponent)}),
          React.createElement(Route, {"path": "/dashboard/conversations_each_hour_by_channel", "component": (ConversationEachHourByChannelComponent)}),
          React.createElement(Route, {"path": "/dashboard/active_conversations_by_agents", "component": (ActiveStatusConversationByAgentsComponent)}),
          React.createElement(Route, {"path": "/dashboard/percent_of_good_rt_and_wt", "render": (() => React.createElement(PercentOfGoodRTAndWTComponent, {"now": (@state.now), "offset": (@state.offset)}))}),
          React.createElement(Route, {"path": "/dashboard/unique_clients_by_agents", "component": (UniqueClientsByAgentsComponent)}),
          React.createElement(Route, {"path": "/dashboard/agent_chats_statistic", "component": (AgentChatsComponent)}),
          React.createElement(Route, {"path": "/dashboard/message_errors", "component": (MessageErrorsComponent)}),
          React.createElement(Route, {"path": "/dashboard/message_errors_glossary", "component": (ErrorsGlossary)}),
          React.createElement(Route, {"path": "/dashboard/sla_tracking", "component": (SLATrackingComponent)}),
          React.createElement(Route, {"path": "/dashboard/sla_lead_tracking", "component": (SLALeadTrackingComponent)}),
          React.createElement(Redirect, {"to": "/dashboard/last_days_by_shift"})
        )
      ),

      React.createElement("ul", {"className": (styles.tabs)},
        (
          buildLinkUrl = @buildLinkUrl
          tabsMap = @buildTabsMap()
          Object.keys tabsMap
            .map (key) ->
              isActive = if key == 'message_errors' && location.pathname.match /^\/dashboard\/message_errors/ then true else false;
              React.createElement("li", {"key": (key), "className": (styles.tab)},
                React.createElement(NavLink, { \
                  "className": (classnames(styles.tab_link, { [styles.tab_active]: isActive })),  \
                  "activeClassName": (styles.tab_active),  \
                  "to": (buildLinkUrl key)
                },
                  (tabsMap[key])
                )
              )
        )
      )
    )

module.exports = DashboardTabsComponent
