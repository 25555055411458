classnames = require 'classnames'
ReactPaginate = require 'react-paginate'

{ reactDndContext } = require 'lib/utils'
{ updateItemPositionAndItemsArray } = require 'lib/dnd-utils'
SearchInputComponent = require '../shared/SearchInputComponent/SearchInputComponent'
dndUtils = require 'lib/dnd-utils'
TagsActionCreator = require 'actions/TagsActionCreator'
CRMInfoModuleActionCreator = require('actions/CRMInfoModuleActionCreator').default
paginationStyles = require '../shared/styles/pagination.styl'
BaseComponent = require '../BaseComponent'
HashTagList = require './HashTagList'
HashTagGroup = require './HashTagList/HashTagGroup'
HashTagItem = require './HashTagList/HashTagItem'
TagsStore = require '../../stores/TagsStore'
CustomerOrdersStore = require 'stores/CustomerOrdersStore'
CRMInfoModuleStore = require 'stores/CRMInfoModuleStore'
LoadableWrapperComponent = require '../shared/LoadableWrapperComponent/LoadableWrapperComponent'
conf = require 'root/config'
styles = require './hash-tags'

propTypes = require 'prop-types'

class HashTags extends React.Component
  constructor: (props) ->
    super(props)
    @state =
      selectedGroup: {}
      groups: []
      tagsWithGroup: []
      tagsWithoutGroup: []
      searchQuery: ''

  componentWillReceiveProps: (nextProps) =>
    if !@state.searchQuery
      @setState
        groups: nextProps.groups
        tagsWithGroup: nextProps.tagsWithGroup
        tagsWithoutGroup: nextProps.tagsWithoutGroup

  onGroupSelected: (group) =>
    return if group.id == @state.selectedGroup.id
    @props.onGroupSelected group
    @setState selectedGroup: group

  onTagSelected: (tag) =>
    @props.onTagSelected(tag, @state.selectedGroup)

  backToGroups: (e) =>
    e.nativeEvent.stopImmediatePropagation()
    e.stopPropagation()
    @setState selectedGroup: {}
    @props.onBackToGroups?()

  onTagCreate: =>
    @props.onTagCreate(@state.selectedGroup)

  onPageChange: ({selected}) =>
    @props.onPageChange(selected, @state.selectedGroup)

  swapItems: (items, dragIndex, hoverIndex) =>
    dragItem = items[dragIndex]
    items.splice(dragIndex, 1)
    items.splice(hoverIndex, 0, dragItem)
    return items

  swapGroups: (dragIndex, hoverIndex) =>
    groups = @state.groups;
    items = @swapItems(groups, dragIndex, hoverIndex)

    @setState groups: items

  swapTags: (dragIndex, hoverIndex, tagsType) =>
    tags = @state[tagsType]
    items = @swapItems(tags, dragIndex, hoverIndex)

    @setState "#{tagsType}": items

  # itemType = <tags, groups>
  # dragId - id of element which dropped
  # tagsType = <tagsWithGroup, tagsWithoutGroup>
  updatePosition: (itemType, dragId, tagsType) =>
    if itemType == 'tags'
      tags = @state[tagsType]
      updateTags = updateItemPositionAndItemsArray(tags, dragId)

      TagsActionCreator.updateTag updateTags.item.id, updateTags.item
      @setState "#{tagsType}": updateTags.items
    else
      groups = @state.groups
      updateGroups = updateItemPositionAndItemsArray(groups, dragId)

      TagsActionCreator.updateGroup updateGroups.item
      @setState groups: updateGroups.items

  generateEditableControls: ->
    addNew = []
    unless @state.selectedGroup.id
      addNew.push(
        React.createElement("div", {"key": (1), "className": (styles.new), "onClick": (@props.onGroupCreate)}, """
          + add new group
""")
      )

    addNew.push(
      React.createElement("div", {"key": (2), "className": (styles.new), "onClick": (@onTagCreate)}, """
        + add new tag
""")
    )
    addNew

  onSearch: (search) =>
    if search && search.length > 2
      val = @props.tagsWithGroup
        .filter (o) ->
          o.name.toLowerCase().indexOf(search.toLowerCase()) > -1
    else
      @setState tagsWithGroup: @props.tagsWithGroup

    if val
      val.map (o) ->
        parts = o.name.split(new RegExp("(#{search})", 'gi'))
      @setState tagsWithGroup: val
    @setState searchQuery: search

  render: ->
    containerStyle = classnames(
      styles.container,
      "#{styles.container_view}": !@props.editable
    )
    React.createElement("div", {"className": (containerStyle)},
      (if @props.onSearch && !@state.selectedGroup.id
        React.createElement(SearchInputComponent, { \
          "onChange": (@props.onSearch),  \
          "onClear": (@props.onSearch),  \
          "placeholder": 'Search tags by keywords',  \
          "className": (styles.search_tags),  \
          "defaultValue": (@props.searchQuery),  \
          "clearCross": (true),  \
          "autofocus": true
        })
      ),
      (if @state.selectedGroup.id
        React.createElement(SearchInputComponent, { \
          "onChange": (@onSearch),  \
          "onClear": (@onSearch),  \
          "placeholder": 'Search by orders',  \
          "className": (styles.search_tags),  \
          "defaultValue": (@state.searchQuery),  \
          "clearCross": (true),  \
          "autofocus": true
        })
      ),
      (if @state.selectedGroup.id
        React.createElement("div", null,
          React.createElement("div", {"className": (styles.back), "onClick": (@backToGroups)},
            React.createElement("i", {"className": (styles.arrow)}),
            React.createElement("span", {"className": (styles.back_maintitle)},
              (@state.selectedGroup.name)
            ),
            React.createElement("span", {"className": (styles.back_subtitle)}, "back")
          ),
          React.createElement(HashTagList, { \
            "groups": ([]),  \
            "tags": (@state.tagsWithGroup),  \
            "isReadyToDrag": (@props.isReadyToDrag),  \
            "loading": (@props.tagsLoading),  \
            "onTagSelected": (@onTagSelected),  \
            "onGroupSelected": (@onGroupSelected),  \
            "editable": (@props.editable),  \
            "isConversationTags": (@props.isConversationTags),  \
            "onTagSave": (@props.onTagSave),  \
            "onTagDelete": (@props.onTagDelete),  \
            "tagTargetLoading": (@props.tagTargetLoading),  \
            "swapItems": ((dragIndex, hoverIndex) => @swapTags(dragIndex, hoverIndex, 'tagsWithGroup')),  \
            "updatePosition": ((itemType, dragId) => @updatePosition(itemType, dragId, 'tagsWithGroup')),  \
            "searchQuery": (@state.searchQuery)
          })
        )
      else
        React.createElement(HashTagList, { \
          "groups": (@state.groups),  \
          "tags": (@state.tagsWithoutGroup),  \
          "isReadyToDrag": (@props.isReadyToDrag),  \
          "loading": (@props.groupsLoading),  \
          "onTagSelected": (@onTagSelected),  \
          "onGroupSelected": (@onGroupSelected),  \
          "editable": (@props.editable),  \
          "isConversationTags": (@props.isConversationTags),  \
          "onTagSave": (@props.onTagSave),  \
          "onGroupSave": (@props.onGroupSave),  \
          "onGroupDelete": (@props.onGroupDelete),  \
          "onTagDelete": (@props.onTagDelete),  \
          "tagTargetLoading": (@props.tagTargetLoading),  \
          "swapGroups": (@swapGroups),  \
          "swapItems": ((dragIndex, hoverIndex) => @swapTags(dragIndex, hoverIndex, 'tagsWithoutGroup')),  \
          "updatePosition": ((itemType, dragId) => @updatePosition(itemType, dragId, 'tagsWithoutGroup')),  \
          "searchQuery": (@props.searchQuery)
        })
      ),
      (if @props.pageCount > 1 && !@state.selectedGroup.id
        React.createElement("div", {"key": (1), "className": (styles.position_center), "data-me": "data-me"},
          React.createElement(ReactPaginate.default, { \
            "previousLabel": '<',  \
            "nextLabel": '>',  \
            "pageCount": (@props.pageCount),  \
            "marginPagesDisplayed": (1),  \
            "pageRangeDisplayed": (1),  \
            "onPageChange": (@onPageChange),  \
            "initialPage": (@props.page),  \
            "pageClassName": (paginationStyles.page),  \
            "nextClassName": (paginationStyles.page),  \
            "previousClassName": (paginationStyles.page),  \
            "activeClassName": (paginationStyles.active),  \
            "pageLinkClassName": (paginationStyles.link),  \
            "nextLinkClassName": (paginationStyles.link),  \
            "previousLinkClassName": (paginationStyles.link),  \
            "containerClassName": (paginationStyles.container),  \
            "breakClassName": (paginationStyles.page)
          })
        )
      ),
      ( @generateEditableControls() if @props.editable )
    )


HashTags.propTypes =
  groups: propTypes.arrayOf(
    propTypes.shape(
      id: propTypes.number.isRequired
      name: propTypes.string.isRequired
      contains_checked: propTypes.bool
    ).isRequired
  ).isRequired
  tagsWithoutGroup: propTypes.arrayOf(
    propTypes.shape(
      id: propTypes.number
      name: propTypes.string.isRequired
      checked: propTypes.bool
      tags_group_id: propTypes.number
    ).isRequired
  ).isRequired
  tagsWithGroup: propTypes.arrayOf(
    propTypes.shape(
      id: propTypes.number
      name: propTypes.string.isRequired
      checked: propTypes.bool
      tags_group_id: propTypes.number
    ).isRequired
  ).isRequired
  isReadyToDrag: propTypes.bool
  groupsLoading: propTypes.bool.isRequired
  groupsError: propTypes.string
  tagsLoading: propTypes.bool.isRequired
  tagsError: propTypes.string
  onGroupSelected: propTypes.func.isRequired
  onTagSelected: propTypes.func.isRequired
  editable: propTypes.bool
  onGroupCreate: propTypes.func
  onTagCreate: propTypes.func
  onGroupSave: propTypes.func
  onTagSave: propTypes.func
  onGroupDelete: propTypes.func
  onTagDelete: propTypes.func
  page: propTypes.number
  pageCount: propTypes.number
  onPageChange: propTypes.func
  onBackToGroups: propTypes.func
  tagTargetLoading: propTypes.object

module.exports = reactDndContext(HashTags)
