moment = require 'moment'

AbstractStatisticComponent = require '../AbstractStatisticComponent'
{ formatNumberToPercent } = require 'lib/utils'
styles = require 'components/shared/BaseAgentStatisticComponent/base-agent-statistic'
AgentStatisticActionCreator = require 'actions/AgentStatisticActionCreator'
{ SHIFT_ROLES, TL_SHIFT_GRADES, TL_SHIFT_ROLES } = require 'constants/Agents'
{ TL_SHIFT_DELETED_ROLES } = require 'constants/Agents'
CustomSelectComponent = require 'components/shared/CustomSelectComponent'
AuthStore = require 'stores/AuthStore'

rolesWithoutDuplications = {}
Object.keys SHIFT_ROLES
  .forEach (key) ->
    unless (rolesWithoutDuplications[SHIFT_ROLES[key].value] || {}).active
      rolesWithoutDuplications[SHIFT_ROLES[key].value] = SHIFT_ROLES[key]

class TLShiftsStatisticComponent extends AbstractStatisticComponent

  constructor: (props) ->
    super(props)
    that = @
    @tableFields =
      date:
        label: 'Shift Date'
        presenter: (value, shift) ->
          moment(value, "YYYY-MM-DD").format 'DD MMM YYYY'
        handlers: {}
        isSortable: true
      shift_type:
        label: 'Shift Type'
        presenter: (value, shift) -> value
        handlers: {}
        isSortable: true
      login_time:
        label: 'Login Time'
        presenter: (value, shift) ->
          if TL_SHIFT_DELETED_ROLES[shift.role]
            return '-'
          moment(value, "YYYY-MM-DDTHH:mm").format 'HH:mm'
        handlers: {}
        isSortable: true
      logout_time:
        label: 'Logout Time'
        presenter: (value, shift) ->
          if TL_SHIFT_DELETED_ROLES[shift.role]
            return '-'
          moment(value, "YYYY-MM-DDTHH:mm").format 'HH:mm'
        handlers: {}
        isSortable: true
      response_time:
        label: 'Response Time'
        presenter: (value, shift) ->
          if TL_SHIFT_DELETED_ROLES[shift.role]
            return '-'
          value
        handlers: {}
        isSortable: false
      waiting_time:
        label: 'Wait Time'
        presenter: (value, shift) ->
          if TL_SHIFT_DELETED_ROLES[shift.role]
            return '-'
          value
        handlers: {}
        isSortable: false
      csr_response_time:
        label: 'Response Time (CSR)'
        presenter: (value, shift) ->
          if TL_SHIFT_DELETED_ROLES[shift.role]
            return '-'
          value
        handlers: {}
        isSortable: false
      csr_waiting_time:
        label: 'Wait Time (CSR)'
        presenter: (value, shift) ->
          if TL_SHIFT_DELETED_ROLES[shift.role]
            return '-'
          value
        handlers: {}
        isSortable: false
      sales_response_time:
        label: 'Response Time (Sales)'
        presenter: (value, shift) ->
          if TL_SHIFT_DELETED_ROLES[shift.role]
            return '-'
          value
        handlers: {}
        isSortable: false
      sales_waiting_time:
        label: 'Wait Time (Sales)'
        presenter: (value, shift) ->
          if TL_SHIFT_DELETED_ROLES[shift.role]
            return '-'
          value
        handlers: {}
        isSortable: false
      good_response_time:
        label: '% of Chats With Good RT'
        presenter: (value, shift) ->
          if TL_SHIFT_DELETED_ROLES[shift.role]
            return '-'
          formatNumberToPercent value
        handlers: {}
        isSortable: false
      good_waiting_time:
        label: '% of Chats With Good WT'
        presenter: (value, shift) ->
          if TL_SHIFT_DELETED_ROLES[shift.role]
            return '-'
          formatNumberToPercent value
        handlers: {}
        isSortable: false
      shift_grade:
        label: 'Grade'
        presenter: (value, shift) =>
          if TL_SHIFT_DELETED_ROLES[shift.role]
            return '-'
          indexOfRole = @accessConfig.accessToEditShiftData
            .indexOf((SHIFT_ROLES[(AuthStore.user || {}).role] || {}).value)
          if indexOfRole != -1
            React.createElement(CustomSelectComponent, {  \
              "key": (shift.id),  \
              "defaultValue": (TL_SHIFT_GRADES[value || 'pending'].value),  \
              "options": (TL_SHIFT_GRADES),  \
              "onOptionSelect": (that.onChangeGradeHandler.bind(that, shift)),  \
              "customSelectStyle": (styles.select),  \
              "customOptionsStyle": (styles.select__options)
            })
          else
            TL_SHIFT_GRADES[value || 'pending'].label
        handlers: {}
        isSortable: false
        addBodyCss: (value) ->
          styles["color-#{value}"]
      role:
        label: 'Role'
        presenter: (value, shift) =>
          indexOfRole = @accessConfig.accessToEditTLShiftData
            .indexOf((SHIFT_ROLES[(AuthStore.user || {}).role] || {}).value)
          if indexOfRole != -1
            React.createElement(CustomSelectComponent, { \
              "key": (shift.id),  \
              "defaultValue": (TL_SHIFT_ROLES[value]?.value),  \
              "options": (TL_SHIFT_ROLES),  \
              "onOptionSelect": (that.onChangeRoleHandler.bind(that, shift)),  \
              "customSelectStyle": (styles.select),  \
              "customOptionsStyle": (styles.select__options)
            })
          else
            value
        handlers: {}
        isSortable: false
    @type = 'shifts'
    @initComponent()

  onChangeGradeHandler: (shift, value) ->
    unless shift.shift_grade == value
      AgentStatisticActionCreator.changeShiftStatistic(
        shift.user_id, shift.id, 'bonus_state', value, 'shift_grade'
      )

  onChangeRoleHandler: (shift, value) ->
    unless shift.shift_ole == value
      AgentStatisticActionCreator.changeShiftStatistic(
        shift.user_id, shift.id, 'shift_role', value, 'role'
      )

module.exports = TLShiftsStatisticComponent
