RankingRulesAPI = require 'api/RankingRulesAPI'
Dispatcher = require '../dispatcher/Dispatcher'
ActionTypes = require 'constants/ActionTypes'

RankingRulesActionCreator =

getRules: (params) ->
  Dispatcher.dispatch
    type: ActionTypes.RANKING_RULES_REQUESTING

  RankingRulesAPI.getRules(
    params
    (json) =>
      Dispatcher.dispatch
        type: ActionTypes.GET_RANKING_RULES_SUCCESS
        payload: json
    (response) =>
      msg = 'An error occurred while getting Message Errors'
      Dispatcher.dispatch
        type: ActionTypes.GET_RANKING_RULES_FAILURE
        payload: msg
  )

updateRule: (id, params) =>
  Dispatcher.dispatch type: ActionTypes.RANKING_RULES_REQUESTING

  RankingRulesAPI.updateRule id, params,
    (json) =>
      Dispatcher.dispatch
        type: ActionTypes.UPDATE_RANKING_RULES_SUCCESS,
        payload: json
    (response) =>
      Dispatcher.dispatch
        type: ActionTypes.UPDATE_RANKING_RULES_FAILURE
        payload: response.error
        
deleteRule: (id) =>
  Dispatcher.dispatch type: ActionTypes.RANKING_RULES_REQUESTING

  RankingRulesAPI.deleteRule id,
    (json) =>
      Dispatcher.dispatch
        type: ActionTypes.DELETE_RANKING_RULES_SUCCESS
        payload: id
    (response) =>
      Dispatcher.dispatch
        type: ActionTypes.DELETE_RANKING_RULES_FAILURE
        payload: response.error

setCurrentConversation: (conversation) ->
  Dispatcher.dispatch type: ActionTypes.RANKING_RULES_OPEN_CONVERSATION, payload: conversation

getConversationRankedInfo: (id) ->
  Dispatcher.dispatch type: ActionTypes.RANKING_RULES_CONVERSATIONS_REQUESTING

  RankingRulesAPI.getConversationRankingReports id,
    (json) =>
      Dispatcher.dispatch
        type: ActionTypes.RANKING_RULES_CONVERSATIONS_SUCCESS
        payload: {id, ...json}
    (response) =>
      Dispatcher.dispatch
        type: ActionTypes.RANKING_RULES_CONVERSATIONS_FAILURE
        payload: response.error

removeConversationRankedMessageReport: (messageId, messageType, reportId) ->
  Dispatcher.dispatch
    type: ActionTypes.RANKING_CONVERSATIONS_MESSAGE_DELETE_REPORT
    payload: { messageId, messageType, reportId }

removeConversationRankedMessageReports: (messageId, messageType) ->
  Dispatcher.dispatch
    type: ActionTypes.RANKING_CONVERSATIONS_MESSAGE_DELETE_REPORTS
    payload: { messageId, messageType }

createConversationRankedMessageReports: (messageId, messageType, reports) ->
  Dispatcher.dispatch
    type: ActionTypes.RANKING_CONVERSATIONS_MESSAGE_ADD_REPORTS
    payload: { messageId, messageType, reports }

selectRankedMessage: (message, text = '') ->
  Dispatcher.dispatch
    type: ActionTypes.RANKING_RULES_SELECT_MESSAGE,
    payload: {
      message,
      text
    }

closeAllConversation: -> Dispatcher.dispatch type: ActionTypes.RANKING_RULES_CLOSE_ALL_CONVERSATION

module.exports = RankingRulesActionCreator
